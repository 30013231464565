<template>
    <div>
        <v-dialog v-model="dialog_pendingTransactions" no-click-animation persistent scrollable content-class="half-screen-dialog" lazy transition="dialog-bottom-transition" :max-width="$root.fn_getActionPaneWidth()">
            <v-card flat>

                <v-toolbar flat fixed>
                  <v-btn icon @click="closeDialogWeb"><v-icon>close</v-icon></v-btn>
                  <v-toolbar-title>{{type != 'estimates' ? formType : ''}} {{type == 'estimates' ? 'Estimate(s)' : type == 'orders' ? 'Order(s)' : type == 'challans' ? 'Challan(s)' : type == 'invoices' ? 'Invoice(s)' : ''}}</v-toolbar-title>
                  <!-- <v-spacer></v-spacer>
                  <v-btn outlined color="primary" @click="fn_getPendingItems">Done</v-btn> -->

                  <!-- <v-text-field  flat solo placeholder="Search..." v-model="searchValue" slot="extension" ></v-text-field> -->

                  <v-text-field grey-back clearable flat solo hide-details placeholder="Search..." v-model="searchValue" slot="extension"></v-text-field>
                </v-toolbar>

                <v-card-text full-height nopadding>

                    <v-list two-line nopadding>
                        <div v-for="(item, index) in filteredItems" :key="index">
                            <v-list-item list-checkbox @click="fn_changeSelection(item)">

                                <v-list-item-content>
                                    <v-list-item-title>{{item.mergeTransactionNumber}}</v-list-item-title>
                                    <v-list-item-subtitle>{{item.transactionDate | moment("DD-MMM-YYYY")}}</v-list-item-subtitle>
                                </v-list-item-content>

                                <v-list-item-action>
                                    <v-checkbox v-model="item.selected" @change="fn_changeSelection(item)"></v-checkbox>
                                </v-list-item-action>

                            </v-list-item>

                            <!-- <v-divider v-if="filteredItems.length-1 != index"></v-divider> -->
                        </div>

                    </v-list>
                        


                    <div class="center-align pa-4" v-if="!filteredItems.length">No Items</div>
                </v-card-text>

                <v-card-actions>
                    <v-layout row wrap ma-0>
                        <v-flex height48 xs12 sm12 md12>
                            <v-btn color="primary" full-height nomargin block no-round-borders noshadow @click="fn_getPendingItems">Done</v-btn>
                        </v-flex>
                    </v-layout>
                </v-card-actions>
            </v-card>


        </v-dialog>
    </div>
</template>

<script>
    export default{
        props:['items', 'type', 'getPendingItems', 'formType'],
        data(){
            return{
                ordersList:[],
                dialog_pendingTransactions:false,
                searchValue:'',
                selected:false
            }
        },
        methods:{
            openDialogWeb: function () {
                this.searchValue = '';
                this.dialog_pendingTransactions = true;
            },
            closeDialogWeb: function () {
                this.dialog_pendingTransactions = false;
            },
            fn_getPendingItems: function () {

                if(!this.filteredItems.length){
                    this.$parent.fn_closeDialog();
                    return;
                }

                let transactionIds = [];
                this.filteredItems.forEach(function (item) {
                    item.selected ? transactionIds.push(item.transactionId) : '';
                });
                console.log(transactionIds);

                if(transactionIds.length){
                    this.getPendingItems(transactionIds, this.type);
                }
                else{
                    // alert('Select transaction');
                    this.$eventHub.$emit('snackbarAlert', {msg:'Select Transaction'});
                }
            },
            fn_changeSelection: function (item) {

                let self = this;
                if(this.type=='invoices'){
                    this.items.forEach(function(invoiceItem){
                        if(item.mergeTransactionNumber != invoiceItem.mergeTransactionNumber){
                            invoiceItem.selected = false;
                            self.$forceUpdate();
                        }
                        else{
                            invoiceItem.selected = !invoiceItem.selected;
                            self.$forceUpdate();
                        }
                    })
                }
                else{
                    item.selected = !item.selected;
                    this.$forceUpdate();
                }
                
            }
        },
        computed:{
            filteredItems: function () {
                let searchText = this.searchValue ? this.searchValue.toLowerCase() : '';

                if(this.items && this.items.length){

                    return this.items.filter(item => {
                      let keyword = searchText.toLowerCase();
                      let transactionNumberString = item.mergeTransactionNumber.toLowerCase();
                      return transactionNumberString.match(keyword);
                    });
                    
                }
                else{
                    return [];
                }


            }
                
        }
    }
</script>