// var dbRef = null;

export const dataTransaction = new Object({

    fn_checkIsDBExist: async function(self, callback){
        let isExist = (await window.indexedDB.databases()).map(db => db.name).includes("idb_"+self.CurrentBusiness.BusinessID);
        callback(isExist);
    },

    fn_getMasterItemsFromDB: async function(self, type, listNames, isGetAll, filterObject){

        console.log('fn_getMasterItemsFromDB');

        const isExisting = (await window.indexedDB.databases()).map(db => db.name).includes("idb_"+self.CurrentBusiness.BusinessID);
        if(isExisting){
            let dbRef = window.indexedDB.open("idb_"+self.CurrentBusiness.BusinessID, 2);

            dbRef.onsuccess = (event) => {
                console.log('existing data');
                console.log(event);
                let db = event.target.result;
                let dbRequest = null;
                try{
                    dbRequest = db.transaction(type).objectStore(type).getAll();
                }
                catch(e){
                    console.log(e);
                    // localStorage.removeItem(self.CurrentBusiness.BusinessID+'_ls_item_counter');
                    let dataObj = {};
                    dataObj.LocalKey = self.CurrentBusiness.BusinessID+'_ls_item_counter';
                    dataObj.LocalValue = '-1';
                    self.fn_saveLocalItem(dataObj);
                    this.fn_getMasterData(self, type, listNames, isGetAll, filterObject);
                }
                
                if(dbRequest){
                    dbRequest.onsuccess = (event)=> {
                        console.log(event.target.result);
                        // callback(event.target.result);
                        if(event.target.result && event.target.result.length){
                            if(type == 'products'){
                                self.products = event.target.result;
                                self.products.sort((a, b) => a.itemName > b.itemName ? 1 : -1);
                            }
                            else if(type == 'ledgers'){
                                self.ledgers = event.target.result;
                                self.ledgers.sort((a, b) => a.itemName > b.itemName ? 1 : -1);
                            }
                        }
                        // callback ? callback() : '';
                        this.fn_getMasterData(self, type, listNames, isGetAll, filterObject);
                    };

                    dbRequest.onerror = (error)=> {
                        console.log(error);
                    }

                    // closing database
                    db.close();
                }
                
                // Do something with request.result!
            };

            dbRef.onerror = (error)=>{
                console.log(error);
            }
        }
        else{
            // Creating database if not exist
            let dbRef = window.indexedDB.open("idb_"+self.CurrentBusiness.BusinessID, 2);

            // This event is only implemented in recent browsers
            dbRef.onupgradeneeded = (event) => {
                // Save the IDBDatabase interface
                let db = event.target.result;
                // Create an objectStore for this database
                let objectStore = db.createObjectStore("products", { keyPath: "itemId" });
                objectStore.createIndex("nameIndex", "itemName", {unique:true});
                objectStore.createIndex("codeIndex", "productCode", {unique:false});

                let ledgerObjectStore = db.createObjectStore("ledgers", { keyPath: "itemId" });
                ledgerObjectStore.createIndex("nameIndex", "itemName", {unique:true});
            };

            dbRef.onsuccess = (event) => {
                console.log(event);

                let db = event.target.result;
                // calling sync list
                this.fn_getMasterData(self, type, listNames, isGetAll, filterObject);

                // closing database
                db.close();
            }

            dbRef.onerror = (error)=>{
                console.log(error);
            }
        }
        
    },

    fn_getMasterData: function(self, type, listNames, isGetAll, filterObject){
        let scopeRef = this;
        let localKey = null;
        if(type == 'products'){
            localKey = self.CurrentBusiness.BusinessID+'_ls_item_counter';
        }
        else if(type == 'ledgers'){
            localKey = self.CurrentBusiness.BusinessID+'_ls_ledger_counter';
        }

        console.log('fn_getMasterData');
        
        self.fn_getLocalItem(localKey, response=>{
            let counterData = JSON.parse(response);
            if(counterData === null){
                scopeRef.fn_checkIsDBExist(self, dbExist=>{
                    if(dbExist){
                        scopeRef.fn_removeIndexedDB(self, type, listNames, isGetAll, filterObject);
                    }
                    else{
                        scopeRef.fn_getSyncItems(self, type, listNames, isGetAll, filterObject);
                    }
                })
            }
            else{
                filterObject.counter = counterData.LocalValue == -1 ? 0 : Number(counterData.LocalValue);

                if(!localStorage.getItem(localKey)){
                    filterObject.counter = 0;
                }
                
                scopeRef.fn_getSyncItems(self, type, listNames, isGetAll, filterObject);
            }
        })  
    },

    fn_getSyncItems: function(self, type, listNames, isGetAll, filterObject){

        console.log('fn_getSyncItems');
        let scopeRef = this;
        self.getFilterData('SyncList', self.CurrentBusiness.BusinessID, filterObject, null, {
            onSuccess: function(response){
                console.log(response);
                console.log('SyncList');
                // callback ? callback() : ''
                let data = JSON.parse(response);

                if(type=='products'){
                    scopeRef.fn_processItems(self, type, listNames, isGetAll, filterObject, data)
                }
                else if(type=='ledgers'){
                    scopeRef.fn_processLedgers(self, type, listNames, isGetAll, filterObject, data)
                }
                
            }, 
            onError: function(error){
                console.log(error);
                // scopeRef.fn_removeIndexedDB(self, type, listNames, isGetAll);
            }
        });
    },

    fn_removeIndexedDB: function(self, type, listNames, isGetAll, filterObject){
        // let dbRef = window.indexedDB.deleteDatabase("idb_"+self.CurrentBusiness.BusinessID);
        let dbRef = window.indexedDB.open("idb_"+self.CurrentBusiness.BusinessID, 2);

        dbRef.onerror = (event) => {
            console.error("Error opening database.");  
            console.log(event.result);
        };
          
        dbRef.onsuccess = (event) => {
            
            console.log(event.result); // should be undefined
            let db = event.target.result;
            if(type == 'products'){
                // let db = event.target.result;
                try{
                    let productObjectStore = db.transaction("products", "readwrite",).objectStore("products");
                    productObjectStore.clear();
                }
                catch(e){
                    console.log(e);
                }
                
                
                self.products = [];
                // localStorage.removeItem(self.CurrentBusiness.BusinessID+"_ls_item_counter");
                let dataObj = {};
                dataObj.LocalKey = self.CurrentBusiness.BusinessID+'_ls_item_counter';
                dataObj.LocalValue = '-1';
                self.fn_saveLocalItem(dataObj, ()=>{
                    this.fn_getMasterData(self, type, listNames, isGetAll, filterObject);
                });
                // self.fn_getMasterItems(type, listNames, isGetAll);
            }

            if(type == 'ledgers'){
                // let db = event.target.result;
                try{
                    let ledgerObjectStore = db.transaction("ledgers", "readwrite",).objectStore("ledgers");
                    ledgerObjectStore.clear();
                }
                catch(e){
                    console.log(e);
                }
                
                self.ledgers = [];
                // localStorage.removeItem(self.CurrentBusiness.BusinessID+"_ls_ledger_counter");
                let dataObj = {};
                dataObj.LocalKey = self.CurrentBusiness.BusinessID+'_ls_ledger_counter';
                dataObj.LocalValue = '-1';
                self.fn_saveLocalItem(dataObj);
                // self.fn_getMasterItems(type, listNames);
                this.fn_getMasterData(self, type, listNames, isGetAll, filterObject);
            }

            // closing database
            db.close();
        };

        dbRef.onblocked = function () {
            console.log("Couldn't delete database due to the operation being blocked");
        };
    },

    fn_processItems: function(self, type, listNames, isGetAll, filterObject, data){
        let scopeRef = this;
        if(data && data.length){
            let newItems = [];
            let oldItems = [];
            let itemsToDelete = [];
            for(let i=0; i<data.length; i++){
                let item = JSON.parse(data[i].data);
                if(data[i].objectName == "Product" && data[i].methodName == "postData" && item){
                    let obj = {};
                    obj.isSystem = item.isSystem;
                    obj.itemId = item.productId;
                    obj.itemName = item.name;
                    obj.productCode = item.productCode;
                    obj.groupTypeId = item.groupTypeId;
                    obj.inventory = item.inventory;
                    obj.logId = data[i].logId;

                    let itemIndex = -1;

                    if(self.products.length){
                        itemIndex = self.products.findIndex(product=>product.itemId == item.productId);
                    }
                    
                    if(itemIndex != -1){
                        // update here
                        oldItems.push(obj);
                    }
                    else{
                        newItems.push(obj);
                    }
                }
                else if(data[i].objectName == "deleteProductWithOpening"){
                    itemsToDelete.push(data[i].objectId);
                }
            }

            let dbRef = window.indexedDB.open("idb_"+self.CurrentBusiness.BusinessID, 2);

            dbRef.onerror = (error)=>{
                console.log(error);
            }

            dbRef.onsuccess = (event) => {
                // Do something with request.result!
                let db = event.target.result;

                if(!db.objectStoreNames.contains("products")){
                    // closing database
                    db.close();
                    this.fn_deleteDatabase(self)
                }
                else{
                    if(newItems.length){
                        let productObjectStore = db.transaction("products", "readwrite",).objectStore("products");
                        newItems.forEach((item, index)=>{
                            let storeRef = productObjectStore.add(item);
    
                            storeRef.onsuccess = function(event) {
                                self.products.push(item);
                                console.log(event + ' Record' +self.products.length+ 'added successfully.');
                                if(index == newItems.length - 1){
                                    scopeRef.fn_setProductCounter(self, data, isGetAll);
                                }
                            };
    
                            storeRef.onerror = (error)=>{
                                console.log(error);
                                console.log(item.name + ' ' + index + ' not added');
                                if(index == newItems.length - 1){
                                    scopeRef.fn_setProductCounter(self, data, isGetAll);
                                }
                            }
                            
                            // productObjectStore.add(item)
                            // self.products.push(item);
                        })
                    }
                    else if(oldItems.length){
                        let productObjectStore = db.transaction("products", "readwrite",).objectStore("products");
                        oldItems.forEach((item, index)=>{
    
                            let storeRef = productObjectStore.put(item);
    
                            storeRef.onsuccess = function(event) {
                                console.log(event + ' Record' +self.products.length+ 'updated successfully.');
                                let itemIndex = self.products.findIndex(product=>product.itemId == item.itemId);
                                if(itemIndex != -1){
                                    self.products[itemIndex] = item;
                                }
    
                                if(index == oldItems.length - 1){
                                    scopeRef.fn_setProductCounter(self, data, isGetAll);
                                }
                            };
    
                            storeRef.onerror = (error)=>{
                                console.log(error);
                                console.log(item.name + ' ' + index + ' not updated');
                                if(index == oldItems.length - 1){
                                    scopeRef.fn_setProductCounter(self, data, isGetAll);
                                }
                            }
    
                            // productObjectStore.put(item);
                            // let itemIndex = self.products.findIndex(product=>product.itemId == item.itemId);
                            // if(itemIndex != -1){
                            //     self.products[itemIndex] = item;
                            // }
                        })
                    }
                    else if(itemsToDelete.length){
                        let productObjectStore = db.transaction("products", "readwrite",).objectStore("products");
                        itemsToDelete.forEach((itemId, index)=>{
    
                            let storeRef = productObjectStore.delete(itemId);
    
                            storeRef.onsuccess = function(event) {
                                console.log(event + ' Record' +self.products.length+ 'updated successfully.');
                                let itemIndex = self.products.findIndex(product=>product.itemId == itemId);
                                if(itemIndex != -1){
                                    self.products.splice(itemIndex, 1);
                                }
    
                                if(index == itemsToDelete.length - 1){
                                    scopeRef.fn_setProductCounter(self, data, isGetAll);
                                }
                            };
    
                            storeRef.onerror = (error)=>{
                                console.log(error);
                                console.log(itemId + ' ' + index + ' not deleted');
                                if(index == itemsToDelete.length - 1){
                                    scopeRef.fn_setProductCounter(self, data, isGetAll);
                                }
                            }
    
                            // productObjectStore.delete(itemId);
                            // console.log(self.products);
                            // let itemIndex = self.products.findIndex(product=>product.itemId == itemId);
                            // if(itemIndex != -1){
                            //     self.products.splice(itemIndex, 1);
                            // }
                        })
                    }
                    else{
                        scopeRef.fn_setProductCounter(self, data, isGetAll);
                    }
                }

                // closing database
                db.close();
                
            };
        }
        else{
            if(isGetAll){
                self.fn_getMasterItems('ledgers', ['Ledger', 'deleteAccountWithOpening']);
            }
        }
    },

    fn_deleteDatabase: function(self){
        // deleting database
        let DBDeleteRequest = window.indexedDB.deleteDatabase("idb_"+self.CurrentBusiness.BusinessID);

        DBDeleteRequest.onerror = (event) => {
            console.log(event);
            console.error("Error deleting database.");
        };
        
        DBDeleteRequest.onsuccess = (event) => {
            console.log("Database deleted successfully");
            console.log(event.result); // should be undefined
            self.products = [];
            self.ledgers = [];
            localStorage.removeItem(self.CurrentBusiness.BusinessID+'_ls_item_counter');
            localStorage.removeItem(self.CurrentBusiness.BusinessID+'_ls_ledger_counter');
            self.fn_getMasterItems('products', ["Product", "deleteProductWithOpening"], true, true); 
        };
    },

    fn_setProductCounter: function(self, data, isGetAll){
        if(data?.length){
            let localObj = {counter:data[data.length-1].logId};
            localStorage.setItem(self.CurrentBusiness.BusinessID+'_ls_item_counter', JSON.stringify(localObj));

            let dataObj = {};
            dataObj.LocalKey = self.CurrentBusiness.BusinessID+'_ls_item_counter';
            dataObj.LocalValue = data[data.length-1].logId.toString();
            self.fn_saveLocalItem(dataObj);
            self.products.sort((a, b) => a.itemName > b.itemName ? 1 : -1);
        }

        if(isGetAll){
            self.fn_getMasterItems('ledgers', ['Ledger', 'deleteAccountWithOpening']);
        }
        else{
            self.dialogLoader ? self.dialogLoader = false : '';
        }
    },

    fn_processLedgers: function(self, type, listNames, isGetAll, filterObject, data){

         let newItems = [];
         let oldItems = [];
         let itemsToDelete = [];
         let scopeRef = this;

        for(let i=0; i<data.length; i++){
            let item = JSON.parse(data[i].data);
            // if(data[i].objectName == "Ledger" && data[i].methodName == "postData" && data[i].logId != 0){
            if(data[i].objectName == "Ledger" && data[i].methodName == "postData" && item){
                let obj = {};
                obj.isSystem = item.isSystem;
                obj.itemId = item.accountId;
                obj.itemName = item.name;
                obj.groupTypeId = item.groupTypeId;
                obj.accountGroupId = item.accountGroupId;
                obj.gstType = item.gstType;
                obj.logId = data[i].logId;
                let itemIndex = self.ledgers.findIndex(ledger=>ledger.itemId == item.accountId);
                // itemIndex != -1 ? self.ledgers[itemIndex] = obj : self.ledgers.push(obj);
                itemIndex != -1 ? oldItems.push(obj) : newItems.push(obj);
            }
            else if(data[i].objectName == "deleteAccountWithOpening"){
                let itemIndex = self.ledgers.findIndex(ledger=>ledger.itemId == data[i].objectId);
                itemIndex != -1 ? itemsToDelete.push(data[i].objectId) : '';
            }
        }

        //  if(!self.ledgers.length){
        //      for(let i=0; i<data.length; i++){
        //          if(data[i].data){
        //              if(data[i].logId == 0){
        //                  let defaultLedgers = JSON.parse(data[i].data);
        //                  for(let j=0; j<defaultLedgers.length; j++){
        //                      let obj = {};
        //                      obj.isSystem = defaultLedgers[j].isSystem;
        //                      obj.itemId = defaultLedgers[j].accountId;
        //                      obj.itemName = defaultLedgers[j].name;
        //                      obj.groupTypeId = defaultLedgers[j].groupTypeId;
        //                      obj.accountGroupId = defaultLedgers[j].accountGroupId;
        //                      obj.gstType = defaultLedgers[j].gstType;
        //                      obj.logId = data[i].logId;
        //                      newItems.push(obj);
        //                  }
        //              }
        //              else{
        //                  let item = JSON.parse(data[i].data);
        //                  let obj = {};
        //                  obj.isSystem = item.isSystem;
        //                  obj.itemId = item.accountId;
        //                  obj.itemName = item.name;
        //                  obj.groupTypeId = item.groupTypeId;
        //                  obj.accountGroupId = item.accountGroupId;
        //                  obj.gstType = item.gstType;
        //                  obj.logId = data[i].logId;
        //                  newItems.push(obj);
        //              }
        //          }
        //      }
        //  }
        //  else{
        //      for(let i=0; i<data.length; i++){
        //          let item = JSON.parse(data[i].data);
        //          if(data[i].objectName == "Ledger" && data[i].methodName == "postData" && data[i].logId != 0){
        //              let obj = {};
        //              obj.isSystem = item.isSystem;
        //              obj.itemId = item.accountId;
        //              obj.itemName = item.name;
        //              obj.groupTypeId = item.groupTypeId;
        //              obj.accountGroupId = item.accountGroupId;
        //              obj.gstType = item.gstType;
        //              obj.logId = data[i].logId;
        //              let itemIndex = self.ledgers.findIndex(ledger=>ledger.itemId == item.accountId);
        //              // itemIndex != -1 ? self.ledgers[itemIndex] = obj : self.ledgers.push(obj);
        //              itemIndex != -1 ? oldItems.push(obj) : newItems.push(obj);
        //          }
        //          else if(data[i].objectName == "deleteAccountWithOpening"){
        //              let itemIndex = self.ledgers.findIndex(ledger=>ledger.itemId == data[i].objectId);
        //              itemIndex != -1 ? itemsToDelete.push(data[i].objectId) : '';
        //          }
        //      }
        //  }

        let request = window.indexedDB.open("idb_"+self.CurrentBusiness.BusinessID, 2);

         request.onerror = (error)=>{
             console.log(error);
         }

         // This event is only implemented in recent browsers
         // request.onupgradeneeded = (event) => {
         //     // Save the IDBDatabase interface
         //     let db = event.target.result;
         
         //     // Create an objectStore for this database
         //     let objectStore = db.createObjectStore("ledgers", { keyPath: "itemId" });
         //     objectStore.createIndex("nameIndex", "itemName", {unique:true});
         // };

         request.onsuccess = (event) => {
             // Do something with request.result!
            let db = event.target.result;

            if(!db.objectStoreNames.contains("ledgers")){
                // closing database
                db.close();
                this.fn_deleteDatabase(self);
                // creating object store if not exist
                // let ledgerObjectStore = db.createObjectStore("ledgers", { keyPath: "itemId" });
                // ledgerObjectStore.createIndex("nameIndex", "itemName", {unique:true});
                
                // self.ledgers = [];
                // let dataObj = {};
                // dataObj.LocalKey = self.CurrentBusiness.BusinessID+'_ls_ledger_counter';
                // dataObj.LocalValue = '-1';
                // self.fn_saveLocalItem(dataObj);
                // this.fn_getMasterData(self, type, listNames, isGetAll, filterObject);
            }
            else{
                if(newItems.length){
                    let ledgerObjectStore = db.transaction("ledgers", "readwrite",).objectStore("ledgers");
                    newItems.forEach((item, index)=>{
   
                       let storeRef = ledgerObjectStore.add(item);
   
                       storeRef.onsuccess = function(event) {
                           self.ledgers.push(item);
                           console.log(event + ' Record' +self.products.length+ 'added successfully.');
                           if(index == newItems.length - 1){
                               scopeRef.fn_setLedgerCounter(self, data);
                           }
                       };
   
                       storeRef.onerror = (error)=>{
                           console.log(error);
                           console.log(item.name + ' ' + index + ' not added');
                           if(index == newItems.length - 1){
                               scopeRef.fn_setLedgerCounter(self, data);
                           }
                       }
   
                       //  ledgerObjectStore.add(item);
                       //  self.ledgers.push(item);
                    })
                }
                else if(oldItems.length){
                    let ledgerObjectStore = db.transaction("ledgers", "readwrite",).objectStore("ledgers");
                    oldItems.forEach((item, index)=>{
   
                       let storeRef = ledgerObjectStore.put(item);
   
                       storeRef.onsuccess = function(event) {
                           console.log(event + ' Record' +self.ledgers.length+ 'updated successfully.');
                           let itemIndex = self.ledgers.findIndex(ledger=>ledger.itemId == item.itemId);
                           if(itemIndex != -1){
                               self.ledgers[itemIndex] = item;
                           }
   
                           if(index == oldItems.length - 1){
                               scopeRef.fn_setLedgerCounter(self, data);
                           }
                       };
   
                       storeRef.onerror = (error)=>{
                           console.log(error);
                           console.log(item.name + ' ' + index + ' not updated');
                           if(index == oldItems.length - 1){
                               scopeRef.fn_setLedgerCounter(self, data);
                           }
                       }
                       
                       //  ledgerObjectStore.put(item);
                       //  let itemIndex = self.ledgers.findIndex(ledger=>ledger.itemId == item.itemId);
                       //  if(itemIndex != -1){
                       //     self.ledgers[itemIndex] = item;
                       //  }
                    })
                }
                else if(itemsToDelete.length){
                    let ledgerObjectStore = db.transaction("ledgers", "readwrite",).objectStore("ledgers");
                    itemsToDelete.forEach((itemId, index)=>{
   
                       let storeRef = ledgerObjectStore.delete(itemId);
   
                       storeRef.onsuccess = function(event) {
                           console.log(event + ' Record' +self.ledgers.length+ 'deleted successfully.');
                           let itemIndex = self.ledgers.findIndex(ledger=>ledger.itemId == itemId);
                           if(itemIndex != -1){
                               self.ledgers.splice(itemIndex, 1);
                           }
   
                           if(index == itemsToDelete.length - 1){
                               scopeRef.fn_setLedgerCounter(self, data);
                           }
                       };
   
                       storeRef.onerror = (error)=>{
                           console.log(error);
                           console.log(itemId + ' ' + index + ' not deleted');
                           if(index == itemsToDelete.length - 1){
                               scopeRef.fn_setLedgerCounter(self, data);
                           }
                       }
                       
                       //  ledgerObjectStore.delete(itemId);
                       //  console.log(self.ledgers);
                       //  let itemIndex = self.ledgers.findIndex(ledger=>ledger.itemId == itemId);
                       //  if(itemIndex != -1){
                       //     self.ledgers.splice(itemIndex, 1);
                       //  }
                    })
                }
                else{
                   scopeRef.fn_setLedgerCounter(self, data);
                }
            }

            // closing database
            db.close();
             

            // if(data?.length){
            //     let localObj = {counter:data[data.length-1].logId == 0 ? 1 : data[data.length-1].logId};
            //     localStorage.setItem(self.CurrentBusiness.BusinessID+'_ls_ledger_counter', JSON.stringify(localObj));
            //     self.ledgers.sort((a, b) => a.itemName > b.itemName ? 1 : -1);
            // }

            // self.dialogLoader = false;
        };
        
    },

    fn_setLedgerCounter: function(self, data){
        if(data?.length){
            let localObj = {counter:data[data.length-1].logId == 0 ? 1 : data[data.length-1].logId};
            localStorage.setItem(self.CurrentBusiness.BusinessID+'_ls_ledger_counter', JSON.stringify(localObj));

            let dataObj = {};
            dataObj.LocalKey = self.CurrentBusiness.BusinessID+'_ls_ledger_counter';
            dataObj.LocalValue = data[data.length-1].logId == 0 ? '1' : data[data.length-1].logId.toString();
            self.fn_saveLocalItem(dataObj);
            self.ledgers.sort((a, b) => a.itemName > b.itemName ? 1 : -1);
        }

        self.dialogLoader = false;

        self.$eventHub.$emit('cachereset');
    }
});