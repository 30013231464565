<template>
    <div>

        <v-toolbar :height="56" flat extended>
            <!-- <v-btn icon @click="fn_goBack"><v-icon>arrow_back</v-icon></v-btn> -->

            <v-toolbar-title>Invoices</v-toolbar-title>

            <v-spacer></v-spacer>

            <v-btn icon color="primary" @click="fn_searchItem"><v-icon>autorenew</v-icon></v-btn>

            <v-btn icon @click="fn_openFilterNav" class="mr-2">
                <v-icon>filter_list</v-icon>
                <span style="font-size: 48px;position: absolute;bottom: -10px;right: 6px;color: #4b77be;" v-if="selectedTransactionListIds.length">&bull;</span>
            </v-btn>

            <v-menu>
                <template v-slot:activator="{ on, attrs }">
                    <div class="subtitle-text dark-opacity-color" v-bind="attrs" v-on="on" cursor-pointer>
                        <div font-small style="max-width: 140px;overflow: hidden;white-space: nowrap;text-overflow: ellipsis;">
                            <template v-if="selectedDate">
                                Due at<span v-if="selectedDueDays != null">: {{ selectedDate | moment("DD-MMM-YYYY")}}</span>
                            </template>
                            <template v-else>
                                Due<span v-if="selectedDueDays != null">: {{ selectedDueDays ? selectedDueDays + ' days' : 'Today' }}</span>
                            </template>
                            
                            <v-icon>arrow_drop_down</v-icon>
                        </div>
                       
                    </div>
                </template>

                <v-list dense nopadding>
                    <v-list-item @click="fn_searchItem(0)">
                        <v-list-item-title>Today</v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="fn_searchItem(7)">
                        <v-list-item-title>7 Days</v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="fn_searchItem(10)">
                        <v-list-item-title>10 Days</v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="fn_searchItem(15)">
                        <v-list-item-title>15 Days</v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="fn_searchItem(30)">
                        <v-list-item-title>30 Days</v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="fn_searchItem(45)">
                        <v-list-item-title>45 Days</v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="fn_searchItem(90)">
                        <v-list-item-title>90 Days</v-list-item-title>
                    </v-list-item>
                    <!-- <v-list-item @click="fn_openDatePicker">
                        <v-list-item-title>Select Date</v-list-item-title>
                    </v-list-item> -->
                </v-list>
            </v-menu>

            <v-text-field grey-back clearable flat solo hide-details placeholder="Search..." v-model="searchValue" @input="fn_debounceSearch" slot="extension"></v-text-field>
        </v-toolbar>

        <v-divider></v-divider>

        <div class="data-container grey-back hasExtention">

            <div class="pa-4 light-opacity-color font12" v-if="$root.appVersion<202">Install updated mobile app to get all pending invoices</div>
            
            <div>
                
                <v-list grey-back three-line nopadding v-if="listItemObject.items && listItemObject.items.length">

                    <div white-back v-for="(item, index) in listItemObject.items" :key="index" :class="{'mt-2':index!=0}" :id="'item_'+item.transactionId">
                        <v-list-item class="right-subtitle-tile" @click="fn_itemClick(item, index)">
                            <v-list-item-content>
                              <v-list-item-title>{{item.partyName ? item.partyName : fn_transactionName(item)}}</v-list-item-title>
                              <!-- <v-list-item-subtitle v-if="item.partyName">{{fn_transactionName(item)}}</v-list-item-subtitle> -->
                              <v-list-item-subtitle>
                                <span v-if="item.baseTransactionNumber" class="primary-color">{{item.baseTransactionNumber}}</span>
                                  {{item.baseTransactionNumber ? ' | ' : ''}}
                                  {{item.transactionDate | moment("DD-MMM-YYYY")}}
                              </v-list-item-subtitle>
                              <v-list-item-subtitle>Bill Amount: &#8377; {{ $root.numberToLocale(item.roundedAmount) }}</v-list-item-subtitle>
                            </v-list-item-content>

                            <v-list-item-action align-flex-end style="justify-content: end;">
                                <!-- <div class="warning-label" v-if="!item.status">Cancelled</div> -->
                                <!-- empty div to align amount at bottom of the tile -->    
                                <!-- <div v-else></div> -->
                                <div class="font14" :class="[item.dueDays > 0 ? 'redcolor' : 'greencolor']">
									<span v-if="item.dueDays == 0">Due today</span>
									<span v-if="item.dueDays > 0">Due {{item.dueDays}} days</span>
									<span v-else-if="item.dueDays < 0">Due in {{item.dueDays}} days</span>
								</div>

                                <div light-opacity-color font14>{{ item.transactionListId == 10004 || item.transactionListId == 20003 || item.transactionListId == 20005 ? 'To Pay' : 'To Receive' }}</div>
                                <div>&#8377; {{$root.numberToLocale(item.outStandingAmount)}}</div>
                            </v-list-item-action>

                        </v-list-item>

                        <v-divider v-if="item.invoiceTemplate"></v-divider>
                        <div right-align style="padding:2px 6px;position:relative" v-if="item.invoiceTemplate">
                            <!-- <div class="warning-label" v-if="!item.status">Cancelled</div> -->
                            <!-- <v-btn disabled nomargin icon flat @click="bindInvoiceTemplate('sharewhatsapp', item)">
                                <v-icon>send</v-icon>
                            </v-btn>
                            <v-btn disabled nomargin icon flat @click="bindInvoiceTemplate('share', item)"><v-icon font20>share</v-icon></v-btn> -->
                            <v-btn nomargin icon text @click="fn_preview(item, true)"><v-icon font20>print</v-icon></v-btn>
                            <v-btn nomargin icon text @click="fn_preview(item)">
                                <!-- <img src="../../../assets/icon_preview_black.svg" style="width:20px" alt="whatapp_icon"></img> -->
                                <v-icon>preview</v-icon>
                            </v-btn>
                        </div>

                        <!-- <v-divider v-if="listItemObject.items.length-1 != index"></v-divider> -->
                    </div>

                    <!-- <infinite-loading :identifier="infiniteId" @infinite="infiniteHandler">
                        <div slot="no-more"></div>
                        <div slot="no-results"></div>
                    </infinite-loading> -->


                </v-list>

                <v-row class="ma-0" align-content="center" justify="center" v-if="loadMore">
                  <v-col cols="6">
                    <v-progress-linear rounded indeterminate :height="6" color="primary"></v-progress-linear>
                  </v-col>
                </v-row>

                <v-row class="ma-0" align-content="center" justify="center" v-if="!listItemObject.totalCount && !loadMore && !loadTimeOut">
                  <v-col cols="8" center-align>
                    There are no transactions!
                  </v-col>
                </v-row>

                <div center-align class="pa-6" v-if="loadTimeOut">
                    <p font12 light-opacity-color>Unable to load the items!</p>
                    <v-btn capitalize text color="primary" @click="fn_searchItem">
                        <v-icon left>refresh</v-icon>
                        Retry
                    </v-btn>
                </div>

                <!-- <transition name="fade" mode="out-in">

                    <v-card noshadow class="create-placeholder-card" v-if="showPlaceHolder && !listItemObject.totalCount">
                        <v-card-text @click="add">

                            <div center-align>
                                <div v-ripple class="placeholder-icon">
                                    <i aria-hidden="true" class="v-icon material-icons add-icon">add</i>
                                    <img src="../../../assets/icon_wallet.svg" alt="">
                                </div><br>

                                <p class="title light-opacity-color">Create New Transaction</p>
                            </div>
                        </v-card-text>
                    </v-card>
                </transition> -->

            </div>

            <!-- <div style="clear:both" class="padding-all-16 light-opacity-color center-align" v-if="!listItemObject.totalCount">There are no transactions!</div> -->

            <!-- <div padding-all-16 center-align>
                <v-progress-circular indeterminate color="primary" v-if="loadMore"></v-progress-circular>
            </div> -->
        </div>

        <!-- <dialog-transaction-list ref="ref_dialogTransactionList" :transactionForms="transactionForms" :selectedTransactionListIds="selectedTransactionListIds" :selectItems="fn_selectItems" :clearAll="fn_clearAll"></dialog-transaction-list> -->

        <v-navigation-drawer v-model="filter_nav" absolute right temporary>
          <filter-items ref="ref_filterItems" :transactionForms="transactionForms" :selectedTransactionListIds="selectedTransactionListIds" :selectItems="fn_selectItems" :clearAll="fn_clearAll" :closeFilterNav="fn_closeFilterNav"></filter-items>
        </v-navigation-drawer>

        <dialog-datepicker ref="ref_dialogDatePicker" :selected="selectedDate" :updateDate="fn_updateDate"></dialog-datepicker>

        <!-- <dialog-transaction-options ref="ref_dialogTransactionOptions" :showAll="true" :showPartyOptions="false"></dialog-transaction-options> -->
    </div>
</template>

<script>
// var tagId = null;
// const menuItem = require('../../../config.js').configs.menuItem;
// const bottomSheetMenu = require('../../../config.js').configs.bottomSheetMenu;
const transactionForms = require('../../config.js').configs.transactionForms;
const transactionTypes = require('../../config.js').configs.transactionTypes;

// import dialogTransactions from '../dialogs/dialog_transaction_list'
// import InfiniteLoading from 'vue-infinite-loading';

import filterTransactionLists from './dialogs/filter_transaction_lists'

import {transactionMixin} from '../transactions/transaction_mixin'
// import dialogTransactionOptions from '../dialogs/dialog_transaction_options'

import dialogDatepicker from '../dialogs/dialog_datepicker'

    export default{
        // props:['preview', 'bindInvoiceTemplate', 'add'],
        mixins: [transactionMixin],
        components: {
            // InfiniteLoading,
            // 'dialog-transaction-list':dialogTransactions
            'filter-items':filterTransactionLists,
            'dialog-datepicker': dialogDatepicker
            // 'dialog-transaction-options':dialogTransactionOptions,
        },
        data(){
            return{
                toolbarTitle:null,
                listItemObject:{"items":[], "totalCount":0, "saleTotal":0, "receiptTotal":0},
                searchValue:'',
                infiniteId: +new Date(),
                startIndex:0,
                scrollPositionTop:0,
                selectedItemId:null,
                // transactionListId:null,
                transactionForms:null,
                selectedTransactionListIds:[],
                dialog_transaction_list:false,
                loadMore:false,
                loadTimeOut:false,
                showPlaceHolder:false,
                filter_nav:false,
                selectedDueDays:null,
				selectedDate:null,
				dialog_datepicker:false
            }
        },
        methods:{

            fn_openDatePicker: function (type, date) {
              let self = this;
              this.dateType = type;
              this.selectedDate = date;
              setTimeout(function () {
                self.$refs['ref_dialogDatePicker'].openDialogWeb();
              })
            },

			fn_updateDate: function (value) {
				this.selectedDate = this.$moment(value).format('DD-MMM-YYYY');
				// Define two dates
				const date1 = new Date(value);
				const date2 = new Date();

				let isPositive = date1 > date2 ? true : false;

				// Calculate the difference in milliseconds
				const diffInMs = Math.abs(date2 - date1);

				// Convert milliseconds to days
				// let diffInDays = Math.floor(diffInMs / (1000 * 60 * 60 * 24));
				let diffInDays = null;
				if(isPositive){
					diffInDays = Math.ceil(diffInMs / (1000 * 60 * 60 * 24));
				}
				else{
					diffInDays = Math.floor(diffInMs / (1000 * 60 * 60 * 24));
				}

				let dayValue = !isPositive ? -diffInDays : diffInDays;

				console.log(diffInDays); // Output: 23

                setTimeout(() => {
                    this.$refs['ref_dialogDatePicker'].disableBtn = false;
                }, 1000);

				this.$refs['ref_dialogDatePicker'].closeDialogWeb();

				this.fn_searchItem(dayValue, true);				
			},

			fn_openDueDays: function(){
				this.$refs['ref_dialogDueDays'].openDialogWeb();
			},

			fn_updateDueDate: function(dueDays){
				this.selectedDueDays = dueDays;
				this.fn_searchItem();
				this.$refs['ref_dialogDueDays'].closeDialogWeb();
			},

            fn_preview: function(item, isPrint){
                item.toolbarTitle = this.fn_transactionName(item);
                let printType = item.invoiceTemplate == 101 ? 'ab' : item.invoiceTemplate == 201 ? 'sj' : null;

                let type = null;
                if(item.transactionListId == 10001 || item.transactionListId == 10002 || item.transactionListId == 10003 || item.transactionListId == 10004 || item.transactionListId == 10005){
                  type = 'sale';
                }
                else if(item.transactionListId == 20001 || item.transactionListId == 20002 || item.transactionListId == 20003 || item.transactionListId == 20004){
                  type = 'purchase';
                }
                else if(item.transactionListId == 20005){
                  type = 'expense';
                }
                // else if(item.transactionListId == 40002){
                //   type = 'inventory';
                // }
                
                this.fn_previewInvoice(true, type, printType, item, isPrint);
            },

            fn_openFilterNav: function(){
                // this.$refs['ref_filterItems'].fn_setCurrentFilterValues();
                this.transactionForms = JSON.parse(JSON.stringify(transactionForms));

                this.transactionForms = this.transactionForms.filter(item=>{
					return item.id == 10003 || item.id == 10004 || item.id == 20003 || item.id == 20004 || item.id == 20005
				})

                this.filter_nav = true;
            },

            fn_closeFilterNav: function(){
                this.filter_nav = false;
            },

            fn_openDialogOptions: function(){
                this.$refs['ref_dialogTransactionOptions'].dialog_transaction_options = true;
            },

            fn_new: function () {
                this.$router.push({path:'/'+this.$root.CurrentBusiness.BusinessID+'/'+this.$route.params.listId+'/saleinvoice/items/new', query:{tagId:this.guidWeb()}});
            },

            onScroll: function(event){
                var element = event.target;
                if (element.scrollHeight - element.scrollTop - 150 <= element.clientHeight)
                {
                    console.log('scrolled');
                    if(this.listItemObject.items.length < this.listItemObject.totalCount){
                        this.fn_loadItems();
                    }
                }
            },

            fn_transactionName: function(item){
                for(let i=0; i<transactionForms.length; i++){
                    if(transactionForms[i].id == item.transactionListId){
                        return transactionForms[i].title;
                    }
                }
            },

            // fn_openFilterDialog: function(){
            //     this.transactionForms = JSON.parse(JSON.stringify(transactionForms));

            //     for(let i=0; i<this.transactionForms.length; i++){
            //         if(this.transactionForms[i].id == 30001){
            //             this.transactionForms[i].title == "Payment In";
            //         }
            //         if(this.transactionForms[i].id == 30002){
            //             this.transactionForms[i].title == "Payment Out";
            //         }
            //         if(this.transactionForms[i].id == 30004){
            //             this.transactionForms[i].title == "Cash and Bank";
            //         }
            //     }
                
            //     this.dialog_transaction_list = true;
            // },

            fn_selectItems: function(listId){
                // let isRemoved = false;

                let itemIndex = this.selectedTransactionListIds.findIndex(transactionListId=>{
                    return transactionListId == listId;
                });

                if(itemIndex != -1){
                    this.selectedTransactionListIds.splice(itemIndex, 1);
                }
                else{
                    this.selectedTransactionListIds.push(listId)    
                }

                let obj = {};
                obj.selectedTransactionListIds = this.selectedTransactionListIds;
                localStorage.setItem('activity_transaction_filter', JSON.stringify(obj));

                this.fn_searchItem();
            },

            fn_clearAll: function(){
                this.selectedTransactionListIds = [];
                // this.dialog_transaction_list = false;
                this.fn_closeFilterNav();
                localStorage.removeItem('activity_transaction_filter');
                this.fn_searchItem();
            },

            // fn_filterTransaction: function(transactionListId){
            //  if(transactionListId == 'sale'){
            //      this.transactionListId = transactionTypes.salesInvoice.id;  
            //  }
            //  else if(transactionListId == 'receipt'){
            //      this.transactionListId = transactionTypes.receipt.id;   
            //  }
            //  else{
            //      this.transactionListId = transactionListId;
            //  }
            //  this.fn_searchItem();
            // },

            // fn_updateSearch: function(value){
            //  this.searchValue = value;
            // },

            // fn_menuItemClick: function(tagId, menuId, menuAction){
            //  // tagId id form future use
            //  // menuId id form future use
            //  if(menuAction=='edit'){
            //      this.$router.push('/transactions/sales/'+this.toolbarTitle.toLowerCase().replace(/ /g,'')+'/items/'+this.selectedItemId+'/edit');
            //  }
            //  else if(menuAction=='copy'){
            //      this.$router.push({path:"/transactions/sales/"+this.toolbarTitle.toLowerCase().replace(/ /g,'')+"/items/new", query:{tagId:this.guidWeb(), itemId:this.selectedItemId, copy:true}});
            //  }
            //  // else if(menuAction == 'filter'){
            //  //  // here "menuId" == "transactionListId"
            //  //  this.fn_filterTransaction(menuId);
            //  // }
            // },

            fn_itemClick: function (item, index) {
                let obj = {};
                obj.itemId = null;
                obj.lastScrollPos = window.pageYOffset;
                obj.itemIndex = index + 1;
                localStorage.setItem("transaction_items_state", JSON.stringify(obj));

                // let viewName = '';

                // if(item.transactionListId==10001 || item.transactionListId==10002 || item.transactionListId==10003 || item.transactionListId==10004 || item.transactionListId==10005){
                //   viewName = 'sales/';
                // }
                // else if(item.transactionListId==20001 || item.transactionListId==20002 || item.transactionListId==20003 || item.transactionListId==20004 || item.transactionListId==20005){
                //   viewName = 'purchase/';
                // }
                // else if(item.transactionListId==40001 || item.transactionListId==40002 || item.transactionListId==40003 || item.transactionListId==40004){
                //   viewName = 'inventory/';
                // }

                let viewName = this.fn_transactionName(item);

                // for(let i=0; i<transactionForms.length; i++){
                //   if(transactionForms[i].id == item.transactionListId){
                //     viewName += transactionForms[i].title.toLowerCase().replace(/ /g,'');
                //     break;
                //   }
                // }

                // this.$root.fn_navigateToDisplayView(item.transactionListId, item.transactionId);
                // this.$router.push('/transactions/sales/'+this.toolbarTitle.toLowerCase().replace(/ /g,'')+'/items/'+itemId);
                this.$router.push({path:'/'+this.$root.CurrentBusiness.BusinessID+'/'+this.$route.params.listId+'/'+viewName.toLowerCase().replace(/ /g,'')+'/items/'+item.transactionId});
            },

            // fn_getBottomMenu: function (elementId, itemId) {
            //  itemId ? this.selectedItemId = itemId : '';

            //  let bottomMenu;

            //  if(elementId == bottomSheetMenu.toolBar.Id){
            //      bottomMenu = {"BottomMenu": [menuItem.help]};
            //  }
            //  else if(elementId == bottomSheetMenu.masterListItems.Id){
            //      bottomMenu = {"BottomMenu": [menuItem.edit]};
            //  }
                
            //  this.setBottomMenu(tagId, bottomMenu, 
            //      {
            //          onSuccess: function (response) {
            //              console.log(response);
            //          },
            //          onError: function (error) {
            //              console.log(error);
            //          }
            //      }
            //  )
            // },

            // infiniteHandler($state) {
         //     let filterObject = {};
            //  filterObject.limit = 10;
            //  filterObject.startIndex = this.startIndex;
            //  !this.searchValue ? this.searchValue = '' : '';
            //  filterObject.search = this.searchValue.toLowerCase();
            //  this.selectedTransactionListIds && this.selectedTransactionListIds.length ? filterObject.transactionListId = this.selectedTransactionListIds : '';

            //  let self = this;
            //  this.getItems("AllTransactionList", filterObject, 
            //      {
            //          onSuccess: function (response) {
            //              console.log(response);
            //              let responseJson = JSON.parse(response);
            //              self.listItemObject.totalCount = responseJson.totalCount;
            //              self.listItemObject.saleTotal = responseJson.saleTotal ? responseJson.saleTotal : 0;
            //              self.listItemObject.receiptTotal = responseJson.receiptTotal ? responseJson.receiptTotal : 0;

            //              for(let i in responseJson.items){
            //                  self.listItemObject.items.push(responseJson.items[i]);  
            //              }
            //              // self.listItemObject.items = self.listItemObject.items.concat(responseJson.items);
            //              self.startIndex = self.listItemObject.items.length;

            //              if(self.listItemObject.items.length < self.listItemObject.totalCount){
            //                  $state.loaded();
            //              }
            //              else{
            //                  $state.complete();
            //              }

            //          },
            //          onError: function (error) {
            //              console.log(error);
            //          }
            //      }
            //  )
         //    },

            // fn_searchItem: function () {
            //  this.startIndex = 0;
            //  this.infiniteId += 1;
            //  this.listItemObject.items = [];
            // }

            fn_loadItems: function() {
                this.loadMore = true;
                let filterObject = {};
                filterObject.limit = 50;
                filterObject.startIndex = this.startIndex;
                !this.searchValue ? this.searchValue = '' : '';
                filterObject.search = this.searchValue.toLowerCase();

                if(localStorage.getItem('activity_invoices_filter')){
                    let data = JSON.parse(localStorage.getItem('activity_invoices_filter'));
                    this.selectedTransactionListIds = data.selectedTransactionListIds;
                }

                // this.selectedTransactionListIds && this.selectedTransactionListIds.length ? filterObject.transactionListId = this.selectedTransactionListIds : '';
                // Excluding opening stock, opening ledger and stock adjustment
                // filterObject.excludeTransactionListIds = [40001, 40003, 40004, 60001];

                filterObject.formTransactionListId = [30001, 30002];

                if(this.selectedTransactionListIds && this.selectedTransactionListIds.length){
                    filterObject.transactionListId = this.selectedTransactionListIds;
                }
                else{
                    filterObject.transactionListId = [transactionTypes.salesInvoice.id, transactionTypes.salesReturn.id, transactionTypes.bwbRefPurchaseInvoice.id, transactionTypes.bwbRefSaleInvoice.id, transactionTypes.purchaseInvoice.id, transactionTypes.purchaseReturn.id, transactionTypes.expense.id, transactionTypes.bwbRefPurchaseInvoice.id, transactionTypes.bwbRefSaleInvoice.id];
                }

                if(this.selectedDueDays){
					filterObject.dueDays = this.selectedDueDays;
				}

                filterObject.endDate = this.$moment(new Date()).endOf('day');
				filterObject.endDate = this.$root.fn_getLocalDateFormat(filterObject.endDate);

                let pageState = null;
                if(localStorage.getItem("transaction_items_state")){
                    pageState = JSON.parse(localStorage.getItem("transaction_items_state"));
                    filterObject.limit = 50 - (pageState.itemIndex % 50) + pageState.itemIndex;
                }

                this.fn_loadTimeOut();

                let self = this;
                this.getItems("ActivityInvoiceList", filterObject, 
                    {
                        onSuccess: function (response) {
                            self.loadTimeOut = false;
                            console.log(response);
                            let responseJson = JSON.parse(response);
                            self.listItemObject.totalCount = responseJson.totalCount;
                            self.listItemObject.saleTotal = responseJson.saleTotal ? responseJson.saleTotal : 0;
                            self.listItemObject.receiptTotal = responseJson.receiptTotal ? responseJson.receiptTotal : 0;

                            for(let i in responseJson.items){
                                // saving transaction date for opening ledger and opening stock one day before opening date
                                // therefore incrementing one day here just to show user
                                // if(responseJson.items[i].transactionListId == 40001 || responseJson.items[i].transactionListId == 40003){
                                //  let od = new Date(responseJson.items[i].transactionDate);
                          //           od.setDate(od.getDate()+1);
                          //           responseJson.items[i].transactionDate = new Date(od);
                                // }
                                let itemIndex = self.listItemObject.items.findIndex(item => item.transactionId==responseJson.items[i].transactionId);

                                if(itemIndex==-1){
                                    responseJson.items[i].transactionId = responseJson.items[i].baseTransactionId;
									responseJson.items[i].mergeTransactionNumber = responseJson.items[i].baseTransactionNumber;
                                    self.listItemObject.items.push(responseJson.items[i]);  
                                }
                                // self.listItemObject.items.push(responseJson.items[i]);   
                            }

                            // console.log(pageState);
                            // if(pageState && pageState.itemId){
                            //     setTimeout(function(){
                            //         let scrollOptions = {"duration":0, "offset":0, "easing":"linear"};
                            //         self.$vuetify.goTo('#item_'+pageState.itemId, scrollOptions);
                            //         localStorage.removeItem("transaction_items_state");
                            //     },100);
                            // }

                            // if(pageState && !pageState.itemId){
                            //     setTimeout(function(){
                            //         let scrollOptions = {"duration":300, "offset":0, "easing":"linear"};
                            //         self.$vuetify.goTo(pageState.lastScrollPos, scrollOptions);
                            //         localStorage.removeItem("transaction_items_state");
                            //     },100);
                            // }

                            self.startIndex = self.listItemObject.items.length;
                            self.loadMore = false;

                            setTimeout(function(){
                                self.showPlaceHolder = true;
                            },300);
                        },
                        onError: function (error) {
                            console.log(error);
                            self.loadMore = false;
                            self.loadTimeOut = false;
                        }
                    }
                )
            },

            fn_searchItem: function (dueDays, date) {
                // this.showPlaceHolder = false;
                this.startIndex = 0;
                this.listItemObject.items = [];

                if(dueDays != undefined){
					this.selectedDueDays = dueDays;
				}

				if(!date){
					this.selectedDate = null;
				}

                if(isNaN(dueDays)){
                    this.selectedDueDays = null;
                }

                this.fn_loadItems();
            },

            fn_debounceSearch: function(){
                 if (this.timeoutid != null){
                    clearTimeout(this.timeoutid);
                 }

                this.timeoutid = setTimeout(() => { 
                    this.fn_searchItem();
                },300);
            },

            fn_loadTimeOut: function(){
                setTimeout(() => {
                    if(this.loadMore){
                        this.loadTimeOut = true;
                        this.loadMore = false;
                    }
                    else{
                        this.loadTimeOut = false;
                    }
                }, 1 * 10 * 1000);
            },

            fn_newTransaction: function(type){
                if(type=='saleinvoice'){
                    this.fn_new();
                }
                else if(type=='salereturn'){
                    this.$refs['ref_dialogTransactionOptions'].fn_openForm('Sale Return');
                }
                else if(type=='estimate'){
                    this.$refs['ref_dialogTransactionOptions'].fn_openForm('Estimate');
                }
                else if(type=='purchaseinvoice'){
                    this.$refs['ref_dialogTransactionOptions'].fn_openForm('Purchase Invoice');
                }
                else if(type=='purchasereturn'){
                    this.$refs['ref_dialogTransactionOptions'].fn_openForm('Purchase Return');
                }
                else if(type=='expense'){
                    this.$refs['ref_dialogTransactionOptions'].fn_openForm('Expense');
                }
                else if(type=='receipt'){
                    this.$refs['ref_dialogTransactionOptions'].fn_openForm('Receipt');
                }
                else if(type=='payment'){
                    this.$refs['ref_dialogTransactionOptions'].fn_openForm('Payment');
                }
            }
        },
        mounted: function(){
            this.fn_loadItems();

            this.$eventHub.$on('UpdateSaleList', dataObj => {
                console.log(dataObj);
                this.fn_searchItem();
            });

            this.$eventHub.$on('UpdatePurchaseList', dataObj => {
                console.log(dataObj);
                this.fn_searchItem();
            });

            this.$eventHub.$on('UpdateExpenseList', dataObj => {
                console.log(dataObj);
                this.fn_searchItem();
            });

            this.$eventHub.$on('UpdateAccountBookList', dataObj => {
                console.log(dataObj);
                this.fn_searchItem();
            });

            // if(this.$route.query && this.$route.query.type){
            //     this.fn_newTransaction(this.$route.query.type);
            // }

            // let eventFired = false;

            // this.$eventHub.$on('newTransaction', dataObj => {
            //     eventFired = true;
            //     eventFired ? this.fn_newTransaction(dataObj.type) : '';
            //     setTimeout(() => {
            //         eventFired = false;
            //     }, 2000);
            // });
        }
    }
</script>