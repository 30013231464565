<template>
    <div>
        <v-dialog v-model="dialog_accountbook_form" content-class="half-screen-dialog" no-click-animation scrollable persistent transition="slide-y-transition" :max-width="$root.fn_getLayoutWidth()">
            <v-card no-border-radius grey-back>
                <v-toolbar :height="56" flat class="noprint">
                    <v-btn icon @click="closeDialog"><v-icon>arrow_back</v-icon></v-btn>
                    <!-- <v-toolbar-title>{{$parent.toolbarTitle}}</v-toolbar-title> -->

                    <v-toolbar-title :class="{'two-line-title':$parent.Form.transactionTypeList && $parent.Form.transactionTypeList.length>1}">
                      {{$parent.toolbarTitle}}
                      <!-- <div class="toolbar-subtitle" v-if="$parent.Form.transactionTypeList && $parent.Form.transactionTypeList.length>1 && $parent.getDataCounter >= 7"> -->
                        <div class="toolbar-subtitle" v-if="$parent.Form.transactionTypeList && $parent.Form.transactionTypeList.length>1 && $parent.getDataCounter >= 5 && !$root.dialogLoader">

                        <v-menu :disabled="$parent.loading">
                          <template v-slot:activator="{ on, attrs }">

                            <div class="subtitle-text dark-opacity-color" v-bind="attrs" v-on="on" cursor-pointer>
                              {{$parent.Form.transactionTypeFormName ? $parent.Form.transactionTypeFormName : 'Select Transaction Form'}}
                              <v-icon class="subtitle-icon">arrow_drop_down</v-icon>
                            </div>

                          </template>

                          <v-list dense nopadding>
                            <template v-for="(item, index) in $parent.Form.transactionTypeList">
                              <v-list-item @click="$parent.fn_setTransactionType(item, null, 'transactionTypeChange')" :key="index">
                                <v-list-item-content>
                                  <v-list-item-title>{{item.name}}</v-list-item-title>
                                </v-list-item-content>
                              </v-list-item>
                            </template>
                            
                          </v-list>
                        </v-menu>

                      </div>
                    </v-toolbar-title>

                </v-toolbar>

                <v-divider class="noprint"></v-divider>

                <div class="data-container grey-back">

                  <!-- <v-progress-linear indeterminate :height="6" color="primary" v-if="$parent.loading || $parent.getDataCounter < 7"></v-progress-linear> -->
                  <v-progress-linear indeterminate :height="6" color="primary" v-if="$parent.loading || $parent.getDataCounter < 5 || $root.dialogLoader"></v-progress-linear>
                  <div center-align class="pa-4 light-opacity-color" v-if="$root.dialogLoader">Syncing master items...</div>

                  
                  <!-- <template v-if="!$parent.loading && $parent.getDataCounter >= 7"> -->
                  <template v-if="!$parent.loading && $parent.getDataCounter >= 5 && !$root.dialogLoader">

                    <!-- {{$parent.Form.gstAddOns}} -->
                    
                    <ValidationObserver ref="observer">

                    <div class="form_wrapper">

                      <v-list class="pa-0">
                        <v-list-item style="height:36px">
                            <v-list-item-content>
                              <v-list-item-title>
                                  <span cursor-pointer class="text-underline" @click="$parent.fn_openDialogNumberSeries">{{$parent.Form.transactionData.mergeTransactionNumber}}</span>
                              </v-list-item-title>
                            </v-list-item-content>

                            <span v-if="$parent.Form.transactionData.transactionDate">{{$parent.Form.transactionData.transactionDate | moment("DD-MMM-YYYY")}}</span> 

                            <v-list-item-action class="ml-0">
                                <v-btn icon @click="$parent.fn_openDatePicker('transactionDate', $parent.Form.transactionData.transactionDate)"><v-icon>date_range</v-icon></v-btn>
                            </v-list-item-action>
                        </v-list-item>
                    </v-list>

                    <v-alert :value="true" class="pl-4 pr-4 pt-2 pb-2" color="info" transition="slide-y-transition" v-if="$parent.Form.pendingCount && $parent.settings.transactionTypeId != 10004">
                      <div font-small style="display: flex;justify-content: space-between;align-items: center;">

                        <span v-if="$parent.Form.transactionData.transactionListId == 10001">
                          {{$parent.Form.pendingCount + ($parent.Form.pendingCount > 1 ? " Estimates are pending." : " Estimate is pending.")}}
                        </span>

                        <!-- <span v-if="$parent.Form.transactionData.transactionListId == 10002">
                          {{$parent.Form.pendingCount + ($parent.Form.pendingCount > 1 ? " Orders are pending." : " Order is pending.")}}
                        </span> -->

                        <span v-else-if="$parent.Form.transactionData.transactionListId == 10001 || $parent.Form.transactionData.transactionListId == 10002 || $parent.Form.transactionData.transactionListId == 10003">
                          {{$parent.Form.pendingCount + " " + $parent.fn_getPendingAlertMsg()}}
                        </span>

                        <span>
                          <v-btn @click="$parent.fn_loadItems('orders')" small capitalize color="primary" text v-if="Object.keys($parent.Form.pendingOrderAndChallan).indexOf('10001') != -1"> <v-icon class="mr-1" font16>add</v-icon> Add Order ({{ $parent.Form.pendingOrderAndChallan["10001"] }})</v-btn>
                          <v-btn @click="$parent.fn_loadItems('challans')" small capitalize color="primary" text v-if="Object.keys($parent.Form.pendingOrderAndChallan).indexOf('10002') != -1"> <v-icon class="mr-1" font16>add</v-icon> Add Delivery Challan ({{ $parent.Form.pendingOrderAndChallan["10002"] }})</v-btn>
                          <v-btn @click="$parent.fn_loadItems('estimates')" small capitalize color="primary" text v-if="Object.keys($parent.Form.pendingOrderAndChallan).indexOf('10005') != -1"> <v-icon class="mr-1" font16>add</v-icon> Add Estimate ({{ $parent.Form.pendingOrderAndChallan["10005"] }})</v-btn>
                        </span>
                      </div>
                    </v-alert>

                  <v-layout ma-0 row wrap white-back pt-2 pb-4 id="supplierfield">

                    <!-- <v-flex xs12 sm12 md12 form-item @click="fn_openRoutes('android', 'Route', 'ref_fieldRoutes')" v-if="Form.transactionTypeData && Form.transactionTypeData.isRouteApplicable && $root.TransactionSettings.beatRouteArea">
                       <v-text-field  
                          readonly
                          v-model="Form.routeName" 
                          label="Beat/Area/Route"
                          ref="ref_fieldRoutes"
                          :onUpdate="fn_updateRoute"
                          placeholder=" " 
                          append-icon="arrow_drop_down"
                          required>
                       </v-text-field>
                    </v-flex> -->

                    <v-flex xs3 sm3 md3 pl-4 pr2 v-if="!$parent.isPartyValid">
                      <div font11 light-opacity-color>{{$parent.fn_partyLabel()}}</div>
                      <v-text-field
                          outlined
                          hide-details
                          dense
                          class="small-text-box"
                          readonly
                          v-model="$parent.Form.selectedParty.itemName"
                        ></v-text-field>
                        <div right-align>
                          <v-btn color="primary" capitalize class="pr-0" text x-small @click="$parent.fn_changeParty">Change Customer</v-btn>
                        </div>
                    </v-flex>

                    <v-flex xs3 sm3 md3 pl-4 pr-2 v-else>

                      <ValidationProvider name="Party" rules="required" v-slot="{ errors }">

                        <div font11 light-opacity-color>{{$parent.fn_partyLabel()}}</div>

                          <!-- <v-text-field
                            v-if="!self.$parent.isPartyValid"
                            class="small-text-box"
                            disabled
                            v-model="$parent.Form.selectedParty.itemName"
                          ></v-text-field> -->

                         <!-- <v-autocomplete
                            outlined class="small-text-box"
                            :items="$parent.parties ? $parent.parties : []"
                            id="party_dropdown"
                            v-model="$parent.Form.selectedParty"
                            :disabled="$parent.Form.items.length && ($parent.Form.pendingOrders.length || $parent.Form.pendingChallans.length || $parent.Form.pendingInvoices.length) ? true : false"
                            @input="$parent.fn_updateCustomer($parent.Form.selectedParty.itemId, $parent.Form.selectedParty.itemName)"
                            item-text="itemName"
                            item-value="itemId"
                            return-object
                            required
                            :error-messages="errors"
                            dense
                            hide-details
                            append>
                            
                            <v-list-item slot="prepend-item" class="primary--text" @click="fn_menuItemClick('newcustomer')">
                              <v-list-item-title>Create New</v-list-item-title>
                            </v-list-item>

                          </v-autocomplete> -->

                          <v-autocomplete
                            outlined class="small-text-box"
                            :items="$parent.filteredLedgers ? $parent.filteredLedgers : []"
                            id="party_dropdown"
                            v-model="$parent.Form.selectedParty"
                            :disabled="$parent.Form.items.length && ($parent.Form.pendingOrders.length || $parent.Form.pendingChallans.length || $parent.Form.pendingInvoices.length) ? true : false"
                            @input="$parent.fn_updateCustomer($parent.Form.selectedParty.itemId, $parent.Form.selectedParty.itemName)"
                            item-text="itemName"
                            item-value="itemId"
                            return-object
                            required
                            :error-messages="errors"
                            dense
                            hide-details
                            append>
                            
                            <v-list-item slot="prepend-item" class="primary--text" @click="fn_menuItemClick('newcustomer')">
                              <v-list-item-title>Create New</v-list-item-title>
                            </v-list-item>

                          </v-autocomplete>

                          <div style="font-size:12px;padding:2px;color:#ff5252">{{errors[0]}}</div>

                          <div font12 style="padding:2px" v-if="$parent.Form.partyDetails">
                            <span light-opacity-color>{{$parent.fn_balanceLabel($parent.Form.partyDetails.groupTypeId, $parent.Form.partyDetails.balance)}}: </span>
                            <span :class="[$parent.Form.partyDetails.balance < 0 ? 'redcolor' : 'greencolor']">&#8377; {{$root.numberToLocale($parent.Form.partyDetails.balance)}}</span>
                          </div>

                      </ValidationProvider>
                    </v-flex>

                    <v-flex xs3 sm3 md3 pr-2 pl-2 v-if="$root.CurrentBusiness.GSTRegistrationStatus==1 && $parent.Form.partyDetails && $parent.Form.partyDetails.gstType!=105 && $parent.Form.transactionTypeData && !$parent.Form.transactionTypeData.importExport">
                      
                      <div font11 light-opacity-color>State/Place of Supply</div>

                        <v-autocomplete
                            outlined class="small-text-box"
                            :items="$root.states ? $root.states : []"
                            v-model="$parent.Form.selectedState"
                            @input="$parent.fn_updateState($parent.Form.selectedState.itemId, $parent.Form.selectedState.itemName)"
                            item-text="itemName"
                            item-value="itemId"
                            return-object
                            hide-details
                            dense>
                        </v-autocomplete>

                    </v-flex>  


                    <!-- <v-flex xs2 sm2 md2></v-flex> -->
                    <v-flex left-align :class="[$root.CurrentBusiness.GSTRegistrationStatus==1 && $parent.Form.partyDetails && $parent.Form.partyDetails.gstType!=105 && $parent.Form.transactionTypeData && !$parent.Form.transactionTypeData.importExport ? 'xs2 sm2 md2' : 'xs5 sm5 md5']">
                      <v-menu v-if="$parent.settings.transactionTypeId != 10001 && $parent.settings.transactionTypeId != 10005 && $parent.Form.selectedParty && !$parent.Form.isPendingItems">
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn class="mt-3" icon v-bind="attrs" v-on="on"><v-icon>more_vert</v-icon></v-btn>
                        </template>

                        <v-list dense nopadding>
                          <v-list-item @click="$parent.fn_loadItems('orders')" v-if="$parent.settings.transactionTypeId == 10002 || $parent.settings.transactionTypeId == 10003">
                            <v-list-item-content>
                              <v-list-item-title>Load Order</v-list-item-title>
                            </v-list-item-content>
                          </v-list-item>

                          <v-list-item @click="$parent.fn_loadItems('challans')" v-if="$parent.settings.transactionTypeId == 10003">
                            <v-list-item-content>
                              <v-list-item-title>Load Delivery Challan</v-list-item-title>
                            </v-list-item-content>
                          </v-list-item>

                          <v-list-item @click="$parent.fn_loadItems('invoices')" v-if="$parent.settings.transactionTypeId == 10004">
                            <v-list-item-content>
                              <v-list-item-title>Load Sale Invoice</v-list-item-title>
                            </v-list-item-content>
                          </v-list-item>
                        </v-list>
                      </v-menu>
                    </v-flex>

                    <v-flex xs4 sm4 md4 form-item v-if="$parent.Form.transactionTypeData && $parent.Form.transactionTypeData.importExport">

                      <div font11 light-opacity-color>Export Type</div>

                      <ValidationProvider name="export type" rules="required" v-slot="{ errors }">

                        <v-autocomplete
                            outlined class="small-text-box"
                            :items="$parent.exportTypes"
                            v-model="$parent.Form.transactionData.exportType"
                            @input="$parent.fn_updateExportType"
                            hide-details
                            :error-messages="errors"
                            required
                            dense>
                        </v-autocomplete>

                        <div style="font-size:12px;padding:2px;color:#ff5252">{{errors[0]}}</div>

                      </ValidationProvider>

                    </v-flex>

                    <!-- <v-flex xs4 sm4 md4 form-item>

                      <div font11 light-opacity-color>Tax</div>

                      <v-select 
                        outlined class="small-text-box"
                        v-model="$parent.Form.selectedTaxMode" row 
                        @change="$parent.fn_calculateTax(true)"
                        :items="[{'title':'None', 'value':0},{'title':'Exclusive', 'value':1},{'title':'Inclusive', 'value':2}]"
                        return-object
                        item-text="title"
                        item-value="value"
                        hide-details
                        dense>
                      </v-select>

                      <div class="small-checkboxes" v-if="$parent.Form.taxMode && $root.CurrentBusiness.GSTRegistrationStatus!=3 && $parent.settings.transactionTypeId == 20003">
                        <v-checkbox class="ml-4" hide-details label="RCM Applicable" v-model="$parent.Form.isRcmApplicable" @change="$parent.fn_calculateTax(false)"></v-checkbox>
                      </div>

                    </v-flex> -->

                  </v-layout>

            

                <v-layout mt-2 ma-0 row wrap pt-4 pb-4 white-back style="z-index:2;">

                  <!-- <div full-width right-align class="pb-2" v-if="selectedItem">
                    <v-chip class="ma-2 pl-4" label small>
                      <v-icon left>info_outline</v-icon>
                      Fill details and press enter <v-icon class="ml-2 mr-2" font20>keyboard_return</v-icon> key to add item
                    </v-chip>
                  </div> -->

                  <v-flex xs4 sm4 md4 form-item>
                    <div font11 light-opacity-color>Select Item</div>
                    <!-- @keydown.enter.tab="$parent.fn_focusFirstItem" -->

                    <!-- <v-autocomplete
                      id="fieldproducts"
                      class="small-text-box"
                      :items="$parent.filteredItemList ? $parent.filteredItemList : []"
                      :filter="$root.customItemFilter"
                      outlined  
                      v-model="selectedItem"
                      item-text="itemName"
                      item-value="itemId"
                      @input="fn_updateProduct"
                      return-object
                      dense
                      append>
                      
                      <v-list-item slot="prepend-item" class="primary--text" @click="fn_menuItemClick('newproduct')">
                        <v-list-item-title>Create New</v-list-item-title>
                      </v-list-item>

                    </v-autocomplete> -->

                    <v-autocomplete
                      id="fieldproducts"
                      class="small-text-box"
                      :items="$parent.filteredProducts ? $parent.filteredProducts : []"
                      :filter="$root.customItemFilter"
                      outlined  
                      v-model="selectedItem"
                      item-text="itemName"
                      item-value="itemId"
                      @input="fn_updateProduct"
                      return-object
                      dense
                      append>
                      
                      <v-list-item slot="prepend-item" class="primary--text" @click="fn_menuItemClick('newproduct')">
                        <v-list-item-title>Create New</v-list-item-title>
                      </v-list-item>

                    </v-autocomplete>

                    <div font12 class="pt-1 pl-1" v-if="selectedItem" style="display:flex">

                      <template v-if="$parent.Form.productDataModel.purchaseRate">
                        Purchase Rate: &#8377;{{$root.numberToLocale($parent.Form.productDataModel.purchaseRate)}}
                        <v-spacer></v-spacer>
                      </template>

                      <template v-for="(sItem, index) in summaryArray">
                        Stock: <span :class="{'redcolor':sItem.qty<0}" :key="index">{{sItem.qty}} {{sItem.unitName}}</span>
                      </template>
                      
                    </div>
                  </v-flex>

                  <v-flex xs2 sm2 md2 style="display:flex;align-items: center;">
                    <v-progress-circular class="mb-2" indeterminate :width="2" :size="20" color="primary" v-if="loadingProductData"></v-progress-circular>
                  </v-flex>

                  <template v-if="selectedItem && !loadingProductData">
                    <v-flex xs2 sm2 md2 pr-2 pl-2>
                      <ValidationProvider name="Rate" rules="min_value:0|decimal2" v-slot="{ errors }">
                        <div font11 light-opacity-color>Rate</div>
                        <v-text-field autocomplete="null" 
                          id="fieldRate"
                          class="small-text-box"
                          v-model="$parent.Form.productDataModel.rate"
                          @keyup.enter="$parent.fn_addItem($parent.Form.productDataModel.productVariants[0].qty)"
                          outlined
                          type="number"
                          hide-details
                          :error-messages="errors"
                          dense>
                        </v-text-field>

                        <div style="font-size:9px;padding:2px" redcolor>{{errors[0]}}</div>
                      </ValidationProvider>
                    </v-flex>

                    <v-flex xs2 sm2 md2 pr-4 pl-2>
                      <template v-for="(vItem, vIndex) in $parent.Form.productDataModel.productVariants">
                            
                        <ValidationProvider :key="'vali'+vIndex" name="Quantity" rules="required" v-slot="{ errors }">

                          <div font11 light-opacity-color>{{fn_getLabel(vItem)}}</div>
                          <v-text-field 
                            autocomplete="null" 
                            id="fieldQty"
                            @input="$root.fn_boundDecimalLength(vItem, 'qty')"
                            @keyup.enter="$parent.fn_addItem(vItem.qty)"
                            class="small-text-box"
                            :key="vIndex"
                            outlined
                            hide-details
                            v-model="vItem.qty"
                            persistent-hint
                            type="number"
                            :error-messages="errors"
                            required
                            dense>
                          </v-text-field>

                          <div style="font-size:9px;padding:2px" redcolor :key="'errors'+vIndex">{{errors[0]}}</div>

                        </ValidationProvider>

                        <div v-if="vItem.qty" font11 right-align :key="'total'+vIndex">

                          <template v-if="$parent.Form.taxMode">
                            <div>
                              <span light-opacity-color>@{{$parent.Form.productDataModel.taxValue}}%</span>&nbsp;&nbsp;&#8377;&nbsp;{{$root.numberToLocale($parent.Form.productDataModel.taxAmount)}}
                            </div>

                            <div v-if="$parent.Form.productDataModel.cessValue !== null">
                              <span light-opacity-color>Cess @{{$parent.Form.productDataModel.cessValue}}%</span>&nbsp;&nbsp;&#8377;&nbsp;
                              {{$root.numberToLocale($parent.Form.productDataModel.cessAmount)}}  
                            </div>
                          </template>
                          
                          <div>
                            <span light-opacity-color>Total:</span>&nbsp;&nbsp;&#8377;&nbsp;{{$root.numberToLocale(fn_computeValues($parent.Form.productDataModel, true))}}
                          </div>
                        </div>

                        <!-- <div class="pt-2" right-align :key="'enterbtn'+vIndex">
                          <v-btn @click="$parent.fn_addItem(vItem.qty)" color="primary" small>Enter <v-icon class="ml-2" font20>keyboard_return</v-icon> </v-btn>
                        </div> -->
                      </template>
                    </v-flex>

                    <v-flex xs2 sm2 md2>
                      <div style="padding-top:15px" class="mr-4" right-align :key="'enterbtn'+vIndex">
                          <v-btn block style="height:33px" @click="$parent.fn_addItem($parent.Form.productDataModel.productVariants[0].qty)" color="primary" small>Add Item</v-btn>
                        </div>

                        <div full-width right-align v-if="selectedItem">
                          <v-chip class="mr-4 mt-3 pl-4" label small>
                            <v-icon left>info_outline</v-icon>
                            Press Enter Key to Add
                          </v-chip>
                        </div>
                    </v-flex>
                  </template>

                  <!-- <div full-width right-align v-if="selectedItem">
                    <v-chip class="mr-4 pl-4" label small>
                      <v-icon left>info_outline</v-icon>
                      Press Enter Key to Add <v-icon class="ml-2 mr-2" font20>keyboard_return</v-icon>
                    </v-chip>
                  </div> -->

                  <!-- <v-flex xs12 sm12 md12 right-align pr-4 pt-1 v-if="selectedItem && !loadingProductData">
                    <v-btn @click="$parent.fn_addItem(vItem.qty)" color="primary" small>Enter <v-icon class="ml-2" font20>keyboard_return</v-icon> </v-btn>
                  </v-flex> -->
                </v-layout>

                  <!-- <v-divider></v-divider> -->

                  <div class="items-table-wrapper pa-4 pt-0 white-back">
                    <table full-width class="items-table" :class="{'has-items':$parent.Form.items.length}" font11>
                      <thead sticky-top white-back>
                        <tr>
                          <th left-align style="min-width:220px">Items</th>
                          <th left-align v-if="$root.TransactionSettings.mrpWiseStock">MRP</th>
                          <th left-align>Rate</th>
                          <th left-align style="width:120px">Qty</th>
                          <th left-align v-if="$parent.Form.hasItemAddOns">Discount (-)</th>  <!-- Fixed for now -->
                          <th left-align v-if="$parent.Form.taxMode" style="width:140px;">{{$parent.Form.isRcmApplicable ? 'Tax (RCM)' : 'Tax' }}</th>
                          <th left-align style="width:120px" v-if="$root.CurrentBusiness.GSTRegistrationStatus==1 && $root.CurrentBusiness.HSNApplicable">HSN</th>
                          <th left-align>Item Total</th>
                          <th left-align style="width:42px">Notes</th>
                          <th left-align style="width:42px">
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <template v-for="(groupItems, category) in $parent.filteredItems">

                          <template v-for="(group, groupIndex) in groupItems">

                            <!-- <tr :key="groupIndex" v-if="category!='self' && group.items && group.items.length" grey-back> -->
                            <tr :key="groupIndex" v-if="($parent.Form.pendingEstimates.length || $parent.Form.pendingOrders.length || $parent.Form.pendingChallans.length || $parent.Form.pendingInvoices.length || ($parent.Form.isPendingItems && category!='self')) && group.items && group.items.length" grey-back>
                              <td :colspan="fn_getHeaderColsLength() - 1">
                                {{group.header}}&nbsp;
                                <span v-if="$parent.Form.transactionData && group.baseTransactionNumber != $parent.Form.transactionData.mergeTransactionNumber">
                                  {{group.baseTransactionNumber}} 
                                </span>
                              </td>

                              <td>
                                <v-btn v-if="category!='self'" small icon color="red" @click="$parent.fn_removeGroup(category, group.baseTransactionNumber)">
                                  <v-icon font16>remove_circle_outline</v-icon>
                                </v-btn>
                              </td>
                            </tr>
                            
                            <tr v-for="(item, itemIndex) in group.items" :key="itemIndex" :class="'item_'+item.groupId">

                              <td><div :id="'itemname'+itemIndex">{{item.productName}}</div></td>

                              <td v-if="$root.TransactionSettings.mrpWiseStock">
                                <ValidationProvider :name="'MRP '+itemIndex" rules="min_value:0|decimal2" v-slot="{ errors }">
                                  <v-text-field autocomplete="null" 
                                    :id="'fieldMRP'+itemIndex"
                                    outlined
                                    v-model.number="item.mrp"
                                    type="number"
                                    hide-details
                                    :error-messages="errors"
                                    dense>
                                  </v-text-field>

                                  <div style="font-size:9px;padding:2px" redcolor>{{errors[0]}}</div>
                                </ValidationProvider>
                              </td>

                              <td>
                                <ValidationProvider :name="'Rate '+itemIndex" rules="min_value:0|decimal2" v-slot="{ errors }">
                                  <v-text-field autocomplete="null" 
                                    :id="'fieldRate'+itemIndex"
                                    outlined
                                    v-model.number="item.rate"
                                    @input="fn_computeValues(item)"
                                    type="number"
                                    hide-details
                                    :error-messages="errors"
                                    dense>
                                  </v-text-field>

                                  <div style="font-size:9px;padding:2px" redcolor>{{errors[0]}}</div>
                                </ValidationProvider>
                              </td>

                              <td>
                                <template v-for="(vItem, vIndex) in item.productVariants">
                                  <ValidationProvider :key="'vali'+vIndex" :name="'Quantity '+itemIndex+vIndex" :rules="fn_getQtyValidationRules(vItem)" v-slot="{ errors }">
                                    <v-text-field autocomplete="null" 
                                      :id="'fieldQty'+itemIndex"
                                      @input="$root.fn_boundDecimalLength(vItem, 'qty', item, fn_computeValues)"
                                      :disabled="!vItem.billQty && category=='invoice' ? true : false"
                                      :key="vIndex"
                                      outlined
                                      hide-details
                                      v-model="vItem.qty"
                                      persistent-hint
                                      type="number"
                                      :error-messages="errors"
                                      required
                                      dense>
                                    </v-text-field>

                                    <div right-align>({{fn_getLabel(vItem)}})</div>
                                    <div style="font-size:9px;padding:2px" redcolor :key="'errors'+vIndex">{{errors[0]}}</div>

                                    <div style="font-size:9px;padding:2px" right-align>
                                      <span v-if="vItem.billQty != undefined">{{'Bill Qty: ' + vItem.billQty}}</span>
                                      <span @click="$parent.fn_getBillQuantity(item, itemIndex)" v-if="!vItem.billQty && category=='invoice'"><v-icon font16 v-ripple cursor-pointer>edit</v-icon></span>
                                    </div>

                                    <!-- <div right-align><v-icon font16 v-if="category=='invoice'" v-ripple cursor-pointer>edit</v-icon></div> -->
                                  </ValidationProvider>
                                </template>
                              </td>

                              <td v-for="(addOnItem, index) in item.addOnsItems" :key="index">
                                <template v-if="!addOnItem.isGst">
                                      
                                    <template v-if="addOnItem.mode==0">
                                      
                                      <!-- <v-flex xs2 sm2 md2 form-item :key="index+'percentage'"> -->
                                        <ValidationProvider :name="'Percentage ' + itemIndex" rules="min_value:0|decimal2" v-slot="{ errors }">
                                          <v-text-field autocomplete="null" 
                                            outlined
                                            hide-details
                                            v-model.number="addOnItem.percentage"
                                            @input="fn_checkPercentage(addOnItem, item)"
                                            suffix="%"
                                            type="number"
                                            :error-messages="errors"
                                            dense>
                                          </v-text-field>

                                          <div style="font-size:9px;padding:2px" redcolor>{{errors[0]}}</div>
                                        </ValidationProvider>

                                      <!-- </v-flex> -->

                                      <!-- <v-flex xs2 sm2 md2 form-item :key="index+'value'"> -->

                                        <ValidationProvider :name="'Value ' + itemIndex" rules="min_value:0|decimal2" v-slot="{ errors }">
                                          <v-text-field autocomplete="null"
                                            outlined
                                            hide-details 
                                            v-model.number="addOnItem.value"
                                            @input="fn_checkValue(addOnItem, item)"
                                            prefix="₹"
                                            type="number"
                                            :error-messages="errors"
                                            dense>
                                          </v-text-field>

                                          <div style="font-size:9px;padding:2px" redcolor>{{errors[0]}}</div>
                                        </ValidationProvider>

                                      <!-- </v-flex> -->

                                    </template>

                                    <template v-else>
                                      <!-- <v-flex xs2 sm2 md2 form-item :key="index+'name'"> -->

                                      <ValidationProvider :name="'Value ' + itemIndex" rules="min_value:0|decimal2" v-slot="{ errors }">
                                        <v-text-field autocomplete="null" 
                                          outlined
                                          @input="fn_computeValues(item)"
                                          hide-details
                                          v-model.number="addOnItem.value"
                                          type="number"
                                          :error-messages="errors"
                                          dense>
                                        </v-text-field>

                                        <div style="font-size:9px;padding:2px" redcolor>{{errors[0]}}</div>
                                      </ValidationProvider>

                                      <!-- </v-flex> -->
                                    </template>
                                    

                                </template>
                              </td>

                              <td v-if="$parent.Form.taxMode">

                                <v-autocomplete
                                  class="small-textbox-table"
                                  :items="$parent.taxCategories ? $parent.taxCategories : []"
                                  v-model="item.selectedTaxCategory"
                                  outlined
                                  item-text="taxCategoryName"
                                  item-value="taxCategoryId"
                                  @input="$parent.fn_updateTaxCategory($event, item.groupId)"
                                  return-object
                                  dense
                                  append>
                                  
                                  <!-- <v-list-item slot="prepend-item" class="primary--text" @click="fn_menuItemClick('newTaxCategory', itemIndex)"> -->
                                    <v-list-item slot="prepend-item" class="primary--text" @click="fn_menuItemClick('newTaxCategory', item.groupId)">
                                        <v-list-item-title>Create New</v-list-item-title>
                                    </v-list-item>

                                </v-autocomplete>

                                <template v-if="item.selectedTaxCategory && item.selectedTaxCategory.taxCategoryId">
                                  <div right-align>
                                    <span light-opacity-color>@{{item.taxValue}}%</span>&nbsp;&nbsp;&#8377;&nbsp;{{$root.numberToLocale(item.taxAmount)}}
                                  </div>

                                  <div right-align v-if="item.isCessApplicable">
                                    <span light-opacity-color>Cess @{{item.cessValue}}%</span>&nbsp;&nbsp;&#8377;&nbsp;{{$root.numberToLocale(item.cessAmount)}}
                                  </div>
                                </template>
                                
                              
                              </td>

                              <td v-if="$root.CurrentBusiness.GSTRegistrationStatus==1 && $root.CurrentBusiness.HSNApplicable">
                                <!-- <v-flex xs2 sm2 md2 form-item v-if="$root.CurrentBusiness.GSTRegistrationStatus==1 && $root.CurrentBusiness.HSNApplicable"> -->
                                  <ValidationProvider :name="'HSN ' + itemIndex" rules="verify_hsn" v-slot="{ errors }">
                                    <v-text-field autocomplete="null" 
                                      outlined
                                      hide-details
                                      v-model="item.hsnCode"
                                      dense>
                                    </v-text-field>

                                    <div style="font-size:9px;padding:2px" redcolor>{{errors[0]}}</div>
                                  </ValidationProvider>
                                <!-- </v-flex> -->
                              </td>

                              <!-- <td>{{$root.numberToLocale(fn_computeValues(item))}}</td> -->
                              <td>{{$root.numberToLocale(item.netAmount)}}</td>

                              <td>
                                <v-menu :close-on-content-click="false" v-model="item.menu" :min-width="280" :max-width="280">
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-btn small icon color="primary" v-bind="attrs" v-on="on" @click="fn_focusNoteTextArea(itemIndex)">
                                      <v-icon font16 :color="item.description && item.description.length > 255 ? 'red' : ''">{{item.description ? 'edit' : 'notes'}}</v-icon>
                                    </v-btn>
                                  </template>

                                  <v-card>
                                    <v-card-text>
                                      <!-- <v-textarea
                                        :id="'note'+itemIndex"
                                        font12
                                        outlined
                                        hide-details
                                        v-model="item.description"
                                        rows="2"
                                        :counter="100"
                                        dense>
                                      </v-textarea>     -->
                                      <ValidationProvider name="Description" rules="max:255" v-slot="{ errors }">
                                        <v-textarea
                                          class="item-note-field"
                                          style="line-height: 1.2rem !important;"
                                          :id="'note'+itemIndex"
                                          font12
                                          outlined
                                          v-model="item.description"
                                          rows="4"
                                          :counter="255"
                                          dense
                                          :error-messages="errors">
                                        </v-textarea>
                                      </ValidationProvider>
                                    </v-card-text>

                                    <v-card-actions>
                                      <v-spacer></v-spacer>
                                      <v-btn color="warning" small text @click="item.description=null;item.menu=false" v-if="item.description">Remove</v-btn>
                                      <v-btn color="primary" small text @click="item.menu=false">Done</v-btn>
                                    </v-card-actions>
                                  </v-card>
                                  
                                </v-menu>

                                <div v-if="item.description && item.description.length > 255" class="error--text font10 field-has-error">Note field should be less then 255 characters</div>

                              </td>

                              <td><v-btn icon small @click="$parent.fn_removeItem(item.groupId, category, groupIndex, group.baseTransactionNumber)"><v-icon font16 color="red">delete</v-icon></v-btn></td>  
                            </tr>

                          </template>
                          
                        </template>
                      </tbody>
                    </table>
                  </div>

                <v-list dense nopadding v-if="$parent.Form.items.length">

                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title>Items Total</v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-action>{{$root.numberToLocale($parent.Form.itemsSummary.grossAmount)}}</v-list-item-action>
                  </v-list-item>

                  <v-list-item v-for="(item, index) in $parent.Form.itemsSummary.addOnsItems" :key="index">
                    <template v-if="item.value!==null && item.value!==''">
                      <v-list-item-content>
                        <v-list-item-title>{{item.nature ? 'Items Discount' : item.name}}</v-list-item-title>
                      </v-list-item-content>
                      <v-list-item-action>{{item.nature ? '-' : ''}}{{$root.numberToLocale(item.value)}}</v-list-item-action>  
                    </template>
                  </v-list-item>

                  <div v-if="$parent.Form.taxMode && $parent.Form.items.length">
                    <!-- When dealing within state -->
                    <div v-if="$parent.Form.gstType=='IntraState'">
                      <v-list-item>
                        <v-list-item-content>
                          <v-list-item-title>CGST{{$parent.Form.isRcmApplicable ? ' (RCM)' : ''}}</v-list-item-title>
                        </v-list-item-content>
                        <v-list-item-action>{{$root.numberToLocale(roundUpto2Decimals($parent.Form.itemsSummary.taxValue/2))}}</v-list-item-action>
                      </v-list-item>
          
                      <v-list-item>
                        <v-list-item-content>
                          <v-list-item-title>SGST{{$parent.Form.isRcmApplicable ? ' (RCM)' : ''}}</v-list-item-title>
                        </v-list-item-content>
                        <v-list-item-action>{{$root.numberToLocale(roundUpto2Decimals($parent.Form.itemsSummary.taxValue/2))}}</v-list-item-action>
                      </v-list-item>
                    </div>

                    <!-- When dealing with other state -->
                    <div v-if="$parent.Form.gstType=='InterState'">
                      <v-list-item>
                        <v-list-item-content>
                          <v-list-item-title>IGST{{$parent.Form.isRcmApplicable ? ' (RCM)' : ''}}</v-list-item-title>
                        </v-list-item-content>
                        <v-list-item-action>{{$root.numberToLocale(roundUpto2Decimals($parent.Form.itemsSummary.taxValue))}}</v-list-item-action>
                      </v-list-item>
                    </div>

                    <!-- When dealing from union territory to other state -->
                    <div v-if="$parent.Form.gstType=='UnionTerritory'">
                      <v-list-item>
                        <v-list-item-content>
                          <v-list-item-title>CGST{{$parent.Form.isRcmApplicable ? ' (RCM)' : ''}}</v-list-item-title>
                        </v-list-item-content>
                        <v-list-item-action>{{$root.numberToLocale(roundUpto2Decimals($parent.Form.itemsSummary.taxValue/2))}}</v-list-item-action>
                      </v-list-item>

                      <v-list-item>
                        <v-list-item-content>
                          <v-list-item-title>UTGST{{$parent.Form.isRcmApplicable ? ' (RCM)' : ''}}</v-list-item-title>
                        </v-list-item-content>
                        <v-list-item-action>{{$root.numberToLocale(roundUpto2Decimals($parent.Form.itemsSummary.taxValue/2))}}</v-list-item-action>
                      </v-list-item>
                    </div>

                    <!-- When Cess is applicable -->
                    <div v-if="$parent.Form.itemsSummary.cessValue">
                      <v-list-item>
                        <v-list-item-content>
                          <v-list-item-title>Cess{{$parent.Form.isRcmApplicable ? ' (RCM)' : ''}}</v-list-item-title>
                        </v-list-item-content>
                        <v-list-item-action>{{$root.numberToLocale(roundUpto2Decimals($parent.Form.itemsSummary.cessValue))}}</v-list-item-action>
                      </v-list-item>
                    </div>
                  </div>

                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title>Net Amount</v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-action>&#8377; {{$root.numberToLocale($parent.netAmount)}}</v-list-item-action>
                  </v-list-item>

                </v-list>


                <v-layout row wrap white-back ma-0 mt-2 pt-4 pb-4 v-if="!$parent.Form.items.length && ($parent.settings.transactionTypeId == 10003 || $parent.settings.transactionTypeId == 10004)">
                  <v-flex xs6 sm6 md6 form-item>
                    <ValidationProvider name="Description" rules="max:255" v-slot="{ errors }">
                      <div font11 light-opacity-color>Description</div>
                      <v-textarea
                        outlined
                        v-model="$parent.Form.description"
                        rows="2"
                        dense
                        :counter="255"
                        :error-messages="errors">
                      </v-textarea>
                    </ValidationProvider>
                  </v-flex>

                  <v-flex xs3 sm3 md3></v-flex>

                  <v-flex xs3 sm3 md3 form-item>
                    <div font11 light-opacity-color>Net Amount</div>
                    <v-text-field autocomplete="null" outlined hide-details dense right-align name="name" prefix="₹" type="number" @input="$root.fn_boundDecimalLengthUpto2($parent.Form, 'netInvoiceValue')" v-model.number="$parent.Form.netInvoiceValue"></v-text-field>
                    <!-- <v-text-field autocomplete="null" outlined hide-details dense right-align name="name" prefix="₹" type="number" v-model.number="$parent.Form.netInvoiceValue"></v-text-field> -->

                    <div class="small-checkboxes" v-if="!$parent.Form.items.length && ($parent.settings.transactionTypeId == 10003 || $parent.settings.transactionTypeId == 10004)">
                      <v-checkbox class="ml-4" hide-details label="Service Bill" v-model="$parent.Form.itemType"></v-checkbox>
                    </div>
                  </v-flex>
                </v-layout>

                <v-layout ma-0 mt-2 row wrap pt-3 pr-4 pb-3 pl-4 white-back v-if="$parent.roundedAmount">
                  
                  <template>
                    <v-flex center-align xs3 sm3 md3 pr-2>
                        <!-- <v-btn full-width outlined light-opacity-color nopadding capitalize nomargin style="border-radius:5px;" text @click="$parent.fn_openAddOns(3, true)"><v-icon font18 style="margin-right:5px">add</v-icon>Charges</v-btn> -->
                        <v-menu :close-on-content-click="false" v-model="chargesMenu" :max-height="400" :min-width="280" :max-width="280">
                          <template v-slot:activator="{ on, attrs }">

                            <v-btn full-width outlined light-opacity-color nopadding capitalize nomargin style="border-radius:5px;" v-bind="attrs" v-on="on" text @click="fn_focusSearch('charge_search')"><v-icon font18 style="margin-right:5px">add</v-icon> Charges</v-btn>

                          </template>

                          <v-list dense nopadding>
                            <v-list-item style="position: sticky;top: 0;background: #fff;z-index: 2;padding-bottom: 8px;">
                              <v-list-item-content>
                                <v-text-field autocomplete="null" id="charge_search" dense v-model="$parent.otherChargeSearchValue" placeholder="search" hide-details></v-text-field>
                              </v-list-item-content>
                            </v-list-item>
                            <v-list-item @click="fn_menuItemClick('addothercharges')">
                              <v-list-item-content>
                                <v-list-item-title class="primary-color">Create New</v-list-item-title>
                              </v-list-item-content>
                            </v-list-item>
                            <v-list-item v-for="(item, index) in $parent.charges" :key="index" @click="fn_updateAddOns(item)">
                              <v-list-item-content>
                                <v-list-item-title medium-theme-color>{{item.itemName}}</v-list-item-title>
                              </v-list-item-content>
                            </v-list-item>
                          </v-list>
                        </v-menu>
                    </v-flex>
                    <v-flex center-align xs3 sm3 md3 pr-2 pl-2>
                        <!-- <v-btn full-width outlined light-opacity-color nopadding capitalize nomargin style="border-radius:5px;" text @click="$parent.fn_openAddOns(1, true)"><v-icon font18 style="margin-right:5px">add</v-icon> Discount</v-btn> -->

                      <v-menu :close-on-content-click="false" v-model="discountMenu" :max-height="400" :min-width="280" :max-width="280">
                        <template v-slot:activator="{ on, attrs }">

                          <v-btn full-width outlined light-opacity-color nopadding capitalize nomargin style="border-radius:5px;" v-bind="attrs" v-on="on" text @click="fn_focusSearch('discount_search')"><v-icon font18 style="margin-right:5px">add</v-icon> Discount</v-btn>

                        </template>

                        <v-list dense nopadding>
                          <v-list-item style="position: sticky;top: 0;background: #fff;z-index: 2;padding-bottom: 8px;">
                            <v-list-item-content>
                              <v-text-field autocomplete="null" id="discount_search" dense v-model="$parent.discountSearchValue" placeholder="search" hide-details></v-text-field>
                            </v-list-item-content>
                          </v-list-item>
                          <v-list-item @click="fn_menuItemClick('adddiscount')">
                            <v-list-item-content>
                              <v-list-item-title class="primary-color">Create New</v-list-item-title>
                            </v-list-item-content>
                          </v-list-item>
                          <v-list-item v-for="(item, index) in $parent.discounts" :key="index" @click="fn_updateAddOns(item)">
                            <v-list-item-content>
                              <v-list-item-title medium-theme-color>{{item.itemName}}</v-list-item-title>
                            </v-list-item-content>
                          </v-list-item>
                        </v-list>
                      </v-menu>

                    </v-flex>
                    <v-flex center-align xs3 sm3 md3 pl-2>
                        <!-- <v-btn full-width outlined light-opacity-color nopadding capitalize nomargin style="border-radius:5px;" text @click="$parent.fn_openAddOns(2, true)" v-if="(($root.CurrentBusiness.GSTRegistrationStatus!=1 && !$parent.Form.isItcEligible) || ($root.CurrentBusiness.GSTRegistrationStatus==1 && $parent.Form.isItcEligible))"><v-icon font18 style="margin-right:5px">add</v-icon> Tax</v-btn> -->

                        <v-menu :close-on-content-click="false" v-model="taxesMenu" :max-height="400" :min-width="280" :max-width="280" v-if="$root.CurrentBusiness.GSTRegistrationStatus == 1 && !$parent.Form.items.length">
                          <template v-slot:activator="{ on, attrs }">

                            <v-btn full-width outlined light-opacity-color nopadding capitalize nomargin style="border-radius:5px;" v-bind="attrs" v-on="on" text @click="fn_focusSearch('tax_search')"><v-icon font18 style="margin-right:5px">add</v-icon> Tax</v-btn>

                          </template>

                          <v-list dense nopadding>
                            <v-list-item style="position: sticky;top: 0;background: #fff;z-index: 2;padding-bottom: 8px;">
                              <v-list-item-content>
                                <v-text-field autocomplete="null" id="tax_search" dense v-model="$parent.taxSearchValue" placeholder="search" hide-details></v-text-field>
                              </v-list-item-content>
                            </v-list-item>
                            <v-list-item @click="fn_menuItemClick('addtaxes')">
                              <v-list-item-content>
                                <v-list-item-title class="primary-color">Create New</v-list-item-title>
                              </v-list-item-content>
                            </v-list-item>
                            <v-list-item v-for="(item, index) in $parent.taxes" :key="index" @click="fn_updateAddOns(item)">
                              <v-list-item-content>
                                <v-list-item-title medium-theme-color>{{item.itemName}}</v-list-item-title>
                              </v-list-item-content>
                            </v-list-item>
                          </v-list>
                        </v-menu>


                        <!-- <v-btn full-width outlined light-opacity-color nopadding capitalize nomargin style="border-radius:5px;" text @click="fn_openTdsAccounts" v-else><v-icon font18 style="margin-right:5px">add</v-icon> TDS</v-btn> -->
                    </v-flex>
                  </template>

                  <!-- <template>
                    <v-flex center-align xs3 sm3 md3 pl-4>

                      <v-menu :close-on-content-click="false" v-model="tdsMenu" :max-height="400" :min-width="280" :max-width="280">
                        <template v-slot:activator="{ on, attrs }">

                          <v-btn full-width outlined light-opacity-color nopadding capitalize nomargin style="border-radius:5px;" v-bind="attrs" v-on="on" text @click="fn_openTdsAccounts"><v-icon font18 style="margin-right:5px">add</v-icon> TDS</v-btn>

                        </template>

                        <v-list dense nopadding>
                          <v-list-item style="position: sticky;top: 0;background: #fff;z-index: 2;padding-bottom: 8px;">
                            <v-list-item-content>
                              <v-text-field autocomplete="null" id="tds_search" dense v-model="$parent.tdsSearchValue" placeholder="search" hide-details></v-text-field>
                            </v-list-item-content>
                          </v-list-item>
                          <v-list-item @click="fn_menuItemClick('newtdsledger')">
                            <v-list-item-content>
                              <v-list-item-title class="primary-color">Create New</v-list-item-title>
                            </v-list-item-content>
                          </v-list-item>
                          <v-list-item v-for="(item, index) in $parent.filteredTds" :key="index" @click="fn_updateTdsAccount(item)">
                            <v-list-item-content>
                              <v-list-item-title medium-theme-color>{{item.itemName}}</v-list-item-title>
                            </v-list-item-content>
                          </v-list-item>
                        </v-list>
                      </v-menu>

                    </v-flex>
                  </template> -->

                </v-layout>

                <div full-width class="mt-2">

                  <!-- Charges applied -->
                  <template v-if="$parent.otherChargeItems && $parent.otherChargeItems.length">
                    <v-layout white-back row wrap ma-0 v-for="(item, index) in $parent.otherChargeItems" :key="index">
                      <v-flex xs1 sm1 md1 pl-3 flex-vertical-center>
                        <v-btn icon @click="$parent.fn_removeAddOns(item)"><v-icon>close</v-icon></v-btn>
                      </v-flex>

                      <v-flex xs5 sm5 md5 flex-vertical-center>{{item.name}}</v-flex>

                      <v-flex xs2 sm2 md2 pt-4 flex-vertical-center justify-end right-align>
                        <v-menu left v-if="item.mode==0">
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn icon v-bind="attrs" v-on="on"><v-icon>more_vert</v-icon></v-btn>
                          </template>
                          <v-list dense>
                            <v-list-item @click="item.appliedOn = 0">
                              <v-list-item-content>
                                <v-list-item-title>Net Value</v-list-item-title>
                              </v-list-item-content>
                              <v-list-item-action><v-icon>{{item.appliedOn == 0 ? 'radio_button_checked' : 'radio_button_unchecked'}}</v-icon></v-list-item-action>
                            </v-list-item>
                            <v-list-item @click="item.appliedOn = 1">
                              <v-list-item-content>
                                <v-list-item-title>Value of Goods</v-list-item-title>
                              </v-list-item-content>
                              <v-list-item-action><v-icon>{{item.appliedOn == 1 ? 'radio_button_checked' : 'radio_button_unchecked'}}</v-icon></v-list-item-action>
                            </v-list-item>
                          </v-list>
                        </v-menu>
                        
                      </v-flex>

                      <v-flex xs2 sm2 md2 pa-2>
                        <template v-if="item.mode==0">
                          <div font11 light-opacity-color>Percentage</div>
                          <ValidationProvider name="chargesPercentage" rules="decimal2" v-slot="{ errors }">
                            <v-text-field autocomplete="null"  
                              :id="'percentageField'+item.addOnsId"
                              class="small-text-box right-align"
                              type="number"
                              v-model.number="item.percentage" 
                              @input="!item.percentage ? item.value = null : ''"
                              suffix="%"
                              dense
                              :error-messages="errors"
                              hide-details
                              outlined>
                            </v-text-field>  
                            <div style="font-size:9px;padding:2px" redcolor>{{errors[0]}}</div>
                          </ValidationProvider>
                        </template>
                      </v-flex>

                      <v-flex xs2 sm2 md2 pa-2 pr-4>
                        <div font11 light-opacity-color>Value</div>
                        <ValidationProvider name="chargesValue" rules="decimal2" v-slot="{ errors }">
                          <v-text-field autocomplete="null"  
                            :id="'valueField'+item.addOnsId"
                            class="small-text-box right-align"
                            type="number"
                            v-model.number="item.value" 
                            @input="item.percentage = null"
                            hide-details
                            :error-messages="errors"
                            :prefix="item.nature ? '-' : ''"
                            dense
                            outlined>
                          </v-text-field>
                          <div style="font-size:9px;padding:2px" redcolor>{{errors[0]}}</div>
                        </ValidationProvider>
                      </v-flex>

                      <!-- <v-flex xs2 sm2 md2 pa-3 pr-4 right-align>-{{$parent.tdsAmount}}</v-flex> -->
                    </v-layout>
                  </template>
                  <!-- <div white-back class="small-textbox-container" v-if="$parent.otherChargeItems && $parent.otherChargeItems.length">

                    <v-layout ma-0 row wrap mt-2 class="layout-wrap" v-for="(item, index) in $parent.otherChargeItems" :key="index">
                    <v-flex xs8 sm8 md8 class="small-textbox-label">
                      <v-btn icon @click="$parent.fn_removeAddOns(item)"><v-icon>close</v-icon></v-btn>
                      <div @click="$parent.fn_editAddOns(item)">{{item.name}} {{item.percentage!==null && item.percentage!=='' ? '@'+item.percentage+'%' : ''}}</div>
                    </v-flex>
                    <v-flex xs4 sm4 md4 pr-3 class="small-textbox">
                      <ValidationProvider :name="item.name" rules="min_value:0|decimal2" v-slot="{ errors }">
                        <v-text-field autocomplete="null" 
                          v-model.number="item.value" 
                          @input="item.percentage = null"  
                          type="number" 
                          label="" 
                          :prefix="item.nature ? '-' : ''"
                          :error-messages="errors"
                          :hide-details="!errors || !errors.length">
                        </v-text-field>
                      </ValidationProvider>
                    </v-flex>
                    </v-layout>
                  </div> -->

                  <!-- Discounts applied -->
                  <template v-if="$parent.discountItems && $parent.discountItems.length">
                    <v-layout white-back row wrap ma-0 v-for="(item, index) in $parent.discountItems" :key="index">
                      <v-flex xs1 sm1 md1 pl-3 flex-vertical-center>
                        <v-btn icon @click="$parent.fn_removeAddOns(item)"><v-icon>close</v-icon></v-btn>
                      </v-flex>

                      <v-flex xs5 sm5 md5 flex-vertical-center>{{item.name}}</v-flex>

                      <v-flex xs2 sm2 md2 pt-4 flex-vertical-center justify-end right-align>
                        <v-menu left v-if="item.mode==0">
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn icon v-bind="attrs" v-on="on"><v-icon>more_vert</v-icon></v-btn>
                          </template>
                          <v-list dense>
                            <v-list-item @click="item.appliedOn = 0">
                              <v-list-item-content>
                                <v-list-item-title>Net Value</v-list-item-title>
                              </v-list-item-content>
                              <v-list-item-action><v-icon>{{item.appliedOn == 0 ? 'radio_button_checked' : 'radio_button_unchecked'}}</v-icon></v-list-item-action>
                            </v-list-item>
                            <v-list-item @click="item.appliedOn = 1">
                              <v-list-item-content>
                                <v-list-item-title>Value of Goods</v-list-item-title>
                              </v-list-item-content>
                              <v-list-item-action><v-icon>{{item.appliedOn == 1 ? 'radio_button_checked' : 'radio_button_unchecked'}}</v-icon></v-list-item-action>
                            </v-list-item>
                          </v-list>
                        </v-menu>
                        
                      </v-flex>

                      <v-flex xs2 sm2 md2 pa-2>
                        <template v-if="item.mode==0">
                          
                          <div font11 light-opacity-color>Percentage</div>
                          <ValidationProvider name="discountPercentage" rules="decimal2" v-slot="{ errors }">
                            <v-text-field autocomplete="null"  
                              :id="'percentageField'+item.addOnsId"
                              class="small-text-box right-align"
                              type="number"
                              v-model.number="item.percentage" 
                              @input="!item.percentage ? item.value = null : ''"
                              suffix="%"
                              dense
                              :error-messages="errors"
                              hide-details
                              outlined>
                            </v-text-field>
                            <div style="font-size:9px;padding:2px" redcolor>{{errors[0]}}</div>
                          </ValidationProvider>
                        </template>
                        
                      </v-flex>

                      <v-flex xs2 sm2 md2 pa-2 pr-4>
                        <div font11 light-opacity-color>Value</div>
                        <ValidationProvider name="discountValue" rules="decimal2" v-slot="{ errors }">
                          <v-text-field autocomplete="null"
                            :id="'valueField'+item.addOnsId"
                            class="small-text-box right-align"
                            type="number"
                            v-model.number="item.value" 
                            @input="item.percentage = null"
                            :error-messages="errors"
                            hide-details
                            :prefix="item.nature ? '-' : ''"
                            dense
                            outlined>
                          </v-text-field>
                          <div style="font-size:9px;padding:2px" redcolor>{{errors[0]}}</div>
                        </ValidationProvider>
                      </v-flex>

                      <!-- <v-flex xs2 sm2 md2 pa-3 pr-4 right-align>-{{$parent.tdsAmount}}</v-flex> -->
                    </v-layout>
                  </template>
                  

                  <!-- <div white-back class="small-textbox-container" v-if="$parent.discountItems && $parent.discountItems.length">
                    <v-layout ma-0 row wrap class="layout-wrap" v-for="(item, index) in $parent.discountItems" :key="index">

                      <v-flex></v-flex>
                    <v-flex xs8 sm8 md8 class="small-textbox-label">
                      <v-btn icon @click="$parent.fn_removeAddOns(item)"><v-icon>close</v-icon></v-btn>
                      <div @click="$parent.fn_editAddOns(item)">{{item.name}} {{item.percentage!==null && item.percentage!=='' ? '@'+item.percentage+'%' : ''}}</div>
                    </v-flex>
                    <v-flex xs4 sm4 md4 pr-3 class="small-textbox">
                      <ValidationProvider :name="item.name" rules="min_value:0|decimal2" v-slot="{ errors }">
                        <v-text-field autocomplete="null" 
                          v-model.number="item.value" 
                          @input="item.percentage = null"  
                          type="number" 
                          label="" 
                          :prefix="item.nature ? '-' : ''"
                          :error-messages="errors"
                          :hide-details="!errors || !errors.length">
                        </v-text-field>
                      </ValidationProvider>
                    </v-flex>
                    </v-layout>
                  </div> -->

                  <!-- Tax applied -->
                  <template v-if="$parent.taxItems && $parent.taxItems.length">
                    <v-layout white-back row wrap ma-0 v-for="(item, index) in $parent.taxItems" :key="index">
                      <v-flex xs1 sm1 md1 pl-3 flex-vertical-center>
                        <v-btn icon @click="$parent.fn_removeAddOns(item)"><v-icon>close</v-icon></v-btn>
                      </v-flex>

                      <v-flex xs5 sm5 md5 flex-vertical-center>{{item.name}}{{$parent.Form.isRcmApplicable ? ' (RCM)' : ''}}</v-flex>

                      <v-flex xs2 sm2 md2 pt-4 flex-vertical-center justify-end right-align>
                        <v-menu left v-if="item.mode==0">
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn icon v-bind="attrs" v-on="on"><v-icon>more_vert</v-icon></v-btn>
                          </template>
                          <v-list dense>
                            <v-list-item @click="item.appliedOn = 0">
                              <v-list-item-content>
                                <v-list-item-title>Net Value</v-list-item-title>
                              </v-list-item-content>
                              <v-list-item-action><v-icon>{{item.appliedOn == 0 ? 'radio_button_checked' : 'radio_button_unchecked'}}</v-icon></v-list-item-action>
                            </v-list-item>
                            <v-list-item @click="item.appliedOn = 1">
                              <v-list-item-content>
                                <v-list-item-title>Value of Goods</v-list-item-title>
                              </v-list-item-content>
                              <v-list-item-action><v-icon>{{item.appliedOn == 1 ? 'radio_button_checked' : 'radio_button_unchecked'}}</v-icon></v-list-item-action>
                            </v-list-item>
                          </v-list>
                        </v-menu>
                        
                      </v-flex>

                      <v-flex xs2 sm2 md2 pa-2>
                        <template v-if="item.mode==0">
                          <div font11 light-opacity-color>Percentage</div>
                          <ValidationProvider name="taxPercentage" rules="decimal2" v-slot="{ errors }">
                            <v-text-field autocomplete="null"  
                              :id="'percentageField'+item.addOnsId"
                              class="small-text-box right-align"
                              type="number"
                              v-model.number="item.percentage" 
                              @input="fn_inputPercentage($event, item)"
                              suffix="%"
                              dense
                              :error-messages="errors"
                              hide-details
                              outlined>
                            </v-text-field>  
                            <div style="font-size:9px;padding:2px" redcolor>{{errors[0]}}</div>
                          </ValidationProvider>
                          
                        </template>
                      </v-flex>

                      <v-flex xs2 sm2 md2 pa-2 pr-4>
                        <div font11 light-opacity-color>Value</div>
                        <ValidationProvider name="taxValue" rules="decimal2" v-slot="{ errors }">
                          <v-text-field autocomplete="null"  
                            :id="'valueField'+item.addOnsId"
                            class="small-text-box right-align"
                            type="number"
                            v-model.number="item.value" 
                            @input="item.percentage = null"
                            :error-messages="errors"
                            hide-details
                            :prefix="item.nature ? '-' : ''"
                            dense
                            outlined>
                          </v-text-field>
                          <div style="font-size:9px;padding:2px" redcolor>{{errors[0]}}</div>
                        </ValidationProvider>
                         
                      </v-flex>

                      <!-- <v-flex xs2 sm2 md2 pa-3 pr-4 right-align>-{{$parent.tdsAmount}}</v-flex> -->
                    </v-layout>
                  </template>

                  <!-- <div white-back class="small-textbox-container" v-if="$parent.taxItems && $parent.taxItems.length">
                    <v-layout ma-0 row wrap class="layout-wrap" v-for="(item, index) in $parent.taxItems" :key="index">
                    <v-flex xs8 sm8 md8 class="small-textbox-label">
                      <v-btn icon @click="$parent.fn_removeAddOns(item)"><v-icon>close</v-icon></v-btn>
                      <div @click="$parent.fn_editAddOns(item)">{{item.name}} {{item.percentage!==null && item.percentage!=='' ? '@'+item.percentage+'%' : ''}} {{$parent.Form.isRcmApplicable ? ' (RCM)' : ''}}</div>
                    </v-flex>
                    <v-flex xs4 sm4 md4 pr-3 class="small-textbox">
                      <ValidationProvider :name="item.name" rules="min_value:0|decimal2" v-slot="{ errors }">
                        <v-text-field autocomplete="null" 
                          v-model.number="item.value" 
                          @input="item.percentage = null"  
                          type="number" 
                          label="" 
                          :prefix="item.nature ? '-' : ''"
                          :error-messages="errors"
                          :hide-details="!errors || !errors.length">
                        </v-text-field>
                      </ValidationProvider>
                    </v-flex>
                    </v-layout>
                  </div> -->

                  <!-- <div white-back class="small-textbox-container" v-if="$parent.Form.tdsDetails.amount">
                    <v-layout ma-0 row wrap class="layout-wrap">
                    <v-flex xs8 sm8 md8 class="small-textbox-label">
                      <v-btn icon @click="$parent.fn_removeTds"><v-icon>close</v-icon></v-btn>
                      <div @click="$parent.fn_openTdsForm">{{$parent.Form.tdsDetails.tdsAccountName}}</div>
                    </v-flex>
                    <v-flex xs4 sm4 md4 pr-3 class="small-textbox">
                      <v-text-field autocomplete="null" 
                        @click="$parent.fn_openTdsForm"
                        readonly
                        :value="$parent.Form.tdsDetails.amount"
                        type="number" 
                        label="" 
                        prefix="-">
                      </v-text-field>
                    </v-flex>
                    </v-layout>
                  </div> -->
                </div>

                <!-- TDS applied -->
                <!-- <v-layout white-back row wrap ma-0 v-if="$parent.Form.tdsDataModel.tdsAccountName">
                  <v-flex xs1 sm1 md1 pl-3 flex-vertical-center>
                    <v-btn icon @click="$parent.fn_removeTds"><v-icon>close</v-icon></v-btn>
                  </v-flex>

                  <v-flex xs5 sm5 md5 flex-vertical-center>{{$parent.Form.tdsDataModel.tdsAccountName}}</v-flex>

                  <v-flex xs2 sm2 md2 pa-2>
                    <div font11 light-opacity-color>TDS Applied On Value</div>
                     <v-text-field autocomplete="null"
                        id="tdsValueField"
                        class="small-text-box right-align"
                        type="number"
                        v-model.number="$parent.Form.tdsDataModel.appliedOnAmount" 
                        prefix="₹"
                        dense
                        outlined>
                     </v-text-field>
                  </v-flex>

                  <v-flex xs2 sm2 md2 pa-2>
                      <div font11 light-opacity-color>TDS Percentage (%)</div>
                      <v-text-field autocomplete="null"  
                        class="small-text-box right-align"
                        type="number"
                        v-model.number="$parent.Form.tdsDataModel.percentage" 
                        dense
                        outlined>
                     </v-text-field>
                  </v-flex>

                  <v-flex xs2 sm2 md2 pa-2 pr-4>
                    <div font11 light-opacity-color>Value</div>
                     <v-text-field autocomplete="null"  
                        class="small-text-box right-align"
                        disabled
                        type="number"
                        :value="$parent.tdsAmount" 
                        prefix="-"
                        hide-details
                        dense
                        outlined>
                     </v-text-field>
                    </v-flex>
                </v-layout> -->

                <v-layout ma-0 mt-2 row wrap white-back v-if="$parent.roundedAmount">
                  <v-flex xs2 sm2 md2 form-item>
                    <!-- <v-checkbox v-model="$parent.Form.isRounded" @change="$parent.fn_changeIsRounded($event, true)" label="Round Off"></v-checkbox> -->
                    <v-checkbox v-model="$parent.Form.isRounded" label="Round Off"></v-checkbox>
                  </v-flex>

                  <v-flex xs8 sm8 md8></v-flex>

                  <template v-if="$parent.Form.isRounded">

                      <v-flex xs2 sm2 md2 pr-4 flex-vertical-center>
                          <!-- <v-menu v-model="roundOffDialogMenu" :close-on-content-click="false" :max-width="240">
                          
                            <template v-slot:activator="{ on, attrs }">

                                <div class="subtitle-text dark-opacity-color" v-bind="attrs" v-on="on" cursor-pointer @click="fn_openRoundOffDialogMenu">
                                    <v-text-field autocomplete="null" 
                                        id="roundOffValueField"
                                        readonly
                                        append-icon="arrow_drop_down"
                                        class="small-text-box right-align"
                                        hide-details
                                        :value="$parent.roundOff"
                                        type="number" 
                                        label="" 
                                        prefix="₹"
                                        outlined
                                        dense>
                                    </v-text-field>
                                </div>

                            </template>
                            <v-card>
                                <v-card-text>
                                    <v-layout ma-0 row wrap>
                                            <v-flex xs12 sm12 md12 pa-2>
                                                <v-radio-group class="ma-0" hide-details v-model="roundOffNature" row>
                                                    <v-radio label="Add" :value="1"></v-radio>
                                                    <v-radio label="Subtract" :value="0"></v-radio> 
                                                </v-radio-group>
                                            </v-flex>

                                            <v-flex xs12 sm12 md12 pa-2>
                                                <div font11 light-opacity-color>Round Off Value</div>
                                                <v-text-field autocomplete="null" 
                                                    id="roundOffValueField"
                                                    class="small-text-box right-align"
                                                    hide-details
                                                    v-model.number="roundOffValue"
                                                    type="number" 
                                                    label="" 
                                                    prefix="₹"
                                                    outlined
                                                    dense>
                                                </v-text-field>
                                            </v-flex>
                                    </v-layout>
                                </v-card-text>

                                <v-card-actions>
                                    <v-spacer></v-spacer>
                                    <v-btn text small color="primary" @click="roundOffDialogMenu=false">cancel</v-btn>
                                    <v-btn text small color="primary">done</v-btn>
                                </v-card-actions>
                            </v-card>
                        </v-menu> -->
                        <dialog-roundoff-menu ref="ref_dialogRoundOffMenu" :roundOffComputed="$parent.roundOff" :openRoundOffDialogMenu="fn_openRoundOffDialogMenu" :setRoundOffValue="fn_setRoundOffValue"></dialog-roundoff-menu>
                      </v-flex>

                      <!-- <v-flex xs2 sm2 md2 pa-2 flex-vertical-center>
                        <v-select
                          class="small-text-box right-align"
                          :items="[{'title':'(+) Add', 'value':1},{'title':'(-) Subtract', 'value':0}]"
                          v-model="$parent.Form.roundOffNature"
                          @change="$parent.fn_setRoundOffValue($parent.Form.roundOffValue)"
                          return-object
                          :height="32"
                          hide-details
                          dense
                          outlined
                          item-text="title"
                          item-value="value">
                        </v-select>            
                      </v-flex>

                      <v-flex xs2 sm2 md2 pa-2 pr-4 flex-vertical-center>
                        <v-text-field autocomplete="null" 
                          id="roundOffValueField"
                          class="small-text-box right-align"
                          hide-details
                          v-model.number="$parent.Form.roundOffValue"
                          @input="$parent.fn_setRoundOffValue"
                          type="number" 
                          label="" 
                          prefix="₹"
                          outlined
                          dense>
                        </v-text-field>
                      </v-flex> -->
                  </template>
                </v-layout>

                <v-layout ma-0 mt-2 pb-2 row wrap white-back v-if="$parent.roundedAmount">
                  <v-flex sm4 md4 xs4 form-item pt-4>
                    <ValidationProvider v-slot="{ errors }" name="Notes" rules="max:255">
                      <div font11 light-opacity-color>Notes</div>
                      <v-textarea
                        rows="2"
                        outlined
                        auto-grow
                        :counter="255"
                        dense
                        v-model="$parent.Form.transactionData.notes"
                        :error-messages="errors">
                      </v-textarea>
                    </ValidationProvider>
                  </v-flex>

                <template v-if="$parent.settings.transactionTypeId == 10003">
                  <v-flex sm2 md2 xs2 form-item pt-4>
                    <div font11 light-opacity-color>Term of Payment</div>
                    <v-select 
                      outlined class="small-text-box"
                      v-model="$parent.Form.selectedPaymentTerm" row 
                      @input="$parent.fn_updatePaymentTerm"
                      :items="[{'title':'7 Days', 'value':7},{'title':'15 Days', 'value':15},{'title':'21 Days', 'value':21},{'title':'30 Days', 'value':30}, {'title':'45 Days', 'value':45}, {'title':'90 Days', 'value':90}, {'title':'No Due Date', 'value':-1}, {'title':'Custom', 'value':null}]"
                      return-object
                      item-text="title"
                      item-value="value"
                      hide-details
                      dense>
                    </v-select>
                  </v-flex>

                  <v-flex sm2 md2 xs2 form-item pt-4 v-if="$parent.Form.selectedPaymentTerm && !$parent.Form.selectedPaymentTerm.value">
                    <div font11 light-opacity-color>Enter Term of Payment</div>
                    <ValidationProvider v-slot="{ errors }" name="term" rules="required">
                      <v-text-field 
                        outlined 
                        class="small-text-box"
                        v-model="$parent.Form.paymentTerm"
                        type="number"
                        placeholder=" "
                        :error-messages="errors"
                        required>
                      </v-text-field>	
                    </ValidationProvider>
                  </v-flex>
                </template>

                  <!-- <v-flex sm3 md3 xs3 form-item font-medium pt-4>Total</v-flex> -->
                  <v-flex :class="[$parent.settings.transactionTypeId == 10003 ? !$parent.Form.selectedPaymentTerm.value ? 'sm4 md4 xs4' : 'sm6 md6 xs6' : 'sm8 md8 xs8']" form-item font-medium pt-4 right-align>&#8377; {{$root.numberToLocale($parent.roundedAmount)}}</v-flex>
                </v-layout>

                <v-layout ma-0 full-width row wrap id="custom_field_panel" v-if="$parent.roundedAmount">
                  
                  <div full-width v-for="(dItem, dIndex) in $parent.Form.dynamicMenuItems" class="mt-2" :key="dIndex">
                    <v-list nopadding white-back>
                      <v-list-item v-if="!dItem.hideOption">
                          <v-list-item-content>
                            <v-list-item-title>{{dItem.Text}}</v-list-item-title>
                          </v-list-item-content>
                          <v-list-item-action>
                            <v-btn small text outlined capitalize color="primary" style="border-radius:5px;" nomargin :ripple="false" @click="$parent.fn_bindDynamicFields(dItem.sectionId)">
                              <v-icon class="mr-1" font16 color="primary">add</v-icon> {{dItem.Text}}
                            </v-btn>
                          </v-list-item-action>
                      </v-list-item>
                    </v-list>

                    <div white-back full-width v-for="(item, index) in $parent.Form.customFields" :key="index">

                      <template v-if="dItem.sectionId == item.section.sectionId" :class="{'margin-top-bottom-five':dItem.sectionId == item.section.sectionId}">
                        
                        <v-list nopadding :id="'section_'+item.section.sectionId">
                          <v-list-item>
                            <v-list-item-action>
                                <v-btn icon @click="$parent.fn_removeFields(index, item.section.sectionId)">
                                  <v-icon>close</v-icon>
                                </v-btn>
                              </v-list-item-action>

                              <v-list-item-content full-width>
                                <v-list-item-title>{{item.section.sectionName}}</v-list-item-title>
                                <!-- <v-icon style="position:absolute;right:16px">keyboard_arrow_right</v-icon> -->
                              </v-list-item-content>

                              <v-list-item-action>
                                <v-btn small text outlined capitalize color="primary" style="border-radius:5px;" nomargin :ripple="false" @click="$parent.fn_bindDynamicFields(item.section.sectionId)">
                                  <v-icon class="mr-1" font16 color="primary">edit</v-icon> Edit
                                </v-btn>
                              </v-list-item-action>

                          </v-list-item>
                        </v-list>

                        <v-divider></v-divider>

                        <v-card flat>
                          <v-card-text class="pb-0">

                            <v-layout full-width ma-0 row wrap>
                              <template v-for="(fieldItem, itemIndex) in item.items">

                                <v-flex pb-3 xs3 sm3 md3 :key="itemIndex" v-if="fieldItem.value || fieldItem.selectedAccountItem || fieldItem.selectedListItem">

                                  <div font12 light-theme-color>{{fieldItem.displayName}}</div>
                                  <div font16 v-if="fieldItem.fieldType=='yes/no'">{{fieldItem.value ? 'Yes' : 'No'}}</div>

                                  <div font16 v-else-if="fieldItem.fieldType=='dateTime'">
                                    <span v-if="fieldItem.value">{{fieldItem.value | moment("DD-MMM-YYYY")}}</span>
                                    <span v-else>-</span>
                                  </div>

                                  <div font16 v-else-if="fieldItem.fieldType=='accountItem' && fieldItem.selectedAccountItem">
                                    {{fieldItem.selectedAccountItem.itemName}}
                                  </div>

                                  <div font16 v-else-if="fieldItem.fieldType=='linkedItem' && fieldItem.selectedListItem">
                                    {{fieldItem.selectedListItem.itemName}}
                                  </div>  

                                  <div font16 v-else v-html="fieldItem.value ? fieldItem.value : '-'" white-space-pre-line></div>
                                </v-flex>
                              </template>
                              
                            </v-layout>
                            
                          </v-card-text>
                        </v-card>

                      </template>
                      
                    </div>

                  </div>

                  <v-list full-width nopadding class="mt-2" v-if="($parent.roundedAmount>=50000 || $root.TransactionSettings.eWayBillConfigValue === 1) && !$parent.Form.hideEwayOption && $root.CurrentBusiness.GSTRegistrationStatus == 1">
                    <v-list-item>
                        <v-list-item-content>
                          <v-list-item-title>E-Way Bill Details</v-list-item-title>
                        </v-list-item-content>
                        <v-list-item-action>
                          <!-- <v-icon>keyboard_arrow_right</v-icon> -->
                          <v-btn small text outlined capitalize color="primary" style="border-radius:5px;" nomargin :ripple="false" @click="$parent.fn_bindDynamicFields($parent.ewayBillDetailSection.sectionId)">
                            <v-icon class="mr-1" font16 color="primary">add</v-icon> E-Way Bill Details
                          </v-btn>
                        </v-list-item-action>
                    </v-list-item>
                  </v-list>

                  <div :class="{'mt-2':$parent.ewayBillDetailSection.sectionId == item.section.sectionId}" white-back full-width margin-top-bottom-five v-for="(item, index) in $parent.Form.customFields" :key="index">

                    <template v-if="$parent.ewayBillDetailSection.sectionId == item.section.sectionId">
                      
                      <v-list full-width nopadding :id="'section_'+item.section.sectionId">
                        <v-list-item>
                          <v-list-item-action>
                              <v-btn icon @click="$parent.fn_removeFields(index, item.section.sectionId)">
                                <v-icon>close</v-icon>
                              </v-btn>
                            </v-list-item-action>

                            <v-list-item-content>
                              <v-list-item-title>{{item.section.sectionName}}</v-list-item-title>
                              <!-- <v-icon style="position:absolute;right:16px">keyboard_arrow_right</v-icon> -->
                            </v-list-item-content>

                            <v-list-item-action>
                              <v-btn small text outlined capitalize color="primary" style="border-radius:5px;" nomargin :ripple="false" @click="$parent.fn_bindDynamicFields(item.section.sectionId)">
                                <v-icon class="mr-1" font16 color="primary">add</v-icon> Edit
                              </v-btn>
                            </v-list-item-action>

                            
                        </v-list-item>
                      </v-list>

                      <v-divider></v-divider>

                      <!-- <v-card flat v-for="(fieldItem, itemIndex) in item.items" :key="itemIndex"> -->
                      <v-card flat>
                        <v-card-text class="pb-0">

                          <v-layout full-width ma-0 row wrap>
                            <template v-for="(fieldItem, itemIndex) in item.items">
                              <v-flex pb-3 xs3 sm3 md3 :key="itemIndex" v-if="fieldItem.value">
                                <div font12 light-theme-color>{{fieldItem.displayName}}</div>
                                <div font16 v-if="fieldItem.fieldType=='yes/no'">{{fieldItem.value ? 'Yes' : 'No'}}</div>

                                <div font16 v-else-if="fieldItem.fieldType=='dateTime'">
                                  <span v-if="fieldItem.value">{{fieldItem.value | moment("DD-MMM-YYYY")}}</span>
                                  <span v-else>-</span>
                                </div>

                                <div font16 v-else-if="fieldItem.fieldType=='accountItem' && fieldItem.selectedAccountItem">
                                  {{fieldItem.selectedAccountItem.itemName}}
                                </div>

                                <div font16 v-else-if="fieldItem.fieldType=='linkedItem' && fieldItem.selectedListItem">
                                  {{fieldItem.selectedListItem.itemName}}
                                </div>

                                <div font16 v-else v-html="fieldItem.value ? fieldItem.value : '-'" white-space-pre-line></div>
                              </v-flex>  
                            </template>
                            
                          </v-layout>

                         <!--  <div font12 light-theme-color>{{fieldItem.displayName}}</div>
                          <div font16 v-if="fieldItem.fieldType=='yes/no'">{{fieldItem.value ? 'Yes' : 'No'}}</div>

                          <div font16 v-else-if="fieldItem.fieldType=='dateTime'">
                            <span v-if="fieldItem.value">{{fieldItem.value | moment("DD-MMM-YYYY")}}</span>
                            <span v-else>-</span>
                          </div>

                          <div font16 v-else v-html="fieldItem.value ? fieldItem.value : '-'" white-space-pre-line></div> -->
                        </v-card-text>
                      </v-card>
                      
                    </template>
                    
                  </div>

                </v-layout>

                <v-list nopadding class="mt-2" v-if="$parent.Form.splitPayment && $parent.Form.paymentOptions && $parent.Form.paymentOptions.length">
                  <v-list-item>
                          <v-list-item-content>
                            <v-list-item-title medium-theme-color>Payment Mode</v-list-item-title>
                          </v-list-item-content>
                    </v-list-item>
                    <v-divider></v-divider>
                  <v-list-item v-for="(item, index) in $parent.Form.paymentOptions" :key="index">
                          <v-list-item-content>
                            <v-list-item-title medium-theme-color>
                              <span v-if="item.accountLedgerId == creditAccountLedgerId">On Credit</span>
                              <span v-else>{{item.accountLedgerName}}</span>
                            </v-list-item-title>
                          </v-list-item-content>

                          <v-list-item-action>&#8377; {{item.amount}}</v-list-item-action>
                    </v-list-item>
                </v-list>   

                <v-layout wrap ma-0 mt-2 white-back v-if="$parent.roundedAmount">
                  <v-list full-width nopadding>
                        <v-list-item>
                            <v-list-item-content>
                              <!-- <v-list-item-title medium-theme-color>Attachments {{ $parent.Form.attachments.length ? '('+$parent.Form.attachments.length+')' : '' }}</v-list-item-title> -->
                              <v-list-item-title medium-theme-color>Attachments</v-list-item-title>
                            </v-list-item-content>

                            <!-- <v-list-item-action v-if="$parent.Form.attachments.length < 5"> -->
                            <v-list-item-action v-if="!$parent.Form.attachments.length">
                                <v-btn small text outlined capitalize :disabled="$root.CurrentBusiness.temporaryBusiness ? true : false" color="primary" style="border-radius:5px;" nomargin :ripple="false" @click="$parent.fn_selectFile" v-if="!$parent.fileBufferValue">
                                    <v-icon class="mr-1" font16 color="primary">add</v-icon> Attachments
                                </v-btn>

                                <v-progress-circular font10 :rotate="-90" :size="28" :width="2" :value="$parent.fileBufferValue" color="primary" v-if="$parent.fileBufferValue">
                                  {{ $parent.fileBufferValue }}
                                </v-progress-circular>
                            </v-list-item-action>
                         </v-list-item>
                      <v-divider v-if="$parent.Form.attachments.length"></v-divider>
                      <template v-for="(item, index) in $parent.Form.attachments">
                        <v-list-item :key="index" :id="'doc_'+item.documentId" v-if="item.uploadStatus!=2">
                            <v-list-item-content>
                              <v-list-item-title medium-theme-color>{{item.fileName}}</v-list-item-title>
                            </v-list-item-content>

                            <v-list-item-action>
                              <v-btn icon :disabled="$root.CurrentBusiness.temporaryBusiness ? true : false" color="primary" @click="$parent.fn_removeAttachment(item)"><v-icon>close</v-icon></v-btn>
                            </v-list-item-action>
                      </v-list-item>
                      <v-divider :key="'divider'+index" v-if="$parent.Form.attachments.length-1 != index"></v-divider>
                    </template>
                  </v-list>

                  <input type="file" id="filePicker" style="display:none" @change="$parent.fn_validateFile"/>
                </v-layout>  

              </div>

          </ValidationObserver>

                  </template>
                  
                    
                </div>

                <!-- <v-card-actions white-back v-if="!$parent.loading && $parent.getDataCounter >= 7"> -->
                  <v-card-actions white-back v-if="!$parent.loading && $parent.getDataCounter >= 5 && !$root.dialogLoader">
                    <v-layout ma-0 row wrap>
                      <v-flex xs6 sm6 md6 style="padding:6px 6px 6px 16px" @click="$parent.fn_openDialog_PaymentOptions" v-ripple v-if="$parent.settings.transactionTypeId == 10003 || $parent.settings.transactionTypeId == 10004">
                        <div font-medium style="overflow: hidden;text-overflow: ellipsis;white-space: nowrap;">&#8377; {{$root.numberToLocale($parent.roundedAmount)}}</div>
                        <div style="font-size:11px">
                          <span light-opacity-color style="overflow:hidden;white-space: nowrap;text-overflow: ellipsis;">Payment:&nbsp;</span> <span style="overflow:hidden;white-space: nowrap;text-overflow: ellipsis;">{{!$parent.Form.splitPayment ? $parent.fn_paymentAccountName($parent.Form.paymentLedgerId) : 'Split Payment'}}</span> <v-icon font16 style="line-height:12px">arrow_drop_down</v-icon>
                          <!-- {{!$parent.Form.splitPayment ? $parent.fn_paymentAccountName($parent.Form.paymentLedgerId) : 'Split Payment'}} -->
                          <!-- <v-icon font16 style="line-height:12px">arrow_drop_down</v-icon> -->
                        </div>
                      </v-flex>
                      <v-flex style="height:48px" :class="[$parent.settings.transactionTypeId == 10003 || $parent.settings.transactionTypeId == 10004 ? 'xs6 sm6 md6' : 'xs12 sm12 md12']">
                        <v-btn color="primary full-height" nomargin block no-round-borders noshadow :disabled="$parent.disableOnSave" :key="$parent.saveBtnKey" @click.once="fn_validateForm">Save</v-btn>
                      </v-flex>
                    </v-layout>
                </v-card-actions>

            </v-card>

            <v-snackbar v-model="$parent.snackbar" :timeout="2000">{{$parent.snackbar_msg}}</v-snackbar>

            <v-snackbar v-model="$parent.transactionNumberSnackbar" right vertical :timeout="0">
                Generate new transaction number?

                <template v-slot:action="{ attrs }">

                    <v-btn text @click="$parent.transactionNumberSnackbar = false" color="warning">Close</v-btn>

                    <v-btn class="ml-2" text @click="$parent.fn_bindTransactionNumber" v-bind="attrs" color="primary">Okay</v-btn>

                </template>
            </v-snackbar>
        </v-dialog>
    </div>
</template>

<script>
// import gstrSubReport from '../print_report_templates/gstr_sub_report'
import { required, max, min_value, max_value } from 'vee-validate/dist/rules'
import { extend, ValidationProvider, ValidationObserver } from 'vee-validate'

extend('verify_hsn', value => {

  if(value.length == 4 || value.length == 6 || value.length == 8){
    return true;
  }

  return 'HSN should be either 4, 6 or 8 digits.';
});

extend('decimal2', value => {

  let decimalCount = 0;
  if(Math.floor(value) != value){
     decimalCount = value.toString().split(".")[1].length || 0;
  }

  if(decimalCount <= 2){
    return true;
  }

  return 'This field must be numeric and may contain 2 decimal points.';

});

extend('required', {
  ...required,
  message: 'This field can not be empty',
})

// extend('max', {
//   ...max,
//   message: 'This field may not be greater than 8 characters.',
// })

extend('max', max)

extend('min_value', {
  ...min_value,
  message: 'This field must be 0 or more.',
})

extend('max_value', {
  ...max_value,
  message: 'This field must not be greater than bill quantity.',
})

// extend('double', {
//   ...double,
//   message: 'This field must be numeric and may contain 2 decimal points.',
// })

const fixedGstAddOns = require('../../../../../config.js').configs.fixedGstAddOns;
const fixedCessAddOns = require('../../../../../config.js').configs.fixedCessAddOns;

import dialogRoundOffMenu from '../../dialogs/dialog_menu_roundoff'

// This field must be 0 or more.
// This field may not be greater than 8 characters.
// Quantity 00 can not be empty
// This field must be numeric and may contain 2 decimal points.

    export default{
        props:['closeDialog'],
        data(){
            return{
                dialog_accountbook_form: false,
                itemMenu:false,
                itemMenuTable:false,
                summaryArray:[],
                // tdsMenu:false,
                discountMenu:false,
                chargesMenu:false,
                taxesMenu:false,
                selectedItem:null,
                loadingProductData:false,
                roundOffDialogMenu:false,
                roundOffNature:null,
                roundOffValue:null
            }
        },
        components:{
            // 'gstr-sub-report':gstrSubReport
            ValidationProvider,
            ValidationObserver,
            'dialog-roundoff-menu':dialogRoundOffMenu
        },
        methods:{
          test(){
            this.select();
          },
            openDialogWeb: function () {
                console.log('ref');
                this.dialog_accountbook_form = true;
            },
            closeDialogWeb: function () {
                this.dialog_accountbook_form = false;
            },
            fn_openRoundOffDialogMenu: function(){
                // this.$parent.Form.roundOffValue = this.$parent.roundOff;
                this.$refs['ref_dialogRoundOffMenu'].openDialogMenu();
            },
            fn_setRoundOffValue: function(value, nature){
              this.$parent.Form.roundOffValue = nature ? value: -value;
              this.$refs['ref_dialogRoundOffMenu'].closeDialogMenu();
            },
            fn_getQtyValidationRules: function(vItem){
              if(vItem.billQty != undefined){
                return 'required|max_value:'+vItem.billQty;
              }
              else{
                return 'required';
              }
            },
            fn_focusNoteTextArea: function(itemIndex){
              setTimeout(()=>{
                document.getElementById("note"+itemIndex).focus();
              },100);
            },
            fn_checkPercentage: function (addOnItem, item) {
              !addOnItem.percentage ? addOnItem.value = null : '';
              this.fn_computeValues(item);
            },
            fn_checkValue: function (addOnItem, item) {
              addOnItem.percentage = null
              this.fn_computeValues(item);
            },
            fn_validateForm: function(){
              let fieldHasError = false;
              if(document.getElementsByClassName('field-has-error').length){
                fieldHasError = true;
              }
              this.$parent.disableOnSave = true;
              this.$refs.observer.validate().then((result) => {
                if(result && !fieldHasError) {
                  this.$parent.fn_checkValidations();
                }
                else{
                  this.$parent.saveBtnKey++;
                  this.$parent.disableOnSave = false;
                  this.fn_scrollToElement('error--text');
                }
              });
            },

            fn_getHeaderColsLength: function(){
              if(document.getElementsByClassName("items-table")){
                return document.querySelectorAll('table.items-table > thead > tr > th').length;
              }
              else{
                return 0;
              }
            },

            // fn_menuItemClick: function(menuAction, itemIndex){
            fn_menuItemClick: function(menuAction, itemGroupId){

                this.backButtonPress = false;
                if(menuAction.indexOf('section_')!=-1){
                    // let sectionId = menuAction.substring(menuAction.indexOf('_')+1);
                    // this.fn_bindDynamicFields(sectionId);
                }
                else if(menuAction=='save'){
                    // this.fn_checkValidations();
                }
                else if(menuAction=='newledger'){
                    //Force save to local storage as dynamic fields need to be save in local storage
                    // this.fn_saveDraft(this.Form.partyName);
                    // this.$router.push({path:'/masters/accounts/ledgers/items/new', query:{tagId:this.guidWeb(), groupTypeId: ledgerGroupTypeId.other, ls:1, type:this.ledgerType}})
                    this.$router.push({path:'/'+this.$route.params.businessId + '/' + this.$route.params.listId +'/ledger/items/new', query:{tagId:this.guidWeb(), groupTypeId:103, ls:1, type:'ledger'}});
                }
                // else if(menuAction=='newtdsledger'){
                //     this.$router.push({path:'/'+this.$route.params.businessId + '/' + this.$route.params.listId +'/ledger/items/new', query:{tagId:this.guidWeb(), groupTypeId:103, ls:1, type:'ledger', tds:1}});
                // }
                else if(menuAction=='newcustomer'){
                    // this.$router.push({path:'/masters/accounts/ledgers/items/new', query:{tagId:this.guidWeb(), groupTypeId: ledgerGroupTypeId.supplier, ls:1, type:"supplier"}})
                    this.$router.push({path:'/'+this.$route.params.businessId + '/' + this.$route.params.listId +'/ledger/items/new', query:{tagId:this.guidWeb(), ls:1, groupTypeId:101, type:'customer'}});
                }
                else if(menuAction=='newexpensecategory'){
                  this.$router.push({path:'/'+this.$route.params.businessId + '/' + this.$route.params.listId +'/expense_category/items/new', query:{tagId:this.guidWeb(), groupTypeId:103, ls:1}});
                    // this.$router.push({path:'/masters/expensecategory/items/new', query:{tagId:this.guidWeb(), groupTypeId: ledgerGroupTypeId.other, ls:1}})
                }
                else if(menuAction=='dynamicAccountField'){
                    this.fn_newDynamicAccount();
                }
                else if(menuAction=='newproduct'){
                  this.$router.push({path:'/'+this.$route.params.businessId + '/' + this.$route.params.listId +'/product/items/new', query:{tagId:this.guidWeb(), ls:1}});

                    // this.$router.push({path:'/masters/inventory/productandservices/items/new', query:{tagId:this.guidWeb(), inventory:0, ls:1}})
                }
                else if(menuAction=='newListItem'){
                    // this.fn_saveDraft(this.Form.partyName);
                    this.$router.push({path:'/masters/lists/'+this.$parent.Form.dynamicFieldsAdditionalData.listId+'/items/new', query:{tagId:this.guidWeb(), listTitle:this.$parent.Form.dynamicFieldsAdditionalData.listName, ls:1, type:'listItem'}});
                }
                else if(menuAction=='reset'){
                    // this.fn_resetForm();
                }
                else if(menuAction=='addnote'){
                    // this.fn_addNote();  
                }
                else if(menuAction=='camera' || menuAction=='fileManager'){
                    // let self = this;
                    // this.$root.fn_checkStoragePermission(function(){
                    //     menuAction=='camera' ? self.fn_captureImage() : self.fn_uploadFiles(); 
                    // });
                }
                // else if(menuAction=='applytds'){
                //     // this.fn_openTdsForm();
                // }
                else if(menuAction=='addonsmenu'){
                    // this.fn_openAddOns(null, true);
                }
                else if(menuAction=='adddiscount'){
                  // this.$router.push({path:'/addons/discounts/new', query:{tagId:this.guidWeb(), ls:1}});
                  this.$router.push({path:'/'+this.$route.params.businessId + '/' + this.$route.params.listId +'/discount/items/new', query:{tagId:this.guidWeb(), ls:1}});
                }
                else if(menuAction=='addtaxes'){
                    // this.$router.push({path:'/addons/taxes/new', query:{tagId:this.guidWeb(), ls:1}});
                    this.$router.push({path:'/'+this.$route.params.businessId + '/' + this.$route.params.listId +'/tax/items/new', query:{tagId:this.guidWeb(), ls:1}});
                }
                else if(menuAction=='addothercharges'){
                    // this.$router.push({path:'/addons/othercharges/new', query:{tagId:this.guidWeb(), ls:1}});
                    this.$router.push({path:'/'+this.$route.params.businessId + '/' + this.$route.params.listId +'/other_charge/items/new', query:{tagId:this.guidWeb(), ls:1}});
                }
                else if(menuAction=='newTaxCategory'){
                    // this.$router.push({path:'/'+this.$route.params.businessId + '/' + this.$route.params.listId +'/tax_category/items/new', query:{tagId:this.guidWeb(), ls:1, itemIndex:itemIndex}});
                    this.$router.push({path:'/'+this.$route.params.businessId + '/' + this.$route.params.listId +'/tax_category/items/new', query:{tagId:this.guidWeb(), ls:1, itemGroupId:itemGroupId}});
                }
            },

            fn_new: function(item){
              this.closeDialogWeb();
              setTimeout(()=>{
                if(item){
                  this.$router.push({path:'/'+this.$route.params.businessId + '/' + this.$route.params.listId +'/ledger/items/new', query:{tagId:this.guidWeb(), ls:1, type:'ledger', amounttype:item.amountType, groupid:item.groupId}});
                }
                else{
                  this.$router.push({path:'/'+this.$route.params.businessId + '/' + this.$route.params.listId +'/ledger/items/new', query:{tagId:this.guidWeb(), ls:1, type:'party'}});
                }
              },100);
            },
            fn_focusItemSearch: function(){
              // this.itemMenu = true;
              this.$parent.itemSearchValue = '';
              setTimeout(()=>{
                this.$refs['item_search'].focus();
              },50);
            },
            // fn_openTdsAccounts: function () {
            //   this.$parent.tdsSearchValue = '';
            //   let accountGroups = [];
            //   let tdsGroupId = "6c92acb9-0ad2-42f4-bbda-d3a9e33972d4";
            //   accountGroups.push(tdsGroupId);
            //   // this.openAccountLedgers('android', 'TdsAccounts', 'ref_dialogTds_AccountLedger', this.$parent.Form.tdsDataModel.tdsAccountId, this.$parent.Form.tdsDataModel.tdsAccountName, accountGroups)
            //   this.$parent.fn_accountLedgers('', null, accountGroups, 'tds', response => {
            //     this.$parent.tdsLedgers = JSON.parse(response);
            //     this.$parent.fn_focusElement('tds_search');
            //   });
            // },

            fn_focusSearch: function(elementId){
              this.$parent.otherChargeSearchValue = '';
              this.$parent.discountSearchValue = '';
              this.$parent.taxSearchValue = '';
              this.$parent.fn_focusElement(elementId);
            },

            // fn_updateTdsAccount: function (item) {
            //   this.tdsMenu = false;
            //   this.$parent.Form.tdsDataModel.transactionTdsId = this.guid();
            //   this.$parent.Form.tdsDataModel.tdsAccountId = item.itemId;
            //   this.$parent.Form.tdsDataModel.tdsAccountName = item.itemName;
            //   this.$parent.fn_scrollAndFocusToElement('tdsValueField');
            // },

            fn_updateAddOns: function (item) {

              this.discountMenu = false;
              this.taxesMenu = false;
              this.chargesMenu = false;

              if(item.addonGroupId == 2 && !this.$parent.Form.selectedParty){
                // alert("Select supplier");
                this.$eventHub.$emit('snackbarAlert', {msg:"Select Supplier"});
                return;
              }

              if((this.$root.CurrentBusiness.GSTRegistrationStatus!=1 && this.$route.params.listId=='sale') && (item.itemId == fixedGstAddOns.CGST.id || item.itemId == fixedGstAddOns.SGST.id || item.itemId == fixedGstAddOns.IGST.id || item.itemId == fixedGstAddOns.UTGST.id || item.itemId == fixedCessAddOns.CESS.id)){
                // alert("Not applicable for this transaction");
                this.$eventHub.$emit('snackbarAlert', {msg:"Not applicable for this transaction"});
              }
              else if(this.$parent.Form.gstType == "IntraState" && (item.itemId == fixedGstAddOns.IGST.id || item.itemId == fixedGstAddOns.UTGST.id)){
                // alert("Not applicable for this transaction");
                this.$eventHub.$emit('snackbarAlert', {msg:"Not applicable for this transaction"});
              }
              else if(this.$parent.Form.gstType == "UnionTerritory" && (item.itemId == fixedGstAddOns.IGST.id || item.itemId == fixedGstAddOns.SGST.id)){
                // alert("Not applicable for this transaction");
                this.$eventHub.$emit('snackbarAlert', {msg:"Not applicable for this transaction"});
              }
              else if(this.$parent.Form.gstType == "InterState" && (item.itemId == fixedGstAddOns.CGST.id || item.itemId == fixedGstAddOns.SGST.id || item.itemId == fixedGstAddOns.UTGST.id)){
                // alert("Not applicable for this transaction");
                this.$eventHub.$emit('snackbarAlert', {msg:"Not applicable for this transaction"});
              }
              else{
                this.$parent.Form.addOnsDataModel = {"transactionTypeAddOnsId":null, "addOnsId":item.itemId, "name":item.itemName, "percentage":null, "value":null, "mode":null, "nature":null, "appliedOn":0, "adjustInTotal":true, "orderNumber":null, "addOnsType":null};
                this.fn_get(item.itemId);
              }
              
            },

            fn_inputPercentage: function(val, item){
              if(item.addOnsId != fixedCessAddOns.CESS.id){
                for(let i=0; i<this.$parent.taxItems.length; i++){
                  if(this.$parent.taxItems[i].addOnsId != item.addOnsId && this.$parent.taxItems[i].addOnsId != fixedCessAddOns.CESS.id){
                    this.$parent.taxItems[i].percentage = val;
                  }

                  if(!val && this.$parent.taxItems[i].addOnsId != fixedCessAddOns.CESS.id){
                    this.$parent.taxItems[i].value = null;
                  }
                }
              }
              // !item.percentage ? item.value = null : ''
            },

            fn_get: function(itemId){
              var self = this;
              this.getData('AddOns', itemId, 
                {
                  onSuccess: function(response){
                    console.log(response);
                    let addOnsData = JSON.parse(response);
                    self.$parent.Form.addOnsDataModel.mode = addOnsData.mode;
                    self.$parent.Form.addOnsDataModel.nature = addOnsData.nature;
                    self.$parent.Form.addOnsDataModel.addOnsType = addOnsData.addonGroupId;

                    self.$parent.Form.addOnsDataModel.purchaseAccountItemId = addOnsData.purchaseAccountItemId;
                    self.$parent.Form.addOnsDataModel.saleAccountItemId = addOnsData.saleAccountItemId;

                    self.$parent.fn_saveAddOns(self.$parent.Form.addOnsDataModel);

                    // self.$parent.fn_openDialogAddons ? self.$parent.fn_openDialogAddons() : '';
                  }, 
                  onError: function(error){
                    console.log(error);
                  }
                }
              );
            },
            // fn_add: function(){
            //   this.itemMenu = false;
            // },

            fn_updateProduct: function (item, isNew) {
              this.loadingProductData = true;
              isNew ? this.selectedItem = {"itemId":item.itemId, "itemName":item.itemName} : '';

              this.itemMenu = false;
              this.itemMenuTable = false;

              this.$parent.Form.productDataModel = {"groupId":null, "productId":null, "groupTypeId":null, "productName":null, "mrp":null, "rate":null, "productVariants":[], "taxValue":null, "taxAmount":0, "hsnCode":null, "orderNumber":null, "grossAmount":0, "grossAmountAsPerMRP":0, "netAmount":0, "addOnsItems":[], "cessValue":null, "cessAmount":0, "taxCategoryId": null, "taxCategoryName": null, 
                        "defaultTaxValue":null, "defaultCessValue":null, "defaultHsnCode":null, "defaultTaxCategoryId":null, "defaultTaxCategoryName": null, "isCessApplicable":false, "taxableValue":null, "defaultSellingPrice":null, "defaultWholeSalePrice":null, "description":null, "selectedTaxCategory":null};

              this.$parent.Form.productDataModel.productId = item.itemId;
              this.$parent.Form.productDataModel.productName = item.itemName;
              this.fn_getProductData(false, item.itemId);
            },

            fn_getProductData: function (refreshUnits, itemId) {
              let self = this;
              this.getData('Product', itemId, 
                {
                  onSuccess: function(response){
                    console.log(response);
                    let productData = JSON.parse(response);

                    if(productData.groupTypeId==101){
                      self.fn_getSummary(productData.productId);
                    }

                    // if(refreshUnits){
                    //   self.fn_refreshUnits(productData.productVariants, baseTransactionListId);
                    // }
                    // else{
                    //   self.fn_bindData(productData);
                    // }

                    
                    self.fn_bindData(productData);
                  }, 
                  onError: function(error){
                    console.log(error);
                    self.loadingProductData = false;
                  }
                }
              );
            },

            // fn_refreshUnits: function (variants, baseTransactionListId) {
            //   let productVariants = this.item.productVariants;
            //   if(variants.length != productVariants.length){

            //   for(var j in variants){
            //       var shared = false;

            //       for (var k in productVariants){
            //         if (productVariants[k].productVariantId == variants[j].productVariantId) {
            //              shared = true;
            //              break;
            //          }
            //       }
                     
            //       if(!shared){
            //         let variantObj = {};

            //             variantObj.unitName = variants[j].unitName;
            //             variantObj.unitId = variants[j].unitId;
            //             variantObj.primaryUnitId = variants[j].variantBaseUnitId;
            //             variantObj.packQuantity = variants[j].packQuantity;
            //             variantObj.productId = variants[j].productId;
            //             variantObj.productVariantId = variants[j].productVariantId;

            //             variantObj.qty = null;

            //             //adding bill qty 0 while ading variant in case of edit
            //             if(this.$parent.Form.transactionData.transactionListId == transactionTypes.purchaseReturn.id && baseTransactionListId == transactionTypes.purchaseInvoice.id){
            //               variantObj.billQty = 0;
            //             }

            //             productVariants.splice(j, 0, variantObj);
            //       } 
            //     }
            //   }
            // },

            fn_bindData: function (productData) {
              this.$parent.Form.productDataModel.groupTypeId = productData.groupTypeId;
              this.$parent.Form.productDataModel.productVariants = JSON.parse(JSON.stringify(productData.productVariants));
              this.$parent.Form.productDataModel.mrp = productData.mrp;

              this.$parent.Form.productDataModel.productVariants.forEach( item => {
                item.primaryUnitId = item.variantBaseUnitId;
                this.$set(item, 'qty', 1);

                //setting pack quantity 1 in case of null or base variant
                !item.packQuantity ? item.packQuantity = 1 : '';
              });

              this.$parent.Form.productDataModel.mrp = productData.mrp;
              this.$parent.Form.productDataModel.defaultSellingPrice = productData.sellingPrice;
              this.$parent.Form.productDataModel.defaultWholeSalePrice = productData.wholeSalePrice;

              if(this.$parent.Form.transactionTypeData.tradingType == 1){
                this.$parent.Form.productDataModel.rate = productData.wholeSalePrice;
              }
              else{
                this.$parent.Form.productDataModel.rate = productData.sellingPrice;
              }


              this.$parent.Form.productDataModel.hsnCode = productData.hsnCode;

              this.$parent.Form.productDataModel.taxCategoryId = productData.taxCategoryId;
              this.$parent.Form.productDataModel.taxCategoryName = productData.taxCategoryName;
              this.$parent.Form.productDataModel.taxValue = productData.taxValue;
              this.$parent.Form.productDataModel.cessValue = productData.cessValue;
              this.$parent.Form.productDataModel.isCessApplicable = productData.isCessApplicable;

              //saving default tax value for draft
              this.$parent.Form.productDataModel.defaultTaxCategoryId = productData.taxCategoryId;
              this.$parent.Form.productDataModel.defaultTaxValue = productData.taxValue;
              this.$parent.Form.productDataModel.defaultCessValue = productData.cessValue;

              this.$parent.Form.productDataModel.selectedTaxCategory = {"taxCategoryId":productData.taxCategoryId, "taxCategoryName":productData.taxCategoryName, "cessValue":productData.cessValue, "taxValue":productData.taxValue, "isCessApplicable":productData.isCessApplicable}

              // this.$parent.Form.productDataModel.selectedTaxCategory = {"taxCategoryId":productData.taxCategoryId, "taxCategoryName":productData.purchaseTaxCategoryName, "cessValue":productData.purchaseCessValue, "taxValue":productData.purchaseTaxValue, "isCessApplicable":productData.purchaseIsCessApplicable}

              //charges in case of service item
              if(this.$parent.Form.productDataModel.groupTypeId==102){
                this.$parent.Form.productDataModel.rate = productData.charges;  
              }

              this.$parent.Form.productDataModel.purchaseRate = productData.purchaseRate;

              this.loadingProductData = false;

              if(!this.$parent.Form.productDataModel.rate){
                  setTimeout(()=>{
                      document.getElementById('fieldRate') ? document.getElementById('fieldRate').focus() : '';
                  },100);
                  // this.$refs['ref_dialogForm'].$refs['fieldRate'+lastItemIndex].focus();
              }
              else{
                  setTimeout(()=>{
                      document.getElementById('fieldQty') ? document.getElementById('fieldQty').focus() : '';
                  },100);
                  // this.$refs['ref_dialogForm'].$refs['fieldQty'+lastItemIndex].focus();
              }

              // this.$parent.fn_addItem();
            },  

            fn_getSummary: function (itemId) {
              var self = this;
              this.getData('ProductSummary', itemId, {
                onSuccess: function(response){
                  console.log(response);
                  self.summaryArray = JSON.parse(response);
                }, 
                onError: function(error){
                  console.log(error);
                  self.loadingProductData = false;
                }
              });
            },

            fn_getLabel: function (item) {
              return item.unitName
              // if(!item.packQuantity){
              //  return item.unitName;
              // }
              // else{
              //  return item.unitName+'('+item.packQuantity+')';
              // }
            },

            fn_computeValues: function (productData, fromPlaceholder) {

                console.log('compute');
              let grossAmount = 0;
              let grossAmountAsPerMRP = 0;

              let netValue = 0;
              // let finalAmount = 0;

              let self = this;

              //Calculating gross amount
              productData.productVariants.forEach(function (item) {
                let packQty = 1;
                // let qty = 1;
                let qty = null;
                let rate = productData.rate;

                item.packQuantity ? packQty = item.packQuantity : '';

                item.qty ? qty = item.qty : '';

                //calculation as per rate
                if(!qty || !rate)
                  grossAmount += 0;
                else{
                  grossAmount += qty * packQty * rate;
                }

                //calculation as per mrp
                let mrp = productData.mrp;
                if(!qty || !mrp)
                  grossAmountAsPerMRP += 0;
                else{
                  grossAmountAsPerMRP += qty * packQty * mrp;
                }
              })

              productData.grossAmount = this.roundUpto2Decimals(grossAmount);
              productData.grossAmountAsPerMRP = this.roundUpto2Decimals(grossAmountAsPerMRP);

              grossAmount = this.roundUpto2Decimals(grossAmount);
              grossAmountAsPerMRP = this.roundUpto2Decimals(grossAmountAsPerMRP);
              netValue = this.roundUpto2Decimals(grossAmount);

              //calculating addons
              // let mrp = productData.mrp ? productData.mrp : 0;
              // let addOnsTotalValue = 0;

              // let itemTaxAmount = 0;
              // let addibleTax = 0;

              productData.addOnsItems.forEach(function (item) {

                let applicableAmount = 0;
                let addOnsValue = 0;

                //on net value
                if(item.appliedOn == 0){
                  applicableAmount = netValue;
                }//value of goods
                else if(item.appliedOn == 1){
                  applicableAmount = grossAmount;
                }//on mrp
                else{
                  applicableAmount = grossAmountAsPerMRP;
                }

                //Caculating addOns except gst's
                if(!item.isGst){
                  //of value or percentage
                  if(item.mode){//value
                    addOnsValue = item.value;
                  }
                  else{//percentage
                    if(item.percentage){
                      addOnsValue = (applicableAmount*item.percentage)/100;
                      item.value = self.roundUpto2Decimals(addOnsValue);
                    }
                    else if(item.value){
                      addOnsValue = item.value;
                    }
                  }
                }
                

                addOnsValue = self.roundUpto2Decimals(addOnsValue);

                //Final calculation
                if(item.adjustInTotal){
                  if(item.nature==0){
                    netValue = netValue + addOnsValue;
                  }
                  else{
                    netValue = netValue - addOnsValue;
                  }
                }   

              });

              //Taxable amount is net value after deduction of addons on which tax(gst) will apply
              productData.taxableValue = this.roundUpto2Decimals(netValue);
              //Tax will only affect net amount in case of exclusive tax where this.$parent.Form.taxMode == 1

              let itemTaxAmount = 0;
              let addibleTax = 0;
              let itemCessAmount = 0;
              if(this.$parent.Form.taxMode){

                let taxAmountInclusive = null;

                if(productData.taxValue && self.$parent.Form.taxMode==2){
                  // let inclusiveTax = (netValue * productData.taxValue)/(100+productData.taxValue);
                  // taxAmountInclusive = this.roundUpto2Decimals(inclusiveTax);
                  taxAmountInclusive = (netValue * productData.taxValue)/(100+productData.taxValue);
                }
                

                this.$parent.Form.gstAddOns.forEach(function (item) {

                  if(productData.taxValue){
                    let gstCategoriesLength = self.$parent.Form.gstAddOns.length;

                    let percentage = productData.taxValue / gstCategoriesLength;

                    item.percentage = percentage;

                    //exclusive tax
                    if(self.$parent.Form.taxMode==1){
                      let taxAmount = (netValue * item.percentage)/100;
                      item.value = self.roundUpto2Decimals(taxAmount);
                      addibleTax += item.value ? item.value : 0;
                      itemTaxAmount += item.value;
                    }
                    //inclusive tax
                    else if(self.$parent.Form.taxMode==2){
                      // let taxAmount = (netValue * item.percentage)/(100+item.percentage);
                      item.value = self.roundUpto2Decimals(taxAmountInclusive/gstCategoriesLength);
                      itemTaxAmount += item.value;
                    }
                  }

                });

                //Calculation of cess

                if(productData.isCessApplicable){

                  this.$parent.Form.cessAddOns.percentage = productData.cessValue;

                  //exclusive tax
                  if(self.$parent.Form.taxMode==1){
                    if(productData.cessValue!=null){
                      let cessAmount = (netValue * this.$parent.Form.cessAddOns.percentage)/100;
                      this.$parent.Form.cessAddOns.value = self.roundUpto2Decimals(cessAmount);
                      addibleTax += this.$parent.Form.cessAddOns.value ? this.$parent.Form.cessAddOns.value : 0;  
                      itemCessAmount = this.$parent.Form.cessAddOns.value;
                    }
                    else{
                      this.$parent.Form.cessAddOns.value = self.roundUpto2Decimals(productData.cessAmount);
                      addibleTax += productData.cessAmount ? productData.cessAmount : 0;  
                      itemCessAmount = productData.cessAmount;
                    }
                    
                  }
                  //inclusive tax
                  else if(self.$parent.Form.taxMode==2){
                    if(productData.cessValue!=null){
                      let cessAmountInclusive = (netValue * productData.cessValue)/(100+productData.cessValue);
                      this.$parent.Form.cessAddOns.value = self.roundUpto2Decimals(cessAmountInclusive);
                      itemCessAmount = this.$parent.Form.cessAddOns.value;
                    }
                    else{
                      this.$parent.Form.cessAddOns.value = self.roundUpto2Decimals(productData.cessAmount);
                      itemCessAmount = productData.cessAmount;
                    }

                  }

                }
              }

              productData.taxAmount = self.roundUpto2Decimals(itemTaxAmount);
              productData.cessAmount = self.roundUpto2Decimals(itemCessAmount);

              if(productData.cessValue==null && itemCessAmount===''){
                productData.cessAmount = null;
              }

              //Taxable amount is net value after deduction of addons on which tax(gst) will apply
              //taxable value in case of exclusive tax
              // if(this.$root.CurrentBusiness.GSTRegistrationStatus!=3){
              if(this.$root.CurrentBusiness.GSTRegistrationStatus==1){
                productData.taxableValue = this.roundUpto2Decimals(netValue); 
              }
              else{
                productData.taxableValue = this.roundUpto2Decimals(netValue + addibleTax);
              }
              // productData.taxableValue = this.roundUpto2Decimals(netValue);

              //Gross amount in case of inclusive tax
              if(self.$parent.Form.taxMode==2){
                productData.grossAmount = this.roundUpto2Decimals(grossAmount - itemTaxAmount - itemCessAmount);
                productData.grossAmountAsPerMRP = this.roundUpto2Decimals(grossAmountAsPerMRP - itemTaxAmount - itemCessAmount);
                //taxable value in case of inclusive tax
                // if(this.$root.CurrentBusiness.GSTRegistrationStatus!=3){
                if(this.$root.CurrentBusiness.GSTRegistrationStatus==1){
                  productData.taxableValue = this.roundUpto2Decimals(netValue - itemTaxAmount - itemCessAmount);  
                }
                else{
                  productData.taxableValue = this.roundUpto2Decimals(netValue);
                }
                // productData.taxableValue = this.roundUpto2Decimals(netValue - itemTaxAmount - itemCessAmount);
              }

              // alert('in');

              //calculating net amount after default tax
              if(this.$parent.Form.isRcmApplicable){
                if(fromPlaceholder){
                  return this.roundUpto2Decimals(netValue); 
                }
                else{
                  productData.netAmount = this.roundUpto2Decimals(netValue);  
                }
                
                // return this.roundUpto2Decimals(netValue);
              }
              else{
                if(fromPlaceholder){
                  return this.roundUpto2Decimals(netValue + addibleTax); 
                }
                else{
                  // productData.netAmount = this.roundUpto2Decimals(netValue + addibleTax);  
                  this.$set(productData, 'netAmount', this.roundUpto2Decimals(netValue + addibleTax));
                }
                // return this.roundUpto2Decimals(netValue + addibleTax);
              }
              // netValue = netValue + addibleTax;

              // return this.roundUpto2Decimals(netValue);
            }
        },
        computed:{
          // netAmount: function () {
          //   return this.fn_computeValues(this.$parent.Form.productDataModel);
          // }
          billDate: function(){
            if(this.$parent.Form.transactionData.refDate){
              return this.$moment(this.$parent.Form.transactionData.refDate).format('DD-MMM-YYYY');
            }
            else{
              return null;
            }
          }
        }
    }
</script>