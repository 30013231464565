<template>
    <div>
        <v-toolbar :height="56">
          <!-- <v-app-bar-nav-icon></v-app-bar-nav-icon> -->

          <v-toolbar-title>Dashboard</v-toolbar-title>

          <v-spacer></v-spacer>

          <!-- <v-btn color="success" @click="fn_downloadSample">text</v-btn> -->

          <v-menu>
            <template v-slot:activator="{ on, attrs }">

                <div style="line-height:16px;" class="right-align" v-bind="attrs" v-on="on">
                    <span style="font-size:14px">{{currentPeriod}}&nbsp;<v-icon style="font-size:20px">date_range</v-icon></span><br>
                    <span font11 light-opacity-color>From {{$root.dashboard.startDate | moment("DD-MMM-YYYY")}} To {{$root.dashboard.endDate | moment("DD-MMM-YYYY")}}</span>
                </div>
              <!-- <v-btn capitalize text v-bind="attrs" v-on="on">
                {{currentPeriod}}&nbsp;<v-icon>date_range</v-icon>
              </v-btn>
              <span>
                  From {{$root.dashboard.startDate | moment("DD-MMM-YYYY")}} To {{$root.dashboard.endDate | moment("DD-MMM-YYYY")}}
              </span> -->
            </template>
              <v-list class="pa-0">
                <v-list-item v-for="(item, index) in periods" :key="index" @click="fn_changePeriod(item)">
                  <v-list-item-title>{{ item.title }}</v-list-item-title>
                </v-list-item>
              </v-list>
          </v-menu>

          <!-- <v-btn color="success" @click="fn_products">text</v-btn> -->
        </v-toolbar>

        <v-divider></v-divider>
        <div class="data-container">

            <!-- <div style="width:360px;border-radius:5px;border:1px #4b77be solid">

                <div style="border-top-left-radius:4px;border-top-right-radius:4px;" class="primary-background-color color-white pt-3 pb-3 pl-4 pr-4">
                    <div style="font-size:20px">{{$root.CurrentBusiness.BusinessName}}</div>
                    <div class="font14">+{{$root.CurrentBusiness.BusinessProfileNumber}}</div>
                </div>

                <v-divider></v-divider>

                <div class="pa-4 back-image" style="border-bottom-left-radius:5px;border-bottom-right-radius:5px;">
                    <div>
                        <div class="title primary-color">Payment Reminder</div>
                        <div class="title pt-1">₹ 24,254</div>
                        <div class="pt-2 font14">as on 1-6-2022</div>
                    </div>

                    <div class="invoice-footer">
                        <div class="branding" style="background-color:rgba(75,119,190,0.1)">
                            <div class="branding-box">
                                <div class="dark-opacity-color right-align powered">Powered By</div>
                                <div class="font16 title primary-color">
                                    <img class="brand_logo" src="../../assets/BizOpsApp_Icon_24x24.png" alt="bizops_logo">BizOps App
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div> -->

            <div v-if="loading">
                <v-progress-linear height="4" nomargin indeterminate color="primary"></v-progress-linear>   
            </div>

            <v-layout ma-0 row wrap style="height:calc(100% - 56px);width:100%">
                <v-flex xs12 sm6 md4 pa-3>
                    <v-card id="profit-and-loss" v-if="!loading">
                        <v-card-text>

                            <v-layout ma-0 row wrap>
                                <v-flex xs4 sm4 md4 flex-align-vertical-center>
                                    <div class="subheading light-opacity-color">
                                        {{profitAndLoss.netProfit ? 'Net Profit' : profitAndLoss.netLoss ? 'Net Loss' : 'Profit and Loss'}}
                                    </div>
                                </v-flex>
                                <v-flex xs8 sm8 md8 right-align>
                                    <div class="title">
                                        &#8377; {{profitAndLoss.netProfit ? $root.numberToLocale(roundUpto2Decimals(profitAndLoss.netProfit)) : profitAndLoss.netLoss ? $root.numberToLocale(roundUpto2Decimals(profitAndLoss.netLoss)) : 0}}
                                    </div>
                                </v-flex>

                                <v-flex xs12 sm12 md12>
                                    <v-menu transition="scale-transition">
                                      <template v-slot:activator="{ on, attrs }">
                                        <v-btn
                                          icon
                                          small
                                          v-bind="attrs"
                                          v-on="on"
                                        >
                                          <v-icon>more_horiz</v-icon>
                                        </v-btn>
                                      </template>

                                        <v-card style="max-width:200px">
                                            <v-card-text>
                                                <v-layout ma-0 row wrap>
                                                    <template v-for="(item, index) in netProfitLossMoreItems">
                                                        <v-flex :class="{'pt-2':index!=0}" xs12 sm12 md12 :key="index">
                                                            <div font11 class="light-opacity-color">{{item.name}}</div>
                                                            <div font16>&#8377; {{$root.numberToLocale(roundUpto2Decimals(item.value))}}</div>
                                                        </v-flex>
                                                    </template>
                                                </v-layout>
                                            </v-card-text>
                                        </v-card>
                                      
                                    </v-menu>
                                </v-flex>

                                <!-- <v-flex xs12 sm12 md12 font11 primary-color cursor-pointer right-align @click="showMoreNetProfitLoss = !showMoreNetProfitLoss">
                                    

                                </v-flex> -->
                            </v-layout>

                            <!-- <v-layout ma-0 row wrap v-if="showMoreNetProfitLoss">
                                <template v-for="(item, index) in netProfitLossMoreItems">
                                    <v-flex pt-2 xs6 sm6 md6 :key="index">
                                        <div font11 class="light-opacity-color">{{item.name}}</div>
                                        <div font16>&#8377; {{$root.numberToLocale(roundUpto2Decimals(item.value))}}</div>
                                    </v-flex>
                                </template>
                            </v-layout> -->

                            <apexchart type="bar" :options="profitAndLoss.options" :series="profitAndLoss.series" v-if="profitAndLoss.netProfit != null || profitAndLoss.netLoss != null"></apexchart>

                        </v-card-text>
                    </v-card>
                </v-flex>

                <v-flex xs12 sm6 md4 pa-3>
                    <v-card id="sales" style="height:100%"  v-if="!loading">
                        <v-card-text>
                            <v-layout ma-0 row wrap>
                                <v-flex xs4 sm4 md4 flex-align-vertical-center>
                                    <div class="subheading light-opacity-color">Sale</div>
                                </v-flex>
                                <v-flex xs8 sm8 md8 right-align>
                                    <div class="title">&#8377; {{$root.numberToLocale(roundUpto2Decimals(sales.total))}}</div>
                                </v-flex>
                                <v-flex xs12 sm12 md12 font11 primary-color right-align>&nbsp;</v-flex>
                            </v-layout>

                            <apexchart type="line" :options="sales.options" :series="sales.series"></apexchart>
                        </v-card-text>
                    </v-card>
                </v-flex>

                <v-flex xs12 sm6 md4 pa-3>

                    <v-card id="account-balance" v-if="!loading">
                        <v-card-text>
                            <v-layout ma-0 row wrap>
                                <v-flex xs4 sm4 md4 flex-align-vertical-center>
                                    <div class="subheading light-opacity-color">Balance</div>
                                </v-flex>
                                <v-flex xs8 sm8 md8 right-align>
                                    <div class="title">&#8377; {{$root.numberToLocale(roundUpto2Decimals(totalAccountBalance))}}</div>
                                </v-flex>

                                <v-flex xs12 sm12 md12>
                                    <v-menu transition="scale-transition" v-if="accountBalanceData.series[0].data && accountBalanceData.series[0].data.length">
                                      <template v-slot:activator="{ on, attrs }">
                                        <v-btn
                                          icon
                                          small
                                          v-bind="attrs"
                                          v-on="on"
                                        >
                                          <v-icon>more_horiz</v-icon>
                                        </v-btn>
                                      </template>

                                        <v-card style="max-width:200px">
                                            <v-card-text>
                                                <v-layout ma-0 row wrap>
                                                    <template v-for="(item, index) in accountBalanceMoreItems">
                                                        <v-flex :class="{'pt-2':index!=0}" xs12 sm12 md12 :key="index" cursor-pointer @click="fn_viewCashDetails(item.accountId)">
                                                            <div font11 class="primary-color">{{item.name}}</div>
                                                            <div font16 :class="{'redcolor':item.value<0}">&#8377; {{$root.numberToLocale(roundUpto2Decimals(item.value))}}</div>
                                                        </v-flex>   
                                                    </template>
                                                </v-layout>
                                            </v-card-text>
                                        </v-card>
                                      
                                    </v-menu>
                                </v-flex>

                                <!-- <v-flex xs12 sm12 md12 font11 primary-color cursor-pointer right-align @click="showMoreAccountBalance = !showMoreAccountBalance" v-if="accountBalanceData.series[0].data && accountBalanceData.series[0].data.length">{{showMoreAccountBalance ? 'Show Less' : 'Show More'}}</v-flex> -->
                            </v-layout>

                            <!-- <v-layout ma-0 row wrap v-if="showMoreAccountBalance">
                                <template v-for="(item, index) in accountBalanceMoreItems">
                                    <v-flex pt-2 xs6 sm6 md6 :key="index">
                                        <div font11 class="light-opacity-color">{{item.name}}</div>
                                        <div font16 :class="{'redcolor':item.value<0}">&#8377; {{$root.numberToLocale(roundUpto2Decimals(item.value))}}</div>
                                    </v-flex>   
                                </template>
                            </v-layout> -->

                            <apexchart type="bar" :options="accountBalanceData.options" :series="accountBalanceData.series" v-if="accountBalanceData.series[0].data && accountBalanceData.series[0].data.length"></apexchart>
                        </v-card-text>
                    </v-card>

                </v-flex>

                <v-flex xs12 sm6 md4 pa-3>
                    <v-card id="expense" margin-top-ten v-if="!loading">

                        <v-card-text>
                            <v-layout ma-0 row wrap>
                                <v-flex xs4 sm4 md4 flex-align-vertical-center>
                                    <div class="subheading light-opacity-color">Purchase &amp; Expense</div>
                                </v-flex>
                                <v-flex xs8 sm8 md8 right-align>
                                    <div class="title">&#8377; {{$root.numberToLocale(roundUpto2Decimals(expense.netTotal))}}</div>
                                </v-flex>
                            </v-layout>

                            <div class="expense-bars">
                                <div margin-top-ten>

                                    <div class="background-div">
                                        
                                        <div class="front-div purchase" :style="{'width':expense.purchasePercent > 100 ? '100%' : expense.purchasePercent < 0 ? '0%' : expense.purchasePercent+'%'}"></div>
                                        
                                        <div class="front-div expense" :style="{'width':expense.expensePercent > 100 ? '100%' : expense.expensePercent < 0 ? '0%' : expense.expensePercent+'%'}"></div>
                                    </div>
                                    <v-layout ma-0 row wrap>
                                        <v-flex xs6 sm6 md6>
                                            <span light-opacity-color>Purchase</span><br>
                                            &#8377; {{$root.numberToLocale(roundUpto2Decimals(expense.purchaseTotal))}} / {{expense.purchasePercent}}%
                                        </v-flex>

                                        <v-flex xs6 sm6 md6 right-align>
                                            <span light-opacity-color>Expense</span><br>
                                            &#8377; {{$root.numberToLocale(roundUpto2Decimals(expense.expenseTotal))}} / {{expense.expensePercent}}%
                                        </v-flex>
                                    </v-layout>
                                </div>

                            </div>      

                        </v-card-text>
                    </v-card>
                </v-flex>

                <v-flex xs12 sm6 md4 pa-3>
                    <v-card id="due" v-if="!loading">
                        <v-card-text>

                            <v-layout ma-0 row wrap>

                                <v-flex xs6 sm6 md6>
                                    <div class="greencolor pb-3">To Receive</div>
                                    <div font11 class="light-opacity-color">During Period</div>
                                    <div font16>
                                        <strong>
                                            &#8377; {{dueAndOrder.totalReceivable ? $root.numberToLocale(roundUpto2Decimals(dueAndOrder.totalReceivable)) : 0}}
                                        </strong>
                                    </div>
                                </v-flex>

                                <v-flex xs6 sm6 md6 right-align>
                                    <div class="pb-3">&nbsp;</div>
                                    <div font11 class="light-opacity-color">As On ({{$root.dashboard.endDate | moment("DD-MMM-YYYY")}})</div>
                                    <div font16>
                                        <strong>
                                            &#8377; {{dueAndOrder.asOnTotalReceivable ? $root.numberToLocale(roundUpto2Decimals(dueAndOrder.asOnTotalReceivable)) : 0}}
                                        </strong>
                                    </div>
                                </v-flex>
                            </v-layout>
                            
                        </v-card-text>
                    </v-card>

                </v-flex>

                <v-flex xs12 sm6 md4 pa-3>
                    <v-card id="outstanding" v-if="!loading">
                        <v-card-text>

                            <v-layout ma-0 row wrap>

                                <v-flex xs6 sm6 md6>
                                    <div class="redcolor pb-3">To Pay</div>
                                    <div font11 class="light-opacity-color">During Period</div>
                                    <div font16>
                                        <strong>
                                            &#8377; {{dueAndOrder.totalPayable ? $root.numberToLocale(roundUpto2Decimals(dueAndOrder.totalPayable)) : 0}}
                                        </strong>
                                    </div>
                                </v-flex>

                                <v-flex xs6 sm6 md6 right-align>
                                    <div font16 class="redcolor pb-3">&nbsp;</div>
                                    <div font11 class="light-opacity-color">As On ({{$root.dashboard.endDate | moment("DD-MMM-YYYY")}})</div>
                                    <div font16>
                                        <strong>
                                            &#8377; {{dueAndOrder.asOnTotalPayable ? $root.numberToLocale(roundUpto2Decimals(dueAndOrder.asOnTotalPayable)) : 0}}
                                        </strong>
                                    </div>
                                </v-flex>
                                
                            </v-layout>
                            
                        </v-card-text>
                    </v-card>
                </v-flex>

            </v-layout>

        </div>

    <!--    <dialog-period-selection ref="ref_dialogCustomPeriod" title="Select Period" :openDatePicker="fn_openDatePicker" :startDate="selectedStartDate" :endDate="selectedEndDate" :actionMethod="fn_updateCustomDates"></dialog-period-selection>

        <dialog-datepicker ref="ref_dialogDatePicker" :selected="selectedDate" :updateDate="fn_updateDate"></dialog-datepicker>

        <dialog-month-filter ref="ref_dialogMonthFilter" :selectPeriod="fn_changePeriod"></dialog-month-filter>
        <dialog-quarter-filter ref="ref_dialogQuarterFilter" :selectPeriod="fn_changePeriod"></dialog-quarter-filter> -->

        <!-- <apexchart type="bar" :options="profitAndLoss.options" :series="profitAndLoss.series"></apexchart> -->

        <!-- <v-row style="height:calc(100% - 56px);width:100%" class="pa-3">
            <v-col cols="4">
              <v-card>
                <v-card-title>Profit &amp; Loss</v-card-title>
                <apexchart height="100%" type="bar" :options="profitAndLoss.options" :series="profitAndLoss.series"></apexchart>
              </v-card>

              <v-card class="mt-3">
                <v-card-title>Sale</v-card-title>
                <apexchart height="100%" type="line" :options="sales.options" :series="sales.series"></apexchart>
              </v-card>
            </v-col>

            <v-col cols="4">

                <v-card>
                    <v-card-title>Purchase &amp; Expense</v-card-title>
                    <v-card-text>
                        <div class="background-div">
                            
                            <div class="front-div purchase" style="width:50%;height:48px;background:orange;float:left"></div>
                            
                            <div class="front-div expense" style="width:50%;height:48px;background:blue;float:right"></div>
                        </div>
                        <v-row>
                            <v-col>Purchase 50%</v-col>
                            <v-col>Expense 50%</v-col>
                        </v-row>
                    </v-card-text>
                </v-card>

                <v-card class="mt-3">
                    <v-card-title>Balance</v-card-title>
                    <apexchart height="100%" type="bar" :options="accountBalanceData.options" :series="accountBalanceData.series"></apexchart>
                </v-card>
              
            </v-col>

            <v-col cols="4">
                <v-card>
                    <v-card-title>Outstanding</v-card-title>
                    <v-card-text>
                        <v-subheader class="pl-0" style="color:green">To Receive</v-subheader>
                        <v-row>
                            <v-col cols="6">
                                <div>During Period</div>
                                <div>4,00,444</div>      
                            </v-col>

                            <v-col cols="6">
                                <div>As On End Date</div>
                                <div>4,00,444</div>      
                            </v-col>
                        </v-row>

                        <v-subheader class="pl-0" style="color:red">To Pay</v-subheader>
                        <v-row>
                            <v-col cols="6">
                                <div>During Period</div>
                                <div>4,00,444</div>      
                            </v-col>

                            <v-col cols="6">
                                <div>As On End Date</div>
                                <div>4,00,444</div>      
                            </v-col>
                        </v-row>
                        
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row> -->

        <v-snackbar v-model="snackbar">{{ snackbarMessage }}
          <template v-slot:action="{ attrs }">
            <v-btn color="primary" text v-bind="attrs" @click="snackbar = false">Close</v-btn>
          </template>
        </v-snackbar>

        <dialog-month-filter ref="ref_dialogMonthFilter" :selectPeriod="fn_changePeriod"></dialog-month-filter>
        <dialog-quarter-filter ref="ref_dialogQuarterFilter" :selectPeriod="fn_changePeriod"></dialog-quarter-filter>
        <dialog-datepicker ref="ref_dialogDatePicker" :selected="selectedDate" :updateDate="fn_updateDate"></dialog-datepicker>

        <v-navigation-drawer v-model="custom_date" absolute right :overlay="true">
            <v-card flat>
                <v-subheader>Custom Date<v-spacer></v-spacer><v-btn icon @click="custom_date=false"><v-icon>close</v-icon></v-btn></v-subheader>
                <v-divider></v-divider>
                <v-card-text>
                    <v-layout ma-0 row wrap>
                        <v-flex xs12 sm12 md12 cursor-pointer @click="fn_openDatePicker('start', $root.dashboard.startDate)">
                            <v-text-field :value="fn_getIndianFormatDate(selectedStartDate)" label="Start Date" readonly append-icon="drop_down"></v-text-field>
                        </v-flex>
                        <v-flex xs12 sm12 md12 cursor-pointer @click="fn_openDatePicker('end', $root.dashboard.endDate)">
                            <v-text-field :value="fn_getIndianFormatDate(selectedEndDate)" label="End Date" readonly append-icon="drop_down"></v-text-field>
                        </v-flex>

                        <v-flex xs12 sm12 md12 redcolor pa-2 v-if="!isDateSelectionValid">
                            Invalid date selection
                        </v-flex>

                        <v-flex xs12 sm12 md12 grey-back pa-2 v-if="!isDateSelectionValid">
                            Note: Start date should be less than end date.
                        </v-flex>

                        <v-flex xs12 sm12 md12 right-align>
                            <v-btn text color="primary" @click="fn_updateCustomDates" :disabled="!isDateSelectionValid">Done</v-btn>
                        </v-flex>

                    </v-layout>
                </v-card-text>
            </v-card>
        </v-navigation-drawer>

    </div>
</template>

<script>
import VueApexCharts from 'vue-apexcharts'

// import dialogPeriodSelection from '../../../dialogs/dialog_period_selection'
// import dialogDatepicker from '../../../dialogs/dialog_datepicker'

// import dialogMonthFilter from '../../../dialogs/dialog_filter_months'
// import dialogQuarterFilter from '../../../dialogs/dialog_filter_quarter'

const paymentAccountsAndLedger  = require('../../config.js').configs.paymentAccountsAndLedger

// import { ContentLoader } from 'vue-content-loader'

import dialogDatepicker from '../dialogs/dialog_datepicker'
import dialogMonthFilter from '../dialogs/dialog_filter_months'
import dialogQuarterFilter from '../dialogs/dialog_filter_quarter'

    export default{
        data(){
            return{
                showMoreNetProfitLoss:false,
                netProfitLossMoreItems:[],

                showMoreAccountBalance:true,
                accountBalanceMoreItems:[],
                
                // profitAndLoss:{
                //  options:null,
                //  series:null,
                //  netLoss:null,
                //  netProfit:null
                // },

                profitAndLoss:{
                    options:{
                        plotOptions: {
                            bar:{
                                horizontal: true,
                            }
                        },
                        yaxis: {categories: ["Opening Stock", "Income", "Expense", "Closing Stock"]},
                        xaxis:{
                            labels:{    
                                formatter: function (val) {
                                  return val.toLocaleString("hi-IN");
                                }
                            }
                        },
                        fill:{type:"gradient"},
                        labels: ["Opening Stock", "Income", "Expense", "Closing Stock"],
                        chart:{toolbar:{show:false}},
                        dataLabels:{
                            enabled: false
                        }
                    },
                    series:[{
                        name: 'Value(₹)',
                        data: [5000, 6000,12000, 8000]
                    }],
                    netLoss:null,
                    netProfit:null
                },

                // sales:{
                //     options:null,
                //     series:null,
                //     total:0
                // },

                sales:{
                 options:{
                     xaxis: {
                         categories: ["24-May", "25-May", "26-May", "27-May", "28-May"]
                     },
                     yaxis:{
                         labels:{
                             formatter: function (val) {
                               return val.toLocaleString("hi-IN");
                             }
                         }
                     },
                        fill:{type:"gradient"},
                        labels: ["24-May", "25-May", "26-May", "27-May", "28-May"],
                        chart:{toolbar:{show:false}},    
                        dataLabels: {
                       formatter: function(val) {
                           return val.toLocaleString("hi-IN");
                       }
                     }
                 },
                 series:[{
                     name: 'Sale Value(₹)',
                     data: [2000,4000,3000,6000,2500]
                 }],
                 total:0
                },

                expense:{
                    netTotal:null,
                    purchaseTotal:null,
                    expenseTotal:null,
                    purchasePercent:null,
                    expensePercent:null,
                },

                dueAndOrder:null,

                dialog_datepicker:false,
                dateType:null,

                currentPeriod:"This Month",

                periods:[
                  {"title":"Today", "value":"currentDate"},
                  {"title":"Yesterday", "value":"previousDate"},
                  {"title":"This Month", "value":"month"},
                  {"title":"Previous Month", "value":"previous_month"},
                  {"title":"Select Month", "value":"M"},
                  {"title":"This Quarter", "value":"quarter"},
                  {"title":"Select Quarter", "value":"Q"},
                  {"title":"This Fiscal Year", "value":"year"},
                  {"title":"All Time", "value":"alltime"},
                  {"title":"Custom", "value":"custom"}
                ],
                
                selectedDate:null,
                loading:true,

                totalAccountBalance:0,

                // accountBalanceData:{
                //     options:null,
                //     series:null
                // },
                accountBalanceData:{
                 options:{
                     plotOptions: {
                            bar:{
                             horizontal: true,
                         }
                        },
                     yaxis: {categories: ["Cash A/c", "HDFC Bank"]},
                     xaxis:{
                         labels:{    
                             formatter: function (val) {
                               return val.toLocaleString("hi-IN");
                             }
                         }
                     },
                        fill:{type:"gradient"},
                        labels: ["Cash A/c", "HDFC Bank"],
                        chart:{toolbar:{show:false}},
                        dataLabels: {
                         formatter: function(val) {
                             return val + '%';
                         }
                     }
                 },
                 series:[{
                     name: 'Account Balance',
                     data: [5000, 10000]
                 }]
                },

                selectedStartDate:null,
                selectedEndDate:null,
                snackbar:false,
                snackbarMessage:null,
                custom_date:false

                // fromOpeningDate:false
                
            }
        },
        components:{
            'apexchart': VueApexCharts,
            'dialog-month-filter': dialogMonthFilter,
            'dialog-quarter-filter': dialogQuarterFilter,
            'dialog-datepicker': dialogDatepicker,
            // 'dialog-period-selection':dialogPeriodSelection,
            // 'dialog-datepicker':dialogDatepicker,
            // 'dialog-month-filter': dialogMonthFilter,
            // 'dialog-quarter-filter': dialogQuarterFilter
            // ContentLoader
        },
        methods:{

            fn_viewCashDetails: function(accountId){
                if(accountId){
                    this.$router.push({path:'/'+ this.$root.CurrentBusiness.BusinessID + '/dashboard/ledger/items/'+accountId, query:{back:1}});
                }
			},

            fn_getIndianFormatDate: function(dataObj){
                return this.$moment(dataObj).format('DD-MMM-YYYY');
            },

            test: function(){
                // this.$eventHub.$emit('snackbarAlert', {msg:'Selected period is not applicable'});
                this.$eventHub.$emit('UpdateMobileApp', {onload:1})
            },

            fn_products: function(){
                let filterObject = {};
                filterObject.search = '';
                filterObject.inventory = 1;
                filterObject.limit = 50;
                filterObject.index = 0;
                
                // let self = this;
                this.getDialogItems("ProductDialog", filterObject, 
                    {
                    onSuccess: function (response) {
                        console.log(response);
                    },
                    onError: function (error) {
                        console.log(error);
                    }
                    })
                },

            fn_accountLedgers: function(val, groupTypeId, accountGroupIds, accountGstTypes, accountGroups){
                let filterObject = {};
                filterObject.search = '';

                // if(this.settings.transactionTypeId == 30004){
                //     filterObject.accountGroupIds = [paymentAccountsAndLedger.groups.cashInHand, paymentAccountsAndLedger.groups.bank, paymentAccountsAndLedger.groups.card, paymentAccountsAndLedger.groups.wallet]
                // }

                if(accountGroupIds){
                    filterObject.accountGroupIds = accountGroupIds;
                }

                if(groupTypeId){
                    filterObject.groupTypeId = groupTypeId;
                }

                if(accountGroups && accountGroups.length){
                    filterObject.accountGroups = accountGroups;
                }

                if(accountGstTypes && accountGstTypes.length){
                    filterObject.gstTypes = accountGstTypes;
                }
                
                // let self = this;
                this.getDialogItems("AccountDialog", filterObject, 
                    {
                    onSuccess: function (response) {
                        console.log(response);
                    },
                    onError: function (error) {
                        console.log(error);
                    }
                    }
                )
                },

        //     fn_getWidth: function(value){
        //         let width = 0;
        //         width = this.roundUpto2Decimals((value/this.expense.netTotal)*100);
        //         return width+"%";
        //     },

            fn_getNetProfitAndLoss: function(){
                let filterObject = {};
                filterObject.startDate = this.$moment(new Date(this.$root.dashboard.startDate)).startOf('day');
                filterObject.endDate = this.$moment(new Date(this.$root.dashboard.endDate)).endOf('day');

                filterObject.startDate = this.$root.fn_getLocalDateFormat(filterObject.startDate);
                filterObject.endDate = this.$root.fn_getLocalDateFormat(filterObject.endDate);

                // filterObject.GSTRegistrationStatus = this.$root.CurrentBusiness.GSTRegistrationStatus;
                filterObject.GSTRegistrationStatus = 1;

                let self = this;
                this.getFilterData('NetProfitLoss', null, filterObject, null, 
                    {
                        onSuccess: function(response){
                            console.log(response);
                            let data = JSON.parse(response);
                            self.profitAndLoss.netLoss = data.netLoss;
                            self.profitAndLoss.netProfit = data.netProfit;

                            let openingStockValue = data.openingStock > 0 ? data.openingStock : 0;
                            let netIncome = data.netIncome > 0 ? data.netIncome : 0;
                            let netExpense = data.netExpense > 0 ? data.netExpense : 0;
                            let closingStockValue = data.closingStock > 0 ? data.closingStock : 0;

                            // self.expense.netTotal = data.netExpense ? data.netExpense : 0;

                            self.profitAndLoss.series = [
                                {
                                    "name": 'Value(₹)',
                                    "data":[openingStockValue, netIncome, netExpense, closingStockValue]
                                }
                            ];

                            self.netProfitLossMoreItems = [
                                {"name":"Opening Stock", "value":data.openingStock ? data.openingStock : 0},
                                {"name":"Income", "value":data.netIncome ? data.netIncome : 0},
                                {"name":"Expense", "value":data.netExpense ? data.netExpense : 0},
                                {"name":"Closing Stock", "value":data.closingStock ? data.closingStock : 0}
                            ];


                            self.fn_updateProfitLossOptions();
                            
                        }, 
                        onError: function(error){
                          console.log(error);
                        }
                    }
                );
            },

            fn_updateProfitLossOptions: function(){
                let self = this;
                this.profitAndLoss.options = {
                    plotOptions: {
                        bar:{
                            horizontal: true,
                   //       dataLabels: {
                            //   position: 'top'
                            // }
                        }
                    },
                    yaxis: {categories: ["Opening Stock", "Income", "Expense", "Closing Stock"]},
                    xaxis:{
                        labels:{    
                            formatter: function (val) {
                                return self.fn_formatValue(val);
                            }
                        }
                    },
                    fill:{type:"gradient"},
                    labels: ["Opening Stock", "Income", "Expense", "Closing Stock"],
                    chart:{toolbar:{show:false}},
                    dataLabels:{
                        enabled: false
                    }
                 //    dataLabels: {
                    //      formatter: function(val) {
                       //   return val.toLocaleString("hi-IN");
                        // }
                    // }
                }

                // getting total purchase
                // calling after profit loss as some data coming in profit and loss is useful for purchase and expense
                this.fn_getPurchaseAndExpense();
            },

            fn_getSales: function(){
                let startDate = this.$moment(new Date(this.$root.dashboard.startDate),'M/D/YYYY').startOf('day');
                let endDate = this.$moment(new Date(this.$root.dashboard.endDate),'M/D/YYYY').endOf('day');
                let diffDays = endDate.diff(startDate, 'days');

                if(diffDays>=7 && diffDays<31){
                    this.fn_getSaleWeekWise(diffDays)
                }
                else{
                    this.fn_getSalesOther()
                }
            },

            fn_getSaleWeekWise: function(daysCount){
                let filterObject = {};

                let startDate = new Date(this.$root.dashboard.startDate);
                let rangeArray = [];
                let labels = [];

                let days = daysCount+1;
                while(days>0){

                    let rangeObj = {}
                    rangeObj.startDate = this.$moment(new Date(startDate)).startOf('day');
                    let range1 = this.$moment(startDate).format('DMMM');

                    let range = 0;
                    if(days > 7){
                        range = 7;
                        days = days - 7;
                    }
                    else{
                        range = days;
                        days = 0;
                    }

                    let endDate = new Date(startDate.setDate(startDate.getDate() + range - 1));
                    rangeObj.endDate = this.$moment(new Date(endDate)).endOf('day');
                    let range2 = this.$moment(endDate).format('DMMM');
                    rangeArray.push(rangeObj);
                    labels.push(range1+'-'+range2);

                    startDate = new Date(startDate.setDate(startDate.getDate() + 1));

                }

                console.log(rangeArray);


                filterObject.dateRanges = {"rangeArray":rangeArray};

                console.log(filterObject);
                console.log(labels);

                let self = this;
                this.getFilterData('SalesDataWeekly', null, filterObject, null,
                    {
                        onSuccess: function(response){
                            console.log(response);
                            let data = JSON.parse(response);
                            self.currentPeriod = self.$root.dashboard.periodValue;
                            self.fn_processSaleWeekWise(data.saleInvoiceItems, labels);
                        }, 
                        onError: function(error){
                            console.log(error);
                        }
                    }
                );
            },

            fn_getSalesOther: function(){
                let filterObject = {};
                filterObject.startDate = this.$moment(this.$root.dashboard.startDate).startOf('day');
                filterObject.endDate = this.$moment(this.$root.dashboard.endDate).endOf('day');

                // sending date for month calculations at android end
                // filterObject.intervalStartDate = this.$root.dashboard.startDate;
                filterObject.intervalStartDate = this.$root.fn_getLocalDateFormat(this.$root.dashboard.startDate);

                filterObject.startDate = this.$root.fn_getLocalDateFormat(filterObject.startDate);
                filterObject.endDate = this.$root.fn_getLocalDateFormat(filterObject.endDate);

                let self = this;
                this.getFilterData('SalesData', null, filterObject, null,
                    {
                        onSuccess: function(response){
                            console.log(response);
                            let data = JSON.parse(response);
                            self.currentPeriod = self.$root.dashboard.periodValue;
                            if(data.daysCount>-1 && data.daysCount<7){
                                self.fn_processSaleDayWise(data.saleInvoiceItems, data.daysCount);
                            }
                            // else if(data.daysCount>=7 && data.daysCount<31){
                            //  self.fn_processSaleWeekWise(data.saleInvoiceItems, data.daysCount);
                            // }
                            else{
                                self.fn_processSaleMonthWise(data.saleInvoiceItems)
                            }
                        }, 
                        onError: function(error){
                            console.log(error);
                        }
                    }
                );
            },

            fn_processSaleMonthWise: function(saleItems){

                let monthsArray = [
                    {"name":"Apr", "Count":4}, {"name":"May", "Count":5}, {"name":"Jun", "Count":6}, {"name":"Jul", "Count":7}, {"name":"Aug", "Count":8}, {"name":"Sep", "Count":9}, {"name":"Oct", "Count":10}, {"name":"Nov", "Count":11}, {"name":"Dec", "Count":12}, {"name":"Jan", "Count":1}, {"name":"Feb", "Count":2}, {"name":"Mar", "Count":3}
                ]

                let totalSale = 0;

                let series = [];
                let categories = [];

                for(let i=0; i<monthsArray.length; i++){

                    // let monthlySaleValue = 0;

                    for(let j=0; j<saleItems.length; j++){
                        if(monthsArray[i].Count == saleItems[j].periodMonthWise){
                            // monthlySaleValue = saleItems[j].saleValue;
                            series.push(saleItems[j].saleValue);
                            totalSale += saleItems[j].saleValue;
                            categories.push(monthsArray[i].name);
                            break;
                        }
                    }

                    // series.push(monthlySaleValue);

                }

                console.log(series);

                this.sales.total = totalSale;

                // let categories = ['Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec', 'Jan', 'Feb', 'Mar'];
                this.fn_updateSaleOptions(categories, series);

            },

            fn_processSaleDayWise: function(saleItems, count){

                // Setting up labels for sales line chart (day/date wise)

                let labels = [];

                let middleDate = new Date(this.$root.dashboard.startDate);

                for(let i=0; i<=count; i++){
                    if(i==0){
                        labels.push(new Date(this.$root.dashboard.startDate));
                    }
                    else if(i==count){
                        labels.push(new Date(this.$root.dashboard.endDate));    
                    }
                    else{
                        labels.push(new Date(middleDate.setDate(middleDate.getDate() + 1)));
                    }
                }

                // Setting up series/sales value for sales line chart (day/date wise)

                let series = [];

                let totalSale = 0;

                for(let j=0; j<labels.length; j++){

                    let lableDate = labels[j];
                    let saleFound = false;

                    for(let k=0; k<saleItems.length; k++){

                        // let salesDate = new Date(saleItems[k].periodDayWise);

                        // if(lableDate.getDate() == salesDate.getDate() && lableDate.getMonth() == salesDate.getMonth() && lableDate.getFullYear() == salesDate.getFullYear()){
                        if(lableDate.getDate() == saleItems[k].periodDayWise){
                            series.push(saleItems[k].saleValue);
                            totalSale += saleItems[k].saleValue;
                            saleFound = true;
                            break;
                        }
                        // else{
                        //  series.push(0);
                        // }
                    }

                    !saleFound ? series.push(0) : ''; 
                    labels[j] = this.$moment(labels[j]).format('DD-MMM');
                }

                console.log(labels);
                console.log(series);

                // labels.length == 1 ? labels.push(labels[0]) : '';

                if(labels.length == 1){
                    let duplicateLabel = labels[0];
                    labels.push(duplicateLabel);
                }

                if(series.length == 1){
                    let value = series[0];
                    series[0] = 0;
                    series.push(value);
                }

                this.sales.total = totalSale;
                this.fn_updateSaleOptions(labels, series);

            },

            fn_processSaleWeekWise: function(saleItems, labels){

                let totalSale = 0;
                let series = [];

                for(let i=0; i<saleItems.length; i++){
                    if(saleItems[i].saleValue){
                        series.push(saleItems[i].saleValue);
                        totalSale += saleItems[i].saleValue;
                    }
                    else{
                        series.push(0); 
                    }
                }

                this.sales.total = totalSale;
                this.fn_updateSaleOptions(labels, series);

            },

            fn_updateSaleOptions: function(categories, series){
                // let self = this;
                this.sales.options = {
                    xaxis: {
                        labels:{rotate: -45, rotateAlways: categories.length > 3 ? true : false},
                        categories: categories
                    },
                    yaxis:{
                        labels:{
                            formatter: function (val) {
                                // return self.fn_formatValue(val);
                                return val.toLocaleString("hi-IN");
                            }
                        }
                    },
                    fill:{type:"gradient"},
                    labels: categories,
                    chart:{toolbar:{show:false}},
                    dataLabels: {
                      formatter: function(val) {
                          return val.toLocaleString("hi-IN");
                      }
                    }
                }
                this.sales.series = [{
                    name: 'Sale Value(₹)',
                    data: series
                }]

                // setTimeout(function(){
                //     let outerDiv = document.querySelector("#sales > div > div:nth-child(2)");
                //     let chartDiv = document.querySelector("#sales > div > div:nth-child(2) > .apexcharts-canvas");
                //     let chartSvg = document.querySelector("#sales > div > div:nth-child(2) > .apexcharts-canvas > svg");
                //     outerDiv.style.height = "295px";
                //     chartDiv.style.height = "280px";
                //     chartSvg.height.baseVal.value = 280;
                // },1000);
            },

            fn_formatValue: function(val){
                let labelVal = Number(val);
                let absVal = Math.abs(labelVal);
                if(absVal <= 999){
                    return Math.round(labelVal);
                }
                else if(absVal >= 1000 && absVal <= 99999){
                    // let roundedVal = Math.round(labelVal/1000);
                    let roundedVal = (Math.round(labelVal/5)*5)/1000;
                    if(roundedVal==100){
                        return '1L';
                    }
                    else{
                        return roundedVal + 'k'
                    }
                }
                else if(absVal >= 100000 && absVal <= 9999999){
                    // let roundedVal = Math.round(labelVal/100000);
                    let roundedVal = (Math.round(labelVal/5)*5)/100000;
                    if(roundedVal==100){
                        return '1C';
                    }
                    else{
                        return roundedVal + 'L'
                    }
                }
                else if(absVal >= 10000000){
                    // let roundedVal = Math.round(labelVal/10000000);
                    let roundedVal = (Math.round(labelVal/5)*5)/10000000;
                    return roundedVal + 'C'
                }
            },


            fn_getDueAndOrderTotal: function(){
                let filterObject = {};
                filterObject.startDate = this.$moment(new Date(this.$root.dashboard.startDate)).startOf('day');
                // filterObject.startDate = new Date(this.$root.dashboard.startDate);
                filterObject.endDate = this.$moment(new Date(this.$root.dashboard.endDate)).endOf('day');

                filterObject.startDate = this.$root.fn_getLocalDateFormat(filterObject.startDate);
                filterObject.endDate = this.$root.fn_getLocalDateFormat(filterObject.endDate);

                // order
                filterObject.formTransactionListId = [10002, 10003];
                filterObject.transactionListId = [10001];

                let self = this;
                this.getFilterData('DueAndOrderTotal', null, filterObject, null,
                    {
                        onSuccess: function(response){
                            console.log(response);
                            self.dueAndOrder = JSON.parse(response)
                        }, 
                        onError: function(error){
                            console.log(error);
                        }
                    }
                );
            },

            fn_getAccountBalance: function(){
                let filterObject = {};
                // filterObject.startDate = this.$moment(this.$root.dashboard.startDate).startOf('day');
                filterObject.endDate = this.$moment(new Date(this.$root.dashboard.endDate)).endOf('day');
                filterObject.endDate = this.$root.fn_getLocalDateFormat(filterObject.endDate);
                //First always will be bank account as at android/database end will consider first one as bank account
                filterObject.accountGroups = [paymentAccountsAndLedger.groups.cashInHand, paymentAccountsAndLedger.groups.bank, paymentAccountsAndLedger.groups.wallet, paymentAccountsAndLedger.groups.card];
                // filterObject.accountGroups = [paymentAccountsAndLedger.groups.bank, paymentAccountsAndLedger.groups.cashInHand, paymentAccountsAndLedger.groups.card, paymentAccountsAndLedger.groups.wallet];

                this.totalAccountBalance = 0;

                let self = this;
                this.getFilterData('AccountBalance', null, filterObject, null,
                    {
                        onSuccess: function(response){
                            console.log(response);
                            // self.accountBalance = JSON.parse(response);
                            let accountData = JSON.parse(response);

                            let seriesDataArr = [];
                            let categories = [];

                            // let positiveValueSum = 0;

                            self.accountBalanceMoreItems = [];

                            if(accountData.cashInHand && accountData.cashInHand.length){
                                for(let i=0; i<accountData.cashInHand.length; i++){
                                    if(accountData.cashInHand[i].balance){
                                        categories.push(accountData.cashInHand[i].name);
                                        seriesDataArr.push(accountData.cashInHand[i].balance > 0 ? accountData.cashInHand[i].balance : 0);
                                        self.totalAccountBalance += accountData.cashInHand[i].balance;

                                        // if(accountData.cashInHand[i].balance > 0){
                                        //  positiveValueSum += accountData.cashInHand[i].balance;
                                        // }

                                        let obj = {};
                                        obj.name = accountData.cashInHand[i].name;
                                        obj.value = accountData.cashInHand[i].balance;
                                        obj.accountId = accountData.cashInHand[i].accountId;
                                        self.accountBalanceMoreItems.push(obj);
                                    }
                                }
                            }
                            

                            if(accountData.bankAccounts && accountData.bankAccounts.length){
                                for(let i=0; i<accountData.bankAccounts.length; i++){
                                    if(accountData.bankAccounts[i].balance){
                                        categories.push(accountData.bankAccounts[i].name);
                                        seriesDataArr.push(accountData.bankAccounts[i].balance > 0 ? accountData.bankAccounts[i].balance : 0);
                                        self.totalAccountBalance += accountData.bankAccounts[i].balance;

                                        // if(accountData.bankAccounts[i].balance > 0){
                                        //  positiveValueSum += accountData.bankAccounts[i].balance;
                                        // }

                                        let obj = {};
                                        obj.name = accountData.bankAccounts[i].name;
                                        obj.value = accountData.bankAccounts[i].balance;
                                        obj.accountId = accountData.bankAccounts[i].accountId;
                                        self.accountBalanceMoreItems.push(obj);
                                    }
                                }   
                            }
                            
                            if(accountData.creditCard && accountData.creditCard.length){
                                for(let i=0; i<accountData.creditCard.length; i++){
                                    if(accountData.creditCard[i].balance){
                                        categories.push(accountData.creditCard[i].name);
                                        seriesDataArr.push(accountData.creditCard[i].balance > 0 ? accountData.creditCard[i].balance : 0 );
                                        self.totalAccountBalance += accountData.creditCard[i].balance;

                                        // if(accountData.creditCard[i].balance > 0){
                                        //  positiveValueSum += accountData.creditCard[i].balance;
                                        // }

                                        let obj = {};
                                        obj.name = accountData.creditCard[i].name;
                                        obj.value = accountData.creditCard[i].balance;
                                        obj.accountId = accountData.creditCard[i].accountId;
                                        self.accountBalanceMoreItems.push(obj);
                                    }
                                }   
                            }

                            if(accountData.wallet && accountData.wallet.length){
                                for(let i=0; i<accountData.wallet.length; i++){
                                    if(accountData.wallet[i].balance){
                                        categories.push(accountData.wallet[i].name);
                                        seriesDataArr.push(accountData.wallet[i].balance > 0 ? accountData.wallet[i].balance : 0);
                                        self.totalAccountBalance += accountData.wallet[i].balance;

                                        // if(accountData.wallet[i].balance > 0){
                                        //  positiveValueSum += accountData.wallet[i].balance;
                                        // }

                                        let obj = {};
                                        obj.name = accountData.wallet[i].name;
                                        obj.value = accountData.wallet[i].balance;
                                        obj.accountId = accountData.wallet[i].accountId;
                                        self.accountBalanceMoreItems.push(obj);
                                    }
                                }   
                            }

                            // let seriesDataInPrecentage = [];
                            // for(let i in seriesDataArr){
                            //  let value = seriesDataArr[i] > 0 ? seriesDataArr[i] : 0;
                            //  let percentage = self.roundUpto2Decimals((value/positiveValueSum)*100);
                            //  seriesDataInPrecentage.push(percentage);
                            // }
                            

                            console.log(seriesDataArr);

                            self.fn_updateAccountBalanceOptions(categories, seriesDataArr);
                        }, 
                        onError: function(error){
                          console.log(error);
                        }
                    }
                );
            },

            fn_updateAccountBalanceOptions: function(accountBalcategories, series){
                let self = this;
                this.accountBalanceData.options = {
                    plotOptions: {
                        bar:{
                            horizontal: true,
                        }
                    },
                    yaxis: {categories: accountBalcategories},
                    xaxis:{
                        labels:{    
                            formatter: function (val) {
                                return self.fn_formatValue(val);
                                // return val;
                            }
                        }
                    },
                    fill:{type:"gradient"},
                    labels: accountBalcategories,
                    chart:{toolbar:{show:false}},
                    dataLabels:{
                        enabled: false
                    }
                }

                this.accountBalanceData.series = [{
                    name: 'Amount(₹)',
                    data: series
                }]
            },

            fn_getPurchaseAndExpense: function(){
                let filterObject = {};
                filterObject.startDate = this.$moment(new Date(this.$root.dashboard.startDate)).startOf('day');
                filterObject.endDate = this.$moment(new Date(this.$root.dashboard.endDate)).endOf('day');

                filterObject.startDate = this.$root.fn_getLocalDateFormat(filterObject.startDate);
                filterObject.endDate = this.$root.fn_getLocalDateFormat(filterObject.endDate);

                this.expense.series = [];
                let self = this;
                this.getFilterData('PurchaseAndExpenseTotal', null, filterObject, null,
                    {
                        onSuccess: function(response){
                            console.log(response);
                            let data = JSON.parse(response);

                            self.expense.purchaseTotal = data.totalPurchase ? data.totalPurchase : 0;
                            self.expense.expenseTotal = data.totalExpense ? data.totalExpense : 0;
                            self.expense.netTotal = self.roundUpto2Decimals(data.totalPurchase + data.totalExpense);

                            self.expense.purchasePercent = self.roundUpto2Decimals((self.expense.purchaseTotal/self.expense.netTotal)*100);
                            self.expense.expensePercent = self.roundUpto2Decimals((self.expense.expenseTotal/self.expense.netTotal)*100);

                            setTimeout(function(){
                                self.loading = false;
                            },500);
                        }, 
                        onError: function(error){
                            console.log(error);
                        }
                    }
                );
            },

        //     fn_openDialogMonthFilter: function(){
        //         this.$refs['ref_dialogMonthFilter'].openDialogWeb();
        //     },

        //     fn_openDialogQuarterFilter: function(){
        //         this.$refs['ref_dialogQuarterFilter'].openDialogWeb();
        //     },

            fn_changePeriod: function(period, month, year){

                let type = null;

                if(period=='monthly' || period=='quarterly'){
                    type = period;
                }
                else{
                    type = period.value;
                }
                

                if(type=='M'){
                    // this.fn_openDialogMonthFilter();
                    this.$refs['ref_dialogMonthFilter'].openDialogWeb();
                }
                else if(type=='Q'){
                    // this.fn_openDialogQuarterFilter();
                    this.$refs['ref_dialogQuarterFilter'].openDialogWeb();
                }
                else{

                    this.$refs['ref_dialogMonthFilter'].closeDialogWeb();
                    this.$refs['ref_dialogQuarterFilter'].closeDialogWeb();

                    let previousDateSelected = false;

                    if(type == "previous_month"){
                        let now = new Date();
                        let previousMonthDate = new Date(now.getFullYear(), now.getMonth(), 0);
                        if(previousMonthDate.getTime() < new Date(this.$root.CurrentBusiness.OpeningDate).getTime()){
                            previousDateSelected = true;
                        }
                    }

                    if(type == "previousDate"){
                        let now = new Date();
                        let yesterday = new Date(now.setDate(now.getDate() - 1));
                        if(yesterday.getTime() < new Date(this.$root.CurrentBusiness.OpeningDate).getTime()){
                            previousDateSelected = true;
                        }
                    }

                    if(previousDateSelected){
                        // this.showToast("Not Applicable");
                        this.snackbar = true;
                        this.snackbarMessage = "Not Applicable!";
                        return;
                    }

                    let dateObj = this.fn_dateRange(type, month, year);

                    this.$root.dashboard.periodValue = period.title;
                    this.$root.dashboard.defaultDateRange = period.value;


                    if(period.value=='custom'){
                        this.selectedStartDate = this.$root.dashboard.startDate;
                        this.selectedEndDate = this.$root.dashboard.endDate;
                        // this.$refs['ref_dialogCustomPeriod'].dialog_period_selection = true;
                        this.custom_date = true;
                    }
                    else{
                        if(type == 'monthly'){
                            this.$root.dashboard.periodValue = this.$moment(dateObj.startDate).format('MMM, YYYY');
                        }
                        else if(type == 'quarterly'){
                            this.$root.dashboard.periodValue = this.$moment(dateObj.startDate).format('MMM') + ' - ' + this.$moment(dateObj.endDate).format('MMM');
                        }
                        
                        this.$root.dashboard.startDate = dateObj.startDate;
                        this.$root.dashboard.endDate = dateObj.endDate;

                        let self = this;
                        setTimeout(function(){
                            self.fn_getDashboardData();
                        },100);
                    }

                }
                
            },

            fn_openDatePicker: function (type, date) {
              let self = this;
              this.dateType = type;
              this.selectedDate = date;
              setTimeout(function () {
                self.$refs['ref_dialogDatePicker'].openDialogWeb();
              })
            },

            fn_updateDate: function (value) {
              this.$refs['ref_dialogDatePicker'].closeDialogWeb();

              if(this.dateType=="start"){
                this.selectedStartDate = value;
              }
              else if(this.dateType=="end"){
                this.selectedEndDate = value;
              }

              // this.fn_updateCustomDates();

              let self = this;
              setTimeout(function(){
                self.$refs['ref_dialogDatePicker'].disableBtn = false;
              }, 1000);

              // this.dialog_datepicker = false;

              // this.fn_getDashboardData();
            },

            // fn_openDatePicker: function (type, date) {
            //     let self = this;
            //     this.dateType = type;
            //     // this.selectedDate = date ? new Date(date) : new Date();
            //     let dateObj = date ? new Date(date) : new Date();
            //     this.$refs['ref_dialogDatePicker'].selectedDate = dateObj.getFullYear()+'-'+("0" + (dateObj.getMonth()+1)).slice(-2)+'-'+("0" + dateObj.getDate()).slice(-2);
            //     setTimeout(function () {
            //         self.dialog_datepicker = true;
            //     })
            // },

        //     fn_updateDate: function (value) {
        //         if(this.dateType=="end" && new Date(value).getTime() < new Date(this.$root.CurrentBusiness.OpeningDate).getTime()){
        //             this.showToast("Not Applicable");
        //             return;
        //         }

        //         if(this.dateType=="start"){
        //             // this.$root.dashboard.startDate = value;
        //             this.selectedStartDate = value;
        //         }
        //         else if(this.dateType=="end"){
        //             this.selectedEndDate = value;
        //             // this.$root.dashboard.endDate = value;
        //         }

        //         this.dialog_datepicker = false;

        //         // this.fn_getDashboardData();
        //     },

            fn_updateCustomDates: function(){
                this.custom_date = false;
                this.$root.dashboard.startDate = this.selectedStartDate;
                this.$root.dashboard.endDate = this.selectedEndDate;
                // this.fn_closePeriodDialog();
                this.fn_getDashboardData();
            },

            fn_dateRange:  function (type, value, year){

                let dateObj = {};

                let minSelectionDate = new Date(this.$root.minDateSelection);
                let currentDate = new Date();
                let currentMonth = currentDate.getMonth() + 1;
                let thisFiscalYear = currentMonth<=3 ? currentDate.getFullYear() : currentDate.getFullYear() + 1;

                if(type=='monthly'){

                    let now = null;

                    // value == month number
                    if(value<=3){
                        now = new Date((year+1)+"/"+value);
                    }
                    else{
                        now = new Date(year+"/"+value);
                    }

                    // let now = new Date();
                    // last date of the month
                    let endDate  = new Date(now.getFullYear(), value, 0);
                    // first date of the month
                    let startDate = new Date(now.getFullYear(), value-1, 1);

                    let sDate = null;
                    if(startDate.getTime() > minSelectionDate.getTime()){
                        sDate = startDate;
                    }
                    else{
                        sDate = minSelectionDate;
                    }

                    dateObj.startDate = this.formatDate(sDate);
                    dateObj.endDate = this.formatDate(endDate);
                }
                // else if(type=='month'){
                //     let now = new Date();
                //     let firstday = new Date(now.getFullYear(), now.getMonth(), 1);
                //     let lastday  = new Date(now.getFullYear(), now.getMonth()+1, 0);
                //     dateObj.startDate = this.formatDate(firstday);
                //     dateObj.endDate = this.formatDate(lastday);
                // }
                else if(type=='month'){
                    let now = new Date();
                    let endDate  = new Date(now.getFullYear(), now.getMonth()+1, 0);
                    let startDate = new Date(endDate.getFullYear(), endDate.getMonth(), 1);

                    let sDate = null;
                    if(startDate.getFullYear() ==  minSelectionDate.getFullYear()){
                        if(startDate.getMonth()+1 > minSelectionDate.getMonth()+1){
                            sDate = startDate;
                        }
                        else{
                            sDate = minSelectionDate
                        }
                    }
                    else{
                        sDate = startDate
                    }

                    dateObj.startDate = this.formatDate(sDate);
                    dateObj.endDate = this.formatDate(endDate);
                }
                else if(type=='previous_month'){
                    let now = new Date();
                    let firstday = new Date(now.getFullYear(), now.getMonth()-1, 1);
                    let lastday  = new Date(now.getFullYear(), now.getMonth(), 0);
                    dateObj.startDate = this.formatDate(firstday);
                    dateObj.endDate = this.formatDate(lastday);
                }
                else if(type=='quarterly'){
                    let now = null;

                    // value == month number
                    if(value<=3){
                        now = new Date((year+1)+"/"+value);
                    }
                    else{
                        now = new Date(year+"/"+value);
                    }

                    let quarter = Math.floor((now.getMonth() / 3));
                    let firstDate = new Date(now.getFullYear(), quarter * 3, 1);
                    let endDate = new Date(firstDate.getFullYear(), firstDate.getMonth() + 3, 0);

                    if(firstDate.getTime() < minSelectionDate.getTime()){
                        dateObj.startDate = this.formatDate(minSelectionDate); 
                    }
                    else{
                        dateObj.startDate = this.formatDate(firstDate);
                    }
                    
                    dateObj.endDate = this.formatDate(endDate);
                }
                else if(type=='quarter'){
                    let now = new Date();
                    let quarter = Math.floor((now.getMonth() / 3));
                    let firstDate = new Date(now.getFullYear(), quarter * 3, 1);
                    let endDate = new Date(firstDate.getFullYear(), firstDate.getMonth() + 3, 0);

                    if(firstDate.getTime() < minSelectionDate.getTime()){
                        dateObj.startDate = this.formatDate(minSelectionDate); 
                    }
                    else{
                        dateObj.startDate = this.formatDate(firstDate);
                    }
                    
                    dateObj.endDate = this.formatDate(endDate);
                }
                  // else if(type=='last_three_months'){
                  //   let now = new Date();
                  //   let firstday = new Date(now.getFullYear(), now.getMonth()-2, 1);
                  //   let lastday  = new Date(now.getFullYear(), now.getMonth()+1, 0);
                  //   dateObj.startDate = this.formatDate(firstday);
                  //   dateObj.endDate = this.formatDate(lastday);
                  // }
                // else if(type=='year' || type=='alltime'){
                //     let now = new Date()
                //     let currentmonth = now.getMonth()+1;
                //     let fullYear = now.getFullYear();

                //     let fy;
                //     currentmonth > 3 ? fy = fullYear : fy = fullYear-1;
                //     let firstday = new Date(fy, 3, 1);
                //     dateObj.startDate = this.formatDate(firstday);

                //     let fyEnd;
                //     fyEnd  = currentmonth > 3 ? fullYear+1 : fullYear;
                //     let lastday = new Date(fyEnd, 2, 31);
                //     dateObj.endDate = this.formatDate(lastday);
                // }
                else if(type=='year'){
                    // let firstday = new Date(this.$root.minDateSelection);
                    let endDate = new Date(this.$moment(new Date(thisFiscalYear+"/03/31")).endOf('day'));
                    // dateObj.startDate = this.formatDate(minSelectionDate);
                    dateObj.startDate = this.formatDate(this.$root.fn_getMinSelectionDate());
                    dateObj.endDate = this.formatDate(endDate);
                }
                else if(type=='alltime'){
                    // let firstday = new Date(this.$root.minDateSelection);
                    let maxDateYear = new Date(this.$root.maxDateSelection).getFullYear();
                    let year = maxDateYear > thisFiscalYear ? maxDateYear : thisFiscalYear;
                    let endDate = new Date(this.$moment(new Date(year+"/03/31")).endOf('day'));
                    dateObj.startDate = this.formatDate(minSelectionDate);
                    dateObj.endDate = this.formatDate(endDate);
                }
                else if(type=='currentDate'){
                    dateObj.startDate = this.$moment(new Date()).startOf('day');
                    dateObj.endDate = this.$moment(new Date()).endOf('day');
                }
                else if(type=='previousDate'){
                    let now = new Date();
                    let yesterday = now.setDate(now.getDate() - 1);
                    dateObj.startDate = this.$moment(yesterday).startOf('day');
                    dateObj.endDate = this.$moment(yesterday).endOf('day');
                }
                else if(type!='custom'){
                    dateObj.startDate = new Date();
                    dateObj.endDate = new Date();
                }

                let filterStartDate = new Date(dateObj.startDate);
                let openingDate = new Date(this.$root.CurrentBusiness.OpeningDate);

                if(filterStartDate.getTime() < openingDate.getTime()){
                    dateObj.startDate = this.formatDate(openingDate);

                    if(type=='month'){
                        // last date of month
                        dateObj.endDate = new Date(openingDate.getFullYear(), openingDate.getMonth()+1, 0);
                    }
                }

                if(new Date(dateObj.endDate) < new Date(this.$root.CurrentBusiness.OpeningDate)){
                    // alert('Selected period is not applicable!');
                    this.$eventHub.$emit('snackbarAlert', {msg:'Selected period is not applicable'});
                    dateObj.startDate = dateObj.endDate;
                }

                return dateObj;

              // let self = this;
              // setTimeout(function(){
              //    self.fn_getDashboardData();
              // },100)
            },

            formatDate: function(date_obj){
                return date_obj.getFullYear() + "-" + ("0" + (date_obj.getMonth()+1)).slice(-2) + "-" + ("0" + date_obj.getDate()).slice(-2);
            },

        //     fn_closePeriodDialog: function(){
        //         this.$refs['ref_dialogCustomPeriod'].dialog_period_selection = false;
        //         // this.periodDialog = false;
        //     },

            fn_getDashboardData: function(){
                this.loading = true;
                this.fn_getNetProfitAndLoss();
                this.fn_getSales();
                this.fn_getDueAndOrderTotal();
                this.fn_getAccountBalance();
            },

            fn_initDashboard: function(){
                let currentDate = new Date();
                let currentMonth = currentDate.getMonth() + 1;
                let thisFiscalYear = currentMonth<=3 ? currentDate.getFullYear() : currentDate.getFullYear() + 1;
                this.fn_changePeriod({"title":"All Time", "value":"alltime", thisFiscalYear})
            },

            // fn_downloadSample: function(){
            //     const XlsxPopulate = require('xlsx-populate');
            //     let self = this;
            //     // Load a new blank workbook
            //     XlsxPopulate.fromBlankAsync()
            //     .then(workbook => {
            //         let data = [["Name", "ServiceItem", "Description", "Charges", "HSNCode", "SellingPrice", "MRP", "WholeSalePrice", "ExpenseItem"]]
            //         // Modify the workbook.
            //         workbook.sheet("Sheet1").cell("A1").value(data);

            //         workbook.outputAsync().then(function (blob) {
            //             var fileName = 'samplefile'
            //             self.exportExcel = false;
            //             var url = window.URL.createObjectURL(blob);
            //             var a = document.createElement("a");
            //             document.body.appendChild(a);
            //             a.href = url;
            //             a.download = fileName + ".xlsx";
            //             a.click();
            //             window.URL.revokeObjectURL(url);
            //             document.body.removeChild(a);
            //         });
            //     });
            // },
        },
        mounted: function(){
            this.fn_initDashboard();
            this.$eventHub.$on('connected', () => {
                setTimeout(() => {
                    this.loading ? this.fn_initDashboard() : '';
                }, 1000);
            });
            // this.fn_getDashboardData();
        },
        computed:{
            isDateSelectionValid: function(){
                if(new Date(this.selectedStartDate) > new Date(this.selectedEndDate)){
                    return false;
                }
                else{
                    return true;
                }
            }
        }
    }
</script>

<style>
    /*#sales .apexcharts-xaxis-label:nth-child(even){
        transform: translateY(16px) !important;
    }*/

    .expense-bars{
      padding: 16px 0px;
    }
    .expense-bars .background-div{
      height:48px;background:#F0F0F0
    }
    .expense-bars .front-div{
      height:48px;transition: width 2s ease;
    }
    .expense-bars .front-div.purchase{
      float: left;
      background:#1976d2;
    }
    .expense-bars .front-div.expense{
      float: right;
      background:orange;
    }
</style>