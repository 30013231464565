// const transactionTypes = require('../../config.js').configs.transactionTypes

export const data = new Object({
    101:{
        reportTitle:"Item Ledger",
        itemLabel:"Item",
        openDialog:true,
        headerRow: {"mergeTransactionNumber":"Number","transactionDate":"Date","transactionListId":"Invoice Type","party":"Party","mrp":"MRP","inward":"Inward","outward":"Outward","balance":"Balance"},
        headerRowTableHeaders: [{"headerName":"Number", "type":"string"},{"headerName":"Date","type":"date"},{"headerName":"Voucher", "type":"string"},{"headerName":"Party", "type":"string"},{"headerName":"MRP","type":"number"},{"headerName":"Inward", "type":"number"},{"headerName":"Outward","type":"number"},{"headerName":"Balance", "type":"number"}],
        columns:['mergeTransactionNumber', 'transactionDate', 'transactionListId', 'party', 'mrp', 'inward', 'outward', 'balance'],
        // tileViewFooterHeaders:{"inward":"Inward","outward":"Outward","balance":"Balance"},
        tileViewFooterHeaders:{"opening":"Opening", "actualInwardValue":"Inward","actualOutwardValue":"Outward","balance":"Balance"},

        tileContent:`<div>

            <v-list three-line nopadding>

                <div v-for="(item, index) in propsObj.reportData.detailRows" :key="index">
                    <v-list-tile avatar class="right-subtitle-tile">

                        <v-list-tile-content @click="fn_getDetails(item)">
                            <v-list-tile-title class="mb-1">{{item.cols.party ? item.cols.party : ''}}</v-list-tile-title>

                            <v-list-tile-sub-title class="mb-1">{{item.cols.transactionDate | moment("DD-MMM-YYYY")}}</v-list-tile-sub-title>

                            <v-list-tile-sub-title v-if="item.cols.mergeTransactionNumber || item.cols.transactionListId">{{item.cols.mergeTransactionNumber ?  item.cols.mergeTransactionNumber + ' | ' : ''}}{{item.cols.transactionListId}}</v-list-tile-sub-title>
                        </v-list-tile-content>

                        <v-list-tile-action align-flex-end>

                            <v-btn icon text @click="fn_expandMore(item)">
                                <v-icon>more_horiz</v-icon>
                            </v-btn>

                            <div class="light-opacity-color">

                                <v-icon font18 v-if="index!=0 && (item.cols.inward || item.cols.outward)" style="padding-bottom:1px;">
                                    {{item.cols.outward ? 'arrow_upward' : 'arrow_downward'}}
                                </v-icon>

                                <span v-if="index==0">{{item.cols.inward ? item.cols.inward : 0}} Units</span>
                                <span v-if="index!=0 && item.cols.inward">{{item.cols.inward}} Units</span>
                                <span v-if="item.cols.outward">{{item.cols.outward}} Units</span>

                            </div>
                        </v-list-tile-action>
                    </v-list-tile>

                    <v-divider v-if="propsObj.reportData.detailRows.length-1 != index"></v-divider>
                </div>
            </v-list>

        </div>`,

        footerContent:`<div>
            <v-subheader subheader-semi-dense font16>Total
              <v-spacer></v-spacer>
              {{propsObj.reportData.footerRow.balance ? propsObj.reportData.footerRow.balance : 0}} Units
              <v-icon>{{!$parent.$parent.tileViewTotalSheet ? 'arrow_drop_up' : 'arrow_drop_down'}}</v-icon>
            </v-subheader>
        </div>`,

        detailReportId:null,
        period:'month',
        periodLabel:'Period',
        reportType:'general',
        filters: null,
        openView:true
    },

    102:{
        reportTitle:"Stock Summary",
        itemLabel:null,
        openDialog:false,
        headerRow: {"productName":"Product Name","categoryName":"Category","brand":"Brand","mrp":"MRP","dqty":"Qty", "unitName":"Unit", "averageRate":"Rate","value":"Value"},
        headerRowTableHeaders: [{"headerName":"Product Name", "type":"string"},{"headerName":"Category","type":"string"},{"headerName":"Brand", "type":"string"},{"headerName":"MRP","type":"number"},{"headerName":"Qty", "type":"number"},{"headerName":"Unit","type":"string"},{"headerName":"Rate", "type":"number"},{"headerName":"Value", "type":"number"}],
        columns:['productName', 'categoryName', 'brand', 'mrp', 'dqty', 'unitName', 'averageRate', 'value'],
        tileViewFooterHeaders:{"dqty":"Qty (In Units)","value":"Value"},

        tileContent:`<div>

            <v-list two-line nopadding>

                <div v-for="(item, index) in propsObj.reportData.detailRows" :key="index">
                    <v-list-tile class="right-subtitle-tile">

                        <v-list-tile-content @click="fn_getDetails(item)">
                            <v-list-tile-title class="mb-1">{{item.cols.productName}}</v-list-tile-title>

                            <v-list-tile-sub-title>
                                {{$root.numberToLocale(item.cols.dqty)}} {{item.cols.unitName}} Units X &#8377; {{$root.numberToLocale(item.cols.averageRate)}}
                            </v-list-tile-sub-title>
                        </v-list-tile-content>

                        <v-list-tile-action align-flex-end>

                            <v-btn icon text @click="fn_expandMore(item)">
                                <v-icon>more_horiz</v-icon>
                            </v-btn>

                            <div class="light-opacity-color">&#8377; {{$root.numberToLocale(item.cols.value)}}</div>
                        </v-list-tile-action>
                    </v-list-tile>

                    <v-divider v-if="propsObj.reportData.detailRows.length-1 != index"></v-divider>
                </div>
            </v-list>

        </div>`,

        footerContent:`<div>
            <v-layout row wrap pt-2 pb-2 pl-3 pr-3 font12>
                <v-flex xs4 sm4 md4>Total</v-flex>
                <v-flex xs8 sm8 md8 right-align>&#8377; {{$root.numberToLocale(propsObj.reportData.footerRow.value)}}</v-flex>
            </v-layout>

            <!--<v-subheader subheader-semi-dense font16>Total Value 
              <v-spacer></v-spacer>
              &#8377; {{$root.numberToLocale(propsObj.reportData.footerRow.value)}}
              <v-icon>{{!$parent.$parent.tileViewTotalSheet ? 'arrow_drop_up' : 'arrow_drop_down'}}</v-icon>
            </v-subheader>-->
        </div>`,

        detailReportId:null,
        period:'endDate',
        periodLabel:'As On',
        // period:'month',
        // periodLabel:'Period',
        reportType:'general',
        filters: null,
    },

    103:{
        reportTitle:"Item Progress",
        itemLabel:null,
        openDialog:false,
        headerRow: {"productName":"Product Name","categoryName":"Category","opening":"Opening","inward":"Inward","outward":"Outward","unitName":"Unit","closing":"Closing"},
        headerRowTableHeaders: [{"headerName":"Product Name", "type":"string"},{"headerName":"Category","type":"string"},{"headerName":"Opening", "type":"string"},{"headerName":"Inward","type":"number"},{"headerName":"Outward","type":"number"},{"headerName":"Unit","type":"string"},{"headerName":"Closing", "type":"number"}],
        columns:['productName', 'categoryName', 'opening', 'inward', 'outward', 'unitName', 'closing'],
        tileViewFooterHeaders:{"opening":"Opening", "inward":"Inward","outward":"Outward","closing":"Closing"},
        tileContent:`<div>

            <v-list three-line nopadding>

                <div v-for="(item, index) in propsObj.reportData.detailRows" :key="index">
                    <v-list-tile class="right-subtitle-tile">

                        <v-list-tile-content @click="fn_getDetails(item)">
                            <v-list-tile-title class="mb-1">{{item.cols.productName}}</v-list-tile-title>

                            <v-list-tile-sub-title>Opening: {{item.cols.opening ? item.cols.opening : '0'}} {{item.cols.unitName}}</v-list-tile-sub-title>
                            <v-list-tile-sub-title>
                                <template v-if="item.cols.inward">
                                    <v-icon class="greencolor font16">arrow_downward</v-icon> {{item.cols.inward}} {{item.cols.unitName}}
                                </template>
                                <template v-if="item.cols.outward">
                                    <v-icon class="redcolor font16">arrow_upward</v-icon> {{item.cols.outward}} {{item.cols.unitName}}
                                </template>
                            </v-list-tile-sub-title>
                        </v-list-tile-content>

                        <v-list-tile-action align-flex-end>

                            <v-btn icon text @click="fn_expandMore(item)">
                                <v-icon>more_horiz</v-icon>
                            </v-btn>

                            <div class="light-opacity-color">
                                {{item.cols.closing ? item.cols.closing : 0}} {{item.cols.unitName}}
                            </div>
                        </v-list-tile-action>
                    </v-list-tile>

                    <v-divider v-if="propsObj.reportData.detailRows.length-1 != index"></v-divider>
                </div>

            </v-list>

        </div>`,
        detailReportId:null,
        period:'month',
        periodLabel:'Period',
        reportType:'general',
        filters: null,
    },

    201:{
        reportTitle:"Ledger",
        itemLabel:"Party",
        openDialog:true,
        headerRow: {"mergeTransactionNumber":"Number","transactionDate":"Date","refNumber":"Bill No","refDate":"Bill Date","transactionListId":"Invoice Type","party":"Party","debit":"To Receive","credit":"To Pay","partyBalance":"Balance","creditDebitType":"","notes":"Notes"},
        headerRowTableHeaders: [{"headerName":"Number", "type":"string"},{"headerName":"Date","type":"date"},{"headerName":"Bill No", "type":"string"},{"headerName":"Bill Date", "type":"date"},{"headerName":"Voucher", "type":"string"},{"headerName":"Party","type":"string"},{"headerName":"To Receive","type":"number"},{"headerName":"To Pay","type":"number"},{"headerName":"Balance", "type":"number"},{"headerName":"", "type":"string"},{"headerName":"Notes", "type":"string"}],
        columns:['mergeTransactionNumber', 'transactionDate', 'refNumber', 'refDate', 'transactionListId', 'party', 'debit', 'credit', 'partyBalance', 'creditDebitType', 'notes'],
        // tileViewColumns:['transactionDate', 'party', 'debit', 'credit', 'balance'],
        tileViewFooterHeaders:{"openingValue":"Opening", "openingCreditDebitType":"-", "actualDebitValue":"To Receive", "actualCreditValue":"To Pay", "partyBalance":"Balance"},
        tileContent:`<div>

            <v-list three-line nopadding>

                <div v-for="(item, index) in propsObj.reportData.detailRows" :key="index">
                    <v-list-tile avatar class="right-subtitle-tile">

                        <v-list-tile-avatar>
                            <v-icon class="grey-back">{{item.cols.debit ? 'call_received' : 'call_made'}}</v-icon>
                        </v-list-tile-avatar>

                        <v-list-tile-content @click="fn_getDetails(item)">
                            <v-list-tile-title class="mb-1">{{item.cols.party}}</v-list-tile-title>

                            <v-list-tile-sub-title class="mb-1">{{item.cols.transactionDate | moment("DD-MMM-YYYY")}}</v-list-tile-sub-title>

                            <v-list-tile-sub-title v-if="item.cols.mergeTransactionNumber || item.cols.transactionListId">{{item.cols.mergeTransactionNumber}} | {{item.cols.transactionListId}}</v-list-tile-sub-title>
                        </v-list-tile-content>

                        <v-list-tile-action align-flex-end>

                            <v-btn icon text @click="fn_expandMore(item)">
                                <v-icon>more_horiz</v-icon>
                            </v-btn>

                            <div class="light-opacity-color">
                                &#8377; {{item.cols.debit ? $root.numberToLocale(item.cols.debit) : item.cols.credit ? $root.numberToLocale(item.cols.credit) : 0}}
                            </div>
                        </v-list-tile-action>
                    </v-list-tile>

                    <v-divider v-if="propsObj.reportData.detailRows.length-1 != index"></v-divider>
                </div>
            </v-list>
        </div>`,

        footerContent:`<div>
            <v-subheader subheader-semi-dense font16>Balance 
              <v-spacer></v-spacer>
              &#8377; {{$root.numberToLocale(propsObj.reportData.footerRow.partyBalance)}}
              <v-icon>{{!$parent.$parent.tileViewTotalSheet ? 'arrow_drop_up' : 'arrow_drop_down'}}</v-icon>
            </v-subheader>
        </div>`,

        detailReportId:2011,
        period:'month',
        periodLabel:'Period',
        reportType:'general',
        filters: null,
    },

    202:{
        reportTitle:"Day Book",
        itemLabel:null,
        openDialog:false,
        headerRow: {"transactionListId":"Invoice Type","transactionDate":"Date","mergeTransactionNumber":"Number","party":"Party","amount":"Amount","notes":"Notes"},
        headerRowTableHeaders: [{"headerName":"Voucher", "type":"string"},{"headerName":"Date","type":"date"},{"headerName":"Number", "type":"string"},{"headerName":"Party","type":"string"},{"headerName":"Amount", "type":"number"},{"headerName":"Notes","type":"string"}],
        columns:['transactionListId', 'transactionDate', 'mergeTransactionNumber', 'party', 'amount', 'notes'],
        tileViewFooterHeaders:{"amount":"Amount"},
        tileContent:`<div>

            <v-list three-line nopadding>

                <div v-for="(item, index) in propsObj.reportData.detailRows" :key="index">
                    <v-list-tile class="right-subtitle-tile">

                        <v-list-tile-content @click="fn_getDetails(item)">
                            <v-list-tile-title class="mb-1">{{item.cols.party}}</v-list-tile-title>

                            <v-list-tile-sub-title class="mb-1">{{item.cols.transactionDate | moment("DD-MMM-YYYY")}}</v-list-tile-sub-title>

                            <v-list-tile-sub-title>{{item.cols.mergeTransactionNumber ? item.cols.mergeTransactionNumber + ' | ' : ''}}{{item.cols.transactionListId}}</v-list-tile-sub-title>
                        </v-list-tile-content>

                        <v-list-tile-action align-flex-end>

                            <v-btn icon text @click="fn_expandMore(item)">
                                <v-icon>more_horiz</v-icon>
                            </v-btn>

                            <div class="light-opacity-color">
                                <span>&#8377; {{$root.numberToLocale(item.cols.amount)}}</span>
                            </div>
                        </v-list-tile-action>
                    </v-list-tile>

                    <v-divider v-if="propsObj.reportData.detailRows.length-1 != index"></v-divider>
                </div>
            </v-list>
        </div>`,

        footerContent:`<div>
            <v-subheader subheader-semi-dense font16>Total
              <v-spacer></v-spacer>
              &#8377; {{$root.numberToLocale(propsObj.reportData.footerRow.amount)}}
            </v-subheader>
        </div>`,

        detailReportId:null,
        period:'currentDate',
        periodLabel:'Period',
        reportType:'general',
        filters: null,
        openView:true
    },

    203:{
        reportTitle:"Collection Summary",
        itemLabel:null,
        openDialog:false,
        headerRow: {"transactionDate":"Date","mergeTransactionNumber":"Number","transactionListId":"Invoice Type","party":"Party","type":"Type","accountName":"Account Name","amount":"Amount"},
        headerRowTableHeaders: [{"headerName":"Date","type":"date"},{"headerName":"Number", "type":"string"},{"headerName":"Voucher", "type":"string"},{"headerName":"Party","type":"string"},{"headerName":"Type","type":"string"},{"headerName":"Account Name", "type":"string"},{"headerName":"Amount","type":"number"}],
        columns:['transactionDate', 'mergeTransactionNumber', 'transactionListId', 'party', 'type', 'accountName', 'amount'],
        tileViewFooterHeaders:{"amount":"Amount"},
        tileContent:`<div>

            <v-list three-line nopadding>

                <div v-for="(item, index) in propsObj.reportData.detailRows" :key="index">
                    <v-list-tile class="right-subtitle-tile">

                        <v-list-tile-content @click="fn_getDetails(item)">
                            <v-list-tile-title class="mb-1">{{item.cols.party}}</v-list-tile-title>

                            <v-list-tile-sub-title class="mb-1">{{item.cols.transactionDate | moment("DD-MMM-YYYY")}}</v-list-tile-sub-title>

                            <v-list-tile-sub-title>{{item.cols.mergeTransactionNumber}} | {{item.cols.transactionListId}}</v-list-tile-sub-title>
                        </v-list-tile-content>

                        <v-list-tile-action align-flex-end>

                            <v-btn icon text @click="fn_expandMore(item)">
                                <v-icon>more_horiz</v-icon>
                            </v-btn>

                            <div class="light-opacity-color">
                                &#8377; {{$root.numberToLocale(item.cols.amount)}}
                            </div>
                        </v-list-tile-action>
                    </v-list-tile>

                    <v-divider v-if="propsObj.reportData.detailRows.length-1 != index"></v-divider>
                </div>
            </v-list>

        </div>`,
        footerContent:`<div>
            <v-subheader subheader-semi-dense font16>Total 
              <v-spacer></v-spacer>
              &#8377; {{$root.numberToLocale(propsObj.reportData.footerRow.amount)}}
            </v-subheader>
        </div>`,
        detailReportId:null,
        period:'month',
        periodLabel:'Period',
        reportType:'general',
        filters: null,
        openView:true
    },

    301:{
        reportTitle:"Itemwise Sale",
        itemLabel:null,
        openDialog:false,
        headerRow: {"productName":"Product Name","qty":"Qty","unitName":"Unit","value":"Value","category":"Category","brand":"Brand"},
        headerRowTableHeaders: [{"headerName":"Product Name", "type":"string"},{"headerName":"Qty", "type":"number"},{"headerName":"Unit","type":"string"},{"headerName":"Value", "type":"number"},{"headerName":"Category","type":"string"},{"headerName":"Brand","type":"string"}],
        columns:['productName', 'qty', 'unitName', 'value', 'category', 'brand'],
        tileViewFooterHeaders:{"qty":"Qty", "value":"Value"},
        tileContent:`<div>

            <v-list two-line nopadding>

                <div v-for="(item, index) in propsObj.reportData.detailRows" :key="index">
                    <v-list-tile class="right-subtitle-tile">

                        <v-list-tile-content @click="fn_getDetails(item)">
                            <v-list-tile-title class="mb-1">{{item.cols.productName}}</v-list-tile-title>
                            <v-list-tile-sub-title>{{$root.numberToLocale(item.cols.qty)}} {{item.cols.unitName}}</v-list-tile-sub-title>
                        </v-list-tile-content>

                        <v-list-tile-action align-flex-end>

                            <v-btn icon text @click="fn_expandMore(item)">
                                <v-icon>more_horiz</v-icon>
                            </v-btn>

                            <div class="light-opacity-color">
                                <span>&#8377; {{$root.numberToLocale(item.cols.value)}}</span>
                            </div>
                        </v-list-tile-action>
                    </v-list-tile>

                    <v-divider v-if="propsObj.reportData.detailRows.length-1 != index"></v-divider>
                </div>
            </v-list>

        </div>`,

        footerContent:`<div>
            <v-layout row wrap pt-2 pb-2 pl-3 pr-3 font12>
                <v-flex xs4 sm4 md4>Total</v-flex>
                <v-flex xs8 sm8 md8 right-align>&#8377; {{$root.numberToLocale(propsObj.reportData.footerRow.value)}}</v-flex>
            </v-layout>

            <!--<v-subheader subheader-semi-dense font16>Total
              <v-spacer></v-spacer>
              &#8377; {{$root.numberToLocale(propsObj.reportData.footerRow.value)}}
              <v-icon>{{!$parent.$parent.tileViewTotalSheet ? 'arrow_drop_up' : 'arrow_drop_down'}}</v-icon>
            </v-subheader>-->
        </div>`,

        detailReportId:null,
        period:'month',
        periodLabel:'Period',
        reportType:'general',
        filters: null,
    },

    302:{
        reportTitle:"Partywise Sale",
        itemLabel:null,
        openDialog:false,
        headerRow: {"party":"Party","taxValue":"Value"},
        headerRowTableHeaders: [{"headerName":"Party", "type":"string"},{"headerName":"Value", "type":"number"}],
        columns:['party', 'taxValue'],
        tileViewFooterHeaders:{"taxValue":"Value"},
        tileContent:`<div>

            <v-list two-line nopadding>

                <div v-for="(item, index) in propsObj.reportData.detailRows" :key="index">
                    <v-subheader class="white-background subheader-semi-dense" v-if="item.cols.isGroup">{{item.cols.party}}</v-subheader>
                    <v-list-tile class="right-subtitle-tile" v-if="!item.cols.isGroup">

                        <v-list-tile-content @click="fn_getDetails(item)">
                            <v-list-tile-title class="mb-1">{{item.cols.party}}</v-list-tile-title>
                        </v-list-tile-content>

                        <v-list-tile-action align-flex-end>

                            <v-btn icon text @click="fn_expandMore(item)">
                                <v-icon>more_horiz</v-icon>
                            </v-btn>

                            <div class="light-opacity-color">
                                <span>&#8377; {{$root.numberToLocale(item.cols.taxValue)}}</span>
                            </div>
                        </v-list-tile-action>
                    </v-list-tile>

                    <v-divider v-if="propsObj.reportData.detailRows.length-1 != index"></v-divider>
                </div>
            </v-list>

        </div>`,

        footerContent:`<div>
            <v-subheader subheader-semi-dense font16>Total 
              <v-spacer></v-spacer>
              &#8377; {{$root.numberToLocale(propsObj.reportData.footerRow.taxValue)}}
            </v-subheader>
        </div>`,

        detailReportId:null,
        period:'month',
        periodLabel:'Period',
        reportType:'general',
        filters: null,
    },

    303:{
        reportTitle:"Sale Tax Summary",
        itemLabel:null,
        openDialog:false,
        headerRow: {"transactionDate":"Date","party":"Party","mergeTransactionNumber":"Number","transactionListId":"Invoice Type","gstNumber":"GSTIN", "grossAmount":"Gross Amount", "discount":"Discount", "taxableValue":"Taxable Value", "cgst":"CGST", "stgst":"SGST/UTGST", "igst":"IGST", "cess":"Cess Amount", "otherCharges":"Other Charges", "roundedOff":"R/O", "roundedAmount":"Net Amount"},
        headerRowTableHeaders: [{"headerName":"Date", "type":"date"},{"headerName":"Party", "type":"string"},{"headerName":"Number", "type":"string"},{"headerName":"Invoice Type", "type":"string"},{"headerName":"GSTIN", "type":"string"},{"headerName":"Gross Amount", "type":"number"},{"headerName":"Discount", "type":"number"},{"headerName":"Taxable Value", "type":"number"},{"headerName":"CGST", "type":"number"},{"headerName":"SGST/UTGST", "type":"number"},{"headerName":"IGST", "type":"number"},{"headerName":"Cess Amount", "type":"number"},{"headerName":"Other Charges", "type":"number"},{"headerName":"R/O", "type":"number"},{"headerName":"Net Amount", "type":"number"}],
        columns:['transactionDate', 'party', 'mergeTransactionNumber', 'transactionListId', 'gstNumber', 'grossAmount', 'discount', 'taxableValue', 'cgst', 'stgst', 'igst', 'cess', 'otherCharges', 'roundedOff', 'roundedAmount'],
        tileViewFooterHeaders:{"roundedAmount":"Net Amount"},
        tileContent:`<div>
            <v-list three-line nopadding>
                <div v-for="(item, index) in propsObj.reportData.detailRows" :key="index">
                    <v-list-tile class="right-subtitle-tile">

                        <v-list-tile-content @click="fn_getDetails(item)">
                            <v-list-tile-title class="mb-1">{{item.cols.party}}</v-list-tile-title>

                            <v-list-tile-sub-title class="mb-1">{{item.cols.transactionDate | moment("DD-MMM-YYYY")}}</v-list-tile-sub-title>

                            <v-list-tile-sub-title>{{item.cols.mergeTransactionNumber}} | {{item.cols.transactionListId}}</v-list-tile-sub-title>
                        </v-list-tile-content>

                        <v-list-tile-action align-flex-end>

                            <v-btn icon text @click="fn_expandMore(item)">
                                <v-icon>more_horiz</v-icon>
                            </v-btn>

                            <div class="light-opacity-color">
                                <span>&#8377; {{$root.numberToLocale(item.cols.tax)}}</span>
                            </div>
                        </v-list-tile-action>
                    </v-list-tile>

                    <v-divider v-if="propsObj.reportData.detailRows.length-1 != index"></v-divider>
                </div>
            </v-list>
        </div>`,

        footerContent:`<div>
            <v-subheader subheader-semi-dense font16>Total 
              <v-spacer></v-spacer>
              &#8377; {{$root.numberToLocale(propsObj.reportData.footerRow.totalTax)}}
            </v-subheader>
        </div>`,

        detailReportId:null,
        period:'month',
        periodLabel:'Period',
        reportType:'general',
        filters: null,
        openView:true
    },

    304:{
        reportTitle:"Delivery Challan",
        itemLabel:null,
        openDialog:false,
        headerRow: {"mergeTransactionNumber":"Number", "transactionDate":"Date","transactionListId":"Invoice Type","party":"Party","netAmount":"Amount","status":"Status"},
        headerRowTableHeaders: [{"headerName":"Number", "type":"string"},{"headerName":"Date", "type":"date"},{"headerName":"Voucher", "type":"string"},{"headerName":"Party", "type":"string"},{"headerName":"Amount", "type":"number"},{"headerName":"Status", "type":"string"}],
        columns:['mergeTransactionNumber', 'transactionDate', 'transactionListId', 'party', 'netAmount', 'status'],
        tileViewFooterHeaders:{"netAmount":"Amount"},
        tileContent:`<div>

            <v-list three-line nopadding>

                <div v-for="(item, index) in propsObj.reportData.detailRows" :key="index">
                    <v-list-tile class="right-subtitle-tile">

                        <v-list-tile-content @click="fn_getDetails(item)">
                            <v-list-tile-title class="mb-1">{{item.cols.party}}</v-list-tile-title>

                            <v-list-tile-sub-title class="mb-1">
                                {{item.cols.mergeTransactionNumber}} | {{item.cols.transactionDate | moment("DD-MMM-YYYY")}}
                            </v-list-tile-sub-title>

                            <v-list-tile-sub-title>
                                <span :style="{'background':item.cols.status == 'open' ? 'red' : 'green'}" style="padding: 1px 6px;border-radius: 2px;color: #fff;">{{item.cols.status == 'open' ? 'Open' : 'Close'}}</span>
                            </v-list-tile-sub-title>
                        </v-list-tile-content>

                        <v-list-tile-action align-flex-end>

                            <v-btn icon text @click="fn_expandMore(item)">
                                <v-icon>more_horiz</v-icon>
                            </v-btn>

                            <div class="light-opacity-color">
                                <span>&#8377; {{$root.numberToLocale(item.cols.netAmount)}}</span>
                            </div>
                        </v-list-tile-action>
                    </v-list-tile>

                    <v-divider v-if="propsObj.reportData.detailRows.length-1 != index"></v-divider>
                </div>
            </v-list>

        </div>`,

        footerContent:`<div>
            <v-subheader subheader-semi-dense font16>Total
              <v-spacer></v-spacer>
              &#8377; {{$root.numberToLocale(propsObj.reportData.footerRow.netAmount)}}
            </v-subheader>
        </div>`,

        detailReportId:null,
        period:'month',
        periodLabel:'Period',
        reportType:'general',
        filters:null,
        openView:true
    },

    305:{
        reportTitle:"Sale Order",
        itemLabel:null,
        openDialog:false,
        headerRow: {"mergeTransactionNumber":"Number", "transactionDate":"Date","transactionListId":"Invoice Type","party":"Party","netAmount":"Amount","status":"Status"},
        headerRowTableHeaders: [{"headerName":"Number", "type":"string"},{"headerName":"Date", "type":"date"},{"headerName":"Voucher", "type":"string"},{"headerName":"Party", "type":"string"},{"headerName":"Amount", "type":"number"},{"headerName":"Status", "type":"string"}],
        columns:['mergeTransactionNumber', 'transactionDate', 'transactionListId', 'party', 'netAmount', 'status'],
        tileViewFooterHeaders:{"netAmount":"Amount"},
        tileContent:`<div>
            <v-list three-line nopadding>

                <div v-for="(item, index) in propsObj.reportData.detailRows" :key="index">
                    <v-list-tile class="right-subtitle-tile">

                        <v-list-tile-content @click="fn_getDetails(item)">
                            <v-list-tile-title class="mb-1">{{item.cols.party}}</v-list-tile-title>

                            <v-list-tile-sub-title class="mb-1">
                                {{item.cols.mergeTransactionNumber}} | {{item.cols.transactionDate | moment("DD-MMM-YYYY")}}
                            </v-list-tile-sub-title>

                            <v-list-tile-sub-title>
                                <span :style="{'background':item.cols.status == 'open' ? 'red' : 'green'}" style="padding: 1px 6px;border-radius: 2px;color: #fff;">{{item.cols.status == 'open' ? 'Open' : 'Close'}}</span>
                            </v-list-tile-sub-title>
                        </v-list-tile-content>

                        <v-list-tile-action align-flex-end>

                            <v-btn icon text @click="fn_expandMore(item)">
                                <v-icon>more_horiz</v-icon>
                            </v-btn>

                            <div class="light-opacity-color">
                                <span>&#8377; {{$root.numberToLocale(item.cols.netAmount)}}</span>
                            </div>
                        </v-list-tile-action>
                    </v-list-tile>

                    <v-divider v-if="propsObj.reportData.detailRows.length-1 != index"></v-divider>
                </div>
            </v-list>
        </div>`,

        footerContent:`<div>
            <v-subheader subheader-semi-dense font16>Total
              <v-spacer></v-spacer>
              &#8377; {{$root.numberToLocale(propsObj.reportData.footerRow.netAmount)}}
            </v-subheader>
        </div>`,

        detailReportId:null,
        period:'month',
        periodLabel:'Period',
        reportType:'general',
        filters:null,
        openView:true
    },

    306:{
        reportTitle:"Itemwise Stock Transfer",
        itemLabel:null,
        openDialog:false,
        headerRow: {"productName":"Product Name","qty":"Qty (In Units)","value":"Value","category":"Category","brand":"Brand"},
        headerRowTableHeaders: [{"headerName":"Product Name", "type":"string"},{"headerName":"Qty (In Units)", "type":"number"},{"headerName":"Value", "type":"number"},{"headerName":"Category", "type":"string"},{"headerName":"Brand", "type":"string"}],
        columns:['productName', 'qty', 'value', 'category', 'brand'],
        tileViewFooterHeaders:{"productName":"Product Name", "qty":"Qty (In Units)", "value":"Value"},
        tileContent:`<div>

            <v-list two-line nopadding>

                <div v-for="(item, index) in propsObj.reportData.detailRows" :key="index">
                    <v-list-tile class="right-subtitle-tile">

                        <v-list-tile-content @click="fn_getDetails(item)">
                            <v-list-tile-title class="mb-1">{{item.cols.productName}}</v-list-tile-title>
                            <v-list-tile-sub-title>{{$root.numberToLocale(item.cols.qty)}} Units</v-list-tile-sub-title>
                        </v-list-tile-content>

                        <v-list-tile-action align-flex-end>

                            <v-btn icon text @click="fn_expandMore(item)">
                                <v-icon>more_horiz</v-icon>
                            </v-btn>

                            <div class="light-opacity-color">
                                <span>&#8377; {{$root.numberToLocale(item.cols.value)}}</span>
                            </div>
                        </v-list-tile-action>
                    </v-list-tile>

                    <v-divider v-if="propsObj.reportData.detailRows.length-1 != index"></v-divider>
                </div>
            </v-list>

        </div>`,

        footerContent:`<div>
            <v-subheader subheader-semi-dense font16>Total Value
              <v-spacer></v-spacer>
              &#8377; {{$root.numberToLocale(propsObj.reportData.footerRow.value)}}
              <v-icon>{{!$parent.$parent.tileViewTotalSheet ? 'arrow_drop_up' : 'arrow_drop_down'}}</v-icon>
            </v-subheader>
        </div>`,

        detailReportId:null,
        period:'month',
        periodLabel:'Period',
        reportType:'general',
        filters: null,
    },

    307:{
        reportTitle:"Partywise Stock Transfer",
        itemLabel:null,
        openDialog:false,
        headerRow: {"party":"Party","taxValue":"Value"},
        headerRowTableHeaders: [{"headerName":"Party", "type":"string"},{"headerName":"Value", "type":"number"}],
        columns:['party', 'taxValue'],
        tileViewFooterHeaders:{"taxValue":"Value"},
        tileContent:`<div>

            <v-list two-line nopadding>

                <div v-for="(item, index) in propsObj.reportData.detailRows" :key="index">
                    <v-list-tile class="right-subtitle-tile">

                        <v-list-tile-content @click="fn_getDetails(item)">
                            <v-list-tile-title class="mb-1">{{item.cols.party}}</v-list-tile-title>
                        </v-list-tile-content>

                        <v-list-tile-action align-flex-end>

                            <v-btn icon text @click="fn_expandMore(item)">
                                <v-icon>more_horiz</v-icon>
                            </v-btn>

                            <div class="light-opacity-color">
                                <span>&#8377; {{$root.numberToLocale(item.cols.taxValue)}}</span>
                            </div>
                        </v-list-tile-action>
                    </v-list-tile>

                    <v-divider v-if="propsObj.reportData.detailRows.length-1 != index"></v-divider>
                </div>
            </v-list>

        </div>`,

        footerContent:`<div>
            <v-subheader subheader-semi-dense font16>Total Value
              <v-spacer></v-spacer>
              &#8377; {{$root.numberToLocale(propsObj.reportData.footerRow.taxValue)}}
              <v-icon>{{!$parent.$parent.tileViewTotalSheet ? 'arrow_drop_up' : 'arrow_drop_down'}}</v-icon>
            </v-subheader>
        </div>`,

        detailReportId:null,
        period:'month',
        periodLabel:'Period',
        reportType:'general',
        filters: null,
    },

    308:{
        reportTitle:"Sale Register",
        itemLabel:null,
        openDialog:false,
        headerRow: {"transactionDate":"Date","party":"Party","invoiceType":"Invoice Type","mergeTransactionNumber":"Number","netAmount":"Amount"},
        headerRowTableHeaders: [{"headerName":"Date", "type":"date"},{"headerName":"Party", "type":"string"},{"headerName":"Invoice Type", "type":"string"},{"headerName":"Number", "type":"string"},{"headerName":"Amount", "type":"number"}],
        columns:['transactionDate', 'party', 'invoiceType', 'mergeTransactionNumber', 'netAmount'],
        tileViewFooterHeaders:{"netAmount":"Amount"},
        tileContent:`<div>

            <v-list three-line nopadding>

                <div v-for="(item, index) in propsObj.reportData.detailRows" :key="index">
                    <v-list-tile class="right-subtitle-tile">

                        <v-list-tile-content @click="fn_getDetails(item)">
                            <v-list-tile-title class="mb-1">{{item.cols.party}}</v-list-tile-title>
                            <v-list-tile-sub-title>{{item.cols.transactionDate | moment("DD-MMM-YYYY")}}</v-list-tile-sub-title>
                            <v-list-tile-sub-title>{{item.cols.mergeTransactionNumber}}</v-list-tile-sub-title>
                        </v-list-tile-content>

                        <v-list-tile-action align-flex-end>

                            <v-btn icon text @click="fn_expandMore(item)">
                                <v-icon>more_horiz</v-icon>
                            </v-btn>

                            <div class="light-opacity-color">
                                <span>&#8377; {{$root.numberToLocale(item.cols.netAmount)}}</span>
                            </div>
                        </v-list-tile-action>
                    </v-list-tile>

                    <v-divider v-if="propsObj.reportData.detailRows.length-1 != index"></v-divider>
                </div>
            </v-list>

        </div>`,

        footerContent:`<div>
            <v-subheader subheader-semi-dense font16>Total
              <v-spacer></v-spacer>
              &#8377; {{$root.numberToLocale(propsObj.reportData.footerRow.netAmount)}}
            </v-subheader>
        </div>`,
        detailReportId:null,
        period:'month',
        periodLabel:'Period',
        reportType:'general',
        filters: null,
        openView:true
    },

    401:{
        reportTitle:"Itemwise Purchase",
        itemLabel:null,
        openDialog:false,
        headerRow: {"productName":"Product Name","qty":"Qty","unitName":"Unit","value":"Value","category":"Category","brand":"Brand"},
        headerRowTableHeaders: [{"headerName":"Product Name", "type":"string"},{"headerName":"Qty", "type":"number"},{"headerName":"Unit","type":"string"},{"headerName":"Value", "type":"number"},{"headerName":"Category","type":"string"},{"headerName":"Brand","type":"string"}],
        columns:['productName', 'qty', 'unitName', 'value', 'category', 'brand'],
        tileViewFooterHeaders:{"qty":"Qty", "value":"Value"},
        tileContent:`<div>

            <v-list two-line nopadding>

                <div v-for="(item, index) in propsObj.reportData.detailRows" :key="index">
                    <v-list-tile class="right-subtitle-tile">

                        <v-list-tile-content @click="fn_getDetails(item)">
                            <v-list-tile-title class="mb-1">{{item.cols.productName}}</v-list-tile-title>
                            <v-list-tile-sub-title>{{$root.numberToLocale(item.cols.qty)}} {{item.cols.unitName}}</v-list-tile-sub-title>
                        </v-list-tile-content>

                        <v-list-tile-action align-flex-end>

                            <v-btn icon text @click="fn_expandMore(item)">
                                <v-icon>more_horiz</v-icon>
                            </v-btn>

                            <div class="light-opacity-color">
                                <span>&#8377; {{$root.numberToLocale(item.cols.value)}}</span>
                            </div>
                        </v-list-tile-action>
                    </v-list-tile>

                    <v-divider v-if="propsObj.reportData.detailRows.length-1 != index"></v-divider>
                </div>
            </v-list>

        </div>`,

        footerContent:`<div>
            <v-layout row wrap pt-2 pb-2 pl-3 pr-3 font12>
                <v-flex xs4 sm4 md4>Total</v-flex>
                <v-flex xs8 sm8 md8 right-align>&#8377; {{$root.numberToLocale(propsObj.reportData.footerRow.value)}}</v-flex>
            </v-layout>
            
            <!--<v-subheader subheader-semi-dense font16>Total
              <v-spacer></v-spacer>
              &#8377; {{$root.numberToLocale(propsObj.reportData.footerRow.value)}}
              <v-icon>{{!$parent.$parent.tileViewTotalSheet ? 'arrow_drop_up' : 'arrow_drop_down'}}</v-icon>
            </v-subheader>-->
        </div>`,

        detailReportId:null,
        period:'month',
        periodLabel:'Period',
        reportType:'general',
        filters: null,
    },

    402:{
        reportTitle:"Partywise Purchase",
        itemLabel:null,
        openDialog:false,
        headerRow: {"party":"Party","taxValue":"Value"},
        headerRowTableHeaders: [{"headerName":"Party", "type":"string"},{"headerName":"Value", "type":"number"}],
        columns:['party', 'taxValue'],
        tileViewFooterHeaders:{"taxValue":"Value"},
        tileContent:`<div>
            <v-list two-line nopadding>

                <div v-for="(item, index) in propsObj.reportData.detailRows" :key="index">
                    <v-subheader class="white-background subheader-semi-dense" v-if="item.cols.isGroup">{{item.cols.party}}</v-subheader>
                    <v-list-tile class="right-subtitle-tile" v-if="!item.cols.isGroup">

                        <v-list-tile-content @click="fn_getDetails(item)">
                            <v-list-tile-title class="mb-1">{{item.cols.party}}</v-list-tile-title>
                        </v-list-tile-content>

                        <v-list-tile-action align-flex-end>

                            <v-btn icon text @click="fn_expandMore(item)">
                                <v-icon>more_horiz</v-icon>
                            </v-btn>

                            <div class="light-opacity-color">
                                <span>&#8377; {{$root.numberToLocale(item.cols.taxValue)}}</span>
                            </div>
                        </v-list-tile-action>
                    </v-list-tile>

                    <v-divider v-if="propsObj.reportData.detailRows.length-1 != index"></v-divider>
                </div>
            </v-list>
        </div>`,

        footerContent:`<div>
            <v-subheader subheader-semi-dense font16>Total 
              <v-spacer></v-spacer>
              &#8377; {{$root.numberToLocale(propsObj.reportData.footerRow.taxValue)}}
            </v-subheader>
        </div>`,

        detailReportId:null,
        period:'month',
        periodLabel:'Period',
        reportType:'general',
        filters: null,
    },

    403:{
        reportTitle:"Purchase Tax Summary",
        itemLabel:null,
        openDialog:false,
        headerRow: {"transactionDate":"Date","party":"Party","mergeTransactionNumber":"Number","refNumber":"Bill No","refDate":"Bill Date","transactionListId":"Invoice Type","gstNumber":"GSTIN", "grossAmount":"Gross Amount", "discount":"Discount", "taxableValue":"Taxable Value", "cgst":"CGST", "stgst":"SGST/UTGST", "igst":"IGST", "cess":"Cess Amount", "otherCharges":"Other Charges", "roundedOff":"R/O", "roundedAmount":"Net Amount"},
        headerRowTableHeaders: [{"headerName":"Date", "type":"date"},{"headerName":"Party", "type":"string"},{"headerName":"Number", "type":"string"},{"headerName":"Bill No", "type":"string"},{"headerName":"Bill Date", "type":"date"},{"headerName":"Invoice Type", "type":"string"},{"headerName":"GSTIN", "type":"string"},{"headerName":"Gross Amount", "type":"number"},{"headerName":"Discount", "type":"number"},{"headerName":"Taxable Value", "type":"number"},{"headerName":"Other Charges", "type":"number"},{"headerName":"R/O", "type":"number"},{"headerName":"Net Amount", "type":"number"}],
        columns:['transactionDate', 'party', 'mergeTransactionNumber', 'refNumber', 'refDate', 'transactionListId', 'gstNumber', 'grossAmount', 'discount', 'taxableValue', 'cgst', 'stgst', 'igst', 'cess', 'otherCharges', 'roundedOff', 'roundedAmount'],
        tileViewFooterHeaders:{"roundedAmount":"Net Amount"},
        tileContent:`<div>
            <v-list three-line nopadding>

                <div v-for="(item, index) in propsObj.reportData.detailRows" :key="index">
                    <v-list-tile class="right-subtitle-tile">

                        <v-list-tile-content @click="fn_getDetails(item)">
                            <v-list-tile-title class="mb-1">{{item.cols.party}}</v-list-tile-title>

                            <v-list-tile-sub-title class="mb-1">{{item.cols.transactionDate | moment("DD-MMM-YYYY")}}</v-list-tile-sub-title>

                            <v-list-tile-sub-title>{{item.cols.mergeTransactionNumber}} | {{item.cols.transactionListId}}</v-list-tile-sub-title>
                        </v-list-tile-content>

                        <v-list-tile-action align-flex-end>

                            <v-btn icon text @click="fn_expandMore(item)">
                                <v-icon>more_horiz</v-icon>
                            </v-btn>

                            <div class="light-opacity-color">
                                <span>&#8377; {{$root.numberToLocale(item.cols.tax)}}</span>
                            </div>
                        </v-list-tile-action>
                    </v-list-tile>

                    <v-divider v-if="propsObj.reportData.detailRows.length-1 != index"></v-divider>
                </div>
            </v-list>
        </div>`,

        footerContent:`<div>
            <v-subheader subheader-semi-dense font16>Total 
              <v-spacer></v-spacer>
              &#8377; {{$root.numberToLocale(propsObj.reportData.footerRow.totalTax)}}
            </v-subheader>
        </div>`,

        detailReportId:null,
        period:'month',
        periodLabel:'Period',
        reportType:'general',
        filters: null,
        openView:true
    },

    404:{
        reportTitle:"Purchase Challan",
        itemLabel:null,
        openDialog:false,
        headerRow: {"mergeTransactionNumber":"Number", "transactionDate":"Date","transactionListId":"Invoice Type","party":"Party","netAmount":"Amount","status":"Status"},
        headerRowTableHeaders: [{"headerName":"Number", "type":"string"},{"headerName":"Date", "type":"date"},{"headerName":"Voucher", "type":"string"},{"headerName":"Party", "type":"string"},{"headerName":"Amount", "type":"number"},{"headerName":"Status", "type":"string"}],
        columns:['mergeTransactionNumber', 'transactionDate', 'transactionListId', 'party', 'netAmount', 'status'],
        tileViewFooterHeaders:{"netAmount":"Amount"},
        tileContent:`<div>
             <v-list three-line nopadding>

                <div v-for="(item, index) in propsObj.reportData.detailRows" :key="index">
                    <v-list-tile class="right-subtitle-tile">

                        <v-list-tile-content @click="fn_getDetails(item)">
                            <v-list-tile-title class="mb-1">{{item.cols.party}}</v-list-tile-title>

                            <v-list-tile-sub-title class="mb-1">
                                {{item.cols.mergeTransactionNumber}} | {{item.cols.transactionDate | moment("DD-MMM-YYYY")}}
                            </v-list-tile-sub-title>

                            <v-list-tile-sub-title>
                                <span :style="{'background':item.cols.status == 'open' ? 'red' : 'green'}" style="padding: 1px 6px;border-radius: 2px;color: #fff;">{{item.cols.status == 'open' ? 'Open' : 'Close'}}</span>
                            </v-list-tile-sub-title>
                        </v-list-tile-content>

                        <v-list-tile-action align-flex-end>

                            <v-btn icon text @click="fn_expandMore(item)">
                                <v-icon>more_horiz</v-icon>
                            </v-btn>

                            <div class="light-opacity-color">
                                <span>&#8377; {{$root.numberToLocale(item.cols.netAmount)}}</span>
                            </div>
                        </v-list-tile-action>
                    </v-list-tile>

                    <v-divider v-if="propsObj.reportData.detailRows.length-1 != index"></v-divider>
                </div>
            </v-list>
        </div>`,

        footerContent:`<div>
            <v-subheader subheader-semi-dense font16>Total
              <v-spacer></v-spacer>
              &#8377; {{$root.numberToLocale(propsObj.reportData.footerRow.netAmount)}}
            </v-subheader>
        </div>`,

        detailReportId:null,
        period:'month',
        periodLabel:'Period',
        reportType:'general',
        filters:null,
        openView:true
    },

    405:{
        reportTitle:"Purchase Order",
        itemLabel:null,
        openDialog:false,
        headerRow: {"mergeTransactionNumber":"Number", "transactionDate":"Date","transactionListId":"Invoice Type","party":"Party","netAmount":"Amount","status":"Status"},
        headerRowTableHeaders: [{"headerName":"Number", "type":"string"},{"headerName":"Date", "type":"date"},{"headerName":"Voucher", "type":"string"},{"headerName":"Party", "type":"string"},{"headerName":"Amount", "type":"number"},{"headerName":"Status", "type":"string"}],
        columns:['mergeTransactionNumber', 'transactionDate', 'transactionListId', 'party', 'netAmount', 'status'],
        tileViewFooterHeaders:{"netAmount":"Amount"},
        tileContent:`<div>
              <v-list three-line nopadding>

                <div v-for="(item, index) in propsObj.reportData.detailRows" :key="index">
                    <v-list-tile class="right-subtitle-tile">

                        <v-list-tile-content @click="fn_getDetails(item)">
                            <v-list-tile-title class="mb-1">{{item.cols.party}}</v-list-tile-title>

                            <v-list-tile-sub-title class="mb-1">
                                {{item.cols.mergeTransactionNumber}} | {{item.cols.transactionDate | moment("DD-MMM-YYYY")}}
                            </v-list-tile-sub-title>

                            <v-list-tile-sub-title>
                                <span :style="{'background':item.cols.status == 'open' ? 'red' : 'green'}" style="padding: 1px 6px;border-radius: 2px;color: #fff;">{{item.cols.status == 'open' ? 'Open' : 'Close'}}</span>
                            </v-list-tile-sub-title>
                        </v-list-tile-content>

                        <v-list-tile-action align-flex-end>

                            <v-btn icon text @click="fn_expandMore(item)">
                                <v-icon>more_horiz</v-icon>
                            </v-btn>

                            <div class="light-opacity-color">
                                <span>&#8377; {{$root.numberToLocale(item.cols.netAmount)}}</span>
                            </div>
                        </v-list-tile-action>
                    </v-list-tile>

                    <v-divider v-if="propsObj.reportData.detailRows.length-1 != index"></v-divider>
                </div>
            </v-list>
        </div>`,

        footerContent:`<div>
            <v-subheader subheader-semi-dense font16>Total
              <v-spacer></v-spacer>
              &#8377; {{$root.numberToLocale(propsObj.reportData.footerRow.netAmount)}}
            </v-subheader>
        </div>`,

        detailReportId:null,
        period:'month',
        periodLabel:'Period',
        reportType:'general',
        filters:null,
        openView:true
    },

    406:{
        reportTitle:"Expense Ledger",
        itemLabel:null,
        openDialog:false,
        headerRow: {"parentGroupName":"Main Group","accountName":"Expense Ledger","outAmount":"Amount"},
        headerRowTableHeaders: [{"headerName":"Main Group", "type":"string"},{"headerName":"Expense Ledger", "type":"string"},{"headerName":"Amount", "type":"number"}],
        columns:['parentGroupName', 'accountName', 'outAmount'],
        tileViewFooterHeaders:{"outAmount":"Amount"},
        tileContent:`<div>

            <v-list two-line nopadding>

                <div v-for="(item, index) in propsObj.reportData.detailRows" :key="index">
                    <v-list-tile class="right-subtitle-tile">

                        <v-list-tile-content @click="fn_getDetails(item)">
                            <v-list-tile-title class="mb-1">{{item.cols.accountName}}</v-list-tile-title>
                            <v-list-tile-sub-title>{{item.cols.parentGroupName}}</v-list-tile-sub-title>
                        </v-list-tile-content>

                        <v-list-tile-action align-flex-end>

                            <v-btn icon text @click="fn_expandMore(item)">
                                <v-icon>more_horiz</v-icon>
                            </v-btn>

                            <div class="light-opacity-color">
                                <span>&#8377; {{$root.numberToLocale(item.cols.outAmount)}}</span>
                            </div>
                        </v-list-tile-action>
                    </v-list-tile>

                    <v-divider v-if="propsObj.reportData.detailRows.length-1 != index"></v-divider>
                </div>
            </v-list>
        </div>`,

        footerContent:`<div>
            <v-subheader subheader-semi-dense font16>Total
              <v-spacer></v-spacer>
              &#8377; {{$root.numberToLocale(propsObj.reportData.footerRow.outAmount)}}
            </v-subheader>
        </div>`,

        detailReportId:null,
        period:'month',
        periodLabel:'Period',
        reportType:'general',
        filters:null,
        hideFilterBtn:true
    },

    407:{
        reportTitle:"Itemwise Stock Receive",
        itemLabel:null,
        openDialog:false,
        headerRow: {"productName":"Product Name","qty":"Qty (In Units)","value":"Value","category":"Category","brand":"Brand"},
        headerRowTableHeaders: [{"headerName":"Product Name", "type":"string"},{"headerName":"Qty (In Units)", "type":"number"},{"headerName":"Value", "type":"number"},{"headerName":"Category", "type":"string"},{"headerName":"Brand", "type":"string"}],
        columns:['productName', 'qty', 'value', 'category', 'brand'],
        tileViewFooterHeaders:{"qty":"Qty (In Units)", "value":"Value"},
        tileContent:`<div>
            <v-list two-line nopadding>

                <div v-for="(item, index) in propsObj.reportData.detailRows" :key="index">
                    <v-list-tile class="right-subtitle-tile">

                        <v-list-tile-content @click="fn_getDetails(item)">
                            <v-list-tile-title class="mb-1">{{item.cols.productName}}</v-list-tile-title>
                            <v-list-tile-sub-title>{{$root.numberToLocale(item.cols.qty)}} Units</v-list-tile-sub-title>
                        </v-list-tile-content>

                        <v-list-tile-action align-flex-end>

                            <v-btn icon text @click="fn_expandMore(item)">
                                <v-icon>more_horiz</v-icon>
                            </v-btn>

                            <div class="light-opacity-color">
                                <span>&#8377; {{$root.numberToLocale(item.cols.value)}}</span>
                            </div>
                        </v-list-tile-action>
                    </v-list-tile>

                    <v-divider v-if="propsObj.reportData.detailRows.length-1 != index"></v-divider>
                </div>
            </v-list>
        </div>`,

        footerContent:`<div>
            <v-subheader subheader-semi-dense font16>Total Value
              <v-spacer></v-spacer>
              &#8377; {{$root.numberToLocale(propsObj.reportData.footerRow.value)}}
              <v-icon>{{!$parent.$parent.tileViewTotalSheet ? 'arrow_drop_up' : 'arrow_drop_down'}}</v-icon>
            </v-subheader>
        </div>`,

        detailReportId:null,
        period:'month',
        periodLabel:'Period',
        reportType:'general',
        filters: null,
    },

    408:{
        reportTitle:"Partywise Stock Receive",
        itemLabel:null,
        openDialog:false,
        headerRow: {"party":"Party","taxValue":"Value"},
        headerRowTableHeaders: [{"headerName":"Party", "type":"string"},{"headerName":"Value", "type":"number"}],
        columns:['party', 'taxValue'],
        tileViewFooterHeaders:{"taxValue":"Value"},
        tileContent:`<div>
            <v-list two-line nopadding>

                <div v-for="(item, index) in propsObj.reportData.detailRows" :key="index">
                    <v-list-tile class="right-subtitle-tile">

                        <v-list-tile-content @click="fn_getDetails(item)">
                            <v-list-tile-title class="mb-1">{{item.cols.party}}</v-list-tile-title>
                        </v-list-tile-content>

                        <v-list-tile-action align-flex-end>

                            <v-btn icon text @click="fn_expandMore(item)">
                                <v-icon>more_horiz</v-icon>
                            </v-btn>

                            <div class="light-opacity-color">
                                <span>&#8377; {{$root.numberToLocale(item.cols.taxValue)}}</span>
                            </div>
                        </v-list-tile-action>
                    </v-list-tile>

                    <v-divider v-if="propsObj.reportData.detailRows.length-1 != index"></v-divider>
                </div>
            </v-list>
        </div>`,

        footerContent:`<div>
            <v-subheader subheader-semi-dense font16>Total Value
              <v-spacer></v-spacer>
              &#8377; {{$root.numberToLocale(propsObj.reportData.footerRow.taxValue)}}
              <v-icon>{{!$parent.$parent.tileViewTotalSheet ? 'arrow_drop_up' : 'arrow_drop_down'}}</v-icon>
            </v-subheader>
        </div>`,

        detailReportId:null,
        period:'month',
        periodLabel:'Period',
        reportType:'general',
        filters: null,
    },

    409:{
        reportTitle:"Purchase Register",
        itemLabel:null,
        openDialog:false,
        headerRow: {"transactionDate":"Date","party":"Party","invoiceType":"Invoice Type","mergeTransactionNumber":"Number", "refNumber":"Bill No", "refDate":"Bill Date", "netAmount":"Amount"},
        headerRowTableHeaders: [{"headerName":"Date", "type":"date"},{"headerName":"Party", "type":"string"},{"headerName":"Invoice Type", "type":"string"},{"headerName":"Number", "type":"string"},{"headerName":"Bill No", "type":"string"},{"headerName":"Bill Date", "type":"date"},{"headerName":"Amount", "type":"number"}],
        columns:['transactionDate', 'party', 'invoiceType', 'mergeTransactionNumber', 'refNumber', 'refDate', 'netAmount'],
        tileViewFooterHeaders:{"netAmount":"Amount"},
        tileContent:`<div>

            <v-list three-line nopadding>

                <div v-for="(item, index) in propsObj.reportData.detailRows" :key="index">
                    <v-list-tile class="right-subtitle-tile">

                        <v-list-tile-content @click="fn_getDetails(item)">
                            <v-list-tile-title class="mb-1">{{item.cols.party}}</v-list-tile-title>
                            <v-list-tile-sub-title>{{item.cols.transactionDate | moment("DD-MMM-YYYY")}}</v-list-tile-sub-title>
                            <v-list-tile-sub-title>{{item.cols.mergeTransactionNumber}}</v-list-tile-sub-title>
                        </v-list-tile-content>

                        <v-list-tile-action align-flex-end>

                            <v-btn icon text @click="fn_expandMore(item)">
                                <v-icon>more_horiz</v-icon>
                            </v-btn>

                            <div class="light-opacity-color">
                                <span>&#8377; {{$root.numberToLocale(item.cols.netAmount)}}</span>
                            </div>
                        </v-list-tile-action>
                    </v-list-tile>

                    <v-divider v-if="propsObj.reportData.detailRows.length-1 != index"></v-divider>
                </div>
            </v-list>

        </div>`,

        footerContent:`<div>
            <v-subheader subheader-semi-dense font16>Total
              <v-spacer></v-spacer>
              &#8377; {{$root.numberToLocale(propsObj.reportData.footerRow.netAmount)}}
            </v-subheader>
        </div>`,
        detailReportId:null,
        period:'month',
        periodLabel:'Period',
        reportType:'general',
        filters: null,
        openView:true
    },

    410:{
        reportTitle:"Expense Item",
        itemLabel:null,
        openDialog:false,
        headerRow: {"itemName":"Item Name","category":"Category","qty":"Qty","unit":"Unit","amount":"Amount"},
        headerRowTableHeaders: [{"headerName":"Item Name", "type":"string"},{"headerName":"Category", "type":"string"},{"headerName":"Qty", "type":"number"},{"headerName":"Unit", "type":"number"},{"headerName":"Amount", "type":"number"}],
        columns:['itemName', 'category', 'qty', 'unit', 'amount'],
        tileViewFooterHeaders:{"qty":"Qty","amount":"Total"},
        tileContent:`<div>

            <v-list two-line nopadding>

                <div v-for="(item, index) in propsObj.reportData.detailRows" :key="index">
                    <v-list-tile class="right-subtitle-tile">

                        <v-list-tile-content @click="fn_getDetails(item)">
                            <v-list-tile-title class="mb-1">{{item.cols.itemName}}</v-list-tile-title>
                            <v-list-tile-sub-title>{{item.cols.qty}} {{item.cols.unit}}</v-list-tile-sub-title>
                        </v-list-tile-content>

                        <v-list-tile-action align-flex-end>

                            <v-btn icon text @click="fn_expandMore(item)">
                                <v-icon>more_horiz</v-icon>
                            </v-btn>

                            <div class="light-opacity-color">
                                <span>&#8377; {{$root.numberToLocale(item.cols.amount)}}</span>
                            </div>
                        </v-list-tile-action>
                    </v-list-tile>

                    <v-divider v-if="propsObj.reportData.detailRows.length-1 != index"></v-divider>
                </div>
            </v-list>
        </div>`,

        footerContent:`<div>
            <v-layout row wrap pt-2 pb-2 pl-3 pr-3 font12>
                <v-flex xs4 sm4 md4>Total</v-flex>
                <v-flex xs8 sm8 md8 right-align>&#8377; {{$root.numberToLocale(propsObj.reportData.footerRow.amount)}}</v-flex>

                <v-flex xs4 sm4 md4 pt-2>Qty (In Units)</v-flex>
                <v-flex xs8 sm8 md8 pt-2 right-align>{{$root.numberToLocale(propsObj.reportData.footerRow.qty)}}</v-flex>
            </v-layout>

            <!--<v-subheader subheader-semi-dense font16>Total
              <v-spacer></v-spacer>
              &#8377; {{$root.numberToLocale(propsObj.reportData.footerRow.amount)}}
            </v-subheader>-->
        </div>`,
        detailReportId:null,
        period:'month',
        periodLabel:'Period',
        reportType:'general',
        filters:null,
        openView:true,
        hideFilterBtn:true
    },

    411:{
        reportTitle:"Transport",
        itemLabel:null,
        openDialog:false,
        headerRow: {"transporter":"Transporter","gstNumber":"Transporter ID","mode":"Mode","lrcrrrNumber":"LR/CR/RR No","vehicleNumber":"Vehicle No","transactionType":"Transaction Type","transactionListId":"Type","transactionDate":"Document Date","mergeTransactionNumber":"Document No.","wayBillNumber":"E-Way Bill No","wayBillDate":"E-Way Bill Date","documentType":"Document Type","subType":"Sub Type","distance":"Distance (in KM)","roundedAmount":"Document Value"},
        headerRowTableHeaders: [{"headerName":"Transporter", "type":"string"},{"headerName":"Transporter ID", "type":"string"},{"headerName":"Mode", "type":"string"},{"headerName":"LR/CR/RR No", "type":"string"},{"headerName":"Vehicle No", "type":"string"},{"headerName":"Transaction Type", "type":"string"},{"headerName":"Type", "type":"string"},{"headerName":"Document Date", "type":"date"},{"headerName":"Document No.", "type":"string"},{"headerName":"E-Way Bill No", "type":"string"},{"headerName":"E-Way Bill Date", "type":"date"},{"headerName":"Document Type", "type":"string"},{"headerName":"Sub Type", "type":"string"},{"headerName":"Distance (in KM)", "type":"string"},{"headerName":"Document Value", "type":"number"}],
        columns:['transporter', 'gstNumber', 'mode', 'lrcrrrNumber', 'vehicleNumber', 'transactionType', 'transactionListId', 'transactionDate', 'mergeTransactionNumber', 'wayBillNumber', 'wayBillDate','documentType', 'subType','distance', 'roundedAmount'],
        tileViewFooterHeaders:null,
        tileContent:null,
        footerContent:null,
        detailReportId:null,
        period:'year',
        periodLabel:'Period',
        reportType:'general',
        filters:null,
        openView:true,
        hideFilterBtn:true
    },

    412:{
        reportTitle:"Expense Category",
        itemLabel:null,
        openDialog:false,
        headerRow: {"parentGroupName":"Main Group","expenseCategoryName":"Expense Category","outAmount":"Amount"},
        headerRowTableHeaders: [{"headerName":"Main Group", "type":"string"},{"headerName":"Expense Category", "type":"string"},{"headerName":"Amount", "type":"number"}],
        columns:['parentGroupName', 'expenseCategoryName', 'outAmount'],
        tileViewFooterHeaders:{"outAmount":"Amount"},
        tileContent:`<div>

            <v-list two-line nopadding>

                <div v-for="(item, index) in propsObj.reportData.detailRows" :key="index">
                    <v-list-tile class="right-subtitle-tile">

                        <v-list-tile-content @click="fn_getDetails(item)">
                            <v-list-tile-title class="mb-1">{{item.cols.expenseCategoryName}}</v-list-tile-title>
                            <v-list-tile-sub-title>{{item.cols.parentGroupName}}</v-list-tile-sub-title>
                        </v-list-tile-content>

                        <v-list-tile-action align-flex-end>

                            <v-btn icon flat @click="fn_expandMore(item)">
                                <v-icon>more_horiz</v-icon>
                            </v-btn>

                            <div class="light-opacity-color">
                                <span>&#8377; {{$root.numberToLocale(item.cols.outAmount)}}</span>
                            </div>
                        </v-list-tile-action>
                    </v-list-tile>

                    <v-divider v-if="propsObj.reportData.detailRows.length-1 != index"></v-divider>
                </div>
            </v-list>
        </div>`,

        footerContent:`<div>
            <v-subheader subheader-semi-dense font16>Total
              <v-spacer></v-spacer>
              &#8377; {{$root.numberToLocale(propsObj.reportData.footerRow.outAmount)}}
            </v-subheader>
        </div>`,
        detailReportId:null,
        period:'month',
        periodLabel:'Period',
        reportType:'general',
        filters:null,
        hideFilterBtn:true
    },

    413:{
        reportTitle:"Expense Payment",
        itemLabel:null,
        openDialog:false,
        headerRow: {"parentGroupName":"Main Group","accountName":"Payment Ledger","outAmount":"Amount"},
        headerRowTableHeaders: [{"headerName":"Main Group", "type":"string"},{"headerName":"Payment Ledger", "type":"string"},{"headerName":"Amount", "type":"number"}],
        columns:['parentGroupName', 'accountName', 'outAmount'],
        tileViewFooterHeaders:{"outAmount":"Amount"},
        tileContent:`<div>

            <v-list two-line nopadding>

                <div v-for="(item, index) in propsObj.reportData.detailRows" :key="index">
                    <v-list-tile class="right-subtitle-tile">

                        <v-list-tile-content @click="fn_getDetails(item)">
                            <v-list-tile-title class="mb-1">{{item.cols.accountName}}</v-list-tile-title>
                            <v-list-tile-sub-title>{{item.cols.parentGroupName}}</v-list-tile-sub-title>
                        </v-list-tile-content>

                        <v-list-tile-action align-flex-end>

                            <v-btn icon flat @click="fn_expandMore(item)">
                                <v-icon>more_horiz</v-icon>
                            </v-btn>

                            <div class="light-opacity-color">
                                <span>&#8377; {{$root.numberToLocale(item.cols.outAmount)}}</span>
                            </div>
                        </v-list-tile-action>
                    </v-list-tile>

                    <v-divider v-if="propsObj.reportData.detailRows.length-1 != index"></v-divider>
                </div>
            </v-list>
        </div>`,

        footerContent:`<div>
            <v-subheader subheader-semi-dense font16>Total
              <v-spacer></v-spacer>
              &#8377; {{$root.numberToLocale(propsObj.reportData.footerRow.outAmount)}}
            </v-subheader>
        </div>`,
        detailReportId:null,
        period:'month',
        periodLabel:'Period',
        reportType:'general',
        filters:null,
        hideFilterBtn:true
    },

    414:{
        reportTitle:"Partywise Expense",
        itemLabel:null,
        openDialog:false,
        headerRow: {"parentGroupName":"Main Group","accountName":"Account Ledger","outAmount":"Amount"},
        headerRowTableHeaders: [{"headerName":"Main Group", "type":"string"},{"headerName":"Account Ledger", "type":"string"},{"headerName":"Amount", "type":"number"}],
        columns:['parentGroupName', 'accountName', 'outAmount'],
        tileViewFooterHeaders:{"outAmount":"Amount"},
        tileContent:`<div>

            <v-list two-line nopadding>

                <div v-for="(item, index) in propsObj.reportData.detailRows" :key="index">
                    <v-list-tile class="right-subtitle-tile">

                        <v-list-tile-content @click="fn_getDetails(item)">
                            <v-list-tile-title class="mb-1">{{item.cols.accountName}}</v-list-tile-title>
                            <v-list-tile-sub-title>{{item.cols.parentGroupName}}</v-list-tile-sub-title>
                        </v-list-tile-content>

                        <v-list-tile-action align-flex-end>

                            <v-btn icon flat @click="fn_expandMore(item)">
                                <v-icon>more_horiz</v-icon>
                            </v-btn>

                            <div class="light-opacity-color">
                                <span>&#8377; {{$root.numberToLocale(item.cols.outAmount)}}</span>
                            </div>
                        </v-list-tile-action>
                    </v-list-tile>

                    <v-divider v-if="propsObj.reportData.detailRows.length-1 != index"></v-divider>
                </div>
            </v-list>
        </div>`,

        footerContent:`<div>
            <v-subheader subheader-semi-dense font16>Total
              <v-spacer></v-spacer>
              &#8377; {{$root.numberToLocale(propsObj.reportData.footerRow.outAmount)}}
            </v-subheader>
        </div>`,
        detailReportId:null,
        period:'month',
        periodLabel:'Period',
        reportType:'general',
        filters:null,
        hideFilterBtn:true
    },

    501:{
        reportTitle:"Outstandings Receivables",
        itemLabel:"Party",
        openDialog:true,
        headerRow: {"mergeTransactionNumber":"Number","transactionListId":"Invoice Type","transactionDate":"Date","outStandingAmount":"Amount"},
        columns:['mergeTransactionNumber', 'transactionListId', 'transactionDate', 'outStandingAmount'],
        tileViewFooterHeaders:{"total":"Total", "onAccount":"On Account", "outStandingAmount":"Outstanding Receivables"},
        tileContent:`<div>

            <v-list two-line nopadding>

                <div v-for="(item, index) in propsObj.reportData.detailRows" :key="index">
                    <v-list-tile class="right-subtitle-tile">

                        <v-list-tile-content @click="fn_getDetails(item)">
                            <v-list-tile-title class="mb-1">
                                <span v-if="item.cols.mergeTransactionNumber">{{item.cols.mergeTransactionNumber}} |</span>
                                {{item.cols.transactionListId}}
                            </v-list-tile-title>
                            <v-list-tile-sub-title>{{item.cols.transactionDate | moment("DD-MMM-YYYY")}}</v-list-tile-sub-title>
                        </v-list-tile-content>

                        <v-list-tile-action align-flex-end>

                            <v-btn icon text @click="fn_expandMore(item)">
                                <v-icon>more_horiz</v-icon>
                            </v-btn>

                            <div class="light-opacity-color">
                                <span>&#8377; {{$root.numberToLocale(item.cols.outStandingAmount)}}</span>
                            </div>
                        </v-list-tile-action>
                    </v-list-tile>

                    <v-divider v-if="propsObj.reportData.detailRows.length-1 != index"></v-divider>
                </div>
            </v-list>

        </div>`,

        footerContent:`<div>
            <v-subheader subheader-semi-dense font16>Outstanding Receivables
              <v-spacer></v-spacer>
              &#8377; {{$root.numberToLocale(propsObj.reportData.summaryRows[2].transactionListId)}}
              <v-icon>{{!$parent.$parent.tileViewTotalSheet ? 'arrow_drop_up' : 'arrow_drop_down'}}</v-icon>
            </v-subheader>
        </div>`,

        footerColumns:['mergeTransactionNumber', 'transactionListId'],
        detailReportId:null,
        period:'endDate',
        periodLabel:'As On',
        reportType:'general',
        filters: null,
        openView:true
    },

    502:{
        reportTitle:"Outstandings Payables",
        itemLabel:"Party",
        openDialog:true,
        headerRow: {"mergeTransactionNumber":"Number","transactionListId":"Invoice Type","transactionDate":"Date","outStandingAmount":"Amount"},
        columns:['mergeTransactionNumber', 'transactionListId', 'transactionDate', 'outStandingAmount'],
        tileViewFooterHeaders:{"total":"Total", "onAccount":"On Account", "outStandingAmount":"Outstanding Receivables"},
        tileContent:`<div>
            <v-list two-line nopadding>

                <div v-for="(item, index) in propsObj.reportData.detailRows" :key="index">
                    <v-list-tile class="right-subtitle-tile">

                        <v-list-tile-content @click="fn_getDetails(item)">
                            <v-list-tile-title class="mb-1">
                                <span v-if="item.cols.mergeTransactionNumber">{{item.cols.mergeTransactionNumber}} |</span>
                                {{item.cols.transactionListId}}
                            </v-list-tile-title>
                            <v-list-tile-sub-title>{{item.cols.transactionDate | moment("DD-MMM-YYYY")}}</v-list-tile-sub-title>
                        </v-list-tile-content>

                        <v-list-tile-action align-flex-end>

                            <v-btn icon text @click="fn_expandMore(item)">
                                <v-icon>more_horiz</v-icon>
                            </v-btn>

                            <div class="light-opacity-color">
                                <span>&#8377; {{$root.numberToLocale(item.cols.outStandingAmount)}}</span>
                            </div>
                        </v-list-tile-action>
                    </v-list-tile>

                    <v-divider v-if="propsObj.reportData.detailRows.length-1 != index"></v-divider>
                </div>
            </v-list>
        </div>`,

        footerContent:`<div>
            <v-subheader subheader-semi-dense font16>Outstanding Payables
              <v-spacer></v-spacer>
              &#8377; {{$root.numberToLocale(propsObj.reportData.summaryRows[2].transactionListId)}}
              <v-icon>{{!$parent.$parent.tileViewTotalSheet ? 'arrow_drop_up' : 'arrow_drop_down'}}</v-icon>
            </v-subheader>
        </div>`,

        footerColumns:['mergeTransactionNumber', 'transactionListId'],
        detailReportId:null,
        period:'endDate',
        periodLabel:'As On',
        reportType:'general',
        filters: null,
        openView:true
    },

    503:{
        reportTitle:"Partywise Outstanding",
        itemLabel:null,
        openDialog:false,
        headerRow: {"party":"Party","partyBalance":"Balance", "balanceType":""},
        headerRowTableHeaders: [{"headerName":"Party", "type":"string"},{"headerName":"Balance", "type":"number"},{"headerName":"", "type":"string"}],
        columns:['party', 'partyBalance', 'balanceType'],
        // tileViewFooterHeaders:{"taxValue":"Value"},
        tileViewFooterHeaders:null,
        tileContent:`<div>

            <v-list nopadding>

                <div v-for="(item, index) in propsObj.reportData.detailRows" :key="index">
                    <v-subheader class="white-background subheader-semi-dense" v-if="item.cols.isGroup">{{item.cols.party}}</v-subheader>
                    <v-list-tile class="right-subtitle-tile" v-if="!item.cols.isGroup">

                        <v-list-tile-content @click="fn_getDetails(item)">
                            <v-list-tile-title class="mb-1">{{item.cols.party}}</v-list-tile-title>
                        </v-list-tile-content>

                        <v-list-tile-action style="min-width:120px">
                            <span :class="[item.cols.balanceType == 'To Pay' ? 'redcolor' : 'greencolor']">&#8377; {{$root.numberToLocale(Math.abs(item.cols.partyBalance))}}</span>
                        </v-list-tile-action>
                    </v-list-tile>

                    <v-divider v-if="propsObj.reportData.detailRows.length-1 != index"></v-divider>
                </div>
            </v-list>

        </div>`,

        footerColumns:null,
        detailReportId:null,
        period:'endDate',
        periodLabel:'As On',
        reportType:'general',
        filters: null,
        openView:true
    },

    504:{
        reportTitle:"Invoice reconciliation Report",
        itemLabel:null,
        openDialog:false,
        headerRow: {"party":"Party","udyamNumber":"Udyam Number", "totalBillAmount":"Total Amount", "totalBillAmountReceived":"Bill Agst Amount", "pendingAmount":"Pending Amount", "dueDays":"Due Days"},
        headerRowTableHeaders: [{"headerName":"Party", "type":"string"},{"headerName":"Udyam Number", "type":"string"},{"headerName":"Total Amount", "type":"number"}, {"headerName":"Bill Agst Amount", "type":"number"}, {"headerName":"Pending Amount", "type":"number"}, {"headerName":"Due Days", "type":"string"}],
        columns:['party', 'udyamNumber', 'totalBillAmount', 'totalBillAmountReceived', 'pendingAmount', 'dueDays'],
        tileViewFooterHeaders:null,
        tileContent:`<div>

           <v-list three-line nopadding>

                <div v-for="(item, index) in propsObj.reportData.detailRows" :key="index">
                    <v-subheader class="white-background subheader-semi-dense" v-if="item.cols.isGroup">{{item.cols.party}}</v-subheader>
                    <v-list-tile class="right-subtitle-tile" v-if="!item.cols.isGroup">

                        <v-list-tile-content @click="fn_getDetails(item)">
                            <v-list-tile-title class="mb-1">{{item.cols.party}}</v-list-tile-title>
                            <v-list-tile-sub-title class="mb-1">Udyam No.: {{item.cols.udyamNumber}}</v-list-tile-sub-title>
                            <v-list-tile-sub-title class="mb-1">Total Amt: {{$root.numberToLocale(item.cols.totalBillAmount)}}</v-list-tile-sub-title>
                        </v-list-tile-content>

                        <v-list-tile-action align-flex-end style="min-width:120px">
                            <v-btn icon flat @click="fn_expandMore(item)">
                                <v-icon>more_horiz</v-icon>
                            </v-btn>
                            <span :class="[item.cols.balanceType == 'To Pay' ? 'redcolor' : 'greencolor']">&#8377; {{$root.numberToLocale(item.cols.pendingAmount)}}</span>
                        </v-list-tile-action>
                    </v-list-tile>

                    <v-divider v-if="propsObj.reportData.detailRows.length-1 != index"></v-divider>
                </div>
            </v-list>
        </div>`,

        footerContent:`<div>
            <v-subheader subheader-semi-dense font16>Total
              <v-spacer></v-spacer>
              &#8377; {{$root.numberToLocale(propsObj.reportData.footerRow.outAmount)}}
            </v-subheader>
        </div>`,
        detailReportId:null,
        period:'endDate',
        periodLabel:'As On',
        reportType:'general',
        filters:null,
        hideFilterBtn:false,
        openView:true,
    },

    505:{
        reportTitle:"Invoice reconciliation Report",
        itemLabel:"Party",
        openDialog:false,
        headerRow: {"udyamNumber":"Udyam Number", "transactionDate":"Transaction Date", "transactionListId":"Type", "mergeTransactionNumber":"Transaction Number", "amount":"Transaction Amount", "billAmountReceived":"Bill Agst Amount", "pendingAmount":"Pending Amount", "Due":"dueStatus", "dueDate":"Due Date", "dueDays":"Due Days"},
        headerRowTableHeaders: [{"headerName":"Udyam Number", "type":"string"},{"headerName":"Transaction Date", "type":"date"}, {"headerName":"Type", "type":"string"}, {"headerName":"Transaction Number", "type":"string"}, {"headerName":"Transaction Amount", "type":"number"}, {"headerName":"Bill Agst Amount", "type":"number"},{"headerName":"Pending Amount", "type":"number"},{"headerName":"Due", "type":"string"},{"headerName":"Due Date", "type":"date"},{"headerName":"Due Days", "type":"string"}],
        columns:['udyamNumber', 'transactionDate', 'transactionListId', 'mergeTransactionNumber', 'amount', 'billAmountReceived', 'pendingAmount', 'dueStatus', 'dueDate', 'dueDays'],
        tileViewFooterHeaders:null,
        tileContent:`<div>

           <v-list three-line nopadding>

                <div v-for="(item, index) in propsObj.reportData.detailRows" :key="index">
                    <v-subheader class="white-background subheader-semi-dense" v-if="item.cols.isGroup">{{item.cols.party}}</v-subheader>
                    <v-list-tile class="right-subtitle-tile" v-if="!item.cols.isGroup">

                        <v-list-tile-content>
                            <v-list-tile-title class="mb-1">{{item.cols.udyamNumber}}</v-list-tile-title>
                            <v-list-tile-sub-title class="mb-1">{{item.cols.mergeTransactionNumber}} | {{item.cols.transactionDate | moment("DD-MMM-YYYY")}}</v-list-tile-sub-title>
                            <v-list-tile-sub-title class="mb-1">Due in {{$root.numberToLocale(item.cols.dueDays)}} days</v-list-tile-sub-title>
                        </v-list-tile-content>

                        <v-list-tile-action align-flex-end style="min-width:120px">
                            <v-btn icon flat @click="fn_expandMore(item)">
                                <v-icon>more_horiz</v-icon>
                            </v-btn>
                            <span :class="[item.cols.balanceType == 'To Pay' ? 'redcolor' : 'greencolor']">&#8377; {{$root.numberToLocale(item.cols.pendingAmount)}}</span>
                        </v-list-tile-action>
                    </v-list-tile>

                    <v-divider v-if="propsObj.reportData.detailRows.length-1 != index"></v-divider>
                </div>
            </v-list>
        </div>`,

        footerContent:null,
        detailReportId:null,
        period:'endDate',
        periodLabel:'As On',
        reportType:'general',
        filters:null,
        hideFilterBtn:false,
        openView:true
    },

    601:{
        reportTitle:"Trial Balance",
        itemLabel:null,
        openDialog:false,
        headerRow: {"accountName":"Particulars","debitAmount":"Debit","creditAmount":"Credit"},
        headerRowTableHeaders: [{"headerName":"Particulars", "type":"string"},{"headerName":"Debit", "type":"number"},{"headerName":"Credit", "type":"number"}],
        columns:['accountName', 'debitAmount', 'creditAmount'],
        tileViewFooterHeaders:{"debitAmount":"Debit", "creditAmount":"Credit"},
        tileContent:null,
        // footerColumns:['col1', 'col2', 'col3'],
        detailReportId:null,
        period:'year',
        periodLabel:'Period',
        reportType:'general',
        filters: null,
        hideFilterBtn:true
    },

    602:{
        reportTitle:"Profit and Loss",
        itemLabel:null,
        openDialog:false,
        headerRow: {"purchaseAccountName":"Particulars","purchaseAmount":"Amount","saleAccountName":"Particulars","saleAmount":"Amount"},
        headerRowTableHeaders: [{"headerName":"Particulars", "type":"string"},{"headerName":"Amount", "type":"number"},{"headerName":"Particulars", "type":"string"},{"headerName":"Amount", "type":"number"}],
        columns:['purchaseAccountName', 'purchaseAmount', 'saleAccountName', 'saleAmount'],
        detailReportId:null,
        period:'year',
        periodLabel:'Period',
        reportType:'general',
        filters: null,
        hideFilterBtn:true
    },

    603:{
        reportTitle:"Balance Sheet",
        itemLabel:null,
        openDialog:false,
        headerRow: {"liabilityAccountName":"Liabilities","liabilityAmount":"Amount","assetAccountName":"Assets","assetAmount":"Amount"},
        headerRowTableHeaders: [{"headerName":"Liabilities", "type":"string"},{"headerName":"Amount", "type":"number"},{"headerName":"Assets", "type":"string"},{"headerName":"Amount", "type":"number"}],
        columns:['liabilityAccountName', 'liabilityAmount', 'assetAccountName', 'assetAmount'],
        detailReportId:null,
        period:'year',
        periodLabel:'Period',
        reportType:'general',
        filters: null,
        hideFilterBtn:true
    },

    701:{
        reportTitle:"Partywise TDS",
        itemLabel:null,
        openDialog:false,
        headerRow: {"tdsAccountName":"Section", "mergeTransactionNumber":"Number", "partyName":"Party","partyPanNumber":"PAN","amount":"Amount Paid", "transactionDate":"Date", "rate":"Percentage", "tds":"TDS"},
        headerRowTableHeaders: [{"headerName":"Section", "type":"string"},{"headerName":"Party", "type":"string"},{"headerName":"PAN", "type":"string"},{"headerName":"Amount Paid", "type":"number"},{"headerName":"Date", "type":"date"},{"headerName":"Percentage", "type":"number"},{"headerName":"TDS", "type":"number"}],
        columns:['tdsAccountName', 'mergeTransactionNumber', 'partyName', 'partyPanNumber', 'amount', 'transactionDate', 'rate', 'tds'],
        tileViewFooterHeaders:{"tds":"TDS"},
        tileContent:`<div>

            <v-list three-line nopadding>

                <div v-for="(item, index) in propsObj.reportData.detailRows" :key="index">
                    <v-list-tile class="right-subtitle-tile">

                        <v-list-tile-content @click="fn_getDetails(item)">
                            <v-list-tile-title class="mb-1">{{item.cols.partyName}}</v-list-tile-title>
                            <v-list-tile-sub-title>{{item.cols.transactionDate | moment("DD-MMM-YYYY")}}</v-list-tile-sub-title>
                            <v-list-tile-sub-title class="mb-1">{{item.cols.mergeTransactionNumber}} | {{item.cols.tdsAccountName}}</v-list-tile-sub-title>
                        </v-list-tile-content>

                        <v-list-tile-action align-flex-end>

                            <v-btn icon text @click="fn_expandMore(item)">
                                <v-icon>more_horiz</v-icon>
                            </v-btn>

                            <div class="light-opacity-color">
                                <span>&#8377; {{$root.numberToLocale(item.cols.tds)}}</span>
                            </div>
                        </v-list-tile-action>
                    </v-list-tile>

                    <v-divider v-if="propsObj.reportData.detailRows.length-1 != index"></v-divider>
                </div>
            </v-list>
        </div>`,

        footerContent:`<div>
            <v-subheader subheader-semi-dense font16>Total
              <v-spacer></v-spacer>
              &#8377; {{$root.numberToLocale(propsObj.reportData.footerRow.tds)}}
            </v-subheader>
        </div>`,

        detailReportId:null,
        period:'month',
        periodLabel:'Period',
        reportType:'general',
        filters: null,
        openView:true,
        hideFilterBtn:true
    },

    901:{
        reportTitle:"Group Summary",
        itemLabel:null,
        openDialog:false,
        headerRow: {"accountName":"Particulars", "openingValue":"Opening", "openingType":"", "debit":"Debit", "credit":"Credit", "partyBalance":"Balance", "balanceType":""},
        headerRowTableHeaders: [{"headerName":"Particulars", "type":"string"},{"headerName":"Opening", "type":"number"},{"headerName":"", "type":"string"},{"headerName":"Debit", "type":"number"},{"headerName":"Credit", "type":"number"},{"headerName":"Balance", "type":"number"},{"headerName":"", "type":"string"}],
        columns:['accountName', 'openingValue', 'openingType', 'debit', 'credit', 'partyBalance', 'balanceType'],
        detailReportId:null,
        period:'year',
        periodLabel:'Period',
        reportType:'general',
        filters: null,
        hideFilterBtn:true
    },

    //Ledger a/c report item/transaction details
    2011:{
        reportTitle:"Details",
        headerRow: {"party":"Party","debit":"To Receive","credit":"To Pay"},
        columns:['party', 'debit', 'credit']
    },

    fn_getData: function(reportId){
        for(let key in data){
            if(key == reportId){
                return data[key]
            }
        }
    },

    filters:[
        {"label":"Party", "id":null, "value":null, "items":[], "ref":"ref_fieldParty", "dialogName":"AccountDialog", "reportIds":[101, 202, 203, 301, 303, 304, 305, 308, 401, 403, 404, 405, 409]},
        {"label":"Item", "id":null, "value":null, "items":[], "ref":"ref_fieldItem", "dialogName":"ProductDialog"},
        {"label":"Category", "id":null, "value":null, "items":[], "ref":"ref_fieldCategory", "dialogName":"ProductCategoryDialog", "reportIds":[102, 103, 301, 401]},
        {"label":"Brand", "id":null, "value":null, "items":[], "ref":"ref_fieldBrand", "dialogName":"ListItemDialog", "reportIds":[102, 103, 301, 401]},
        {"label":"Transaction Form", "id":null, "value":null, "items":[], "ref":"ref_fieldTransactionForm", "dialogName":"TransactionTypeForm", "reportIds":[201, 202, 303, 403]},
        // {"label":"Tax Type", "id":null, "value":null, "items":[], "ref":"ref_fieldTaxType", "dialogName":"AccountDialog", "reportIds":[303, 403]},
        {"label":"Account Group", "id":null, "value":null, "items":[], "ref":"ref_fieldAccountGroup", "dialogName":"AccountGroupDialog", "reportIds":[302, 402]},
    ]
    
})