<template>
    <div>

        <v-layout row wrap style="margin:0" class="right_layout">
            <v-flex xs4 sm4 md4 style="border-right:1px #ddd solid" class="list_pane donotprint" v-if="!$route.params.reportId">
                <component :is="currentComponent" :ref="componentRef"></component>
            </v-flex>
            <v-flex class="action_pane grey-back" :class="[$route.params.reportId ? 'xs12 sm12 md12' : 'xs8 sm8 md8']">
                <!-- <div v-if="getCaption">{{getCaption}}</div> -->
                <template v-if="$route.matched.length<=2">
                    <div style="display: flex;align-items: center;justify-content: center;height:100vh">
                        <v-layout ma-0 row wrap center-align>
                            <v-flex xs12 sm12 md12>
                                <v-icon style="font-size:48px">list_alt</v-icon>   
                            </v-flex>
                            <v-flex xs12 sm12 md12 pt-4 font16>
                                Select an item to get details
                            </v-flex>
                            <v-flex xs12 sm12 md12 pt-2 light-opacity-color>
                                Nothing is selected
                            </v-flex>
                        </v-layout>
                    </div>
                </template>
                <template v-else>
                    <div><router-view/></div>
                </template>
            </v-flex>
        </v-layout>
        <!-- <v-row>
            <v-col cols="4">
                <component :is="currentComponent" :ref="componentRef"></component>
            </v-col>

            <v-col cols="8">
                <div v-if="getCaption">{{getCaption}}</div>
                <div v-else><router-view/></div>
            </v-col>
        </v-row> -->
    </div>
</template>

<script>
import reportList from '../components/reports/report_list'

// actions
import favouriteItems from '../components/actions/favourites'
import masterItems from '../components/actions/masters'
import transactionItems from '../components/actions/transactions'

// dynamic master lists
import accountGroupItems from '../components/masters/forms/account_groups/items'
import dicountItems from '../components/masters/forms/discounts/items'
import expenseCategoryItems from '../components/masters/forms/expense_categories/items'
import ledgerItems from '../components/masters/forms/ledgers/items'
import listsItems from '../components/masters/forms/lists/items'
import listsItemItems from '../components/masters/forms/lists/list_items/items'
import otherCharges from '../components/masters/forms/other_charges/items'
import taxes from '../components/masters/forms/taxes/items'
import product from '../components/masters/forms/product/items'
import productCategories from '../components/masters/forms/product_categories/items'
import taxCategories from '../components/masters/forms/tax_category/items'
import unitItems from '../components/masters/forms/units/items'

// dynamic transaction lists
import accountbookItems from '../components/transactions/forms/accountbooks/items'
import expenseItems from '../components/transactions/forms/expense/items'
import purchaseItems from '../components/transactions/forms/purchase/items'
import saleItems from '../components/transactions/forms/sale/items'
import openingStock from '../components/transactions/forms/opening_stock/items'
import openingLedger from '../components/transactions/forms/opening_ledger/items'

// activity
import transactions from '../components/activity/transactions'
import parties from '../components/activity/parties'
import items from '../components/activity/items'
import orders from '../components/activity/orders'
import invoices from '../components/activity/invoices'

// settings
import settings from '../components/settings/settings'
import accountantItems from '../components/settings/accountant/items'

// printfields
import printfields from '../components/settings/print_fields/items'
import printfieldTerms from '../components/settings/print_fields/term_items'



    export default{
        data: () => ({
            currentComponent:null,
            componentRef:null
        }),
        methods:{
            fn_init: function(nextRoute){
                if(nextRoute.params.listId == "reports"){
                    this.currentComponent = reportList;
                    this.componentRef = "ref_reports";
                }
                else if(nextRoute.params.listId == "favourite_items"){
                    this.currentComponent = favouriteItems;
                    this.componentRef = "ref_favouriteItems";
                }
                else if(nextRoute.params.listId == "master_items"){
                    this.currentComponent = masterItems;
                    this.componentRef = "ref_masterItems";
                }
                else if(nextRoute.params.listId == "transaction_items"){
                    this.currentComponent = transactionItems;
                    this.componentRef = "ref_masters";
                }
                else if(nextRoute.params.listId == "account_group"){
                    this.currentComponent = accountGroupItems;
                    this.componentRef = "ref_accountGroups";
                }
                else if(nextRoute.params.listId == "discounts"){
                    this.currentComponent = dicountItems;
                    this.componentRef = "ref_discounts";
                }
                else if(nextRoute.params.listId == "expense_categories"){
                    this.currentComponent = expenseCategoryItems;
                    this.componentRef = "ref_expenseCategories";
                }
                else if(nextRoute.params.listId == "ledgers"){
                    this.currentComponent = ledgerItems;
                    this.componentRef = "ref_ledgers";
                }
                else if(nextRoute.params.listId == "lists"){
                    this.currentComponent = listsItems;
                    this.componentRef = "ref_lists";
                }
                else if(nextRoute.params.listId == "lists_items"){
                    this.currentComponent = listsItemItems;
                    this.componentRef = "ref_listsItems";
                }
                else if(nextRoute.params.listId == "other_charges"){
                    this.currentComponent = otherCharges;
                    this.componentRef = "ref_otherCharges";
                }
                else if(nextRoute.params.listId == "taxes"){
                    this.currentComponent = taxes;
                    this.componentRef = "ref_taxes";
                }
                else if(nextRoute.params.listId == "product"){
                    this.currentComponent = product;
                    this.componentRef = "ref_product";
                }
                else if(nextRoute.params.listId == "product_categories"){
                    this.currentComponent = productCategories;
                    this.componentRef = "ref_productCategories";
                }
                else if(nextRoute.params.listId == "tax_category"){
                    this.currentComponent = taxCategories;
                    this.componentRef = "ref_taxCategories";
                }
                else if(nextRoute.params.listId == "units"){
                    this.currentComponent = unitItems;
                    this.componentRef = "ref_units";
                }
                else if(nextRoute.params.listId == "accountbooks"){
                    this.currentComponent = accountbookItems;
                    this.componentRef = "ref_accountbooks";
                }
                else if(nextRoute.params.listId == "expense"){
                    this.currentComponent = expenseItems;
                    this.componentRef = "ref_expense";
                }
                else if(nextRoute.params.listId == "purchase"){
                    this.currentComponent = purchaseItems;
                    this.componentRef = "ref_purchase";
                }
                else if(nextRoute.params.listId == "sale"){
                    this.currentComponent = saleItems;
                    this.componentRef = "ref_sale";
                }
                else if(nextRoute.params.listId == "opening_stock"){
                    this.currentComponent = openingStock;
                    this.componentRef = "ref_openingStock";
                }
                else if(nextRoute.params.listId == "opening_ledger"){
                    this.currentComponent = openingLedger;
                    this.componentRef = "ref_openingLedger";
                }
                else if(nextRoute.params.listId == "transactions"){
                    this.currentComponent = transactions;
                    this.componentRef = "ref_transactions";

                    if(nextRoute.query.type){
                        setTimeout(() => {
                            this.$refs[this.componentRef].fn_newTransaction(nextRoute.query.type);
                        }, 100);
                    }
                }
                else if(nextRoute.params.listId == "parties"){
                    this.currentComponent = parties;
                    this.componentRef = "ref_parties";

                    if(nextRoute.query.type == 'party'){
                        setTimeout(() => {
                            this.$refs[this.componentRef].fn_new();
                        }, 100);
                    }
                }
                else if(nextRoute.params.listId == "items"){
                    this.currentComponent = items;
                    this.componentRef = "ref_items";

                    if(nextRoute.query.type == 'item'){
                        setTimeout(() => {
                            this.$refs[this.componentRef].fn_new();
                        }, 100);
                    }
                }
                else if(nextRoute.params.listId == "orders"){
                    this.currentComponent = orders;
                    this.componentRef = "ref_orders";
                }
                else if(nextRoute.params.listId == "invoices"){
                    this.currentComponent = invoices;
                    this.componentRef = "ref_invoices";
                }
                else if(nextRoute.params.listId == "settings"){
                    this.currentComponent = settings;
                    this.componentRef = "ref_settings";
                }
                else if(nextRoute.params.listId == "accountants"){
                    this.currentComponent = accountantItems;
                    this.componentRef = "ref_accountantItems";
                }
                else if(nextRoute.params.listId == "print_fields"){
                    this.currentComponent = printfields;
                    this.componentRef = "ref_printFields";
                }
                else if(nextRoute.params.listId == "print_field_terms"){
                    this.currentComponent = printfieldTerms;
                    this.componentRef = "ref_printFieldTerms";
                }
            }
        },
        mounted: function(){
            console.log(this.$route);
        },
        computed:{
            getCaption: function(){
                let caption = "Select an item to get details.";
                return caption;
            }
        },
        beforeRouteEnter (to, from, next) {
            next(vm => {
                vm.fn_init(to);
            })
        },
        beforeRouteUpdate(to, from, next){
            if(this.$route.params.listId != to.params.listId || (this.$route.query.type != to.query.type && localStorage.getItem('fromFormView'))){
                localStorage.getItem('fromFormView') ? localStorage.removeItem('fromFormView') : '';
                this.fn_init(to);
            }

            if(this.$route.params.listId == to.params.listId){
                this.$eventHub.$once('party', () => {
                    setTimeout(() => {
                        this.$refs['ref_parties'].fn_new();
                    }, 100);
                });

                this.$eventHub.$once('item', () => {
                    setTimeout(() => {
                        this.$refs['ref_items'].fn_new();
                    }, 100);
                });

                this.$eventHub.$once('newTransaction', dataObj => {
                    setTimeout(() => {
                        this.$refs['ref_transactions'].fn_newTransaction(dataObj.type);
                    }, 100);
                });
            }
            // else{
            //     this.$eventHub.$once('party', () => {
            //         setTimeout(() => {
            //             this.$refs['ref_parties'].fn_new();
            //         }, 100);
            //     });

            //     this.$eventHub.$once('item', () => {
            //         setTimeout(() => {
            //             this.$refs['ref_items'].fn_new();
            //         }, 100);
            //     });

            //     this.$eventHub.$once('newTransaction', dataObj => {
            //         setTimeout(() => {
            //             this.$refs['ref_transactions'].fn_newTransaction(dataObj.type);
            //         }, 100);
            //     });
            // }
            
            next();
        },
    }
</script>