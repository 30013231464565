<template>
    <div>
        <div v-for="(item, index) in items" :key="index">
            <v-divider v-if="index != items.length-1"></v-divider>
            
            <div class="items-wrapper" v-if="filterItems(item.amountType)">
                <v-list two-line full-width nopadding>

                    <v-list-item three-line>
                      <v-list-item-content>
                        <v-list-item-title>{{item.accountLedgerName}}</v-list-item-title>
                        <v-list-item-subtitle v-if="item.refNumber">Ref No: {{item.refNumber}}</v-list-item-subtitle>
                        <v-list-item-subtitle>
                            <span v-if="$root.appVersion >= 136">{{ $parent.fn_balanceLabel(item.groupTypeId, item.currentBalance) }}</span>
                            <span v-else>Balance:</span>
                            <span :class="[item.currentBalance < 0 ? 'redcolor' : 'greencolor']">&#8377; {{$root.numberToLocale(item.currentBalance)}}</span>
                        </v-list-item-subtitle>
                      </v-list-item-content>

                        <v-list-item-action font16>
                          <!-- &#8377; {{$root.numberToLocale(item.groupTotal)}} -->
                          <div>&#8377; {{$root.numberToLocale(item.groupTotal ? item.groupTotal : $parent.displayData.amount)}}</div>
                        </v-list-item-action>
                    </v-list-item>

                    <v-expansion-panels nomargin flat>
                        <v-expansion-panel v-if="item.references && item.references.length" v-model="item.expansionPanelModel" expand>
                            <v-expansion-panel-header style="min-height: 48px;">
                                <template>
                                    <v-subheader dense class="pa-0">Linked Invoice(s)</v-subheader>
                                </template>
                            </v-expansion-panel-header>

                            <v-expansion-panel-content>
                                <v-card class="pb-0" flat v-if="item.onAccount">
                                    <v-card-text class="pb-0">
                                        <v-layout row wrap ma-0>
                                            <v-flex xs6 sm6 md6>
                                                On Account    
                                            </v-flex>
                                            <v-flex xs6 sm6 md6 right-align>
                                                <span>&#8377; {{$root.numberToLocale(item.onAccount)}}</span>
                                            </v-flex>
                                        </v-layout>
                                    </v-card-text>
                                </v-card>

                                <template v-for="(ref, refIndex) in item.references">
                                    <v-card class="pb-0" flat :key="refIndex">
                                        <v-card-text>
                                               <!-- {{ref.prefix}}/{{ref.baseTransactionNumber}}{{ref.suffix ? '/'+ref.suffix : ''}} -->
                                            <!-- {{ref.baseTransactionNumber}} -->
                                             <v-layout row wrap ma-0>
                                                <v-flex xs6 sm6 md6>
                                                    <span v-if="ref.baseTransactionNumber">{{ref.baseTransactionNumber}}</span>
                                                    <span v-else>{{ref.baseRefNumber ? ref.baseRefNumber : ''}}</span>&nbsp;
                                                </v-flex>
                                                <v-flex xs6 sm6 md6 right-align>
                                                    {{ $parent.fn_getPrefix(ref.transactionListId) == 'minus' ? '(-)' : '' }}
                                                    <span>&#8377; {{$root.numberToLocale(ref.amount)}}</span>
                                                </v-flex>
                                             </v-layout>
                                        </v-card-text>
                                    </v-card>
                                    <v-divider :key="'divider'+refIndex" v-if="item.references.length - 1 != refIndex"></v-divider>
                                </template>
                            </v-expansion-panel-content>
                        </v-expansion-panel>
                    </v-expansion-panels>
                </v-list>
            
            </div>
        </div>
    </div>
</template>

<script>
    export default{
        props:['items', 'showAll', 'showDebitItems', 'showCreditItems', 'selectedNatureValue'],
        methods:{
            filterItems: function (amountType) {
                if(this.showAll){
                    return true;
                }
                else if(this.showDebitItems && amountType==1){
                    return true;
                }
                else if(this.showCreditItems && amountType==0){
                    return true;
                }
                else{
                    return false;
                }
            },
            // fn_processedBalance: function(balance, balanceType){
            //  let partyBal = this.$root.numberToLocale(this.roundUpto2Decimals(balance));
            //  let balanceString = 'Current Bal: ₹ '+ partyBal;
            //  if(balanceType==1){
            //      balanceString = balanceString+' Dr';
            //  }
            //  else if(balanceType==0){
            //      balanceString = balanceString+' Cr';
            //  }

            //  return balanceString;
            // },
        }

    }
</script>