<template>
    <div>
        <!-- <v-dialog v-model="dialog_mark" no-click-animation persistent scrollable fullscreen content-class="full-height" lazy transition="dialog-bottom-transition"> -->
        <v-dialog v-model="dialog_mark" content-class="half-screen-dialog" no-click-animation scrollable persistent transition="slide-y-transition" :max-width="$root.fn_getActionPaneWidth()">
            <v-card flat>
                <v-toolbar :height="56" flat extended>
                    <v-btn icon @click="closeDialogWeb"><v-icon>arrow_back</v-icon></v-btn>
                    <v-toolbar-title>{{clearValue ? 'Close Pending' : 'Reopen Closed'}} Bill/Invoice(s)</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-btn icon small :disabled="disableBtn" color="primary" @click="fn_openSheet" class="mr-3">Done</v-btn>

                    <v-menu v-if="clearValue">

                        <template v-slot:activator="{ on, attrs }">
                        <v-btn v-bind="attrs" v-on="on" icon>
                            <v-icon font20>more_vert</v-icon>
                        </v-btn>
                        </template>


                        <v-list nopadding dense>
                            <v-list-item @click="$parent.fn_markClear(false)">
                                <v-list-item-content>
                                    <v-list-item-title>Reopen Closed Bill/Invoice</v-list-item-title>
                                    <v-list-item-subtitle>Reopen Bill/Invoice from Closed List</v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>
                            <!-- <v-list-item @click="$parent.fn_markClear(false)">
                                <v-list-item-content>
                                    <v-list-item-title>Reopen Bills/Invoices</v-list-item-title>
                                </v-list-item-content>
                            </v-list-item> -->
                        </v-list>

                    </v-menu>

                    <v-text-field grey-back clearable flat solo hide-details placeholder="Search..." v-model="searchValue" slot="extension"></v-text-field>
                </v-toolbar>

                <div class="data-container grey-back hasExtention">
                    <v-card-text nopadding id="scrollable-div">
                        <v-list nopadding>
                            <v-list-item>
                                <v-list-item-content>
                                    <v-list-item-title light-opacity-color>Select All</v-list-item-title>
                                </v-list-item-content>
                                <v-list-item-action style="padding-left: 24px;">
                                    <v-checkbox  @change="fn_selectAll" v-model="selectAll"></v-checkbox>
                                </v-list-item-action>
                            </v-list-item>
                        </v-list>
                        <v-divider></v-divider>

                        <v-list two-line nopadding>
                            <template v-for="(item, index) in filteredItems">
                                <v-list-item :key="index">
                                    <v-list-item-content>
                                        <v-list-item-title>{{item.baseTransactionNumber}} | {{item.transactionDate | moment("DD-MMM-YYYY")}}</v-list-item-title>
                                        <v-list-item-subtitle>Bill Amount: &#8377; {{ $root.numberToLocale(item.billAmount) }} &nbsp;&nbsp; Due: &#8377; {{ $root.numberToLocale(item.outStandingAmount) }}</v-list-item-subtitle>
                                    </v-list-item-content>
                                    <v-list-item-action style="padding-left: 24px;">
                                        <v-checkbox v-model="item.selected" :value="item.selected" @change="fn_unselect(item, true)"></v-checkbox>
                                    </v-list-item-action>
                                </v-list-item>
                                <v-divider v-if="filteredItems.length - 1 != index" :key="'divider'+index"></v-divider>
                            </template>
                            
                        </v-list>

                        <div class="pa-4 light-opacity-color center-align" v-if="!filteredItems.length">There is no pending items</div>

                        </v-card-text>
                </div>

                
                <!-- <v-divider></v-divider>
                <v-card-actions>
                    Total
                    <v-spacer></v-spacer>
                      &#8377; {{$root.numberToLocale(referenceTotal)}}
                </v-card-actions> -->
            </v-card>
          </v-dialog>

        <!-- <v-bottom-sheet v-model="confirmSheet" persistent> -->
        <v-dialog v-model="confirmSheet" no-click-animation scrollable persistent transition="slide-y-transition" :max-width="320">
            <v-card flat>
                <v-card-text class="pt-4">
                    <p>These selected bills/invoices will be {{ this.clearValue ? 'Closed' : 'reopen' }}.</p>
                    <div style="overflow-y: scroll;max-height: 200px;padding-bottom: 16px;"> <template v-for="(item, index) in pendingInvoices"><v-chip close @click="fn_unselect(item)" small :key="index" v-if="item.selected">{{ item.baseTransactionNumber }}</v-chip></template> </div>
                    <v-textarea 
                        rows="2" 
                        label="Enter Note" 
                        placeholder=" " 
                        v-model="clearNote">
                    </v-textarea>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn small font-heavy class="primary-color" text @click="confirmSheet=false">Cancel</v-btn>
                    <v-btn small font-heavy class="primary-color" text @click="fn_markClear">{{ this.clearValue ? 'Close' : 'Reopen' }} All</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <!-- </v-bottom-sheet> -->
    </div>
</template>

<script>

const transactionTypes = require('../../../../config.js').configs.transactionTypes;

export default{
    props:['party', 'showBottomNavigation', 'refreshList', 'clearValue'],
    data(){
        return{
            dialog_mark: false,
            searchValue:'',
            disableBtn:false,
            referenceTotal:0,
            hasAmountFilled:false,
            onAccount:0,
            pendingInvoices:[],
            selectAll:false,
            confirmSheet:false,
            clearNote:null
        }
    },
    methods:{
        openDialogWeb: function () {
            this.dialog_mark = true;
            this.selectAll = false;
            // if(!this.$parent.Form.particularsData.onAccount && !this.$parent.Form.particularsData.references.length){
            // 	this.onAccount = this.$parent.Form.particularsData.amount;
            // }
            // else{
            // 	this.onAccount = this.$parent.Form.particularsData.onAccount;
            // }

            // making textbox empty and checkbox unchecked if no references save yet
            // setTimeout(() => {
            // 	this.fn_referenceTotal();
            // }, 500);

            this.searchValue = '';
            this.clearNote = null;
            // this.$validator.reset();
            this.fn_loadPendingItems();
        },
        closeDialogWeb: function () {
            this.dialog_mark = false;
            // this.showBottomNavigation ? this.showBottomNavigation() : '';
        },
        // fn_closeDialog: function(){
        //     if(this.$parent.fn_closeDialog){
        //         this.$parent.fn_closeDialog();
        //     }
        //     else{
        //         this.closeDialogWeb();
        //     }
        // },
        onScroll: function () {
            let elements = document.getElementsByClassName('error--text');
            let scrollableDiv = document.getElementById('scrollable-div');
            scrollableDiv.scrollTop = elements[0].offsetTop - 120;
        },

        fn_selectAll: function(val){
            for(let i=0; i<this.pendingInvoices.length; i++){
                this.pendingInvoices[i].selected = val;
                this.$forceUpdate();
            }
        },

        fn_unselect: function(item, donotfalse){
            if(!donotfalse){
                item.selected = false;
            }
            this.$forceUpdate();
            this.fn_watchSelection();
        },

        fn_loadPendingItems: function () {

            let filterObject = {};

            if(this.party && this.party.itemId){
                filterObject.formTransactionListId = [this.$parent.Form.transactionData.transactionListId];
            }
            else{
                filterObject.formTransactionListId = [30001, 30002];
            }

            filterObject.transactionListId = [transactionTypes.salesInvoice.id, transactionTypes.salesReturn.id, transactionTypes.bwbRefPurchaseInvoice.id, transactionTypes.bwbRefSaleInvoice.id, transactionTypes.purchaseInvoice.id, transactionTypes.purchaseReturn.id, transactionTypes.expense.id, transactionTypes.bwbRefPurchaseInvoice.id, transactionTypes.bwbRefSaleInvoice.id];

            // if(this.Form.transactionData.transactionListId == transactionTypes.receipt.id){
            //     filterObject.transactionListId = [transactionTypes.salesInvoice.id, transactionTypes.salesReturn.id, transactionTypes.bwbRefPurchaseInvoice.id, transactionTypes.bwbRefSaleInvoice.id];
            // }
            // else if(this.Form.transactionData.transactionListId == transactionTypes.payment.id){
            //     filterObject.transactionListId = [transactionTypes.purchaseInvoice.id, transactionTypes.purchaseReturn.id, transactionTypes.expense.id, transactionTypes.bwbRefPurchaseInvoice.id, transactionTypes.bwbRefSaleInvoice.id];
            // }

            let self = this;
            let fnName = this.clearValue ? 'PendingReferences' : 'ClearedReferences';
            this.getFilterData(fnName, this.party ? this.party.itemId : null, filterObject, null,
                {
                    onSuccess: function(response){
                        console.log(response);
                        self.pendingInvoices = JSON.parse(response);
                        self.pendingInvoices.forEach(function(item){
                            item.selected = false;
                        })

                        //setting up max value to be filled and amount type dr/cr
                        // self.Form.references.forEach(function(item){
                        //     item.maxValue = item.outStandingAmount;

                        //     if(item.transactionListId == transactionTypes.purchaseInvoice.id || item.transactionListId == transactionTypes.expense.id || item.transactionListId == transactionTypes.salesReturn.id){
                        //             item.amountType = 1;
                        //     }
                        //     else if(item.transactionListId == transactionTypes.salesInvoice.id || item.transactionListId == transactionTypes.purchaseReturn.id){
                        //         item.amountType = 0;
                        //     }

                        // });

                        // //Appending amount if any
                        // for(let i=0; i<self.Form.particularsData.references.length; i++){

                        //     for(let j=0; j<self.Form.references.length; j++){

                        //     if(self.Form.particularsData.references[i].baseTransactionId == self.Form.references[j].baseTransactionId && self.Form.particularsData.references[i].baseTransactionNumber == self.Form.references[j].baseTransactionNumber){

                        //             self.$set(self.Form.references[j], 'amount', self.Form.particularsData.references[i].amount)

                        //             let amount = 0;
                        //             if(!this.isNew){
                        //                 self.Form.references[j].amount ? amount = self.Form.references[j].amount : '';
                        //                 self.Form.references[j].outStandingAmount = amount + self.Form.references[j].outStandingAmount;
                        //                 self.Form.references[j].maxValue = self.Form.references[j].outStandingAmount;
                        //             }

                        //             self.$forceUpdate();
                        //             break;
                        //     }
                            
                        //     }	

                        // }

                        // self.fn_openDialogReferences();
                        
                    }, 
                    onError: function(error){
                    console.log(error);
                    }
                }
            );
        },

        fn_markClear: function(){

            var self = this;
            let dataObj = {"transactionIds":[]};
            let selectedItems = this.pendingInvoices.filter(item=>{ return item.selected});
            if(selectedItems && selectedItems.length){
                for(let i=0; i<selectedItems.length; i++){
                    dataObj.transactionIds.push(selectedItems[i].baseTransactionId)
                }

                dataObj.markClear = this.clearValue;
                dataObj.markClearNote = this.clearNote;

                this.postData('UpdateMarkClear', dataObj, 
                {
                    onSuccess: function(response){
                        console.log(response);
                        self.$eventHub.$emit('snackbarAlert', {msg:self.clearValue ? 'Closed Successfully' : 'Reopened Successfully'});
                        self.confirmSheet=false;
                        self.refreshList ? self.refreshList() : '';
                        self.closeDialogWeb();
                    },
                    onError: function(error){
                        console.log(error);
                        self.$eventHub.$emit('snackbarAlert', {msg:'Something went wrong'});
                    }
                });
            }
            else{
                self.$eventHub.$emit('snackbarAlert', {msg:'Select transaction'});
                this.confirmSheet = false;
            }
        },

        fn_watchSelection: function(){
            let selectedItems = this.pendingInvoices.filter(item=>{
                return item.selected;
            })
            if(!selectedItems || !selectedItems.length){
                this.selectAll = false;
            }
        },

        fn_saveFilledReferences: function () {
            // if(this.referenceTotal<=0){
            // 	if(this.hasAmountFilled){
            // 		this.showToast("Total should not be negative or zero");
            // 	}
            // 	else{
            // 		this.$parent.fn_closeDialog();	
            // 	}
            // 	return;
            // }

            this.disableBtn = true;
            // this.$validator.validateAll('fields').then((result) => {

                if(!this.onAccount){
                    this.$parent.Form.amount = this.referenceTotal;
                    this.$parent.fn_autoFillPaymentMode(this.$parent.Form.amount);
                }
                else{
                    this.$parent.Form.particularsData.onAccount = this.roundUpto2Decimals(this.$parent.Form.amount - this.referenceTotal);
                }

                this.saveFilledReferences();

                // if(this.referenceTotal > this.$parent.Form.particularsData.amount){
                // if(this.referenceTotal > this.$parent.Form.amount){
                // 	this.disableBtn = false;
                // 	this.showToast("Reference total amount shouldn't greater than amount");
                // }
                // else{
                // 	// this.$parent.Form.particularsData.onAccount = this.roundUpto2Decimals(this.$parent.Form.particularsData.amount - this.referenceTotal);
                // 	this.$parent.Form.particularsData.onAccount = this.roundUpto2Decimals(this.$parent.Form.amount - this.referenceTotal);
                // 	this.saveFilledReferences();
                // }

                // if(this.referenceTotal>0){
                // 	if(this.referenceTotal > this.$parent.Form.particularsData.amount){
                // 		this.disableBtn = false;
                // 		this.showToast("Reference total amount shouldn't greater than amount");
                // 	}
                // 	else{
                // 		this.$parent.Form.particularsData.onAccount = this.roundUpto2Decimals(this.$parent.Form.particularsData.amount - this.referenceTotal);
             //    		this.saveFilledReferences();
                // 	}
                // }
                // else{
                // 	this.disableBtn = false;
                // 	this.showToast("Invalid reference total");
                // }

            // });
        },
        // fn_selectAllReferences: function(val){
        // 	for(let i in this.filteredItems){
        // 		val ? this.filteredItems[i].amount = this.filteredItems[i].outStandingAmount : this.filteredItems[i].amount = null;
        // 	}
        // 	this.fn_referenceTotal();
        // },

        fn_autoFillAmount: function(selected, item){

            // if(item.amount == item.outStandingAmount){
            // let remainingAmount = this.$parent.Form.particularsData.amount - this.referenceTotal;
            let remainingAmount = this.$parent.Form.amount - this.referenceTotal;

            if(!selected){
                item.amount = null;
                // this.selectAllRef = false;
            }
            else{
                // if(this.referenceTotal < this.$parent.Form.particularsData.amount){
                if(this.referenceTotal < this.$parent.Form.amount){
                    if(item.outStandingAmount > remainingAmount){
                        item.amount = remainingAmount;
                    }
                    else{
                        item.amount = item.outStandingAmount;
                    }

                    // item.amount = this.$parent.Form.particularsData.amount;
                }
                else{
                    item.amount = item.outStandingAmount;
                }
                
                // if(item.amount != item.outStandingAmount){
                    
                // 	if(item.outStandingAmount > this.$parent.Form.particularsData.amount){
                // 		item.amount = this.$parent.Form.particularsData.amount;
                // 	}
                // 	else{
                // 		item.amount = item.outStandingAmount;
                // 	}
                    
                // }
                // else{
                // 	item.amount = null;
                // }
            }

            // if(item.amount != item.outStandingAmount){
            // 	item.amount = item.outStandingAmount;
            // }
            // else{
            // 	item.amount = null;
            // 	this.selectAllRef = false;
            // }

            this.fn_referenceTotal();
        },
        // fn_changeAmount: function(item){
        // 	if(item.amount == item.outStandingAmount)
        // 		item.autoFill = true;
        // 	else
        // 		item.autoFill = false;

        // 	this.fn_referenceTotal();
        // },
        fn_referenceTotal: function(){
            let referenceTotal = 0;
            this.hasAmountFilled = false;
            // this.isEmptyAmountField = true;

            if(this.filteredItems && this.filteredItems.length){
                for(let i=0; i<this.filteredItems.length; i++){

                    let prefix = this.getPrefix(this.filteredItems[i].transactionListId);

                    if(this.filteredItems[i].amount){

                        this.hasAmountFilled = true;

                        // calculating reference total
                        if(prefix=='minus'){
                            referenceTotal -= this.filteredItems[i].amount;
                        }
                        else{
                            referenceTotal += this.filteredItems[i].amount;
                        }

                    }
                }
            }

            // let onAccount = 0;

            // if(this.onAccount){
            // 	onAccount = this.onAccount;
            // 	this.isEmptyAmountField = false;
            // }
            
            this.referenceTotal = this.roundUpto2Decimals(referenceTotal);

            if(this.$parent.Form.amount){
                if(this.referenceTotal > this.$parent.Form.amount){
                    this.onAccount = this.referenceTotal;
                }
                else{
                    this.onAccount =  this.$parent.Form.amount - this.referenceTotal;
                }
            }

            // if(this.referenceTotal > this.$parent.Form.particularsData.amount){
            // if(this.referenceTotal > this.$parent.Form.amount){
            // 	this.showToast("Reference total amount shouldn't greater than amount");
            // 	this.showToast("Please adjust references as per amount");
            // }
        },

        fn_openSheet: function(){
            this.confirmSheet = true;
        }
    },
    computed:{
        filteredItems: function () {
            let searchText = this.searchValue.toLowerCase();

            if(this.pendingInvoices && this.pendingInvoices.length){

                return this.pendingInvoices.filter(item => {
                  // let name = item.name.toLowerCase();
                  let keyword = searchText.toLowerCase();

                  // let transactionNumberString = item.baseTransactionNumber ? item.baseTransactionNumber.toLowerCase() : '';
                  let transactionNumberString = ''

                  if(item.baseTransactionNumber){
                      transactionNumberString = item.baseTransactionNumber.toLowerCase();
                  }
                  else if(item.baseRefNumber){
                      transactionNumberString = item.baseRefNumber.toLowerCase();	
                  }

                  return transactionNumberString.match(keyword);
                });
                
            }
            else{
                return [];
            }
        }
        // disableReferences: function(){
        // 	if(this.$parent.Form.particularsData.amount == this.referenceTotal){
        // 		return true;
        // 	}
        // 	else{
        // 		return false;
        // 	}
        // }
     //    referenceTotal: function(){
        // 	let total = 0;

        // 	if(this.filteredItems && this.filteredItems.length){
        // 		for(let i=0; i<this.filteredItems.length; i++){

        // 			let prefix = this.getPrefix(this.filteredItems[i].transactionListId);

        // 			if(this.filteredItems[i].amount){

        // 				if(prefix=='minus'){
        // 					total -= this.filteredItems[i].amount;
        // 				}
        // 				else{
        // 					total += this.filteredItems[i].amount;
        // 				}

        // 			}
        // 		}
        // 	}
            
        // 	return this.roundUpto2Decimals(total);
        // }
    }
}
</script>