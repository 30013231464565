<template>
    <div>
        <!-- <v-dialog v-model="against_reference" no-click-animation persistent scrollable fullscreen content-class="full-height" lazy transition="dialog-bottom-transition"> -->
        <v-dialog v-model="against_reference" content-class="half-screen-dialog" no-click-animation scrollable persistent transition="slide-y-transition" :max-width="$root.fn_getActionPaneWidth()">
            <v-card flat>
                <!-- <v-toolbar :height="56" flat class="noprint">
                    <v-btn icon @click="fn_closeDialog"><v-icon>arrow_back</v-icon></v-btn>
                    <v-toolbar-title>Against Reference</v-toolbar-title>
                </v-toolbar> -->

                <v-toolbar :height="56" flat extended class="noprint">
                    <v-btn icon @click="closeDialogWeb"><v-icon>arrow_back</v-icon></v-btn>
                  <v-toolbar-title>Pending Bills/Invoices</v-toolbar-title>
                  <v-spacer></v-spacer>
                  <v-btn small :disabled="disableBtn" color="primary" text @click="fn_saveFilledReferences">Done</v-btn>

                  <v-menu>

                    <template v-slot:activator="{ on, attrs }">
                    <v-btn v-bind="attrs" v-on="on" icon>
                        <v-icon font20>more_vert</v-icon>
                    </v-btn>
                    </template>


                    <v-list two-line dense nopadding>
                        <v-list-item @click="$parent.fn_markClear(true)">
                            <v-list-item-content>
                                <v-list-item-title>Close Pending Bill/Invoice</v-list-item-title>
                                <v-list-item-subtitle>Remove Bill/Invoice from Pending List</v-list-item-subtitle>
                            </v-list-item-content>
                        </v-list-item>
                        <!-- <v-list-item @click="$parent.fn_markClear(true)">
                            <v-list-item-content>
                                <v-list-item-title>Close Pending Bill/Invoice</v-list-item-title>
                                <v-list-item-subtitle>Remove Bill/Invoice from Pending List</v-list-item-subtitle>
                            </v-list-item-content>
                        </v-list-item> -->
                        <!-- <v-list-item @click="$parent.fn_markClear(false)">
                            <v-list-item-content>
                                <v-list-item-title>Reopen Bills/Invoices</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item> -->
                    </v-list>

                    </v-menu>

                    <v-text-field grey-back clearable flat solo hide-details placeholder="Search..." v-model="searchValue" slot="extension"></v-text-field>
                  <!-- <v-text-field  flat solo placeholder="Search..." v-model="searchValue" slot="extension" ></v-text-field> -->
                </v-toolbar>

                <v-divider></v-divider>


                <v-card-text nopadding full-height id="scrollable-div">

                    <v-layout ma-0 pt-2 pb-2 row style="border-bottom:10px #F0F0F0 solid">
                          <v-flex xs6 sm6 md6 pl-3>On Account</v-flex>
                        <v-flex xs6 sm6 md6 pr-3 right-align>&#8377; {{onAccount ? $root.numberToLocale(onAccount) : 0}}</v-flex>
                    </v-layout>

                    <v-form data-vv-scope="fields" full-width>

                        <!-- <v-layout row wrap white-background class="amount-item" style="padding:16px;border-bottom:10px #F0F0F0 solid">

                            <v-flex xs8 sm8 md8 style="display:flex;align-items:flex-end;">
                                  <span>On Account (&#8377;)</span>
                              </v-flex>
                              <v-flex xs4 sm4 md4 right-align custom-text-field>
                                  <v-text-field
                                      style="margin-top:6px;"
                                      nomargin
                                      hide-details
                                    @input="fn_referenceTotal"
                                      v-model.number="onAccount" 
                                      label="" 
                                      placeholder=" " 
                                      type="number" 
                                      v-validate="'decimal:2|min_value:0'" 
                                       data-vv-name="onaccount" 
                                       data-vv-as="on account"
                                       :error-messages="errors.collect('fields.onaccount')">
                                  </v-text-field>
                              </v-flex>
                              <v-flex xs12 sm12 md12 mt-2 v-if="errors.has('fields.onaccount')">
                                    <div class="error--text font-small pa-2 grey-back full-width">
                                        {{ errors.first('fields.onaccount') }}
                                    </div>
                              </v-flex>
                        </v-layout> -->

                        <!-- <v-checkbox class="select-all-reference" label="Select All" v-model="selectAllRef" @change="fn_selectAllReferences" margin-top-ten style="padding: 0px 16px;border-bottom: 1px #F0F0F0 solid;color: rgba(0,0,0,0.72);" v-if="filteredItems && filteredItems.length"></v-checkbox> -->
                        
                        <ValidationObserver ref="observer">
                            <div class="pt-2 pr-4" v-for="(item, index) in filteredItems" :key="index">
                            
                                <v-checkbox v-model="item.selected" style="float:left;padding-left:16px;margin:0px;" @change="fn_autoFillAmount($event, item)"></v-checkbox>
                                <div class="reference-items" style="padding-left:56px !important">
                                    <v-layout ma-0 wrap>
                                        <v-flex xs8 sm8 md8>
                                            <!-- <span>{{item.prefix}}/{{item.baseTransactionNumber}}{{item.suffix ? '/'+item.suffix : ''}}</span> 
                                            -->
                                            <span v-if="item.baseTransactionNumber">{{item.baseTransactionNumber}}</span>
                                            <span v-else>{{item.baseRefNumber ? item.baseRefNumber : ''}}</span>
                                        </v-flex>
                                        <v-flex xs4 sm4 md4 right-align>
                                            <span>{{item.transactionDate | moment("DD-MMM-YYYY")}}</span>
                                        </v-flex>
                                    </v-layout>

                                    <v-layout wrap>
                                        <v-flex xs8 sm8 md8>
                                            <span>Bill Amount</span>
                                        </v-flex>
                                        <v-flex xs4 sm4 md4 right-align>
                                            <span>&#8377; {{$root.numberToLocale(item.billAmount)}}</span>
                                        </v-flex>
                                    </v-layout>

                                    <v-layout wrap>
                                        <v-flex xs8 sm8 md8>
                                            <span>Outstanding Amount</span>
                                        </v-flex>
                                        <v-flex xs4 sm4 md4 right-align>
                                            <span>&#8377; {{$root.numberToLocale(item.outStandingAmount)}}</span>
                                        </v-flex>
                                    </v-layout>

                                    <v-layout wrap class="font-weight-bold amount-item">
                                        <v-flex xs10 sm10 md10>
                                            <span>Amount (&#8377;)</span>
                                        </v-flex>
                                        <v-flex xs2 sm2 md2 right-align custom-text-field>
                                            <!-- <v-text-field 
                                                hide-details
                                                :prefix="getPrefix(item.transactionListId) == 'minus' ? '(-)' : ''"
                                                @input="fn_referenceTotal"
                                                v-model.number="item.amount" 
                                                label="" 
                                                placeholder=" " 
                                                type="number" 
                                                v-validate="'min_value:1|decimal:2|max_value:'+item.maxValue" 
                                                :data-vv-name="'amount'+index" 
                                                data-vv-as="amount"
                                                :error-messages="errors.collect('fields.amount'+index)">
                                                    
                                            </v-text-field> -->
                                            <ValidationProvider :name="'amount' + index" :rules="'min_value:0|decimal2|max_value:'+item.maxValue" v-slot="{ errors }">
                                                <v-text-field 
                                                    class="pa-0 ma-0 right-text-field"
                                                    :prefix="getPrefix(item.transactionListId) == 'minus' ? '(-)' : ''"
                                                    @input="fn_changeAmount(item)"
                                                    v-model.number="item.amount" 
                                                    :error-messages="errors"
                                                    label="" 
                                                    placeholder=" " 
                                                    type="number"
                                                    step="0.01">
                                                </v-text-field>
                                            </ValidationProvider>

                                        </v-flex>
                                    </v-layout>

                                    <!-- <div class="font14" style="margin-top:-8px;padding-bottom: 4px;" :class="[item.dueDays > 0 ? 'redcolor' : 'greencolor']">
                                        <span v-if="item.dueDays == 0">Due today</span>
                                        <span v-if="item.dueDays > 0">Due {{item.dueDays}} days</span>
                                        <span v-else-if="item.dueDays < 0">Due in {{item.dueDays}} days</span>
                                    </div> -->

                                </div>

                              <!-- <div class="error--text font-small pa-2 grey-back full-width" v-if="errors.has('fields.amount'+index)">
                                    {{ errors.first('fields.amount'+index) }}
                                </div> -->

                                <v-divider v-if="filteredItems.length-1!=index"></v-divider>
                              
                            </div>
                        </ValidationObserver>

                    </v-form>

                    <div class="pa-4 center-align light-opacity-color" v-if="!filteredItems.length">There is no reference item</div>

                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                    Total
                    <v-spacer></v-spacer>
                    <template v-if="onAccount">&#8377; {{ $root.numberToLocale(referenceTotal + onAccount) }}</template>
                    <template v-else>&#8377; {{ $root.numberToLocale(referenceTotal)}}</template>
                      <!-- &#8377; {{$root.numberToLocale(referenceTotal) + onAccount ? onAccount : 0}} -->
                </v-card-actions>
            </v-card>
          </v-dialog>
    </div>
</template>

<script>

import { max, min_value, max_value } from 'vee-validate/dist/rules'
import { extend, ValidationProvider, ValidationObserver } from 'vee-validate'

extend('max', max);

extend('decimal2', value => {

  let decimalCount = 0;
  if(Math.floor(value) != value){
     decimalCount = value.toString().split(".")[1].length || 0;
  }

  if(decimalCount <= 2){
    return true;
  }

  return 'This field must be numeric and may contain 2 decimal points.';

});

extend('min_value', {
  ...min_value,
  message: 'This field must not be negative.',
})

extend('max_value', {
  ...max_value,
  message: 'This field must less than or equal to outstanding amount.',
})



export default{
    props:['references', 'saveFilledReferences', 'getPrefix'],
    components:{
        // 'gstr-sub-report':gstrSubReport
        ValidationProvider,
        ValidationObserver
    },
    data(){
        return{
            against_reference: false,
            searchValue:'',
            disableBtn:false,
            // onAccount:null,
            // selectAllRef:null,
            referenceTotal:0,
            hasAmountFilled:false,
            onAccount:0
            // isEmptyAmountField:true
        }
    },
    methods:{
        openDialogWeb: function () {
            this.against_reference = true;
            this.onAccount = null;
            // if(!this.$parent.Form.particularsData.onAccount && !this.$parent.Form.particularsData.references.length){
            // 	this.onAccount = this.$parent.Form.particularsData.amount;
            // }
            // else{
            // 	this.onAccount = this.$parent.Form.particularsData.onAccount;
            // }

            // making textbox empty and checkbox unchecked if no references save yet
            setTimeout(() => {
                this.fn_referenceTotal();
            }, 500);

            this.searchValue = '';
            // this.$validator.reset();
        },
        closeDialogWeb: function () {
            this.against_reference = false;
        },
        onScroll: function () {
            let elements = document.getElementsByClassName('error--text');
            let scrollableDiv = document.getElementById('scrollable-div');
            scrollableDiv.scrollTop = elements[0].offsetTop - 120;
        },
        fn_saveFilledReferences: function () {
            if(this.referenceTotal<=0){
                if(this.hasAmountFilled){
                    this.showToast("Total should not be negative or zero");
                }
                else{
                    this.closeDialogWeb();	
                }
                return;
            }

            this.disableBtn = true;
            // this.$validator.validateAll('fields').then((result) => {
            this.$refs.observer.validate().then((result) => {
                if(result){
                    if(!this.onAccount){
                        this.$parent.Form.amount = this.referenceTotal;
                        this.$parent.Form.particularsData.onAccount = null;
                        this.$parent.fn_autoFillPaymentMode(this.$parent.Form.amount, true);
                    }
                    else{
                        if(this.referenceTotal){
                            this.$parent.Form.particularsData.onAccount = this.roundUpto2Decimals(this.$parent.Form.amount - this.referenceTotal);
                        }
                    }

                    this.saveFilledReferences();
                }
                else{
                    this.disableBtn = false;
                }

            });
        },
        // fn_selectAllReferences: function(val){
        // 	for(let i in this.filteredItems){
        // 		val ? this.filteredItems[i].amount = this.filteredItems[i].outStandingAmount : this.filteredItems[i].amount = null;
        // 	}
        // 	this.fn_referenceTotal();
        // },

        fn_autoFillAmount: function(selected, item){

            // if(item.amount == item.outStandingAmount){
            // let remainingAmount = this.$parent.Form.particularsData.amount - this.referenceTotal;
            // let remainingAmount = this.$parent.Form.amount - this.referenceTotal;

            if(!selected){
                item.amount = null;
                // this.selectAllRef = false;
            }
            else{
                // if(this.referenceTotal < this.$parent.Form.particularsData.amount){
                // if(this.referenceTotal < this.$parent.Form.amount){
                // 	if(item.outStandingAmount > remainingAmount){
                // 		item.amount = remainingAmount;
                // 	}
                // 	else{
                // 		item.amount = item.outStandingAmount;
                // 	}
                // }
                // else{
                // 	item.amount = item.outStandingAmount;
                // }

                if(!this.referenceTotal || this.referenceTotal < this.$parent.Form.amount || !this.$parent.Form.amount){
                    if(this.$parent.Form.amount){
                        // let onaccount = this.onAccount ? this.onAccount : 0;
                        if(this.referenceTotal < this.$parent.Form.amount){
                            if(item.outStandingAmount >= Number(this.$parent.Form.amount - this.referenceTotal)){
                                item.amount = this.roundUpto2Decimals(Number(this.$parent.Form.amount - this.referenceTotal)).toFixed(2);
                            }
                            else{
                                item.amount = this.roundUpto2Decimals(item.outStandingAmount).toFixed(2);
                            }
                        }
                        // else{
                        // 	item.amount = item.outStandingAmount;
                        // }
                    }
                    else{
                        item.amount = this.roundUpto2Decimals(item.outStandingAmount).toFixed(2);
                    }
                }
                
                
            }

            this.fn_referenceTotal();
        },
        // fn_changeAmount: function(item){
        // 	if(item.amount == item.outStandingAmount)
        // 		item.autoFill = true;
        // 	else
        // 		item.autoFill = false;

        // 	this.fn_referenceTotal();
        // },

        fn_changeAmount: function(item){
			if(item.amount){
				item.selected = true;
			}
			else{
				item.selected = false;
			}
			this.fn_referenceTotal()
		},

        fn_referenceTotal: function(){
            let referenceTotal = 0;
            this.hasAmountFilled = false;
            // this.isEmptyAmountField = true;

            if(this.filteredItems && this.filteredItems.length){
                for(let i=0; i<this.filteredItems.length; i++){

                    let prefix = this.getPrefix(this.filteredItems[i].transactionListId);

                    if(this.filteredItems[i].amount){

                        this.hasAmountFilled = true;

                        // calculating reference total
                        if(prefix=='minus'){
                            referenceTotal -= Number(this.filteredItems[i].amount);
                        }
                        else{
                            referenceTotal += Number(this.filteredItems[i].amount);
                        }

                    }
                }
            }

            // let onAccount = 0;

            // if(this.onAccount){
            // 	onAccount = this.onAccount;
            // 	this.isEmptyAmountField = false;
            // }
            
            this.referenceTotal = this.roundUpto2Decimals(referenceTotal);

            if(this.$parent.Form.amount){
                if(this.$parent.Form.amount > this.referenceTotal){
                    this.onAccount =  this.$parent.Form.amount - this.referenceTotal;
                }
                else{
                    this.onAccount = null;
                }

                if(this.referenceTotal < 0){
                    this.onAccount = null;
                }
                // if(this.referenceTotal > this.$parent.Form.amount){
                // 	this.onAccount = this.referenceTotal;
                // }
                // else{
                // 	this.onAccount =  this.$parent.Form.amount - this.referenceTotal;
                // }
            }

            // if(this.referenceTotal > this.$parent.Form.particularsData.amount){
            // if(this.referenceTotal > this.$parent.Form.amount){
            // 	this.showToast("Reference total amount shouldn't greater than amount");
            // 	this.showToast("Please adjust references as per amount");
            // }
        }
    },
    computed:{
        filteredItems: function () {
            let searchText = this.searchValue.toLowerCase();

            if(this.references && this.references.length){

                return this.references.filter(item => {
                  // let name = item.name.toLowerCase();
                  let keyword = searchText.toLowerCase();

                  // let transactionNumberString = item.baseTransactionNumber ? item.baseTransactionNumber.toLowerCase() : '';
                  let transactionNumberString = ''

                  if(item.baseTransactionNumber){
                      transactionNumberString = item.baseTransactionNumber.toLowerCase();
                  }
                  else if(item.baseRefNumber){
                      transactionNumberString = item.baseRefNumber.toLowerCase();	
                  }

                  return transactionNumberString.match(keyword);
                });
                
            }
            else{
                return [];
            }
        }
        // disableReferences: function(){
        // 	if(this.$parent.Form.particularsData.amount == this.referenceTotal){
        // 		return true;
        // 	}
        // 	else{
        // 		return false;
        // 	}
        // }
     //    referenceTotal: function(){
        // 	let total = 0;

        // 	if(this.filteredItems && this.filteredItems.length){
        // 		for(let i=0; i<this.filteredItems.length; i++){

        // 			let prefix = this.getPrefix(this.filteredItems[i].transactionListId);

        // 			if(this.filteredItems[i].amount){

        // 				if(prefix=='minus'){
        // 					total -= this.filteredItems[i].amount;
        // 				}
        // 				else{
        // 					total += this.filteredItems[i].amount;
        // 				}

        // 			}
        // 		}
        // 	}
            
        // 	return this.roundUpto2Decimals(total);
        // }
    }
}
</script>