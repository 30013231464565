// const transactionTypes = require('../../config.js').configs.transactionTypes

export const data = new Object({
    101:{
        reportTitle:"Item Ledger",
        itemLabel:"Item",
        openDialog:true,
        // headerRow: {"col1":"Number","col2":"Date","col3":"Voucher","col4":"Party","col5":"MRP","col6":"Inward","col7":"Outward","col8":"Balance"},
        // columns:['col1', 'col2', 'col3', 'col4', 'col5', 'col6', 'col7', 'col8'],
        headerRow: {"mergeTransactionNumber":"Number","transactionDate":"Date","transactionListId":"Voucher","party":"Party","mrp":"MRP","inward":"Inward","outward":"Outward","balance":"Balance"},
        headerRowTableHeaders: [{"headerName":"Number", "type":"string"},{"headerName":"Date","type":"date"},{"headerName":"Voucher", "type":"string"},{"headerName":"Party", "type":"string"},{"headerName":"MRP","type":"number"},{"headerName":"Inward", "type":"number"},{"headerName":"Outward","type":"number"},{"headerName":"Balance", "type":"number"}],
        columns:['mergeTransactionNumber', 'transactionDate', 'transactionListId', 'party', 'mrp', 'inward', 'outward', 'balance'],
         // tileViewFooterHeaders:{"inward":"Inward","outward":"Outward","balance":"Balance"},
        tileViewFooterHeaders:{"opening":"Opening", "actualInwardValue":"Inward","actualOutwardValue":"Outward","balance":"Balance"},
        tileContent:`<div>

            <v-list three-line nopadding>

                <div v-for="(item, index) in propsObj.reportData.detailRows" :key="index">
                    <v-list-tile avatar class="right-subtitle-tile">

                        <v-list-tile-content @click="fn_getDetails(item)">
                            <v-list-tile-title class="mb-1">{{item.cols.party ? item.cols.party : ''}}</v-list-tile-title>

                            <v-list-tile-sub-title class="mb-1">{{item.cols.transactionDate | moment("DD-MMM-YYYY")}}</v-list-tile-sub-title>

                            <v-list-tile-sub-title v-if="item.cols.mergeTransactionNumber || item.cols.transactionListId">{{item.cols.mergeTransactionNumber ?  item.cols.mergeTransactionNumber + ' | ' : ''}}{{item.cols.transactionListId}}</v-list-tile-sub-title>
                        </v-list-tile-content>

                        <v-list-tile-action align-flex-end>

                            <v-btn icon text @click="fn_expandMore(item)">
                                <v-icon>more_horiz</v-icon>
                            </v-btn>

                            <div>

                                <v-icon font18 v-if="index!=0 && (item.cols.inward || item.cols.outward)" style="padding-bottom:1px;">
                                    {{item.cols.outward ? 'arrow_upward' : 'arrow_downward'}}
                                </v-icon>

                                <span v-if="index==0">{{item.cols.inward ? item.cols.inward : 0}} Units</span>
                                <span v-if="index!=0 && item.cols.inward">{{item.cols.inward}} Units</span>
                                <span v-if="item.cols.outward">{{item.cols.outward}} Units</span>
                            </div>
                        </v-list-tile-action>
                    </v-list-tile>

                    <v-divider v-if="propsObj.reportData.detailRows.length-1 != index"></v-divider>
                </div>
            </v-list>

        </div>`,

        footerContent:`<div>
            <v-subheader subheader-semi-dense font16>Total 
              <v-spacer></v-spacer>
              {{propsObj.reportData.footerRow.balance ? propsObj.reportData.footerRow.balance : 0}} Units
              <v-icon>{{!$parent.$parent.tileViewTotalSheet ? 'arrow_drop_up' : 'arrow_drop_down'}}</v-icon>
            </v-subheader>
        </div>`,
        detailReportId:null,
        period:'month',
        periodLabel:'Period',
        reportType:'general',
        filters: null,
        openView:true
    },

    102:{
        reportTitle:"Stock Summary",
        itemLabel:null,
        openDialog:false,
        headerRow: {"productName":"Product Name","categoryName":"Category","brand":"Brand","mrp":"MRP","dqty":"Qty", "unitName":"Unit", "averageRate":"Rate","value":"Value"},
        headerRowTableHeaders: [{"headerName":"Product Name", "value":"productName", "type":"string"},{"headerName":"Category", "value":"categoryName", "type":"string"},{"headerName":"Brand", "value":"brand", "type":"string"},{"headerName":"MRP", "value":"mrp", "type":"number"},{"headerName":"Qty", "value":"dqty", "type":"number"},{"headerName":"Unit", "value":"unitName", "type":"string"},{"headerName":"Rate", "value":"averageRate", "type":"number"},{"headerName":"Value", "value":"value", "type":"number"}],
        columns:['productName', 'categoryName', 'brand', 'mrp', 'dqty', 'unitName', 'averageRate', 'value'],
        tileViewFooterHeaders:{"dqty":"Qty (In Units)","value":"Value"},

        tileContent:`<div>

            <v-list two-line nopadding>

                <div v-for="(item, index) in propsObj.reportData.detailRows" :key="index">
                    <v-list-tile class="right-subtitle-tile">

                        <v-list-tile-content @click="fn_getDetails(item)">
                            <v-list-tile-title class="mb-1">{{item.cols.productName}}</v-list-tile-title>

                            <v-list-tile-sub-title>
                                {{$root.numberToLocale(item.cols.dqty)}} {{item.cols.unitName}} X &#8377; {{$root.numberToLocale(item.cols.averageRate)}}
                            </v-list-tile-sub-title>
                        </v-list-tile-content>

                        <v-list-tile-action align-flex-end>

                            <v-btn icon text @click="fn_expandMore(item)">
                                <v-icon>more_horiz</v-icon>
                            </v-btn>

                            <div class="light-opacity-color">&#8377; {{$root.numberToLocale(item.cols.value)}}</div>
                        </v-list-tile-action>
                    </v-list-tile>

                    <v-divider v-if="propsObj.reportData.detailRows.length-1 != index"></v-divider>
                </div>
            </v-list>

        </div>`,

        footerContent:`<div>

            <v-layout row wrap pt-2 pb-2 pl-3 pr-3 font12>
                <v-flex xs4 sm4 md4>Total</v-flex>
                <v-flex xs8 sm8 md8 right-align>&#8377; {{$root.numberToLocale(propsObj.reportData.footerRow.value)}}</v-flex>
            </v-layout>

            <!--<v-subheader subheader-semi-dense font16>Total Value 
              <v-spacer></v-spacer>
              &#8377; {{$root.numberToLocale(propsObj.reportData.footerRow.value)}}
              <v-icon>{{!$parent.$parent.tileViewTotalSheet ? 'arrow_drop_up' : 'arrow_drop_down'}}</v-icon>
            </v-subheader>-->
        </div>`,
        detailReportId:null,
        period:'endDate',
        periodLabel:'As On',
        // period:'month',
        // periodLabel:'Period',
        reportType:'general',
        filters: null,
    },

    103:{
        reportTitle:"Item Progress",
        itemLabel:null,
        openDialog:false,
        headerRow: {"productName":"Product Name","categoryName":"Category","opening":"Opening","inward":"Inward","outward":"Outward","unitName":"Unit","closing":"Closing"},
        headerRowTableHeaders: [{"headerName":"Product Name", "type":"string"},{"headerName":"Category","type":"string"},{"headerName":"Opening", "type":"string"},{"headerName":"Inward","type":"number"},{"headerName":"Outward","type":"number"},{"headerName":"Unit","type":"string"},{"headerName":"Closing", "type":"number"}],
        columns:['productName', 'categoryName', 'opening', 'inward', 'outward', 'unitName', 'closing'],
        tileViewFooterHeaders:{"opening":"Opening", "inward":"Inward","outward":"Outward","closing":"Closing"},
        tileContent:`<div>

            <v-list three-line nopadding>

                <div v-for="(item, index) in propsObj.reportData.detailRows" :key="index">
                    <v-list-tile class="right-subtitle-tile">

                        <v-list-tile-content @click="fn_getDetails(item)">
                            <v-list-tile-title class="mb-1">{{item.cols.productName}}</v-list-tile-title>

                            <v-list-tile-sub-title>Opening: {{item.cols.opening ? item.cols.opening : '0'}} {{item.cols.unitName}}</v-list-tile-sub-title>
                            <v-list-tile-sub-title>
                                <template v-if="item.cols.inward">
                                    <v-icon class="greencolor font16">arrow_downward</v-icon> {{item.cols.inward}} {{item.cols.unitName}}
                                </template>
                                <template v-if="item.cols.outward">
                                    <v-icon class="redcolor font16">arrow_upward</v-icon> {{item.cols.outward}} {{item.cols.unitName}}
                                </template>
                            </v-list-tile-sub-title>
                        </v-list-tile-content>

                        <v-list-tile-action align-flex-end>

                            <v-btn icon text @click="fn_expandMore(item)">
                                <v-icon>more_horiz</v-icon>
                            </v-btn>

                            <div class="light-opacity-color">
                                {{item.cols.closing ? item.cols.closing : 0}} {{item.cols.unitName}}
                            </div>
                        </v-list-tile-action>
                    </v-list-tile>

                    <v-divider v-if="propsObj.reportData.detailRows.length-1 != index"></v-divider>
                </div>

            </v-list>

        </div>`,
        detailReportId:null,
        period:'month',
        periodLabel:'Period',
        reportType:'general',
        filters: null,
    },

    201:{
        reportTitle:"Ledger",
        itemLabel:"Party",
        openDialog:true,
        headerRow: {"mergeTransactionNumber":"Number","transactionDate":"Date","refNumber":"Bill No","refDate":"Bill Date","transactionListId":"Voucher","party":"Party","debit":"To Receive","credit":"To Pay","partyBalance":"Balance","creditDebitType":"","notes":"Notes"},
        headerRowTableHeaders: [{"headerName":"Number", "type":"string"},{"headerName":"Date","type":"date"},{"headerName":"Bill No", "type":"string"},{"headerName":"Bill Date", "type":"date"},{"headerName":"Voucher", "type":"string"},{"headerName":"Party","type":"string"},{"headerName":"To Receive","type":"number"},{"headerName":"To Pay","type":"number"},{"headerName":"Balance", "type":"number"},{"headerName":"", "type":"string"},{"headerName":"Notes", "type":"string"}],
        columns:['mergeTransactionNumber', 'transactionDate', 'refNumber', 'refDate', 'transactionListId', 'party', 'debit', 'credit', 'partyBalance', 'creditDebitType', 'notes'],
        // tileViewFooterHeaders:{"debit":"Debit","credit":"Credit","balance":"Balance"},
        tileViewFooterHeaders:{"openingValue":"Opening", "openingCreditDebitType":"-", "actualDebitValue":"To Receive", "actualCreditValue":"To Pay", "partyBalance":"Balance"},
        tileContent:`<div>

            <v-list three-line nopadding>

                <div v-for="(item, index) in propsObj.reportData.detailRows" :key="index">
                    <v-list-tile avatar class="right-subtitle-tile">

                        <v-list-tile-avatar>
                            <v-icon class="grey-back">{{item.cols.debit ? 'call_received' : 'call_made'}}</v-icon>
                        </v-list-tile-avatar>

                        <v-list-tile-content @click="fn_getDetails(item)">
                            <v-list-tile-title class="mb-1">{{item.cols.party}}</v-list-tile-title>

                            <v-list-tile-sub-title class="mb-1">{{item.cols.transactionDate | moment("DD-MMM-YYYY")}}</v-list-tile-sub-title>

                            <v-list-tile-sub-title v-if="item.cols.mergeTransactionNumber || item.cols.transactionListId">{{item.cols.mergeTransactionNumber}} | {{item.cols.transactionListId}}</v-list-tile-sub-title>
                        </v-list-tile-content>

                        <v-list-tile-action align-flex-end>

                            <v-btn icon text @click="fn_expandMore(item)">
                                <v-icon>more_horiz</v-icon>
                            </v-btn>

                            <div class="light-opacity-color">
                                &#8377; {{item.cols.debit ? $root.numberToLocale(item.cols.debit) : item.cols.credit ? $root.numberToLocale(item.cols.credit) : 0}}
                            </div>
                        </v-list-tile-action>
                    </v-list-tile>

                    <v-divider v-if="propsObj.reportData.detailRows.length-1 != index"></v-divider>
                </div>
            </v-list>
        </div>`,

        footerContent:`<div>
            <v-subheader subheader-semi-dense font16>Balance 
              <v-spacer></v-spacer>
              &#8377; {{$root.numberToLocale(propsObj.reportData.footerRow.partyBalance)}}
              <v-icon>{{!$parent.$parent.tileViewTotalSheet ? 'arrow_drop_up' : 'arrow_drop_down'}}</v-icon>
            </v-subheader>
        </div>`,
        detailReportId:2011,
        period:'month',
        periodLabel:'Period',
        reportType:'general',
        filters: null,
    },

    202:{
        reportTitle:"Day Book",
        itemLabel:null,
        openDialog:false,
        headerRow: {"transactionListId":"Voucher","transactionDate":"Date","mergeTransactionNumber":"Number","party":"Party","amount":"Amount","notes":"Notes"},
        headerRowTableHeaders: [{"headerName":"Voucher", "type":"string"},{"headerName":"Date","type":"date"},{"headerName":"Number", "type":"string"},{"headerName":"Party","type":"string"},{"headerName":"Amount", "type":"number"},{"headerName":"Notes","type":"string"}],
        columns:['transactionListId', 'transactionDate', 'mergeTransactionNumber', 'party', 'amount', 'notes'],
        tileViewFooterHeaders:{"amount":"Amount"},
        tileContent:`<div>

            <v-list three-line nopadding>

                <div v-for="(item, index) in propsObj.reportData.detailRows" :key="index">
                    <v-list-tile class="right-subtitle-tile">

                        <v-list-tile-content @click="fn_getDetails(item)">
                            <v-list-tile-title class="mb-1">{{item.cols.party}}</v-list-tile-title>

                            <v-list-tile-sub-title class="mb-1">{{item.cols.transactionDate | moment("DD-MMM-YYYY")}}</v-list-tile-sub-title>

                            <v-list-tile-sub-title>{{item.cols.mergeTransactionNumber ? item.cols.mergeTransactionNumber + ' | ' : ''}}{{item.cols.transactionListId}}</v-list-tile-sub-title>
                        </v-list-tile-content>

                        <v-list-tile-action align-flex-end>

                            <v-btn icon text @click="fn_expandMore(item)">
                                <v-icon>more_horiz</v-icon>
                            </v-btn>

                            <div class="light-opacity-color">
                                <span>&#8377; {{$root.numberToLocale(item.cols.amount)}}</span>
                            </div>
                        </v-list-tile-action>
                    </v-list-tile>

                    <v-divider v-if="propsObj.reportData.detailRows.length-1 != index"></v-divider>
                </div>
            </v-list>
        </div>`,

        footerContent:`<div>
            <v-subheader subheader-semi-dense font16>Total 
              <v-spacer></v-spacer>
              &#8377; {{$root.numberToLocale(propsObj.reportData.footerRow.amount)}}
            </v-subheader>
        </div>`,
        detailReportId:null,
        period:'currentDate',
        periodLabel:'Period',
        reportType:'general',
        filters: null,
        openView:true
    },

    203:{
        reportTitle:"Collection Summary",
        itemLabel:null,
        openDialog:false,
        headerRow: {"transactionDate":"Date","mergeTransactionNumber":"Number","transactionListId":"Voucher","party":"Party","type":"Type","accountName":"Account Name","amount":"Amount"},
        headerRowTableHeaders: [{"headerName":"Date","type":"date"},{"headerName":"Number", "type":"string"},{"headerName":"Voucher", "type":"string"},{"headerName":"Party","type":"string"},{"headerName":"Type","type":"string"},{"headerName":"Account Name", "type":"string"},{"headerName":"Amount","type":"number"}],
        columns:['transactionDate', 'mergeTransactionNumber', 'transactionListId', 'party', 'type', 'accountName', 'amount'],
        tileViewFooterHeaders:{"amount":"Amount"},
        tileContent:`<div>

            <v-list three-line nopadding>

                <div v-for="(item, index) in propsObj.reportData.detailRows" :key="index">
                    <v-list-tile class="right-subtitle-tile">

                        <v-list-tile-content @click="fn_getDetails(item)">
                            <v-list-tile-title class="mb-1">{{item.cols.party}}</v-list-tile-title>

                            <v-list-tile-sub-title class="mb-1">{{item.cols.transactionDate | moment("DD-MMM-YYYY")}}</v-list-tile-sub-title>

                            <v-list-tile-sub-title>{{item.cols.mergeTransactionNumber}} | {{item.cols.transactionListId}}</v-list-tile-sub-title>
                        </v-list-tile-content>

                        <v-list-tile-action align-flex-end>

                            <v-btn icon text @click="fn_expandMore(item)">
                                <v-icon>more_horiz</v-icon>
                            </v-btn>

                            <div class="light-opacity-color">
                                &#8377; {{$root.numberToLocale(item.cols.amount)}}
                            </div>
                        </v-list-tile-action>
                    </v-list-tile>

                    <v-divider v-if="propsObj.reportData.detailRows.length-1 != index"></v-divider>
                </div>
            </v-list>

        </div>`,
        footerContent:`<div>
            <v-subheader subheader-semi-dense font16>Total 
              <v-spacer></v-spacer>
              &#8377; {{$root.numberToLocale(propsObj.reportData.footerRow.amount)}}
            </v-subheader>
        </div>`,
        detailReportId:null,
        period:'month',
        periodLabel:'Period',
        reportType:'general',
        filters: null,
        openView:true
    },

    301:{
        reportTitle:"Itemwise Sale",
        itemLabel:null,
        openDialog:false,
        headerRow: {"productName":"Product Name","qty":"Qty", "unitName":"Unit", "value":"Value","category":"Category","brand":"Brand"},
        headerRowTableHeaders: [{"headerName":"Product Name", "type":"string"},{"headerName":"Qty", "type":"number"},{"headerName":"Unit","type":"string"},{"headerName":"Value", "type":"number"},{"headerName":"Category","type":"string"},{"headerName":"Brand","type":"string"}],
        columns:['productName', 'qty', 'unitName', 'value', 'category', 'brand'],
        tileViewFooterHeaders:{"qty":"Qty", "value":"Value"},
        tileContent:`<div>

            <v-list two-line nopadding>

                <div v-for="(item, index) in propsObj.reportData.detailRows" :key="index">
                    <v-list-tile class="right-subtitle-tile">

                        <v-list-tile-content @click="fn_getDetails(item)">
                            <v-list-tile-title class="mb-1">{{item.cols.productName}}</v-list-tile-title>
                            <v-list-tile-sub-title>{{$root.numberToLocale(item.cols.qty)}} {{item.cols.unitName}}</v-list-tile-sub-title>
                        </v-list-tile-content>

                        <v-list-tile-action align-flex-end>

                            <v-btn icon text @click="fn_expandMore(item)">
                                <v-icon>more_horiz</v-icon>
                            </v-btn>

                            <div class="light-opacity-color">
                                <span>&#8377; {{$root.numberToLocale(item.cols.value)}}</span>
                            </div>
                        </v-list-tile-action>
                    </v-list-tile>

                    <v-divider v-if="propsObj.reportData.detailRows.length-1 != index"></v-divider>
                </div>
            </v-list>

        </div>`,

        footerContent:`<div>
            <v-layout row wrap pt-2 pb-2 pl-3 pr-3 font12>
                <v-flex xs4 sm4 md4>Total</v-flex>
                <v-flex xs8 sm8 md8 right-align>&#8377; {{$root.numberToLocale(propsObj.reportData.footerRow.value)}}</v-flex>
            </v-layout>

            <!--<v-subheader subheader-semi-dense font16>Total 
              <v-spacer></v-spacer>
              &#8377; {{$root.numberToLocale(propsObj.reportData.footerRow.value)}}
              <v-icon>{{!$parent.$parent.tileViewTotalSheet ? 'arrow_drop_up' : 'arrow_drop_down'}}</v-icon>
            </v-subheader>-->
        </div>`,
        detailReportId:null,
        period:'month',
        periodLabel:'Period',
        reportType:'general',
        filters: null,
    },

    302:{
        reportTitle:"Partywise Sale",
        itemLabel:null,
        openDialog:false,
        headerRow: {"party":"Party","taxValue":"Value"},
        headerRowTableHeaders: [{"headerName":"Party", "type":"string"},{"headerName":"Value", "type":"number"}],
        columns:['party', 'taxValue'],
        tileViewFooterHeaders:{"taxValue":"Value"},
        tileContent:`<div>

            <v-list two-line nopadding>

                <div v-for="(item, index) in propsObj.reportData.detailRows" :key="index">

                    <v-subheader class="white-background subheader-semi-dense" v-if="item.cols.isGroup">{{item.cols.party}}</v-subheader>
                    <v-list-tile class="right-subtitle-tile" v-if="!item.cols.isGroup">

                        <v-list-tile-content @click="fn_getDetails(item)">
                            <v-list-tile-title class="mb-1">{{item.cols.party}}</v-list-tile-title>
                        </v-list-tile-content>

                        <v-list-tile-action align-flex-end>

                            <v-btn icon text @click="fn_expandMore(item)">
                                <v-icon>more_horiz</v-icon>
                            </v-btn>

                            <div class="light-opacity-color">
                                <span>&#8377; {{$root.numberToLocale(item.cols.taxValue)}}</span>
                            </div>
                        </v-list-tile-action>
                    </v-list-tile>

                    <v-divider v-if="propsObj.reportData.detailRows.length-1 != index"></v-divider>
                </div>
            </v-list>

        </div>`,

        footerContent:`<div>
            <v-subheader subheader-semi-dense font16>Total 
              <v-spacer></v-spacer>
              &#8377; {{$root.numberToLocale(propsObj.reportData.footerRow.taxValue)}}
            </v-subheader>
        </div>`,
        detailReportId:null,
        period:'month',
        periodLabel:'Period',
        reportType:'general',
        filters: null,
    },

    303:{
        reportTitle:"Sale Tax Summary",
        itemLabel:null,
        openDialog:false,
        headerRow: {"transactionDate":"Date","party":"Party","mergeTransactionNumber":"Number","transactionListId":"Invoice Type","gstNumber":"GSTIN", "grossAmount":"Gross Amount", "discount":"Discount", "taxableValue":"Taxable Value", "cgst":"CGST", "stgst":"SGST/UTGST", "igst":"IGST", "cess":"Cess Amount", "otherCharges":"Other Charges", "roundedOff":"R/O", "roundedAmount":"Net Amount"},
        headerRowTableHeaders: [{"headerName":"Date", "type":"date"},{"headerName":"Party", "type":"string"},{"headerName":"Number", "type":"string"},{"headerName":"Invoice Type", "type":"string"},{"headerName":"GSTIN", "type":"string"},{"headerName":"Gross Amount", "type":"number"},{"headerName":"Discount", "type":"number"},{"headerName":"Taxable Value", "type":"number"},{"headerName":"CGST", "type":"number"},{"headerName":"SGST/UTGST", "type":"number"},{"headerName":"IGST", "type":"number"},{"headerName":"Cess Amount", "type":"number"},{"headerName":"Other Charges", "type":"number"},{"headerName":"R/O", "type":"number"},{"headerName":"Net Amount", "type":"number"}],
        columns:['transactionDate', 'party', 'mergeTransactionNumber', 'transactionListId', 'gstNumber', 'grossAmount', 'discount', 'taxableValue', 'cgst', 'stgst', 'igst', 'cess', 'otherCharges', 'roundedOff', 'roundedAmount'],
        tileViewFooterHeaders:{"roundedAmount":"Net Amount"},
        tileContent:`<div>
            <v-list three-line nopadding>
                <div v-for="(item, index) in propsObj.reportData.detailRows" :key="index">
                    <v-list-tile class="right-subtitle-tile">

                        <v-list-tile-content @click="fn_getDetails(item)">
                            <v-list-tile-title class="mb-1">{{item.cols.party}}</v-list-tile-title>

                            <v-list-tile-sub-title class="mb-1">{{item.cols.transactionDate | moment("DD-MMM-YYYY")}}</v-list-tile-sub-title>

                            <v-list-tile-sub-title>{{item.cols.mergeTransactionNumber}} | {{item.cols.transactionListId}}</v-list-tile-sub-title>
                        </v-list-tile-content>

                        <v-list-tile-action align-flex-end>

                            <v-btn icon text @click="fn_expandMore(item)">
                                <v-icon>more_horiz</v-icon>
                            </v-btn>

                            <div class="light-opacity-color">
                                <span>&#8377; {{$root.numberToLocale(item.cols.tax)}}</span>
                            </div>
                        </v-list-tile-action>
                    </v-list-tile>

                    <v-divider v-if="propsObj.reportData.detailRows.length-1 != index"></v-divider>
                </div>
            </v-list>
        </div>`,

        footerContent:`<div>
            <v-subheader subheader-semi-dense font16>Total 
              <v-spacer></v-spacer>
              &#8377; {{$root.numberToLocale(propsObj.reportData.footerRow.totalTax)}}
            </v-subheader>
        </div>`,
        detailReportId:null,
        period:'month',
        periodLabel:'Period',
        reportType:'general',
        filters: null,
        openView:true
    },

    304:{
        reportTitle:"Delivery Challan",
        itemLabel:null,
        openDialog:false,
        headerRow: {"mergeTransactionNumber":"Number", "transactionDate":"Date","transactionListId":"Voucher","party":"Party","netAmount":"Amount","status":"Status"},
        headerRowTableHeaders: [{"headerName":"Number", "type":"string"},{"headerName":"Date", "type":"date"},{"headerName":"Voucher", "type":"string"},{"headerName":"Party", "type":"string"},{"headerName":"Amount", "type":"number"},{"headerName":"Status", "type":"string"}],
        columns:['mergeTransactionNumber', 'transactionDate', 'transactionListId', 'party', 'netAmount', 'status'],
        tileViewFooterHeaders:{"netAmount":"Amount"},
        tileContent:`<div>

            <v-list three-line nopadding>

                <div v-for="(item, index) in propsObj.reportData.detailRows" :key="index">
                    <v-list-tile class="right-subtitle-tile">

                        <v-list-tile-content @click="fn_getDetails(item)">
                            <v-list-tile-title class="mb-1">{{item.cols.party}}</v-list-tile-title>

                            <v-list-tile-sub-title class="mb-1">
                                {{item.cols.mergeTransactionNumber}} | {{item.cols.transactionDate | moment("DD-MMM-YYYY")}}
                            </v-list-tile-sub-title>

                            <v-list-tile-sub-title>
                                <span :style="{'background':item.cols.status == 'open' ? 'red' : 'green'}" style="padding: 1px 6px;border-radius: 2px;color: #fff;">{{item.cols.status == 'open' ? 'Open' : 'Close'}}</span>
                            </v-list-tile-sub-title>
                        </v-list-tile-content>

                        <v-list-tile-action align-flex-end>

                            <v-btn icon text @click="fn_expandMore(item)">
                                <v-icon>more_horiz</v-icon>
                            </v-btn>

                            <div class="light-opacity-color">
                                <span>&#8377; {{$root.numberToLocale(item.cols.netAmount)}}</span>
                            </div>
                        </v-list-tile-action>
                    </v-list-tile>

                    <v-divider v-if="propsObj.reportData.detailRows.length-1 != index"></v-divider>
                </div>
            </v-list>

        </div>`,

        footerContent:`<div>
            <v-subheader subheader-semi-dense font16>Total
              <v-spacer></v-spacer>
              &#8377; {{$root.numberToLocale(propsObj.reportData.footerRow.netAmount)}}
            </v-subheader>
        </div>`,
        detailReportId:null,
        period:'month',
        periodLabel:'Period',
        reportType:'general',
        filters:null,
        openView:true
    },

    305:{
        reportTitle:"Sale Order",
        itemLabel:null,
        openDialog:false,
        headerRow: {"mergeTransactionNumber":"Number", "transactionDate":"Date","transactionListId":"Voucher","party":"Party","netAmount":"Amount","status":"Status"},
        headerRowTableHeaders: [{"headerName":"Number", "type":"string"},{"headerName":"Date", "type":"date"},{"headerName":"Voucher", "type":"string"},{"headerName":"Party", "type":"string"},{"headerName":"Amount", "type":"number"},{"headerName":"Status", "type":"string"}],
        columns:['mergeTransactionNumber', 'transactionDate', 'transactionListId', 'party', 'netAmount', 'status'],
        tileViewFooterHeaders:{"netAmount":"Amount"},
        tileContent:`<div>
            <v-list three-line nopadding>

                <div v-for="(item, index) in propsObj.reportData.detailRows" :key="index">
                    <v-list-tile class="right-subtitle-tile">

                        <v-list-tile-content @click="fn_getDetails(item)">
                            <v-list-tile-title class="mb-1">{{item.cols.party}}</v-list-tile-title>

                            <v-list-tile-sub-title class="mb-1">
                                {{item.cols.mergeTransactionNumber}} | {{item.cols.transactionDate | moment("DD-MMM-YYYY")}}
                            </v-list-tile-sub-title>

                            <v-list-tile-sub-title>
                                <span :style="{'background':item.cols.status == 'open' ? 'red' : 'green'}" style="padding: 1px 6px;border-radius: 2px;color: #fff;">{{item.cols.status == 'open' ? 'Open' : 'Close'}}</span>
                            </v-list-tile-sub-title>
                        </v-list-tile-content>

                        <v-list-tile-action align-flex-end>

                            <v-btn icon text @click="fn_expandMore(item)">
                                <v-icon>more_horiz</v-icon>
                            </v-btn>

                            <div class="light-opacity-color">
                                <span>&#8377; {{$root.numberToLocale(item.cols.netAmount)}}</span>
                            </div>
                        </v-list-tile-action>
                    </v-list-tile>

                    <v-divider v-if="propsObj.reportData.detailRows.length-1 != index"></v-divider>
                </div>
            </v-list>
        </div>`,

        footerContent:`<div>
            <v-subheader subheader-semi-dense font16>Total
              <v-spacer></v-spacer>
              &#8377; {{$root.numberToLocale(propsObj.reportData.footerRow.netAmount)}}
            </v-subheader>
        </div>`,
        detailReportId:null,
        period:'month',
        periodLabel:'Period',
        reportType:'general',
        filters:null,
        openView:true
    },

    306:{
        reportTitle:"Itemwise Stock Transfer",
        itemLabel:null,
        openDialog:false,
        headerRow: {"productName":"Product Name","qty":"Qty (In Units)","value":"Value","category":"Category","brand":"Brand"},
        headerRowTableHeaders: [{"headerName":"Product Name", "type":"string"},{"headerName":"Qty (In Units)", "type":"number"},{"headerName":"Value", "type":"number"},{"headerName":"Category", "type":"string"},{"headerName":"Brand", "type":"string"}],
        columns:['productName', 'qty', 'value', 'category', 'brand'],
        tileViewFooterHeaders:{"productName":"Product Name", "qty":"Qty (In Units)", "value":"Value"},
        tileContent:`<div>

            <v-list two-line nopadding>

                <div v-for="(item, index) in propsObj.reportData.detailRows" :key="index">
                    <v-list-tile class="right-subtitle-tile">

                        <v-list-tile-content @click="fn_getDetails(item)">
                            <v-list-tile-title class="mb-1">{{item.cols.productName}}</v-list-tile-title>
                            <v-list-tile-sub-title>{{$root.numberToLocale(item.cols.qty)}} Units</v-list-tile-sub-title>
                        </v-list-tile-content>

                        <v-list-tile-action align-flex-end>

                            <v-btn icon text @click="fn_expandMore(item)">
                                <v-icon>more_horiz</v-icon>
                            </v-btn>

                            <div class="light-opacity-color">
                                <span>&#8377; {{$root.numberToLocale(item.cols.value)}}</span>
                            </div>
                        </v-list-tile-action>
                    </v-list-tile>

                    <v-divider v-if="propsObj.reportData.detailRows.length-1 != index"></v-divider>
                </div>
            </v-list>

        </div>`,

        footerContent:`<div>
            <v-subheader subheader-semi-dense font16>Total Value
              <v-spacer></v-spacer>
              &#8377; {{$root.numberToLocale(propsObj.reportData.footerRow.value)}}
              <v-icon>{{!$parent.$parent.tileViewTotalSheet ? 'arrow_drop_up' : 'arrow_drop_down'}}</v-icon>
            </v-subheader>
        </div>`,
        detailReportId:null,
        period:'month',
        periodLabel:'Period',
        reportType:'general',
        filters: null,
    },

    307:{
        reportTitle:"Partywise Stock Transfer",
        itemLabel:null,
        openDialog:false,
        headerRow: {"party":"Party","taxValue":"Value"},
        headerRowTableHeaders: [{"headerName":"Party", "type":"string"},{"headerName":"Value", "type":"number"}],
        columns:['party', 'taxValue'],
        tileViewFooterHeaders:{"taxValue":"Value"},
        tileContent:`<div>

            <v-list two-line nopadding>

                <div v-for="(item, index) in propsObj.reportData.detailRows" :key="index">
                    <v-list-tile class="right-subtitle-tile">

                        <v-list-tile-content @click="fn_getDetails(item)">
                            <v-list-tile-title class="mb-1">{{item.cols.party}}</v-list-tile-title>
                        </v-list-tile-content>

                        <v-list-tile-action align-flex-end>

                            <v-btn icon text @click="fn_expandMore(item)">
                                <v-icon>more_horiz</v-icon>
                            </v-btn>

                            <div class="light-opacity-color">
                                <span>&#8377; {{$root.numberToLocale(item.cols.taxValue)}}</span>
                            </div>
                        </v-list-tile-action>
                    </v-list-tile>

                    <v-divider v-if="propsObj.reportData.detailRows.length-1 != index"></v-divider>
                </div>
            </v-list>

        </div>`,

        footerContent:`<div>
            <v-subheader subheader-semi-dense font16>Total Value
              <v-spacer></v-spacer>
              &#8377; {{$root.numberToLocale(propsObj.reportData.footerRow.taxValue)}}
              <v-icon>{{!$parent.$parent.tileViewTotalSheet ? 'arrow_drop_up' : 'arrow_drop_down'}}</v-icon>
            </v-subheader>
        </div>`,
        detailReportId:null,
        period:'month',
        periodLabel:'Period',
        reportType:'general',
        filters: null,
    },

    308:{
        reportTitle:"Sale Register",
        itemLabel:null,
        openDialog:false,
        headerRow: {"transactionDate":"Date","party":"Party","invoiceType":"Invoice Type","mergeTransactionNumber":"Number","netAmount":"Amount"},
        headerRowTableHeaders: [{"headerName":"Date", "type":"date"},{"headerName":"Party", "type":"string"},{"headerName":"Invoice Type", "type":"string"},{"headerName":"Number", "type":"string"},{"headerName":"Amount", "type":"number"}],
        columns:['transactionDate', 'party', 'invoiceType', 'mergeTransactionNumber', 'netAmount'],
        tileViewFooterHeaders:{"netAmount":"Amount"},
        tileContent:`<div>

            <v-list three-line nopadding>

                <div v-for="(item, index) in propsObj.reportData.detailRows" :key="index">
                    <v-list-tile class="right-subtitle-tile">

                        <v-list-tile-content @click="fn_getDetails(item)">
                            <v-list-tile-title class="mb-1">{{item.cols.party}}</v-list-tile-title>
                            <v-list-tile-sub-title>{{item.cols.transactionDate | moment("DD-MMM-YYYY")}}</v-list-tile-sub-title>
                            <v-list-tile-sub-title>{{item.cols.mergeTransactionNumber}}</v-list-tile-sub-title>
                        </v-list-tile-content>

                        <v-list-tile-action align-flex-end>

                            <v-btn icon text @click="fn_expandMore(item)">
                                <v-icon>more_horiz</v-icon>
                            </v-btn>

                            <div class="light-opacity-color">
                                <span>&#8377; {{$root.numberToLocale(item.cols.netAmount)}}</span>
                            </div>
                        </v-list-tile-action>
                    </v-list-tile>

                    <v-divider v-if="propsObj.reportData.detailRows.length-1 != index"></v-divider>
                </div>
            </v-list>

        </div>`,

        footerContent:`<div>
            <v-subheader subheader-semi-dense font16>Total
              <v-spacer></v-spacer>
              &#8377; {{$root.numberToLocale(propsObj.reportData.footerRow.netAmount)}}
            </v-subheader>
        </div>`,
        detailReportId:null,
        period:'month',
        periodLabel:'Period',
        reportType:'general',
        filters: null,
        openView:true
    },

    401:{
        reportTitle:"Itemwise Purchase",
        itemLabel:null,
        openDialog:false,
        headerRow: {"productName":"Product Name","qty":"Qty", "unitName":"Unit", "value":"Value","category":"Category","brand":"Brand"},
        headerRowTableHeaders: [{"headerName":"Product Name", "type":"string"},{"headerName":"Qty", "type":"number"},{"headerName":"Unit","type":"string"},{"headerName":"Value", "type":"number"},{"headerName":"Category","type":"string"},{"headerName":"Brand","type":"string"}],
        columns:['productName', 'qty', 'unitName', 'value', 'category', 'brand'],
        tileViewFooterHeaders:{"qty":"Qty", "value":"Value"},
        tileContent:`<div>

            <v-list two-line nopadding>

                <div v-for="(item, index) in propsObj.reportData.detailRows" :key="index">
                    <v-list-tile class="right-subtitle-tile">

                        <v-list-tile-content @click="fn_getDetails(item)">
                            <v-list-tile-title class="mb-1">{{item.cols.productName}}</v-list-tile-title>
                            <v-list-tile-sub-title>{{$root.numberToLocale(item.cols.qty)}} {{item.cols.unitName}}</v-list-tile-sub-title>
                        </v-list-tile-content>

                        <v-list-tile-action align-flex-end>

                            <v-btn icon text @click="fn_expandMore(item)">
                                <v-icon>more_horiz</v-icon>
                            </v-btn>

                            <div class="light-opacity-color">
                                <span>&#8377; {{$root.numberToLocale(item.cols.value)}}</span>
                            </div>
                        </v-list-tile-action>
                    </v-list-tile>

                    <v-divider v-if="propsObj.reportData.detailRows.length-1 != index"></v-divider>
                </div>
            </v-list>

        </div>`,

        footerContent:`<div>
            <v-layout row wrap pt-2 pb-2 pl-3 pr-3 font12>
                <v-flex xs4 sm4 md4>Total</v-flex>
                <v-flex xs8 sm8 md8 right-align>&#8377; {{$root.numberToLocale(propsObj.reportData.footerRow.value)}}</v-flex>
            </v-layout>

            <!--<v-subheader subheader-semi-dense font16>Total 
              <v-spacer></v-spacer>
              &#8377; {{$root.numberToLocale(propsObj.reportData.footerRow.value)}}
              <v-icon>{{!$parent.$parent.tileViewTotalSheet ? 'arrow_drop_up' : 'arrow_drop_down'}}</v-icon>
            </v-subheader>-->
        </div>`,
        detailReportId:null,
        period:'month',
        periodLabel:'Period',
        reportType:'general',
        filters: null,
    },

    402:{
        reportTitle:"Partywise Purchase",
        itemLabel:null,
        openDialog:false,
        headerRow: {"party":"Party","taxValue":"Value"},
        headerRowTableHeaders: [{"headerName":"Party", "type":"string"},{"headerName":"Value", "type":"number"}],
        columns:['party', 'taxValue'],
        tileViewFooterHeaders:{"taxValue":"Value"},
        tileContent:`<div>
            <v-list two-line nopadding>

                <div v-for="(item, index) in propsObj.reportData.detailRows" :key="index">

                    <v-subheader class="white-background subheader-semi-dense" v-if="item.cols.isGroup">{{item.cols.party}}</v-subheader>
                    <v-list-tile class="right-subtitle-tile" v-if="!item.cols.isGroup">

                        <v-list-tile-content @click="fn_getDetails(item)">
                            <v-list-tile-title class="mb-1">{{item.cols.party}}</v-list-tile-title>
                        </v-list-tile-content>

                        <v-list-tile-action align-flex-end>

                            <v-btn icon text @click="fn_expandMore(item)">
                                <v-icon>more_horiz</v-icon>
                            </v-btn>

                            <div class="light-opacity-color">
                                <span>&#8377; {{$root.numberToLocale(item.cols.taxValue)}}</span>
                            </div>
                        </v-list-tile-action>
                    </v-list-tile>

                    <v-divider v-if="propsObj.reportData.detailRows.length-1 != index"></v-divider>
                </div>
            </v-list>
        </div>`,

        footerContent:`<div>
            <v-subheader subheader-semi-dense font16>Total 
              <v-spacer></v-spacer>
              &#8377; {{$root.numberToLocale(propsObj.reportData.footerRow.taxValue)}}
            </v-subheader>
        </div>`,
        detailReportId:null,
        period:'month',
        periodLabel:'Period',
        reportType:'general',
        filters: null,
    },

    403:{
        reportTitle:"Purchase Tax Summary",
        itemLabel:null,
        openDialog:false,
        headerRow: {"transactionDate":"Date","party":"Party","mergeTransactionNumber":"Number","refNumber":"Bill No","refDate":"Bill Date","transactionListId":"Invoice Type","gstNumber":"GSTIN", "grossAmount":"Gross Amount", "discount":"Discount", "taxableValue":"Taxable Value", "cgst":"CGST", "stgst":"SGST/UTGST", "igst":"IGST", "cess":"Cess Amount", "otherCharges":"Other Charges", "roundedOff":"R/O", "roundedAmount":"Net Amount"},
        headerRowTableHeaders: [{"headerName":"Date", "type":"date"},{"headerName":"Party", "type":"string"},{"headerName":"Number", "type":"string"},{"headerName":"Bill No", "type":"string"},{"headerName":"Bill Date", "type":"date"},{"headerName":"Invoice Type", "type":"string"},{"headerName":"GSTIN", "type":"string"},{"headerName":"Gross Amount", "type":"number"},{"headerName":"Discount", "type":"number"},{"headerName":"Taxable Value", "type":"number"},{"headerName":"CGST", "type":"number"},{"headerName":"SGST/UTGST", "type":"number"},{"headerName":"IGST", "type":"number"},{"headerName":"Cess Amount", "type":"number"},{"headerName":"Other Charges", "type":"number"},{"headerName":"R/O", "type":"number"},{"headerName":"Net Amount", "type":"number"}],
        columns:['transactionDate', 'party', 'mergeTransactionNumber', 'refNumber', 'refDate', 'transactionListId', 'gstNumber', 'grossAmount', 'discount', 'taxableValue', 'cgst', 'stgst', 'igst', 'cess', 'otherCharges', 'roundedOff', 'roundedAmount'],
        tileViewFooterHeaders:{"roundedAmount":"Net Amount"},
        tileContent:`<div>
            <v-list three-line nopadding>

                <div v-for="(item, index) in propsObj.reportData.detailRows" :key="index">
                    <v-list-tile class="right-subtitle-tile">

                        <v-list-tile-content @click="fn_getDetails(item)">
                            <v-list-tile-title class="mb-1">{{item.cols.party}}</v-list-tile-title>

                            <v-list-tile-sub-title class="mb-1">{{item.cols.transactionDate | moment("DD-MMM-YYYY")}}</v-list-tile-sub-title>

                            <v-list-tile-sub-title>{{item.cols.mergeTransactionNumber}} | {{item.cols.transactionListId}}</v-list-tile-sub-title>
                        </v-list-tile-content>

                        <v-list-tile-action align-flex-end>

                            <v-btn icon text @click="fn_expandMore(item)">
                                <v-icon>more_horiz</v-icon>
                            </v-btn>

                            <div class="light-opacity-color">
                                <span>&#8377; {{$root.numberToLocale(item.cols.tax)}}</span>
                            </div>
                        </v-list-tile-action>
                    </v-list-tile>

                    <v-divider v-if="propsObj.reportData.detailRows.length-1 != index"></v-divider>
                </div>
            </v-list>
        </div>`,

        footerContent:`<div>
            <v-subheader subheader-semi-dense font16>Total 
              <v-spacer></v-spacer>
              &#8377; {{$root.numberToLocale(propsObj.reportData.footerRow.totalTax)}}
            </v-subheader>
        </div>`,
        detailReportId:null,
        period:'month',
        periodLabel:'Period',
        reportType:'general',
        filters: null,
        openView:true
    },

    404:{
        reportTitle:"Purchase Challan",
        itemLabel:null,
        openDialog:false,
        headerRow: {"mergeTransactionNumber":"Number", "transactionDate":"Date","transactionListId":"Voucher","party":"Party","netAmount":"Amount","status":"Status"},
        columns:['mergeTransactionNumber', 'transactionDate', 'transactionListId', 'party', 'netAmount', 'status'],
        headerRowTableHeaders: [{"headerName":"Number", "type":"string"},{"headerName":"Date", "type":"date"},{"headerName":"Voucher", "type":"string"},{"headerName":"Party", "type":"string"},{"headerName":"Amount", "type":"number"},{"headerName":"Status", "type":"string"}],
        tileViewFooterHeaders:{"netAmount":"Amount"},
        tileContent:`<div>
             <v-list three-line nopadding>

                <div v-for="(item, index) in propsObj.reportData.detailRows" :key="index">
                    <v-list-tile class="right-subtitle-tile">

                        <v-list-tile-content @click="fn_getDetails(item)">
                            <v-list-tile-title class="mb-1">{{item.cols.party}}</v-list-tile-title>

                            <v-list-tile-sub-title class="mb-1">
                                {{item.cols.mergeTransactionNumber}} | {{item.cols.transactionDate | moment("DD-MMM-YYYY")}}
                            </v-list-tile-sub-title>

                            <v-list-tile-sub-title>
                                <span :style="{'background':item.cols.status == 'open' ? 'red' : 'green'}" style="padding: 1px 6px;border-radius: 2px;color: #fff;">{{item.cols.status == 'open' ? 'Open' : 'Close'}}</span>
                            </v-list-tile-sub-title>
                        </v-list-tile-content>

                        <v-list-tile-action align-flex-end>

                            <v-btn icon text @click="fn_expandMore(item)">
                                <v-icon>more_horiz</v-icon>
                            </v-btn>

                            <div class="light-opacity-color">
                                <span>&#8377; {{$root.numberToLocale(item.cols.netAmount)}}</span>
                            </div>
                        </v-list-tile-action>
                    </v-list-tile>

                    <v-divider v-if="propsObj.reportData.detailRows.length-1 != index"></v-divider>
                </div>
            </v-list>
        </div>`,

        footerContent:`<div>
            <v-subheader subheader-semi-dense font16>Total
              <v-spacer></v-spacer>
              &#8377; {{$root.numberToLocale(propsObj.reportData.footerRow.netAmount)}}
            </v-subheader>
        </div>`,
        detailReportId:null,
        period:'month',
        periodLabel:'Period',
        reportType:'general',
        filters:null,
        openView:true
    },

    405:{
        reportTitle:"Purchase Order",
        itemLabel:null,
        openDialog:false,
        headerRow: {"mergeTransactionNumber":"Number", "transactionDate":"Date","transactionListId":"Voucher","party":"Party","netAmount":"Amount","status":"Status"},
        headerRowTableHeaders: [{"headerName":"Number", "type":"string"},{"headerName":"Date", "type":"date"},{"headerName":"Voucher", "type":"string"},{"headerName":"Party", "type":"string"},{"headerName":"Amount", "type":"number"},{"headerName":"Status", "type":"string"}],
        columns:['mergeTransactionNumber', 'transactionDate', 'transactionListId', 'party', 'netAmount', 'status'],
        tileViewFooterHeaders:{"netAmount":"Amount"},
        tileContent:`<div>
              <v-list three-line nopadding>

                <div v-for="(item, index) in propsObj.reportData.detailRows" :key="index">
                    <v-list-tile class="right-subtitle-tile">

                        <v-list-tile-content @click="fn_getDetails(item)">
                            <v-list-tile-title class="mb-1">{{item.cols.party}}</v-list-tile-title>

                            <v-list-tile-sub-title class="mb-1">
                                {{item.cols.mergeTransactionNumber}} | {{item.cols.transactionDate | moment("DD-MMM-YYYY")}}
                            </v-list-tile-sub-title>

                            <v-list-tile-sub-title>
                                <span :style="{'background':item.cols.status == 'open' ? 'red' : 'green'}" style="padding: 1px 6px;border-radius: 2px;color: #fff;">{{item.cols.status == 'open' ? 'Open' : 'Close'}}</span>
                            </v-list-tile-sub-title>
                        </v-list-tile-content>

                        <v-list-tile-action align-flex-end>

                            <v-btn icon text @click="fn_expandMore(item)">
                                <v-icon>more_horiz</v-icon>
                            </v-btn>

                            <div class="light-opacity-color">
                                <span>&#8377; {{$root.numberToLocale(item.cols.netAmount)}}</span>
                            </div>
                        </v-list-tile-action>
                    </v-list-tile>

                    <v-divider v-if="propsObj.reportData.detailRows.length-1 != index"></v-divider>
                </div>
            </v-list>
        </div>`,

        footerContent:`<div>
            <v-subheader subheader-semi-dense font16>Total
              <v-spacer></v-spacer>
              &#8377; {{$root.numberToLocale(propsObj.reportData.footerRow.netAmount)}}
            </v-subheader>
        </div>`,
        detailReportId:null,
        period:'month',
        periodLabel:'Period',
        reportType:'general',
        filters:null,
        openView:true
    },

    406:{
        reportTitle:"Expense Ledger",
        itemLabel:null,
        openDialog:false,
        headerRow: {"parentGroupName":"Main Group","accountName":"Expense Ledger","outAmount":"Amount"},
        headerRowTableHeaders: [{"headerName":"Main Group", "type":"string"},{"headerName":"Expense Ledger", "type":"string"},{"headerName":"Amount", "type":"number"}],
        columns:['parentGroupName', 'accountName', 'outAmount'],
        tileViewFooterHeaders:{"outAmount":"Amount"},
        tileContent:`<div>

            <v-list two-line nopadding>

                <div v-for="(item, index) in propsObj.reportData.detailRows" :key="index">
                    <v-list-tile class="right-subtitle-tile">

                        <v-list-tile-content @click="fn_getDetails(item)">
                            <v-list-tile-title class="mb-1">{{item.cols.accountName}}</v-list-tile-title>
                            <v-list-tile-sub-title>{{item.cols.parentGroupName}}</v-list-tile-sub-title>
                        </v-list-tile-content>

                        <v-list-tile-action align-flex-end>

                            <v-btn icon text @click="fn_expandMore(item)">
                                <v-icon>more_horiz</v-icon>
                            </v-btn>

                            <div class="light-opacity-color">
                                <span>&#8377; {{$root.numberToLocale(item.cols.outAmount)}}</span>
                            </div>
                        </v-list-tile-action>
                    </v-list-tile>

                    <v-divider v-if="propsObj.reportData.detailRows.length-1 != index"></v-divider>
                </div>
            </v-list>
        </div>`,

        footerContent:`<div>
            <v-subheader subheader-semi-dense font16>Total
              <v-spacer></v-spacer>
              &#8377; {{$root.numberToLocale(propsObj.reportData.footerRow.outAmount)}}
            </v-subheader>
        </div>`,
        detailReportId:null,
        period:'month',
        periodLabel:'Period',
        reportType:'general',
        filters:null,
        hideFilterBtn:true
    },

    407:{
        reportTitle:"Itemwise Stock Receive",
        itemLabel:null,
        openDialog:false,
        headerRow: {"productName":"Product Name","qty":"Qty (In Units)","value":"Value","category":"Category","brand":"Brand"},
        headerRowTableHeaders: [{"headerName":"Product Name", "type":"string"},{"headerName":"Qty (In Units)", "type":"number"},{"headerName":"Value", "type":"number"},{"headerName":"Category", "type":"string"},{"headerName":"Brand", "type":"string"}],
        columns:['productName', 'qty', 'value', 'category', 'brand'],
        tileViewFooterHeaders:{"qty":"Qty (In Units)", "value":"Value"},
        tileContent:`<div>
            <v-list two-line nopadding>

                <div v-for="(item, index) in propsObj.reportData.detailRows" :key="index">
                    <v-list-tile class="right-subtitle-tile">

                        <v-list-tile-content @click="fn_getDetails(item)">
                            <v-list-tile-title class="mb-1">{{item.cols.productName}}</v-list-tile-title>
                            <v-list-tile-sub-title>{{$root.numberToLocale(item.cols.qty)}} Units</v-list-tile-sub-title>
                        </v-list-tile-content>

                        <v-list-tile-action align-flex-end>

                            <v-btn icon text @click="fn_expandMore(item)">
                                <v-icon>more_horiz</v-icon>
                            </v-btn>

                            <div class="light-opacity-color">
                                <span>&#8377; {{$root.numberToLocale(item.cols.value)}}</span>
                            </div>
                        </v-list-tile-action>
                    </v-list-tile>

                    <v-divider v-if="propsObj.reportData.detailRows.length-1 != index"></v-divider>
                </div>
            </v-list>
        </div>`,

        footerContent:`<div>
            <v-subheader subheader-semi-dense font16>Total Value
              <v-spacer></v-spacer>
              &#8377; {{$root.numberToLocale(propsObj.reportData.footerRow.value)}}
              <v-icon>{{!$parent.$parent.tileViewTotalSheet ? 'arrow_drop_up' : 'arrow_drop_down'}}</v-icon>
            </v-subheader>
        </div>`,
        detailReportId:null,
        period:'month',
        periodLabel:'Period',
        reportType:'general',
        filters: null,
    },

    408:{
        reportTitle:"Partywise Stock Receive",
        itemLabel:null,
        openDialog:false,
        headerRow: {"party":"Party","taxValue":"Value"},
        headerRowTableHeaders: [{"headerName":"Party", "type":"string"},{"headerName":"Value", "type":"number"}],
        columns:['party', 'taxValue'],
        tileViewFooterHeaders:{"taxValue":"Value"},
        tileContent:`<div>
            <v-list two-line nopadding>

                <div v-for="(item, index) in propsObj.reportData.detailRows" :key="index">
                    <v-list-tile class="right-subtitle-tile">

                        <v-list-tile-content @click="fn_getDetails(item)">
                            <v-list-tile-title class="mb-1">{{item.cols.party}}</v-list-tile-title>
                        </v-list-tile-content>

                        <v-list-tile-action align-flex-end>

                            <v-btn icon text @click="fn_expandMore(item)">
                                <v-icon>more_horiz</v-icon>
                            </v-btn>

                            <div class="light-opacity-color">
                                <span>&#8377; {{$root.numberToLocale(item.cols.taxValue)}}</span>
                            </div>
                        </v-list-tile-action>
                    </v-list-tile>

                    <v-divider v-if="propsObj.reportData.detailRows.length-1 != index"></v-divider>
                </div>
            </v-list>
        </div>`,

        footerContent:`<div>
            <v-subheader subheader-semi-dense font16>Total Value
              <v-spacer></v-spacer>
              &#8377; {{$root.numberToLocale(propsObj.reportData.footerRow.taxValue)}}
              <v-icon>{{!$parent.$parent.tileViewTotalSheet ? 'arrow_drop_up' : 'arrow_drop_down'}}</v-icon>
            </v-subheader>
        </div>`,
        detailReportId:null,
        period:'month',
        periodLabel:'Period',
        reportType:'general',
        filters: null,
    },

    409:{
        reportTitle:"Purchase Register",
        itemLabel:null,
        openDialog:false,
        headerRow: {"transactionDate":"Date","party":"Party","invoiceType":"Invoice Type","mergeTransactionNumber":"Number", "refNumber":"Bill No", "refDate":"Bill Date", "netAmount":"Amount"},
        headerRowTableHeaders: [{"headerName":"Date", "type":"date"},{"headerName":"Party", "type":"string"},{"headerName":"Invoice Type", "type":"string"},{"headerName":"Number", "type":"string"},{"headerName":"Bill No", "type":"string"},{"headerName":"Bill Date", "type":"date"},{"headerName":"Amount", "type":"number"}],
        columns:['transactionDate', 'party', 'invoiceType', 'mergeTransactionNumber', 'refNumber', 'refDate', 'netAmount'],
        tileViewFooterHeaders:{"netAmount":"Amount"},
        tileContent:`<div>

            <v-list three-line nopadding>

                <div v-for="(item, index) in propsObj.reportData.detailRows" :key="index">
                    <v-list-tile class="right-subtitle-tile">

                        <v-list-tile-content @click="fn_getDetails(item)">
                            <v-list-tile-title class="mb-1">{{item.cols.party}}</v-list-tile-title>
                            <v-list-tile-sub-title>{{item.cols.transactionDate | moment("DD-MMM-YYYY")}}</v-list-tile-sub-title>
                            <v-list-tile-sub-title>{{item.cols.mergeTransactionNumber}}</v-list-tile-sub-title>
                        </v-list-tile-content>

                        <v-list-tile-action align-flex-end>

                            <v-btn icon text @click="fn_expandMore(item)">
                                <v-icon>more_horiz</v-icon>
                            </v-btn>

                            <div class="light-opacity-color">
                                <span>&#8377; {{$root.numberToLocale(item.cols.netAmount)}}</span>
                            </div>
                        </v-list-tile-action>
                    </v-list-tile>

                    <v-divider v-if="propsObj.reportData.detailRows.length-1 != index"></v-divider>
                </div>
            </v-list>

        </div>`,

        footerContent:`<div>
            <v-subheader subheader-semi-dense font16>Total
              <v-spacer></v-spacer>
              &#8377; {{$root.numberToLocale(propsObj.reportData.footerRow.netAmount)}}
            </v-subheader>
        </div>`,
        detailReportId:null,
        period:'month',
        periodLabel:'Period',
        reportType:'general',
        filters: null,
        openView:true
    },

    410:{
        reportTitle:"Expense Item",
        itemLabel:null,
        openDialog:false,
        headerRow: {"itemName":"Item Name","category":"Category","qty":"Qty","unit":"Unit","amount":"Amount"},
        headerRowTableHeaders: [{"headerName":"Item Name", "type":"string"},{"headerName":"Category", "type":"string"},{"headerName":"Qty", "type":"number"},{"headerName":"Unit"},{"headerName":"Amount", "type":"number"}],
        columns:['itemName', 'category', 'qty', 'unit', 'amount'],
        tileViewFooterHeaders:{"qty":"Qty","amount":"Total"},
        tileContent:`<div>

            <v-list two-line nopadding>

                <div v-for="(item, index) in propsObj.reportData.detailRows" :key="index">
                    <v-list-tile class="right-subtitle-tile">

                        <v-list-tile-content @click="fn_getDetails(item)">
                            <v-list-tile-title class="mb-1">{{item.cols.itemName}}</v-list-tile-title>
                            <v-list-tile-sub-title>{{item.cols.qty}} {{item.cols.unit}}</v-list-tile-sub-title>
                        </v-list-tile-content>

                        <v-list-tile-action align-flex-end>

                            <v-btn icon text @click="fn_expandMore(item)">
                                <v-icon>more_horiz</v-icon>
                            </v-btn>

                            <div class="light-opacity-color">
                                <span>&#8377; {{$root.numberToLocale(item.cols.amount)}}</span>
                            </div>
                        </v-list-tile-action>
                    </v-list-tile>

                    <v-divider v-if="propsObj.reportData.detailRows.length-1 != index"></v-divider>
                </div>
            </v-list>
        </div>`,

        footerContent:`<div>
            <v-layout row wrap pt-2 pb-2 pl-3 pr-3 font12>
                <v-flex xs4 sm4 md4>Total</v-flex>
                <v-flex xs8 sm8 md8 right-align>&#8377; {{$root.numberToLocale(propsObj.reportData.footerRow.amount)}}</v-flex>

                <v-flex xs4 sm4 md4 pt-2>Qty (In Units)</v-flex>
                <v-flex xs8 sm8 md8 pt-2 right-align>{{$root.numberToLocale(propsObj.reportData.footerRow.qty)}}</v-flex>
            </v-layout>

            <!--<v-subheader subheader-semi-dense font16>Total
              <v-spacer></v-spacer>
              &#8377; {{$root.numberToLocale(propsObj.reportData.footerRow.amount)}}
            </v-subheader>-->
        </div>`,
        detailReportId:null,
        period:'month',
        periodLabel:'Period',
        reportType:'general',
        filters:null,
        openView:true,
        hideFilterBtn:true
    },

    411:{
        reportTitle:"Transport",
        itemLabel:null,
        openDialog:false,
        headerRow: {"transporter":"Transporter","gstNumber":"Transporter ID","mode":"Mode","lrcrrrNumber":"LR/CR/RR No","vehicleNumber":"Vehicle No","transactionType":"Transaction Type","transactionListId":"Type","transactionDate":"Document Date","mergeTransactionNumber":"Document No.","wayBillNumber":"E-Way Bill No","wayBillDate":"E-Way Bill Date","documentType":"Document Type","subType":"Sub Type","distance":"Distance (in KM)","roundedAmount":"Document Value"},
        headerRowTableHeaders: [{"headerName":"Transporter", "type":"string"},{"headerName":"Transporter ID", "type":"string"},{"headerName":"Mode", "type":"string"},{"headerName":"LR/CR/RR No", "type":"string"},{"headerName":"Vehicle No", "type":"string"},{"headerName":"Transaction Type", "type":"string"},{"headerName":"Type", "type":"string"},{"headerName":"Document Date", "type":"date"},{"headerName":"Document No.", "type":"string"},{"headerName":"E-Way Bill No", "type":"string"},{"headerName":"E-Way Bill Date", "type":"date"},{"headerName":"Document Type", "type":"string"},{"headerName":"Sub Type", "type":"string"},{"headerName":"Distance (in KM)", "type":"string"},{"headerName":"Document Value", "type":"number"}],
        columns:['transporter', 'gstNumber', 'mode', 'lrcrrrNumber', 'vehicleNumber', 'transactionType', 'transactionListId', 'transactionDate', 'mergeTransactionNumber', 'wayBillNumber', 'wayBillDate','documentType', 'subType','distance', 'roundedAmount'],
        tileViewFooterHeaders:null,
        tileContent:null,
        footerContent:null,
        detailReportId:null,
        period:'year',
        periodLabel:'Period',
        reportType:'general',
        filters:null,
        openView:true,
        hideFilterBtn:true
    },

    412:{
        reportTitle:"Expense Category",
        itemLabel:null,
        openDialog:false,
        headerRow: {"parentGroupName":"Main Group","expenseCategoryName":"Expense Category","outAmount":"Amount"},
        headerRowTableHeaders: [{"headerName":"Main Group", "type":"string"},{"headerName":"Expense Category", "type":"string"},{"headerName":"Amount", "type":"number"}],
        columns:['parentGroupName', 'expenseCategoryName', 'outAmount'],
        tileViewFooterHeaders:{"outAmount":"Amount"},
        tileContent:`<div>

            <v-list two-line nopadding>

                <div v-for="(item, index) in propsObj.reportData.detailRows" :key="index">
                    <v-list-tile class="right-subtitle-tile">

                        <v-list-tile-content @click="fn_getDetails(item)">
                            <v-list-tile-title class="mb-1">{{item.cols.expenseCategoryName}}</v-list-tile-title>
                            <v-list-tile-sub-title>{{item.cols.parentGroupName}}</v-list-tile-sub-title>
                        </v-list-tile-content>

                        <v-list-tile-action align-flex-end>

                            <v-btn icon flat @click="fn_expandMore(item)">
                                <v-icon>more_horiz</v-icon>
                            </v-btn>

                            <div class="light-opacity-color">
                                <span>&#8377; {{$root.numberToLocale(item.cols.outAmount)}}</span>
                            </div>
                        </v-list-tile-action>
                    </v-list-tile>

                    <v-divider v-if="propsObj.reportData.detailRows.length-1 != index"></v-divider>
                </div>
            </v-list>
        </div>`,

        footerContent:`<div>
            <v-subheader subheader-semi-dense font16>Total
              <v-spacer></v-spacer>
              &#8377; {{$root.numberToLocale(propsObj.reportData.footerRow.outAmount)}}
            </v-subheader>
        </div>`,
        detailReportId:null,
        period:'month',
        periodLabel:'Period',
        reportType:'general',
        filters:null,
        hideFilterBtn:true
    },

    413:{
        reportTitle:"Expense Payment",
        itemLabel:null,
        openDialog:false,
        headerRow: {"parentGroupName":"Main Group","accountName":"Payment Ledger","outAmount":"Amount"},
        headerRowTableHeaders: [{"headerName":"Main Group", "type":"string"},{"headerName":"Payment Ledger", "type":"string"},{"headerName":"Amount", "type":"number"}],
        columns:['parentGroupName', 'accountName', 'outAmount'],
        tileViewFooterHeaders:{"outAmount":"Amount"},
        tileContent:`<div>

            <v-list two-line nopadding>

                <div v-for="(item, index) in propsObj.reportData.detailRows" :key="index">
                    <v-list-tile class="right-subtitle-tile">

                        <v-list-tile-content @click="fn_getDetails(item)">
                            <v-list-tile-title class="mb-1">{{item.cols.accountName}}</v-list-tile-title>
                            <v-list-tile-sub-title>{{item.cols.parentGroupName}}</v-list-tile-sub-title>
                        </v-list-tile-content>

                        <v-list-tile-action align-flex-end>

                            <v-btn icon flat @click="fn_expandMore(item)">
                                <v-icon>more_horiz</v-icon>
                            </v-btn>

                            <div class="light-opacity-color">
                                <span>&#8377; {{$root.numberToLocale(item.cols.outAmount)}}</span>
                            </div>
                        </v-list-tile-action>
                    </v-list-tile>

                    <v-divider v-if="propsObj.reportData.detailRows.length-1 != index"></v-divider>
                </div>
            </v-list>
        </div>`,

        footerContent:`<div>
            <v-subheader subheader-semi-dense font16>Total
              <v-spacer></v-spacer>
              &#8377; {{$root.numberToLocale(propsObj.reportData.footerRow.outAmount)}}
            </v-subheader>
        </div>`,
        detailReportId:null,
        period:'month',
        periodLabel:'Period',
        reportType:'general',
        filters:null,
        hideFilterBtn:true
    },

    414:{
        reportTitle:"Partywise Expense",
        itemLabel:null,
        openDialog:false,
        headerRow: {"parentGroupName":"Main Group","accountName":"Account Ledger","outAmount":"Amount"},
        headerRowTableHeaders: [{"headerName":"Main Group", "type":"string"},{"headerName":"Account Ledger", "type":"string"},{"headerName":"Amount", "type":"number"}],
        columns:['parentGroupName', 'accountName', 'outAmount'],
        tileViewFooterHeaders:{"outAmount":"Amount"},
        tileContent:`<div>

            <v-list two-line nopadding>

                <div v-for="(item, index) in propsObj.reportData.detailRows" :key="index">
                    <v-list-tile class="right-subtitle-tile">

                        <v-list-tile-content @click="fn_getDetails(item)">
                            <v-list-tile-title class="mb-1">{{item.cols.accountName}}</v-list-tile-title>
                            <v-list-tile-sub-title>{{item.cols.parentGroupName}}</v-list-tile-sub-title>
                        </v-list-tile-content>

                        <v-list-tile-action align-flex-end>

                            <v-btn icon flat @click="fn_expandMore(item)">
                                <v-icon>more_horiz</v-icon>
                            </v-btn>

                            <div class="light-opacity-color">
                                <span>&#8377; {{$root.numberToLocale(item.cols.outAmount)}}</span>
                            </div>
                        </v-list-tile-action>
                    </v-list-tile>

                    <v-divider v-if="propsObj.reportData.detailRows.length-1 != index"></v-divider>
                </div>
            </v-list>
        </div>`,

        footerContent:`<div>
            <v-subheader subheader-semi-dense font16>Total
              <v-spacer></v-spacer>
              &#8377; {{$root.numberToLocale(propsObj.reportData.footerRow.outAmount)}}
            </v-subheader>
        </div>`,
        detailReportId:null,
        period:'month',
        periodLabel:'Period',
        reportType:'general',
        filters:null,
        hideFilterBtn:true
    },

    501:{
        reportTitle:"Outstanding Receivables",
        itemLabel:"Party",
        openDialog:true,
        headerRow: {"mergeTransactionNumber":"Number","transactionListId":"Voucher","transactionDate":"Date","outStandingAmount":"Amount"},
        columns:['mergeTransactionNumber', 'transactionListId', 'transactionDate', 'outStandingAmount'],
        footerColumns:['mergeTransactionNumber', 'transactionListId'],
        tileViewFooterHeaders:{"total":"Total", "onAccount":"On Account", "outStandingAmount":"Outstanding Receivables"},
        tileContent:`<div>

            <v-list two-line nopadding>

                <div v-for="(item, index) in propsObj.reportData.detailRows" :key="index">
                    <v-list-tile class="right-subtitle-tile">

                        <v-list-tile-content @click="fn_getDetails(item)">
                            <v-list-tile-title class="mb-1">
                                <span v-if="item.cols.mergeTransactionNumber">{{item.cols.mergeTransactionNumber}} |</span>
                                {{item.cols.transactionListId}}
                            </v-list-tile-title>
                            <v-list-tile-sub-title>{{item.cols.transactionDate | moment("DD-MMM-YYYY")}}</v-list-tile-sub-title>
                        </v-list-tile-content>

                        <v-list-tile-action align-flex-end>

                            <v-btn icon text @click="fn_expandMore(item)">
                                <v-icon>more_horiz</v-icon>
                            </v-btn>

                            <div class="light-opacity-color">
                                <span>&#8377; {{$root.numberToLocale(item.cols.outStandingAmount)}}</span>
                            </div>
                        </v-list-tile-action>
                    </v-list-tile>

                    <v-divider v-if="propsObj.reportData.detailRows.length-1 != index"></v-divider>
                </div>
            </v-list>

        </div>`,

        footerContent:`<div>
            <v-subheader subheader-semi-dense font16>Outstanding Receivables
              <v-spacer></v-spacer>
              &#8377; {{$root.numberToLocale(propsObj.reportData.summaryRows[2].transactionListId)}}
              <v-icon>{{!$parent.$parent.tileViewTotalSheet ? 'arrow_drop_up' : 'arrow_drop_down'}}</v-icon>
            </v-subheader>
        </div>`,
        detailReportId:null,
        period:'endDate',
        periodLabel:'As On',
        reportType:'general',
        filters: null,
        openView:true
    },

    502:{
        reportTitle:"Outstanding Payables",
        itemLabel:"Party",
        openDialog:true,
        headerRow: {"mergeTransactionNumber":"Number","transactionListId":"Voucher","transactionDate":"Date","outStandingAmount":"Amount"},
        columns:['mergeTransactionNumber', 'transactionListId', 'transactionDate', 'outStandingAmount'],
        tileViewFooterHeaders:{"total":"Total", "onAccount":"On Account", "outStandingAmount":"Outstanding Receivables"},
        tileContent:`<div>
            <v-list two-line nopadding>

                <div v-for="(item, index) in propsObj.reportData.detailRows" :key="index">
                    <v-list-tile class="right-subtitle-tile">

                        <v-list-tile-content @click="fn_getDetails(item)">
                            <v-list-tile-title class="mb-1">
                                <span v-if="item.cols.mergeTransactionNumber">{{item.cols.mergeTransactionNumber}} |</span>
                                {{item.cols.transactionListId}}
                            </v-list-tile-title>
                            <v-list-tile-sub-title>{{item.cols.transactionDate | moment("DD-MMM-YYYY")}}</v-list-tile-sub-title>
                        </v-list-tile-content>

                        <v-list-tile-action align-flex-end>

                            <v-btn icon text @click="fn_expandMore(item)">
                                <v-icon>more_horiz</v-icon>
                            </v-btn>

                            <div class="light-opacity-color">
                                <span>&#8377; {{$root.numberToLocale(item.cols.outStandingAmount)}}</span>
                            </div>
                        </v-list-tile-action>
                    </v-list-tile>

                    <v-divider v-if="propsObj.reportData.detailRows.length-1 != index"></v-divider>
                </div>
            </v-list>
        </div>`,

        footerContent:`<div>
            <v-subheader subheader-semi-dense font16>Outstanding Payables
              <v-spacer></v-spacer>
              &#8377; {{$root.numberToLocale(propsObj.reportData.summaryRows[2].transactionListId)}}
              <v-icon>{{!$parent.$parent.tileViewTotalSheet ? 'arrow_drop_up' : 'arrow_drop_down'}}</v-icon>
            </v-subheader>
        </div>`,
        footerColumns:['mergeTransactionNumber', 'transactionListId'],
        detailReportId:null,
        period:'endDate',
        periodLabel:'As On',
        reportType:'general',
        filters: null,
        openView:true
    },

    503:{
        reportTitle:"Partywise Outstanding",
        itemLabel:null,
        openDialog:false,
        headerRow: {"party":"Party","partyBalance":"Balance", "balanceType":""},
        headerRowTableHeaders: [{"headerName":"Party", "type":"string"},{"headerName":"Balance", "type":"number"},{"headerName":"", "type":"string"}],
        columns:['party', 'partyBalance', 'balanceType'],
        // tileViewFooterHeaders:{"taxValue":"Value"},
        tileViewFooterHeaders:null,
        tileContent:`<div>

            <v-list nopadding>

                <div v-for="(item, index) in propsObj.reportData.detailRows" :key="index">
                    <v-subheader class="white-background subheader-semi-dense" v-if="item.cols.isGroup">{{item.cols.party}}</v-subheader>
                    <v-list-tile class="right-subtitle-tile" v-if="!item.cols.isGroup">

                        <v-list-tile-content @click="fn_getDetails(item)">
                            <v-list-tile-title class="mb-1">{{item.cols.party}}</v-list-tile-title>
                        </v-list-tile-content>

                        <v-list-tile-action style="min-width:120px">
                            <span :class="[item.cols.balanceType == 'To Pay' ? 'redcolor' : 'greencolor']">&#8377; {{$root.numberToLocale(Math.abs(item.cols.partyBalance))}}</span>
                        </v-list-tile-action>
                    </v-list-tile>

                    <v-divider v-if="propsObj.reportData.detailRows.length-1 != index"></v-divider>
                </div>
            </v-list>

        </div>`,

        footerColumns:null,
        detailReportId:null,
        period:'endDate',
        periodLabel:'As On',
        reportType:'general',
        filters: null,
        openView:true
    },

    504:{
        reportTitle:"Invoice reconciliation Report",
        itemLabel:null,
        openDialog:false,
        headerRow: {"party":"Party","udyamNumber":"Udyam Number", "totalBillAmount":"Total Amount", "totalBillAmountReceived":"Bill Agst Amount", "pendingAmount":"Pending Amount", "dueDays":"Due Days"},
        headerRowTableHeaders: [{"headerName":"Party", "type":"string"},{"headerName":"Udyam Number", "type":"string"},{"headerName":"Total Amount", "type":"number"}, {"headerName":"Bill Agst Amount", "type":"number"}, {"headerName":"Pending Amount", "type":"number"}, {"headerName":"Due Days", "type":"string"}],
        columns:['party', 'udyamNumber', 'totalBillAmount', 'totalBillAmountReceived', 'pendingAmount', 'dueDays'],
        tileViewFooterHeaders:null,
        tileContent:`<div>

           <v-list three-line nopadding>

                <div v-for="(item, index) in propsObj.reportData.detailRows" :key="index">
                    <v-subheader class="white-background subheader-semi-dense" v-if="item.cols.isGroup">{{item.cols.party}}</v-subheader>
                    <v-list-tile class="right-subtitle-tile" v-if="!item.cols.isGroup">

                        <v-list-tile-content @click="fn_getDetails(item)">
                            <v-list-tile-title class="mb-1">{{item.cols.party}}</v-list-tile-title>
                            <v-list-tile-sub-title class="mb-1">Udyam No.: {{item.cols.udyamNumber}}</v-list-tile-sub-title>
                            <v-list-tile-sub-title class="mb-1">Total Amt: {{$root.numberToLocale(item.cols.totalBillAmount)}}</v-list-tile-sub-title>
                        </v-list-tile-content>

                        <v-list-tile-action align-flex-end style="min-width:120px">
                            <v-btn icon flat @click="fn_expandMore(item)">
                                <v-icon>more_horiz</v-icon>
                            </v-btn>
                            <span :class="[item.cols.balanceType == 'To Pay' ? 'redcolor' : 'greencolor']">&#8377; {{$root.numberToLocale(item.cols.pendingAmount)}}</span>
                        </v-list-tile-action>
                    </v-list-tile>

                    <v-divider v-if="propsObj.reportData.detailRows.length-1 != index"></v-divider>
                </div>
            </v-list>
        </div>`,

        footerContent:`<div>
            <v-subheader subheader-semi-dense font16>Total
              <v-spacer></v-spacer>
              &#8377; {{$root.numberToLocale(propsObj.reportData.footerRow.outAmount)}}
            </v-subheader>
        </div>`,
        detailReportId:null,
        period:'endDate',
        periodLabel:'As On',
        reportType:'general',
        filters:null,
        hideFilterBtn:false
    },

    505:{
        reportTitle:"Invoice reconciliation Report",
        itemLabel:"Party",
        openDialog:false,
        headerRow: {"udyamNumber":"Udyam Number", "transactionDate":"Transaction Date", "transactionListId":"Type", "mergeTransactionNumber":"Transaction Number", "amount":"Transaction Amount", "billAmountReceived":"Bill Agst Amount", "pendingAmount":"Pending Amount", "Due":"dueStatus", "dueDate":"Due Date", "dueDays":"Due Days"},
        headerRowTableHeaders: [{"headerName":"Udyam Number", "type":"string"},{"headerName":"Transaction Date", "type":"date"}, {"headerName":"Type", "type":"string"}, {"headerName":"Transaction Number", "type":"string"}, {"headerName":"Transaction Amount", "type":"number"}, {"headerName":"Bill Agst Amount", "type":"number"},{"headerName":"Pending Amount", "type":"number"},{"headerName":"Due", "type":"string"},{"headerName":"Due Date", "type":"date"},{"headerName":"Due Days", "type":"string"}],
        columns:['udyamNumber', 'transactionDate', 'transactionListId', 'mergeTransactionNumber', 'amount', 'billAmountReceived', 'pendingAmount', 'dueStatus', 'dueDate', 'dueDays'],
        tileViewFooterHeaders:null,
        tileContent:`<div>

           <v-list three-line nopadding>

                <div v-for="(item, index) in propsObj.reportData.detailRows" :key="index">
                    <v-subheader class="white-background subheader-semi-dense" v-if="item.cols.isGroup">{{item.cols.party}}</v-subheader>
                    <v-list-tile class="right-subtitle-tile" v-if="!item.cols.isGroup">

                        <v-list-tile-content>
                            <v-list-tile-title class="mb-1">{{item.cols.udyamNumber}}</v-list-tile-title>
                            <v-list-tile-sub-title class="mb-1">{{item.cols.mergeTransactionNumber}} | {{item.cols.transactionDate | moment("DD-MMM-YYYY")}}</v-list-tile-sub-title>
                            <v-list-tile-sub-title class="mb-1">Due in {{$root.numberToLocale(item.cols.dueDays)}} days</v-list-tile-sub-title>
                        </v-list-tile-content>

                        <v-list-tile-action align-flex-end style="min-width:120px">
                            <v-btn icon flat @click="fn_expandMore(item)">
                                <v-icon>more_horiz</v-icon>
                            </v-btn>
                            <span :class="[item.cols.balanceType == 'To Pay' ? 'redcolor' : 'greencolor']">&#8377; {{$root.numberToLocale(item.cols.pendingAmount)}}</span>
                        </v-list-tile-action>
                    </v-list-tile>

                    <v-divider v-if="propsObj.reportData.detailRows.length-1 != index"></v-divider>
                </div>
            </v-list>
        </div>`,

        footerContent:null,
        detailReportId:null,
        period:'endDate',
        periodLabel:'As On',
        reportType:'general',
        filters:null,
        hideFilterBtn:false,
        openView:true,
    },

    601:{
        reportTitle:"Trial Balance",
        itemLabel:null,
        openDialog:false,
        headerRow: {"accountName":"Particulars","debitAmount":"Debit","creditAmount":"Credit"},
        headerRowTableHeaders: [{"headerName":"Particulars", "type":"string"},{"headerName":"Debit", "type":"number"},{"headerName":"Credit", "type":"number"}],
        columns:['accountName', 'debitAmount', 'creditAmount'],
        detailReportId:null,
        period:'year',
        periodLabel:'Period',
        reportType:'general',
        filters: null,
        hideFilterBtn:true
    },

    602:{
        reportTitle:"Profit and Loss",
        itemLabel:null,
        openDialog:false,
        headerRow: {"purchaseAccountName":"Particulars","purchaseAmount":"Amount","saleAccountName":"Particulars","saleAmount":"Amount"},
        headerRowTableHeaders: [{"headerName":"Particulars", "type":"string"},{"headerName":"Amount", "type":"number"},{"headerName":"Particulars", "type":"string"},{"headerName":"Amount", "type":"number"}],
        columns:['purchaseAccountName', 'purchaseAmount', 'saleAccountName', 'saleAmount'],
        detailReportId:null,
        period:'year',
        periodLabel:'Period',
        reportType:'general',
        filters: null,
        hideFilterBtn:true
    },

    603:{
        reportTitle:"Balance Sheet",
        itemLabel:null,
        openDialog:false,
        headerRow: {"liabilityAccountName":"Liabilities","liabilityAmount":"Amount","assetAccountName":"Assets","assetAmount":"Amount"},
        headerRowTableHeaders: [{"headerName":"Liabilities", "type":"string"},{"headerName":"Amount", "type":"number"},{"headerName":"Assets", "type":"string"},{"headerName":"Amount", "type":"number"}],
        columns:['liabilityAccountName', 'liabilityAmount', 'assetAccountName', 'assetAmount'],
        detailReportId:null,
        period:'year',
        periodLabel:'Period',
        reportType:'general',
        filters: null,
        hideFilterBtn:true
    },

    701:{
        reportTitle:"Partywise TDS",
        itemLabel:null,
        openDialog:false,
        headerRow: {"tdsAccountName":"Section","partyName":"Party","partyPanNumber":"PAN","amount":"Amount Paid", "transactionDate":"Date", "rate":"Percentage", "tds":"TDS"},
        headerRowTableHeaders: [{"headerName":"Section", "type":"string"},{"headerName":"Party", "type":"string"},{"headerName":"PAN", "type":"string"},{"headerName":"Amount Paid", "type":"number"},{"headerName":"Date", "type":"date"},{"headerName":"Percentage", "type":"number"},{"headerName":"TDS", "type":"number"}],
        columns:['tdsAccountName', 'partyName', 'partyPanNumber', 'amount', 'transactionDate', 'rate', 'tds'],
        tileViewFooterHeaders:{"tds":"TDS"},
        tileContent:`<div>

            <v-list three-line nopadding>

                <div v-for="(item, index) in propsObj.reportData.detailRows" :key="index">
                    <v-list-tile class="right-subtitle-tile">

                        <v-list-tile-content @click="fn_getDetails(item)">
                            <v-list-tile-title class="mb-1">
                                <template v-if="item.cols.partyName">{{item.cols.party}}</template>
                                <template v-if="!item.cols.partyName && item.transactionListId == 20005">Cash A/c</template>
                            </v-list-tile-title>
                            <v-list-tile-sub-title>{{item.cols.transactionDate | moment("DD-MMM-YYYY")}}</v-list-tile-sub-title>
                            <v-list-tile-sub-title class="mb-1">{{item.cols.mergeTransactionNumber}} | {{item.cols.tdsAccountName}}</v-list-tile-sub-title>
                        </v-list-tile-content>

                        <v-list-tile-action align-flex-end>

                            <v-btn icon text @click="fn_expandMore(item)">
                                <v-icon>more_horiz</v-icon>
                            </v-btn>

                            <div class="light-opacity-color">
                                <span>&#8377; {{$root.numberToLocale(item.cols.tds)}}</span>
                            </div>
                        </v-list-tile-action>
                    </v-list-tile>

                    <v-divider v-if="propsObj.reportData.detailRows.length-1 != index"></v-divider>
                </div>
            </v-list>
        </div>`,

        footerContent:`<div>
            <v-subheader subheader-semi-dense font16>Total
              <v-spacer></v-spacer>
              &#8377; {{$root.numberToLocale(propsObj.reportData.footerRow.tds)}}
            </v-subheader>
        </div>`,
        detailReportId:null,
        period:'month',
        periodLabel:'Period',
        reportType:'general',
        filters: null,
        openView:true,
        hideFilterBtn:true
    },

    // 801:{
    //     reportTitle:"GSTR-1",
    //     itemLabel:null,
    //     openDialog:false,
    //     headerRow: {"gstR1Title":"Particulars","voucherCount":"Voucher Count","totalTaxableValue":"Taxable Value","totalTaxAmount":"Tax Amount", "totalInvoiceAmount":"Invoice Amount"},
    //     headerRowTableHeaders: [{"headerName":"Particulars", "type":"string"},{"headerName":"Voucher Count", "type":"number"},{"headerName":"Taxable Value", "type":"number"},{"headerName":"Tax Amount", "type":"number"},{"headerName":"Invoice Amount", "type":"number"}],
    //     columns:['gstR1Title', 'voucherCount', 'totalTaxableValue', 'totalTaxAmount', 'totalInvoiceAmount'],
    //     detailReportId:null,
    //     period:'year',
    //     periodLabel:'Period',
    //     reportType:'general',
    //     filters: null,
    //     hideFilterBtn:true
    // },

    801:{
        reportTitle:"GSTR-1",
        itemLabel:null,
        openDialog:false,
        headerRow: {"gstR1Title":"Particulars","voucherCount":"Voucher Count","totalTaxableValue":"Taxable Value","totalTaxAmount":"Tax Amount", "totalInvoiceAmount":"Invoice Amount"},
        headerRowTableHeaders: [{"headerName":"Particulars", "type":"string"},{"headerName":"Voucher Count", "type":"number"},{"headerName":"Taxable Value", "type":"number"},{"headerName":"Tax Amount", "type":"number"},{"headerName":"Invoice Amount", "type":"number"}],
        columns:['gstR1Title', 'voucherCount', 'totalTaxableValue', 'totalTaxAmount', 'totalInvoiceAmount'],

        gstPortalReportObj:{
            b2b:{
                title:"Summary For B2B, SEZ, DE (4A, 4B, 6B, 6C)",
                sheetTitle:"b2b",
                headerRow: {"gstNumber":"GSTIN/UIN of Recipient", "accountName":"Receiver Name", "mergeTransactionNumber":"Invoice Number", "transactionDate":"Invoice date", "roundedAmount":"Invoice Value", "placeOfSupply":"Place Of Supply", "reverseCharge":"Reverse Charge", "applicableRate":"Applicable % of Tax Rate", "exportType":"Invoice Type", "ecommerceGstNumber":"E-Commerce GSTIN", "percentage":"Rate", "taxableValue":"Taxable Value", "cess":"Cess Amount"},
            },
            b2cl:{
                title:"Summary For B2CL(5)",
                sheetTitle:"b2cl",
                headerRow: {"mergeTransactionNumber":"Invoice Number", "transactionDate":"Invoice date", "roundedAmount":"Invoice Value", "placeOfSupply":"Place Of Supply", "applicableRate":"Applicable % of Tax Rate", "percentage":"Rate", "taxableValue":"Taxable Value", "cess":"Cess Amount", "ecommerceGstNumber":"E-Commerce GSTIN"},
            },
            b2cs:{
                title:"Summary For B2CS(7)",
                sheetTitle:"b2cs",
                headerRow: {"type":"Type", "placeOfSupply":"Place Of Supply", "applicableRate":"Applicable % of Tax Rate", "percentage":"Rate", "taxableValue":"Taxable Value", "cess":"Cess Amount", "eCommerceGstNumber":"E-Commerce GSTIN"},
            },
            cdnr:{
                title:"Summary For CDNR(9B)",
                sheetTitle:"cdnr",
                headerRow: {"gstNumber":"GSTIN/UIN of Recipient", "accountName":"Receiver Name", "mergeTransactionNumber":"Note Number", "transactionDate":"Note Date", "noteType":"Note Type", "placeOfSupply":"Place Of Supply", "reverseCharge":"Reverse Charge", "supplyType":"Note Supply Type", "roundedAmount":"Note Value", "applicableRate":"Applicable % of Tax Rate", "percentage":"Rate", "taxableValue":"Taxable Value", "cess":"Cess Amount"},
            },
            cdnur:{
                title:"Summary For CDNUR(9B)",
                sheetTitle:"cdnur",
                headerRow: {"urType":"UR Type", "mergeTransactionNumber":"Note Number", "transactionDate":"Note Date", "noteType":"Note Type", "placeOfSupply":"Place Of Supply", "roundedAmount":"Note Value", "applicableRate":"Applicable % of Tax Rate", "percentage":"Rate", "taxableValue":"Taxable Value", "cess":"Cess Amount"},
            },
            exp:{
                title:"Summary For EXP(6)",
                sheetTitle:"exp",
                headerRow: {"exportType":"Export Type", "mergeTransactionNumber":"Invoice Number", "transactionDate":"Invoice date", "roundedAmount":"Invoice Value", "portCode":"Port Code", "shippingBillNumber":"Shipping Bill Number", "shippingBillDate":"Shipping Bill Date",  "percentage":"Rate", "taxableValue":"Taxable Value", "cess":"Cess Amount"},
            },
            exemp:{
                title:"Summary For Nil rated, exempted and non GST outward supplies (8)",
                sheetTitle:"exemp",
                headerRow: {"description":"Description", "nillRatedAmount":"Nil Rated Supplies", "exemptedAmount":"Exempted(other than nil rated/non GST supply)", "nonGstAmount":"Non-GST Supplies"},
            },
            hsn:{
                title:"Summary For HSN(12)",
                sheetTitle:"hsn",
                headerRow: {"hsnCode":"HSN", "description":"Description", "unitQtyCode":"UQC", "qty":"Total Quantity", "roundedAmount":"Total Value", "percentage":"Rate", "taxableValue":"Taxable Value", "igst":"Integrated Tax Amount", "cgst":"Central Tax Amount", "stgst":"State/UT Tax Amount", "cess":"Cess Amount"},
            },
            docs:{
                title:"Summary of documents issued during the tax period (13)",
                sheetTitle:"docs",
                headerRow: {"documentNature":"Nature of Document", "mergeTransactionNumberFrom":"Sr. No. From", "mergeTransactionNumberTo":"Sr. No. To", "totalNumber":"Total Number", "cancelled":"Cancelled"},
            },
        },
        

        tileContent:`<div>

            <div v-for="(item, index) in propsObj.reportData.detailRows" :key="index" @click="fn_getDetails(item)">

                <v-card v-ripple flat class="mt-2 mr-2 ml-2" font16>

                    <v-card-title noshadow>
                        <div full-width class="font-medium">{{item.cols.gstR1Title}}</div>
                    </v-card-title>
                    <v-divider></v-divider>
                    <v-card-text>
                        
                        <v-layout row wrap>

                            <template v-if="item.subReportId == 'GSTR1EXEMPT'">
                                <v-flex xs6 sm6 md6>
                                    <div font12 light-opacity-color>Total Nil Amount</div>
                                    <div>&#8377; {{item.cols.totalTaxableValue ? $root.numberToLocale(item.cols.totalTaxableValue) : '0'}}</div>
                                </v-flex>

                                <v-flex xs6 sm6 md6>
                                    <div font12 light-opacity-color>Total Exempted Amount</div>
                                    <div>&#8377; 0</div>
                                </v-flex>

                                <v-flex xs6 sm6 md6>
                                    <div font12 light-opacity-color>Total Non-GST Amount</div>
                                    <div>&#8377; 0</div>
                                </v-flex>
                            </template>

                            <template v-else-if="item.subReportId == 'GSTR1DOCUMENT'">
                                <v-flex xs6 sm6 md6>
                                    <div font12 light-opacity-color>Total Docs</div>
                                    <div>{{item.cols.totalInvoiceAmount ? $root.numberToLocale(item.cols.totalDocs) : '0'}}</div>
                                </v-flex>

                                <v-flex xs6 sm6 md6>
                                    <div font12 light-opacity-color>Cancelled Docs</div>
                                    <div>{{item.cols.totalTaxableValue ? $root.numberToLocale(item.cols.cancelDocs) : '0'}}</div>
                                </v-flex>

                                <v-flex xs6 sm6 md6>
                                    <div font12 light-opacity-color>Net Issued Docs</div>
                                    <div>{{item.cols.totalTaxAmount ? $root.numberToLocale(item.cols.netIssueDocs) : '0'}}</div>
                                </v-flex>
                            </template>

                            <template v-else>
                                <v-flex xs6 sm6 md6 v-if="item.subReportId != 'GSTR1HSN'">
                                    <div font12 light-opacity-color>Total Value</div>
                                    <div>&#8377; {{item.cols.totalInvoiceAmount ? $root.numberToLocale(item.cols.totalInvoiceAmount) : '0'}}</div>
                                </v-flex>

                                <v-flex xs6 sm6 md6>
                                    <div font12 light-opacity-color>Total Taxable Value</div>
                                    <div>&#8377; {{item.cols.totalTaxableValue ? $root.numberToLocale(item.cols.totalTaxableValue) : '0'}}</div>
                                </v-flex>

                                <v-flex xs6 sm6 md6>
                                    <div font12 light-opacity-color>Total Tax Liability</div>
                                    <div>&#8377; {{item.cols.totalTaxAmount ? $root.numberToLocale(item.cols.totalTaxAmount) : '0'}}</div>
                                </v-flex>
                            </template>
                        </v-layout>

                    </v-card-text>
                    
                </v-card>

            </div>

        </div>`,

        footerContent:null,
        detailReportId:null,
        period:'year',
        periodLabel:'Period',
        reportType:'general',
        filters: null,
        hideFilterBtn:true
    },

    "GSTR1B2B":{
        reportTitle:"Summary For B2B (4)",
        headerRow:{"gstNumber":"GSTIN/UIN of Recipient", "accountName":"Receiver Name", "mergeTransactionNumber":"Invoice Number", "transactionDate":"Invoice Date", "placeOfSupply":"Place Of Supply", "roundedAmount":"Invoice Value", "supplyType":"Supply Type"},
        headerRowTableHeaders: [{"headerName":"GSTIN/UIN of Recipient", "type":"string"},{"headerName":"Receiver Name", "type":"string"},{"headerName":"Invoice Number", "type":"string"},{"headerName":"Invoice Date", "type":"date"},{"headerName":"Place Of Supply", "type":"string"},{"headerName":"Invoice Value", "type":"number"},{"headerName":"Supply Type", "type":"string"}],
        columns:['gstNumber', 'accountName', 'mergeTransactionNumber', 'transactionDate', 'placeOfSupply', 'roundedAmount', 'supplyType'],
        // internalHeaderRow:{"total":null, "percentage":"Rate(%)", "taxableValue":"Taxable Value", "igst":"Integrated Tax", "cgst":"Central Tax", "sgst":"State/UT Tax", "cess":"CESS"},
        // internalHeaderRowArr:[{"headerName":null,"type":"string"},{"headerName":"Rate(%)","type":"number"},{"headerName":"Taxable Value","type":"number","rupeeSymbol":true},{"headerName":"Integrated Tax","type":"number", "rupeeSymbol":true},{"headerName":"Central Tax","type":"number", "rupeeSymbol":true},{"headerName":"State/UT Tax","type":"number"},{"headerName":"CESS","type":"number"}],
        internalHeaderRow:{"total":null, "percentage":"Rate(%)", "taxableValue":"Taxable Value", "igst":"Integrated Tax", "cgst":"Central Tax", "sgst":"State/UT Tax", "cess":"CESS"},
        internalHeaderRowArr:[{"headerName":null,"type":"string"},{"headerName":"Rate(%)","type":"number"},{"headerName":"Taxable Value","type":"number","rupeeSymbol":true},{"headerName":"Integrated Tax","type":"number", "rupeeSymbol":true},{"headerName":"Central Tax","type":"number", "rupeeSymbol":true},{"headerName":"State/UT Tax","type":"number","rupeeSymbol":true},{"headerName":"CESS","type":"number","rupeeSymbol":true}],

        internalColumns:["total", "percentage", "taxableValue", "igst", "cgst", "sgst", "cess"]
    },

    "GSTR1B2CL":{
        reportTitle:"Summary For B2CL (5)",
        headerRow:{"placeOfSupply":"Place Of Supply", "mergeTransactionNumber":"Invoice Number", "transactionDate":"Invoice Date", "supplyType":"Supply Type", "roundedAmount":"Invoice Value"},
        headerRowTableHeaders: [{"headerName":"Place Of Supply", "type":"string"},{"headerName":"Invoice Number", "type":"string"},{"headerName":"Invoice Date", "type":"date"},{"headerName":"Supply Type", "type":"string"},{"headerName":"Invoice Value", "type":"number"}],
        columns:['placeOfSupply', 'mergeTransactionNumber', 'transactionDate', 'supplyType', 'roundedAmount'],
        // internalHeaderRow:{"total":null, "percentage":"Rate(%)", "taxableValue":"Taxable Value", "igst":"Integrated Tax", "cess":"CESS"},
        // internalHeaderRowArr:[{"headerName":null,"type":"string"},{"headerName":"Rate(%)","type":"number"},{"headerName":"Taxable Value","type":"number"},{"headerName":"Integrated Tax","type":"number"},{"headerName":"CESS","type":"number"}],
        internalHeaderRow:{"total":null, "percentage":"Rate(%)", "taxableValue":"Taxable Value", "igst":"Integrated Tax", "cess":"CESS"},
        internalHeaderRowArr:[{"headerName":null,"type":"string"},{"headerName":"Rate(%)","type":"number"},{"headerName":"Taxable Value","type":"number", "rupeeSymbol":true},{"headerName":"Integrated Tax","type":"number", "rupeeSymbol":true},{"headerName":"CESS","type":"number", "rupeeSymbol":true}],

        internalColumns:["total", "percentage", "taxableValue", "igst", "cess"]
    },

    "GSTR1B2CS":{
        reportTitle:"Summary For B2CS (7)",
        headerRow:{"placeOfSupply":"Place Of Supply", "supplyType":null, "percentage":null, "igst":null, "cgst":null, "sgst":null, "cess":null},
        headerRowTableHeaders: [{"headerName":"Place Of Supply", "type":"string"},{"headerName":"", "type":"string"},{"headerName":"", "type":"string"},{"headerName":"", "type":"string"},{"headerName":"", "type":"number"},{"headerName":"", "type":"number"},{"headerName":"", "type":"number"}],
        columns:['placeOfSupply'],
        // internalHeaderRow:{"taxableAmount":"Taxable Value", "supplyType":"Supply Type", "percentage":"Rate(%)", "igst":"Integrated Tax", "cgst":"Central Tax", "sgst":"State/UT Tax", "cess":"CESS"},
        // internalHeaderRowArr:[{"headerName":"Taxable Value","type":"number"},{"headerName":"Supply Type","type":"string"},{"headerName":"Rate(%)","type":"number"},{"headerName":"Integrated Tax","type":"number"},{"headerName":"Central Tax","type":"number"},{"headerName":"State/UT Tax","type":"number"},{"headerName":"CESS","type":"number"}],
        internalHeaderRow:{"taxableAmount":"Taxable Value", "supplyType":"Supply Type", "percentage":"Rate(%)", "igst":"Integrated Tax", "cgst":"Central Tax", "sgst":"State/UT Tax", "cess":"CESS"},
        internalHeaderRowArr:[{"headerName":"Taxable Value","type":"number"},{"headerName":"Supply Type","type":"string"},{"headerName":"Rate(%)","type":"number"},{"headerName":"Integrated Tax","type":"number", "rupeeSymbol":true},{"headerName":"Central Tax","type":"number", "rupeeSymbol":true},{"headerName":"State/UT Tax","type":"number", "rupeeSymbol":true},{"headerName":"CESS","type":"number", "rupeeSymbol":true}],

        internalColumns:['taxableAmount', 'supplyType', 'percentage', 'igst', 'cgst', 'sgst', 'cess']
    },

    "GSTR1CDNR":{
        reportTitle:"Summary For CDNR (9B)",
        headerRow:{"gstNumber":"GSTIN/UIN of Recipient", "accountName":"Receiver Name", "mergeTransactionNumber":"Debit/Credit Note No", "transactionDate":"Debit/Credit Note Date", "referenceTransactionNumber":"Original Invoice Number", "referenceTransactionDate":"Original Invoice Date", "noteType":"Note Type", "noteValue":"Note Value", "supplyType":"Supply Type"},
        headerRowTableHeaders: [{"headerName":"GSTIN/UIN of Recipient", "type":"string"},{"headerName":"Receiver Name", "type":"string"},{"headerName":"Debit/Credit Note No", "type":"string"},{"headerName":"Debit/Credit Note Date", "type":"date"},{"headerName":"Original Invoice Number", "type":"string"},{"headerName":"Original Invoice Date", "type":"date"},{"headerName":"Note Type", "type":"string"},{"headerName":"Note Value", "type":"number"},{"headerName":"Supply Type", "type":"string"}],
        columns:['gstNumber', 'accountName', 'mergeTransactionNumber', 'transactionDate', 'referenceTransactionNumber', 'referenceTransactionDate', 'noteType', 'noteValue', 'supplyType'],
        internalHeaderRow:{"total":null, "percentage":"Rate(%)", "taxableValue":"Taxable Value", "igst":"Integrated Tax", "cgst":"Central Tax", "sgst":"State/UT Tax", "cess":"CESS"},
        internalHeaderRowArr:[{"headerName":null,"type":"string"},{"headerName":"Rate(%)","type":"number"},{"headerName":"Taxable Value","type":"number", "rupeeSymbol":true},{"headerName":"Integrated Tax","type":"number", "rupeeSymbol":true},{"headerName":"Central Tax","type":"number", "rupeeSymbol":true},{"headerName":"State/UT Tax","type":"number", "rupeeSymbol":true},{"headerName":"CESS","type":"number", "rupeeSymbol":true}],

        internalColumns:["total", "percentage", "taxableValue", "igst", "cgst", "sgst", "cess"]
    },

    "GSTR1CDUNR":{
        reportTitle:"Summary For CDNUR (9B)",
        headerRow:{"type":"Type", "mergeTransactionNumber":"Debit/Credit Note No", "transactionDate":"Debit/Credit Note Date", "referenceTransactionNumber":"Original Invoice Number", "referenceTransactionDate":"Original Invoice Date", "noteType":"Note Type", "noteValue":"Note Value", "supplyType":"Supply Type"},

        headerRowTableHeaders: [{"headerName":"Type", "type":"string"},{"headerName":"Debit/Credit Note No", "type":"string"},{"headerName":"Debit/Credit Note Date", "type":"date"},{"headerName":"Original Invoice Number", "type":"string"},{"headerName":"Original Invoice Date", "type":"date"},{"headerName":"Note Type", "type":"string"},{"headerName":"Note Value", "type":"number"},{"headerName":"Supply Type", "type":"string"}],

        columns:['type', 'mergeTransactionNumber', 'transactionDate', 'referenceTransactionNumber', 'referenceTransactionDate', 'noteType', 'noteValue', 'supplyType'],
        internalHeaderRow:{"total":null, "percentage":"Rate(%)", "taxableValue":"Taxable Value", "igst":"Integrated Tax", "cess":"CESS"},
        internalHeaderRowArr:[{"headerName":null,"type":"string"},{"headerName":"Rate(%)","type":"number"},{"headerName":"Taxable Value","type":"number", "rupeeSymbol":true},{"headerName":"Integrated Tax","type":"number", "rupeeSymbol":true},{"headerName":"CESS","type":"number", "rupeeSymbol":true}],

        internalColumns:["total", "percentage", "taxableValue", "igst", "cess"]
    },

    "GSTR1EXPORT":{
        reportTitle:"Summary For EXP (6)",
        headerRow:{"mergeTransactionNumber":"Invoice Number", "transactionDate":"Invoice Date", "portCode":"Port Code", "shippingBillNumber":"Shipping Bill No./Bill of Export No", "shippingBillDate":"Shipping Bill Date/Bill of Export Date", "roundedAmount":"Total Invoice Value", "supplyType":"Supply Type", "gstPayment":"GST Payment"},

        headerRowTableHeaders: [{"headerName":"Invoice Number", "type":"string"},{"headerName":"Invoice Date", "type":"date"},{"headerName":"Port Code", "type":"string"},{"headerName":"Shipping Bill No./Bill of Export No", "type":"string"},{"headerName":"Shipping Bill Date/Bill of Export Date", "type":"date"},{"headerName":"Total Invoice Value", "type":"number"},{"headerName":"Supply Type", "type":"string"},{"headerName":"GST Payment", "type":"string"}],

        columns:['mergeTransactionNumber', 'transactionDate', 'portCode', 'shippingBillNumber', 'shippingBillDate', 'roundedAmount', 'supplyType', 'gstPayment'],
        internalHeaderRow:{"total":null, "percentage":"Rate(%)", "taxableValue":"Taxable Value", "igst":"Integrated Tax", "cess":"CESS"},
        internalHeaderRowArr:[{"headerName":null,"type":"string"},{"headerName":"Rate(%)","type":"number"},{"headerName":"Taxable Value","type":"number", "rupeeSymbol":true},{"headerName":"Integrated Tax","type":"number", "rupeeSymbol":true},{"headerName":"CESS","type":"number", "rupeeSymbol":true}],

        internalColumns:["total", "percentage", "taxableValue", "igst", "cess"]
    },

    "GSTR1EXEMPT":{
        reportTitle:"Summary For Nil rated, exempted and non GST outward supplies (8)",
        // headerRow:{"description":"Description", "nillRatedAmount":"Nil Rated Supplies", "exemptedAmount":"Exempted(Other than Nil rated/non-GST supply)", "nonGstAmount":"Non-GST Supplies"},
        // headerRowTableHeaders: [{"headerName":"Description", "type":"string"},{"headerName":"Nil Rated Supplies", "type":"number"},{"headerName":"Exempted(Other than Nil rated/non-GST supply)", "type":"number"},{"headerName":"Non-GST Supplies", "type":"number"}],
        headerRow:{"description":"Description", "nillRatedAmount":"Nil Rated Supplies", "exemptedAmount":"Exempted(Other than Nil rated/non-GST supply)", "nonGstAmount":"Non-GST Supplies"},
        headerRowTableHeaders: [{"headerName":"Description", "type":"string"},{"headerName":"Nil Rated Supplies", "type":"number", "rupeeSymbol":true},{"headerName":"Exempted(Other than Nil rated/non-GST supply)", "type":"number", "rupeeSymbol":true},{"headerName":"Non-GST Supplies", "type":"number", "rupeeSymbol":true}],

        columns:['description', 'nillRatedAmount', 'exemptedAmount', 'nonGstAmount'],
        internalHeaderRow:null,
        internalColumns:null
    },

    "GSTR1HSN":{
        reportTitle:"Summary For HSN (12)",
        headerRow:{"hsnCode":"HSN", "description":"Description", "unitQtyCode":"UQC", "qty":"Total Quantity", "taxableValue":"Total Taxable Value", "percentage":"Rate (%)", "igst":"Integrated Tax", "cgst":"Central Tax", "stgst":"State/UT Tax", "cess":"CESS"},
        headerRowTableHeaders: [{"headerName":"HSN", "type":"string"},{"headerName":"Description", "type":"string"},{"headerName":"UQC", "type":"string"},{"headerName":"Total Quantity", "type":"number"},{"headerName":"Total Taxable Value","type":"number", "rupeeSymbol":true},{"headerName":"Rate (%)", "type":"number"},{"headerName":"Integrated Tax","type":"number", "rupeeSymbol":true},{"headerName":"Central Tax","type":"number", "rupeeSymbol":true},{"headerName":"State/UT Tax","type":"number", "rupeeSymbol":true},{"headerName":"CESS Tax","type":"number", "rupeeSymbol":true}],

        columns:['hsnCode', 'description', 'unitQtyCode', 'qty', 'taxableValue', "percentage", 'igst', 'cgst', 'stgst', 'cess'],
        internalHeaderRow:null,
        internalColumns:null
    },

    "GSTR1DOCUMENT":{
        reportTitle:"Summary of documents issued during the tax period (13)",
        headerRow:{"documentNature":"Nature of Document", "mergeTransactionNumberFrom":"Sr. No. From", "mergeTransactionNumberTo":"Sr. No. To", "totalNumber":"Total Number", "cancelled":"Cancelled", "netIssue":"Net Issue"},

        headerRowTableHeaders:[{"headerName":"Nature of Document","type":"string"},{"headerName":"Sr. No. From","type":"string"},{"headerName":"Sr. No. To","type":"string"},{"headerName":"Total number","type":"number"},{"headerName":"Cancelled","type":"string"},{"headerName":"Net Issue","type":"string"}],

        columns:['documentNature', 'mergeTransactionNumberFrom', 'mergeTransactionNumberTo', 'totalNumber', 'cancelled', 'netIssue'],
        internalHeaderRow:null,
        internalColumns:null
    },

    802:{
        reportTitle:"GSTR-2",
        itemLabel:null,
        openDialog:false,
        headerRow: {"gstR2Title":"Particulars","voucherCount":"Voucher Count","totalTaxableValue":"Taxable Value","totalTaxAmount":"Tax Amount", "totalInvoiceAmount":"Invoice Amount"},
        headerRowTableHeaders: [{"headerName":"Particulars", "type":"string"},{"headerName":"Voucher Count", "type":"number"},{"headerName":"Taxable Value", "type":"number"},{"headerName":"Tax Amount", "type":"number"},{"headerName":"Invoice Amount", "type":"number"}],
        columns:['gstR2Title', 'voucherCount', 'totalTaxableValue', 'totalTaxAmount', 'totalInvoiceAmount'],
        detailReportId:null,
        period:'year',
        periodLabel:'Period',
        reportType:'general',
        filters: null,
        hideFilterBtn:true
    },

    "GSTR2B2B":{
        reportTitle:"B2B Invoices - 3, 4A",
        headerRow:{"gstNumber":"GSTIN/UIN of Supplier", "accountName":"Supplier Name", "mergeTransactionNumber":"Invoice Number", "transactionDate":"Invoice Date", "placeOfSupply":"Place Of Supply", "supplierTransactionNumber":"Supplier Invoice No", "roundedAmount":"Invoice Value", "supplyType":"Supply Type"},

        headerRowTableHeaders:[{"headerName":"GSTIN/UIN of Supplier","type":"string"},{"headerName":"Supplier Name","type":"string"},{"headerName":"Invoice Number","type":"string"},{"headerName":"Invoice Date","type":"date"},{"headerName":"Place Of Supply","type":"string"},{"headerName":"Supplier Invoice No","type":"string"},{"headerName":"Invoice Value","type":"number"},{"headerName":"Supply Type","type":"string"}],

        columns:['gstNumber', 'accountName', 'mergeTransactionNumber', 'transactionDate', 'placeOfSupply', 'supplierTransactionNumber', 'roundedAmount', 'supplyType'],
        internalHeaderRow:{"total":null, "percentage":"Rate(%)", "taxableValue":"Taxable Value", "igst":"Integrated Tax", "cgst":"Central Tax", "sgst":"State/UT Tax", "cess":"CESS"},
        internalHeaderRowArr:[{"headerName":null,"type":"string"},{"headerName":"Rate(%)","type":"number"},{"headerName":"Taxable Value","type":"number", "rupeeSymbol":true},{"headerName":"Integrated Tax","type":"number", "rupeeSymbol":true},{"headerName":"Central Tax","type":"number", "rupeeSymbol":true},{"headerName":"State/UT Tax","type":"number", "rupeeSymbol":true},{"headerName":"CESS","type":"number", "rupeeSymbol":true}],

        internalColumns:["total", "percentage", "taxableValue", "igst", "cgst", "sgst", "cess"]
    },

    "GSTR2B2BUR":{
        reportTitle:"B2BUR Invoice - 4B",
        headerRow:{"gstNumber":"GSTIN/UIN of Supplier", "accountName":"Supplier Name", "mergeTransactionNumber":"Invoice Number", "transactionDate":"Invoice Date", "placeOfSupply":"Place Of Supply", "supplierTransactionNumber":"Supplier Invoice No", "roundedAmount":"Invoice Value", "supplyType":"Supply Type"},

        headerRowTableHeaders:[{"headerName":"GSTIN/UIN of Supplier","type":"string"},{"headerName":"Supplier Name","type":"string"},{"headerName":"Invoice Number","type":"string"},{"headerName":"Invoice Date","type":"date"},{"headerName":"Place Of Supply","type":"string"},{"headerName":"Supplier Invoice No","type":"string"},{"headerName":"Invoice Value","type":"number"},{"headerName":"Supply Type","type":"string"}],

        columns:['gstNumber', 'accountName', 'mergeTransactionNumber', 'transactionDate', 'placeOfSupply', 'supplierTransactionNumber', 'roundedAmount', 'supplyType'],
        internalHeaderRow:{"total":null, "percentage":"Rate(%)", "taxableValue":"Taxable Value", "igst":"Integrated Tax", "cgst":"Central Tax", "sgst":"State/UT Tax", "cess":"CESS"},

        internalHeaderRowArr:[{"headerName":null,"type":"string"},{"headerName":"Rate(%)","type":"number"},{"headerName":"Taxable Value","type":"number", "rupeeSymbol":true},{"headerName":"Integrated Tax","type":"number", "rupeeSymbol":true},{"headerName":"Central Tax","type":"number", "rupeeSymbol":true},{"headerName":"State/UT Tax","type":"number", "rupeeSymbol":true},{"headerName":"CESS","type":"number", "rupeeSymbol":true}],

        internalColumns:["total", "percentage", "taxableValue", "igst", "cgst", "sgst", "cess"]
    },

    "GSTR2CDNR":{
        reportTitle:"Summary For CDNR (6C)",
        headerRow:{"gstNumber":"GSTIN/UIN of Supplier", "accountName":"Supplier Name", "mergeTransactionNumber":"Debit/Credit Note No", "transactionDate":"Debit/Credit Note Date", "referenceTransactionNumber":"Original Invoice Number", "referenceTransactionDate":"Original Invoice Date", "noteType":"Note Type", "noteValue":"Note Value", "supplyType":"Supply Type"},

        headerRowTableHeaders:[{"headerName":"GSTIN/UIN of Supplier","type":"string"},{"headerName":"Supplier Name","type":"string"},{"headerName":"Debit/Credit Note No","type":"string"},{"headerName":"Debit/Credit Note Date","type":"date"},{"headerName":"Original Invoice Number","type":"string"},{"headerName":"Original Invoice Date","type":"date"},{"headerName":"Note Type","type":"string"},{"headerName":"Note Value","type":"string"},{"headerName":"Supply Type","type":"string"}],

        columns:['gstNumber', 'accountName', 'mergeTransactionNumber', 'transactionDate', 'referenceTransactionNumber', 'referenceTransactionDate', 'noteType', 'noteValue', 'supplyType'],
        internalHeaderRow:{"total":null, "percentage":"Rate(%)", "taxableValue":"Taxable Value", "igst":"Integrated Tax", "cgst":"Central Tax", "sgst":"State/UT Tax", "cess":"CESS"},
        internalHeaderRowArr:[{"headerName":null,"type":"string"},{"headerName":"Rate(%)","type":"number"},{"headerName":"Taxable Value","type":"number", "rupeeSymbol":true},{"headerName":"Integrated Tax","type":"number", "rupeeSymbol":true},{"headerName":"Central Tax","type":"number", "rupeeSymbol":true},{"headerName":"State/UT Tax","type":"number", "rupeeSymbol":true},{"headerName":"CESS","type":"number", "rupeeSymbol":true}],

        internalColumns:["total", "percentage", "taxableValue", "igst", "cgst", "sgst", "cess"]
    },

    "GSTR2IMPORTSERVICE":{
        reportTitle:"Import of Services - 4C",
        headerRow:{"accountName":"Supplier Name", "mergeTransactionNumber":"Supplier Invoice Number", "transactionDate":"Date", "refTransactionDate":"Supplier Invoice Date", "roundedAmount":"Total Invoice Value"},

        headerRowTableHeaders:[{"headerName":"Supplier Name","type":"string"},{"headerName":"Supplier Invoice Number","type":"string"},{"headerName":"Date","type":"string"},{"headerName":"Supplier Invoice Date","type":"date"},{"headerName":"Total Invoice Value","type":"number"}],

        columns:['accountName', 'mergeTransactionNumber', 'transactionDate', 'refTransactionDate', 'roundedAmount'],
        internalHeaderRow:{"total":null, "percentage":"Rate(%)", "taxableValue":"Taxable Value", "igst":"Integrated Tax", "cess":"CESS"},

        internalHeaderRowArr:[{"headerName":null,"type":"string"},{"headerName":"Rate(%)","type":"number"},{"headerName":"Taxable Value","type":"number", "rupeeSymbol":true},{"headerName":"Integrated Tax","type":"number", "rupeeSymbol":true},{"headerName":"CESS","type":"number", "rupeeSymbol":true}],

        internalColumns:["total", "percentage", "taxableValue", "igst", "cess"]
    },

    "GSTR2IMPORTGOODS":{
        reportTitle:"Import of Goods - 5",
        headerRow:{"accountName":"Supplier Name", "transactionDate":"Invoice Date", "billEntryNumber":"Bill of Entry No.", "billEntryDate":"Bill of Entry Date", "portCode":"Port Code", "roundedAmount":"Total Invoice Value"},

        headerRowTableHeaders:[{"headerName":"Supplier Name","type":"string"},{"headerName":"Invoice Date","type":"date"},{"headerName":"Bill of Entry No.","type":"string"},{"headerName":"Bill of Entry Date","type":"date"},{"headerName":"Port Code","type":"string"},{"headerName":"Total Invoice Value","type":"number"}],

        columns:['accountName', 'transactionDate', 'billEntryNumber', 'billEntryDate', 'portCode', 'roundedAmount'],
        internalHeaderRow:{"total":null, "percentage":"Rate(%)", "taxableValue":"Taxable Value", "igst":"Integrated Tax", "cess":"CESS"},

        internalHeaderRowArr:[{"headerName":null,"type":"string"},{"headerName":"Rate(%)","type":"number"},{"headerName":"Taxable Value","type":"number", "rupeeSymbol":true},{"headerName":"Integrated Tax","type":"number", "rupeeSymbol":true},{"headerName":"CESS","type":"number", "rupeeSymbol":true}],

        internalColumns:["total", "percentage", "taxableValue", "igst", "cess"]
    },

    "GSTR2EXEMPT":{
        reportTitle:"Nil Rated Invoices - 7 - (Summary)",
        headerRow:{"description":"Description", "taxablePerson":"Composition Taxable Person", "nillRatedAmount":"Nil Rated Supplies", "exemptedAmount":"Exempted(Other than Nil rated/non-GST supply)", "nonGstAmount":"Non-GST Supplies"},

        headerRowTableHeaders:[{"headerName":"Description","type":"string"},{"headerName":"Composition Taxable Person","type":"string"},{"headerName":"Nil Rated Supplies","type":"number", "rupeeSymbol":true},{"headerName":"Exempted(Other than Nil rated/non-GST supply)","type":"number", "rupeeSymbol":true},{"headerName":"Non-GST Supplies","type":"number", "rupeeSymbol":true}],

        columns:['description', 'taxablePerson', 'nillRatedAmount', 'exemptedAmount', 'nonGstAmount'],
        internalHeaderRow:null,
        internalColumns:null
    },

    "GSTR2HSN":{
        reportTitle:"Summary For HSN (12)",
        headerRow:{"hsnCode":"HSN", "description":"Description", "unitQtyCode":"UQC", "qty":"Total Quantity", "taxableValue":"Total Taxable Value", "percentage":"Rate (%)", "igst":"Integrated Tax", "cgst":"Central Tax", "stgst":"State/UT Tax", "cess":"CESS"},

        headerRowTableHeaders:[{"headerName":"HSN","type":"string"},{"headerName":"Description","type":"string"},{"headerName":"UQC","type":"string"},{"headerName":"Total Quantity","type":"number"},{"headerName":"Total Taxable Value","type":"number", "rupeeSymbol":true},{"headerName":"Rate (%)","type":"number"},{"headerName":"Integrated Tax","type":"number", "rupeeSymbol":true},{"headerName":"Central Tax","type":"number", "rupeeSymbol":true},{"headerName":"State/UT Tax","type":"number", "rupeeSymbol":true},{"headerName":"CESS","type":"number", "rupeeSymbol":true}],

        columns:['hsnCode', 'description', 'unitQtyCode', 'qty', 'taxableValue', 'percentage', 'igst', 'cgst', 'stgst', 'cess'],
        internalHeaderRow:null,
        internalColumns:null
    },

    "GSTR2CDUNR":{
        reportTitle:"Credit/Debit Notes Unregistered Summary",
        headerRow:{"gstNumber":"GSTIN/UIN of Supplier", "accountName":"Supplier Name", "mergeTransactionNumber":"Debit/Credit Note No", "transactionDate":"Debit/Credit Note Date", "referenceTransactionNumber":"Original Invoice Number", "referenceTransactionDate":"Original Invoice Date", "noteType":"Note Type", "noteValue":"Note Value", "supplyType":"Supply Type"},

        headerRowTableHeaders:[{"headerName":"GSTIN/UIN of Supplier","type":"string"},{"headerName":"Supplier Name","type":"string"},{"headerName":"Debit/Credit Note No","type":"string"},{"headerName":"Debit/Credit Note Date","type":"date"},{"headerName":"Original Invoice Number","type":"string"},{"headerName":"Original Invoice Date","type":"date"},{"headerName":"Note Type","type":"string"},{"headerName":"Note Value","type":"string"},{"headerName":"Supply Type","type":"string"}],

        columns:['gstNumber', 'accountName', 'mergeTransactionNumber', 'transactionDate', 'referenceTransactionNumber', 'referenceTransactionDate', 'noteType', 'noteValue', 'supplyType'],
        internalHeaderRow:{"total":null, "percentage":"Rate(%)", "taxableValue":"Taxable Value", "igst":"Integrated Tax", "cgst":"Central Tax", "sgst":"State/UT Tax", "cess":"CESS"},
        internalHeaderRowArr:[{"headerName":null,"type":"string"},{"headerName":"Rate(%)","type":"number"},{"headerName":"Taxable Value","type":"number", "rupeeSymbol":true},{"headerName":"Integrated Tax","type":"number", "rupeeSymbol":true},{"headerName":"Central Tax","type":"number", "rupeeSymbol":true},{"headerName":"State/UT Tax","type":"number", "rupeeSymbol":true},{"headerName":"CESS","type":"number", "rupeeSymbol":true}],
        
        internalColumns:["total", "percentage", "taxableValue", "igst", "cgst", "sgst", "cess"]
    },

    803:{
        reportTitle:"GSTR-3B",
        itemLabel:null,
        openDialog:false,
        // headerRow: {"natureOfSupplies":"Nature of Supplies","taxableValue":"Total Taxable value (Rs)", "intraStateAmount":"Intra State Amount (Rs)", "interStateAmount":"Inter State Amount (Rs)", "igst":"Integrated Tax (Rs)","cgst":"Central Tax (Rs)", "sgst":"State/UT Tax (Rs)", "cess":"CESS (Rs)"},
        // headerRowTableHeaders: [{"headerName":"Nature of Supplies", "type":"string"},{"headerName":"Total Taxable value (Rs)", "type":"number"},{"headerName":"Intra State Amount (Rs)", "type":"number"},{"headerName":"Inter State Amount (Rs)", "type":"number"},{"headerName":"Integrated Tax (Rs)", "type":"number"},{"headerName":"Central Tax (Rs)", "type":"number"},{"headerName":"State/UT Tax (Rs)", "type":"number"},{"headerName":"CESS (Rs)", "type":"number"}],
        headerRow: {"natureOfSupplies":"Nature of Supplies","taxableValue":"Total Taxable value", "intraStateAmount":"Intra State Amount", "interStateAmount":"Inter State Amount", "igst":"Integrated Tax","cgst":"Central Tax", "sgst":"State/UT Tax", "cess":"CESS"},
        headerRowTableHeaders: [{"headerName":"Nature of Supplies", "type":"string"},{"headerName":"Total Taxable value", "type":"number", "rupeeSymbol":true},{"headerName":"Intra State Amount", "type":"number", "rupeeSymbol":true},{"headerName":"Inter State Amount", "type":"number", "rupeeSymbol":true},{"headerName":"Integrated Tax", "type":"number", "rupeeSymbol":true},{"headerName":"Central Tax", "type":"number", "rupeeSymbol":true},{"headerName":"State/UT Tax", "type":"number", "rupeeSymbol":true},{"headerName":"CESS", "type":"number", "rupeeSymbol":true}],
        columns:['natureOfSupplies', 'taxableValue', 'intraStateAmount', 'interStateAmount', 'igst', 'cgst', 'sgst', "cess"],

        tileContent:`<div>

            <div v-for="(item, index) in propsObj.reportData.gstr3BData.categories" :key="index" @click="fn_getDetails(item)">

                <v-card v-ripple flat class="mt-2 mr-2 ml-2" font16>

                    <v-card-title noshadow>
                        <div full-width class="font-medium">{{item.title}}</div>
                    </v-card-title>
                    <v-divider></v-divider>
                    <v-card-text>
                        
                        <v-layout row wrap>
                            <v-flex xs6 sm6 md6 v-for="(col, colIndex) in item.items" :key="colIndex">
                                <div font12 light-opacity-color>{{col.title}}</div>
                                <div>&#8377; {{$root.numberToLocale(col.value)}}</div>
                            </v-flex>
                        </v-layout>

                    </v-card-text>
                    
                </v-card>

            </div>

        </div>`,

        footerContent:null,
        detailReportId:null,
        period:'year',
        periodLabel:'Period',
        reportType:'general',
        filters: null,
        hideFilterBtn:true
    },

    "GSTR3B31A":{
        reportTitle:"3.1 (A) Outward taxable supplies (other than zero rated, nil rated and exempted)",
        headerRow:{"accountName":"Receiver Name", "mergeTransactionNumber":"Invoice Number", "transactionDate":"Invoice Date", "placeOfSupply":"Place Of Supply", "roundedAmount":"Invoice Value", "supplyType":"Supply Type"},
        headerRowTableHeaders: [{"headerName":"Receiver Name", "type":"string"},{"headerName":"Invoice Number", "type":"string"},{"headerName":"Invoice Date", "type":"date"},{"headerName":"Place Of Supply", "type":"string"},{"headerName":"Invoice Value", "type":"number"},{"headerName":"Supply Type", "type":"string"}],
        columns:['accountName', 'mergeTransactionNumber', 'transactionDate', 'placeOfSupply', 'roundedAmount', 'supplyType'],
        internalHeaderRow:{"total":null, "percentage":"Rate(%)", "taxableValue":"Taxable Value", "igst":"Integrated Tax", "cgst":"Central Tax", "sgst":"State/UT Tax", "cess":"CESS"},
        internalHeaderRowArr:[{"headerName":null,"type":"string"},{"headerName":"Rate(%)","type":"number"},{"headerName":"Taxable Value","type":"number", "rupeeSymbol":true},{"headerName":"Integrated Tax","type":"number", "rupeeSymbol":true},{"headerName":"Central Tax","type":"number", "rupeeSymbol":true},{"headerName":"State/UT Tax","type":"number", "rupeeSymbol":true},{"headerName":"CESS","type":"number", "rupeeSymbol":true}],
        internalColumns:["total", "percentage", "taxableValue", "igst", "cgst", "sgst", "cess"]
    },

    "GSTR3B31B":{
        reportTitle:"3.1 (B) Outward taxable supplies (zero rated )",
        headerRow:{"accountName":"Receiver Name", "mergeTransactionNumber":"Invoice Number", "transactionDate":"Invoice Date", "roundedAmount":"Invoice Value", "supplyType":"Supply Type"},
        headerRowTableHeaders: [{"headerName":"Receiver Name", "type":"string"},{"headerName":"Invoice Number", "type":"string"},{"headerName":"Invoice Date", "type":"date"},{"headerName":"Invoice Value", "type":"number"},{"headerName":"Supply Type", "type":"string"}],
        columns:['accountName', 'mergeTransactionNumber', 'transactionDate', 'roundedAmount', 'supplyType'],
        internalHeaderRow:{"total":null, "percentage":"Rate(%)", "taxableValue":"Taxable Value", "igst":"Integrated Tax", "cess":"CESS"},
        internalHeaderRowArr:[{"headerName":null,"type":"string"},{"headerName":"Rate(%)","type":"number"},{"headerName":"Taxable Value","type":"number", "rupeeSymbol":true},{"headerName":"Integrated Tax","type":"number", "rupeeSymbol":true},{"headerName":"CESS","type":"number", "rupeeSymbol":true}],
        internalColumns:["total", "percentage", "taxableValue", "igst", "cess"]
    },

    "GSTR3B31C":{
        reportTitle:"3.1 (C) Other outward supplies (Nil rated, exempted)",
        headerRow:{"accountName":"Receiver Name", "mergeTransactionNumber":"Invoice Number", "transactionDate":"Invoice Date", "placeOfSupply":"Place Of Supply", "roundedAmount":"Invoice Value", "supplyType":"Supply Type"},
        headerRowTableHeaders: [{"headerName":"Receiver Name", "type":"string"},{"headerName":"Invoice Number", "type":"string"},{"headerName":"Invoice Date", "type":"date"},{"headerName":"Place Of Supply", "type":"string"},{"headerName":"Invoice Value", "type":"number"},{"headerName":"Supply Type", "type":"string"}],
        columns:['accountName', 'mergeTransactionNumber', 'transactionDate', 'placeOfSupply', 'roundedAmount', 'supplyType'],
        internalHeaderRow:{"total":null, "percentage":"Rate(%)", "taxableValue":"Taxable Value", "igst":"Integrated Tax", "cgst":"Central Tax", "sgst":"State/UT Tax", "cess":"CESS"},
        internalHeaderRowArr:[{"headerName":null,"type":"string"},{"headerName":"Rate(%)","type":"number"},{"headerName":"Taxable Value","type":"number", "rupeeSymbol":true},{"headerName":"Integrated Tax","type":"number", "rupeeSymbol":true},{"headerName":"Central Tax","type":"number", "rupeeSymbol":true},{"headerName":"State/UT Tax","type":"number", "rupeeSymbol":true},{"headerName":"CESS","type":"number", "rupeeSymbol":true}],
        internalColumns:["total", "percentage", "taxableValue", "igst", "cgst", "sgst", "cess"]
    },

    "GSTR3B31D":{
        reportTitle:"3.1 (D) Inward supplies (liable to reverse charge)",
        headerRow:{"accountName":"Supplier Name", "mergeTransactionNumber":"Invoice Number", "transactionDate":"Invoice Date", "placeOfSupply":"Place Of Supply", "referenceTransactionNumber":"Supplier Invoice No", "roundedAmount":"Invoice Value", "supplyType":"Supply Type"},
        headerRowTableHeaders: [{"headerName":"Receiver Name", "type":"string"},{"headerName":"Invoice Number", "type":"string"},{"headerName":"Invoice Date", "type":"date"},{"headerName":"Place Of Supply", "type":"string"},{"headerName":"Supplier Invoice No", "type":"string"},{"headerName":"Invoice Value", "type":"number"},{"headerName":"Supply Type", "type":"string"}],
        columns:['accountName', 'mergeTransactionNumber', 'transactionDate', 'placeOfSupply', 'referenceTransactionNumber', 'roundedAmount', 'supplyType'],
        internalHeaderRow:{"total":null, "percentage":"Rate(%)", "taxableValue":"Taxable Value", "igst":"Integrated Tax", "cgst":"Central Tax", "sgst":"State/UT Tax", "cess":"CESS"},
        internalHeaderRowArr:[{"headerName":null,"type":"string"},{"headerName":"Rate(%)","type":"number"},{"headerName":"Taxable Value","type":"number", "rupeeSymbol":true},{"headerName":"Integrated Tax","type":"number", "rupeeSymbol":true},{"headerName":"Central Tax","type":"number", "rupeeSymbol":true},{"headerName":"State/UT Tax","type":"number", "rupeeSymbol":true},{"headerName":"CESS","type":"number", "rupeeSymbol":true}],
        internalColumns:["total", "percentage", "taxableValue", "igst", "cgst", "sgst", "cess"]
    },

    "GSTR3B32A":{
        reportTitle:"3.2 (A) Supplies made to unregistered Persons",
        headerRow:{"accountName":"Receiver Name", "mergeTransactionNumber":"Invoice Number", "transactionDate":"Invoice Date", "placeOfSupply":"Place Of Supply", "roundedAmount":"Invoice Value", "supplyType":"Supply Type"},
        headerRowTableHeaders: [{"headerName":"Receiver Name", "type":"string"},{"headerName":"Invoice Number", "type":"string"},{"headerName":"Invoice Date", "type":"date"},{"headerName":"Place Of Supply", "type":"string"},{"headerName":"Invoice Value", "type":"number"},{"headerName":"Supply Type", "type":"string"}],
        columns:['accountName', 'mergeTransactionNumber', 'transactionDate', 'placeOfSupply', 'roundedAmount', 'supplyType'],
        internalHeaderRow:{"total":null, "percentage":"Rate(%)", "taxableValue":"Taxable Value", "igst":"Integrated Tax", "cess":"CESS"},
        internalHeaderRowArr:[{"headerName":null,"type":"string"},{"headerName":"Rate(%)","type":"number"},{"headerName":"Taxable Value","type":"number", "rupeeSymbol":true},{"headerName":"Integrated Tax","type":"number", "rupeeSymbol":true},{"headerName":"CESS","type":"number", "rupeeSymbol":true}],
        internalColumns:["total", "percentage", "taxableValue", "igst", "cess"]
    },

    "GSTR3B32B":{
        reportTitle:"3.2 (B) Supplies made to Composition Taxable Person",
        headerRow:{"accountName":"Receiver Name", "mergeTransactionNumber":"Invoice Number", "transactionDate":"Invoice Date", "placeOfSupply":"Place Of Supply", "taxableValue":"Invoice Value", "supplyType":"Supply Type"},
        headerRowTableHeaders: [{"headerName":"Receiver Name", "type":"string"},{"headerName":"Invoice Number", "type":"string"},{"headerName":"Invoice Date", "type":"date"},{"headerName":"Place Of Supply", "type":"string"},{"headerName":"Invoice Value", "type":"number"},{"headerName":"Supply Type", "type":"string"}],
        columns:['accountName', 'mergeTransactionNumber', 'transactionDate', 'placeOfSupply', 'taxableValue', 'supplyType'],
        internalHeaderRow:{"total":null, "percentage":"Rate(%)", "taxableValue":"Taxable Value", "igst":"Integrated Tax", "cess":"CESS"},
        internalHeaderRowArr:[{"headerName":null,"type":"string"},{"headerName":"Rate(%)","type":"number"},{"headerName":"Taxable Value","type":"number", "rupeeSymbol":true},{"headerName":"Integrated Tax","type":"number", "rupeeSymbol":true},{"headerName":"CESS","type":"number", "rupeeSymbol":true}],
        internalColumns:["total", "percentage", "taxableValue", "igst", "cess"]
    },

    "GSTR3B4A1":{
        reportTitle:"4A (1) Import of goods",
        headerRow:{"accountName":"Receiver Name", "mergeTransactionNumber":"Invoice Number", "transactionDate":"Invoice Date", "roundedAmount":"Invoice Value", "supplyType":"Supply Type"},
        headerRowTableHeaders: [{"headerName":"Receiver Name", "type":"string"},{"headerName":"Invoice Number", "type":"string"},{"headerName":"Invoice Date", "type":"date"},{"headerName":"Invoice Value", "type":"number"},{"headerName":"Supply Type", "type":"string"}],
        columns:['accountName', 'mergeTransactionNumber', 'transactionDate', 'roundedAmount', 'supplyType'],
        internalHeaderRow:{"total":null, "percentage":"Rate(%)", "taxableValue":"Taxable Value", "igst":"Integrated Tax", "cess":"CESS"},
        internalHeaderRowArr:[{"headerName":null,"type":"string"},{"headerName":"Rate(%)","type":"number"},{"headerName":"Taxable Value","type":"number", "rupeeSymbol":true},{"headerName":"Integrated Tax","type":"number", "rupeeSymbol":true},{"headerName":"CESS","type":"number", "rupeeSymbol":true}],
        internalColumns:["total", "percentage", "taxableValue", "igst", "cess"]
    },

    "GSTR3B4A2":{
        reportTitle:"4A (2) Import of services",
        headerRow:{"accountName":"Receiver Name", "mergeTransactionNumber":"Invoice Number", "transactionDate":"Invoice Date", "roundedAmount":"Invoice Value", "supplyType":"Supply Type"},
        headerRowTableHeaders: [{"headerName":"Receiver Name", "type":"string"},{"headerName":"Invoice Number", "type":"string"},{"headerName":"Invoice Date", "type":"date"},{"headerName":"Invoice Value", "type":"number"},{"headerName":"Supply Type", "type":"string"}],
        columns:['accountName', 'mergeTransactionNumber', 'transactionDate', 'roundedAmount', 'supplyType'],
        internalHeaderRow:{"total":null, "percentage":"Rate(%)", "taxableValue":"Taxable Value", "igst":"Integrated Tax", "cess":"CESS"},
        internalHeaderRowArr:[{"headerName":null,"type":"string"},{"headerName":"Rate(%)","type":"number"},{"headerName":"Taxable Value","type":"number", "rupeeSymbol":true},{"headerName":"Integrated Tax","type":"number", "rupeeSymbol":true},{"headerName":"CESS","type":"number", "rupeeSymbol":true}],
        internalColumns:["total", "percentage", "taxableValue", "igst", "cess"]
    },

    "GSTR3B4A3":{
        reportTitle:"4A (3) Inward supplies liable to reverse charge (other than 1 & 2 above)",
        headerRow:{"accountName":"Supplier Name", "mergeTransactionNumber":"Invoice Number", "transactionDate":"Invoice Date", "placeOfSupply":"Place Of Supply", "referenceTransactionNumber":"Supplier Invoice No", "roundedAmount":"Invoice Value", "supplyType":"Supply Type"},
        headerRowTableHeaders: [{"headerName":"Receiver Name", "type":"string"},{"headerName":"Invoice Number", "type":"string"},{"headerName":"Invoice Date", "type":"date"},{"headerName":"Place Of Supply", "type":"string"},{"headerName":"Supplier Invoice No", "type":"string"},{"headerName":"Invoice Value", "type":"number"},{"headerName":"Supply Type", "type":"string"}],
        columns:['accountName', 'mergeTransactionNumber', 'transactionDate', 'placeOfSupply', 'referenceTransactionNumber', 'roundedAmount', 'supplyType'],
        internalHeaderRow:{"total":null, "percentage":"Rate(%)", "taxableValue":"Taxable Value", "igst":"Integrated Tax", "cgst":"Central Tax", "sgst":"State/UT Tax", "cess":"CESS"},
        internalHeaderRowArr:[{"headerName":null,"type":"string"},{"headerName":"Rate(%)","type":"number"},{"headerName":"Taxable Value","type":"number", "rupeeSymbol":true},{"headerName":"Integrated Tax","type":"number", "rupeeSymbol":true},{"headerName":"Central Tax","type":"number", "rupeeSymbol":true},{"headerName":"State/UT Tax","type":"number", "rupeeSymbol":true},{"headerName":"CESS","type":"number", "rupeeSymbol":true}],
        internalColumns:["total", "percentage", "taxableValue", "igst", "cgst", "sgst", "cess"]
    },

    "GSTR3B4A5":{
        reportTitle:"4A (5) All other ITC",
        headerRow:{"accountName":"Supplier Name", "mergeTransactionNumber":"Invoice Number", "transactionDate":"Invoice Date", "placeOfSupply":"Place Of Supply", "referenceTransactionNumber":"Supplier Invoice No", "roundedAmount":"Invoice Value", "supplyType":"Supply Type"},
        headerRowTableHeaders: [{"headerName":"Receiver Name", "type":"string"},{"headerName":"Invoice Number", "type":"string"},{"headerName":"Invoice Date", "type":"date"},{"headerName":"Place Of Supply", "type":"string"},{"headerName":"Supplier Invoice No", "type":"string"},{"headerName":"Invoice Value", "type":"number"},{"headerName":"Supply Type", "type":"string"}],
        columns:['accountName', 'mergeTransactionNumber', 'transactionDate', 'placeOfSupply', 'referenceTransactionNumber', 'roundedAmount', 'supplyType'],
        internalHeaderRow:{"total":null, "percentage":"Rate(%)", "taxableValue":"Taxable Value", "igst":"Integrated Tax", "cgst":"Central Tax", "sgst":"State/UT Tax", "cess":"CESS"},
        internalHeaderRowArr:[{"headerName":null,"type":"string"},{"headerName":"Rate(%)","type":"number"},{"headerName":"Taxable Value","type":"number", "rupeeSymbol":true},{"headerName":"Integrated Tax","type":"number", "rupeeSymbol":true},{"headerName":"Central Tax","type":"number", "rupeeSymbol":true},{"headerName":"State/UT Tax","type":"number", "rupeeSymbol":true},{"headerName":"CESS","type":"number", "rupeeSymbol":true}],
        internalColumns:["total", "percentage", "taxableValue", "igst", "cgst", "sgst", "cess"]
    },

    "GSTR3B5":{
        reportTitle:"5. From a supplier under composition scheme, Exempt and Nil rated supply",
        headerRow:{"accountName":"Receiver Name", "mergeTransactionNumber":"Invoice Number", "transactionDate":"Invoice Date", "placeOfSupply":"Place Of Supply", "roundedAmount":"Invoice Value", "supplyType":"Supply Type"},
        headerRowTableHeaders: [{"headerName":"Receiver Name", "type":"string"},{"headerName":"Invoice Number", "type":"string"},{"headerName":"Invoice Date", "type":"date"},{"headerName":"Place Of Supply", "type":"string"},{"headerName":"Invoice Value", "type":"number"},{"headerName":"Supply Type", "type":"string"}],
        columns:['accountName', 'mergeTransactionNumber', 'transactionDate', 'placeOfSupply', 'roundedAmount', 'supplyType'],
        internalHeaderRow:{"total":null, "percentage":"Rate(%)", "taxableValue":"Taxable Value", "igst":"Integrated Tax", "cgst":"Central Tax", "sgst":"State/UT Tax", "cess":"CESS"},
        internalHeaderRowArr:[{"headerName":null,"type":"string"},{"headerName":"Rate(%)","type":"number"},{"headerName":"Taxable Value","type":"number", "rupeeSymbol":true},{"headerName":"Integrated Tax","type":"number", "rupeeSymbol":true},{"headerName":"Central Tax","type":"number", "rupeeSymbol":true},{"headerName":"State/UT Tax","type":"number", "rupeeSymbol":true},{"headerName":"CESS","type":"number", "rupeeSymbol":true}],
        internalColumns:["total", "percentage", "taxableValue", "igst", "cgst", "sgst", "cess"]
    },

    901:{
        reportTitle:"Group Summary",
        itemLabel:null,
        openDialog:false,
        headerRow: {"accountName":"Particulars", "openingValue":"Opening", "openingType":"", "debit":"Debit", "credit":"Credit", "partyBalance":"Balance", "balanceType":""},
        headerRowTableHeaders: [{"headerName":"Particulars", "type":"string"},{"headerName":"Opening", "type":"number"},{"headerName":"", "type":"string"},{"headerName":"Debit", "type":"number"},{"headerName":"Credit", "type":"number"},{"headerName":"Balance", "type":"number"},{"headerName":"", "type":"string"}],
        columns:['accountName', 'openingValue', 'openingType', 'debit', 'credit', 'partyBalance', 'balanceType'],
        detailReportId:null,
        period:'year',
        periodLabel:'Period',
        reportType:'general',
        filters: null,
        hideFilterBtn:true
    },

    //Ledger a/c report item/transaction details
    2011:{
        reportTitle:"Details",
        headerRow: {"party":"Party","debit":"To Receive","credit":"To Pay"},
        columns:['party', 'debit', 'credit']
    },

    fn_getData: function(reportId){
        for(let key in data){
            if(key == reportId){
                return data[key]
            }
        }
    },

    filters:[
        {"label":"Party", "id":null, "value":null, "items":[], "ref":"ref_fieldParty", "dialogName":"AccountDialog", "reportIds":[101, 202, 203, 301, 303, 304, 305, 308, 401, 403, 404, 405, 409, 504]},
        {"label":"Item", "id":null, "value":null, "items":[], "ref":"ref_fieldItem", "dialogName":"ProductDialog"},
        {"label":"Category", "id":null, "value":null, "items":[], "ref":"ref_fieldCategory", "dialogName":"ProductCategoryDialog", "reportIds":[102, 103, 301, 401]},
        {"label":"Brand", "id":null, "value":null, "items":[], "ref":"ref_fieldBrand", "dialogName":"ListItemDialog", "reportIds":[102, 103, 301, 401]},
        {"label":"Transaction Form", "id":null, "value":null, "items":[], "ref":"ref_fieldTransactionForm", "dialogName":"TransactionTypeForm", "reportIds":[201, 202, 303, 403]},
        // {"label":"Tax Type", "id":null, "value":null, "items":[], "ref":"ref_fieldTaxType", "dialogName":"AccountDialog", "reportIds":[303, 403]},
        {"label":"Account Group", "id":null, "value":null, "items":[], "ref":"ref_fieldAccountGroup", "dialogName":"AccountGroupDialog", "reportIds":[302, 402]},
    ]
    
})