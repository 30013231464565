<template>
    <div>
        <v-dialog v-model="dialog_import_item_form" content-class="half-screen-dialog without-left-nav" no-click-animation scrollable persistent transition="slide-y-transition">
            <v-card no-border-radius grey-back>
                <v-toolbar :height="56" flat>
                    <v-btn icon @click="dialog_import_item_form = false"><v-icon>arrow_back</v-icon></v-btn>
                    <v-toolbar-title>Import Party</v-toolbar-title>
                    <v-spacer></v-spacer>
                </v-toolbar>

                <v-divider class="noprint"></v-divider>

                <div class="data-container grey-back" v-if="itemModel">

                    <ValidationObserver ref="observer">

                        <v-form @submit.prevent="submit">
                            <v-layout ma-0 wrap pt-4 white-back class="section-wrapper">

                                <v-flex form-item xs8 sm8 md8 mb-2>
                                    <ValidationProvider name="Party Name" rules="required|max250" v-slot="{ errors }">
                                        <v-text-field 
                                            :key="validationCheckKey"
                                            :error="itemModel.nameHasError"
                                            @input="itemModel.nameHasError = errors.length ? true : false"
                                            v-model="itemModel.Name" 
                                            label="Name"
                                            required
                                            autocomplete="null"
                                            :error-messages="errors">
                                        </v-text-field>
                                        <div font12 class="pt-1 error--text" v-if="itemModel.nameHasError && !errors.length">This field must not be duplicate or blank</div>
                                    </ValidationProvider>
                                </v-flex>

                                <v-flex form-item xs4 sm4 md4 mb-2>
                                    <ValidationProvider name="Party Name" rules="required" v-slot="{ errors }">
                                        <!-- <v-text-field 
                                            :key="validationCheckKey"
                                            :error="itemModel.nameHasError"
                                            @input="itemModel.nameHasError = errors.length ? true : false"
                                            v-model="itemModel.Name" 
                                            label="Name"
                                            required
                                            autocomplete="null"
                                            :hide-details="!errors.length"
                                            :error-messages="errors">
                                        </v-text-field>
                                        <div font12 class="pt-1 error--text" v-if="itemModel.nameHasError && !errors.length">This field must not be duplicate or blank</div> -->

                                        <v-select
                                            :items="['Customer', 'Supplier']"
                                            label="Party Type"
                                            placeholder=" "
                                            v-model="itemModel.PartyType"
                                            required
                                            autocomplete="null"
                                            :hide-details="!errors.length"
                                            :error-messages="errors"
                                        ></v-select>
                                    </ValidationProvider>
                                </v-flex>

                                <v-flex form-item xs6 sm6 md6 mb-2>
                                    <v-text-field  v-model="itemModel.MobileNumber" label="Mobile Number" placeholder=" "></v-text-field>
                                </v-flex>

                                <v-flex form-item xs6 sm6 md6 mb-2>
                                    <ValidationProvider name="Email" rules="email" v-slot="{ errors }">
                                        <v-text-field 
                                            :key="validationCheckKey"
                                            :error="itemModel.emailHasError"
                                            @input="itemModel.emailHasError = errors.length &&  itemModel.Email ? true : false"
                                            v-model="itemModel.Email" 
                                            label="Email"
                                            required
                                            autocomplete="null"
                                            :hide-details="!errors.length"
                                            :error-messages="errors">
                                        </v-text-field>
                                        <div font12 class="pt-1 error--text" v-if="itemModel.emailHasError && errors.length">Invalid email</div>
                                    </ValidationProvider>
                                </v-flex>

                                <v-flex form-item xs6 sm6 md6 mb-2>
                                    <ValidationProvider name="GST Number" rules="verify_gstnumber" v-slot="{ errors }">
                                        <v-text-field 
                                            :key="validationCheckKey"
                                            :error="errors.length ? true : false"
                                            @input="itemModel.gstNumberHasError = errors.length > 0 ? true : false"
                                            v-model="itemModel.GstNumber" 
                                            label="Gst Number"
                                            autocomplete="null"
                                            :hide-details="!errors.length"
                                            :error-messages="errors">
                                        </v-text-field>
                                        <div font12 class="pt-1 error--text" v-if="itemModel.nameHasError && errors.length">{{ errors[0] }}</div>
                                    </ValidationProvider>
                                </v-flex>

                                <v-flex form-item xs6 sm6 md6 pr-4>

                                    <ValidationProvider name="Opening Balance" rules="decimal2" v-slot="{ errors }">
                                        <v-text-field 
                                            type="number"
                                            :error="itemModel.openingHasError"
                                            @input="fn_changeOpening"
                                            v-model.number="itemModel.OpeningBalance"  
                                            label="Opening Balance"
                                            autocomplete="null"
                                            :hide-details="!errors.length"
                                            :error-messages="errors">
                                        </v-text-field>
                                        <!-- <div font12 class="pt-1 error--text" v-if="errors.length">{{ errors[0] }}</div> -->
                                        <!-- {{ errors.length }}
                                        {{ itemModel.openingHasError }}

                                        {{ errors.length && Number(itemModel.OpeningBalance) }} -->
                                    </ValidationProvider>

                                </v-flex>

                            </v-layout>

                            <v-subheader class="mt-4 white-back">Billing Address</v-subheader>
                            <v-divider></v-divider>
                            <v-layout ma-0 pt-4 wrap white-back class="section-wrapper">
                                <v-flex form-item xs12 sm12 md12 mb-2>
                                    <v-text-field  v-model="itemModel.BillingAddress" label="Address Line" placeholder=" "></v-text-field>
                                </v-flex>

                                <v-flex form-item xs4 sm4 md4 mb-2>
                                    <v-text-field  v-model="itemModel.BillingCity" label="City" placeholder=" "></v-text-field>
                                </v-flex>

                                <v-flex form-item xs4 sm4 md4 mb-2>
                                    <v-text-field  v-model="itemModel.BillingState" label="State" placeholder=" "></v-text-field>
                                </v-flex>

                                <v-flex form-item xs4 sm4 md4 mb-2>
                                    <v-text-field type="number"  v-model="itemModel.BillingPincode" label="Pincode" placeholder=" "></v-text-field>
                                </v-flex>
                            </v-layout>

                            <v-subheader class="mt-4">Shipping Address</v-subheader>
                            <v-divider></v-divider>
                            <v-layout ma-0 wrap pt-4 white-back class="section-wrapper">
                                <v-flex form-item xs12 sm12 md12 mb-2>
                                    <v-text-field  v-model="itemModel.ShippingAddress" label="Address Line" placeholder=" "></v-text-field>
                                </v-flex>

                                <v-flex form-item xs4 sm4 md4 mb-2>
                                    <v-text-field  v-model="itemModel.ShippingCity" label="City" placeholder=" "></v-text-field>
                                </v-flex>

                                <v-flex form-item xs4 sm4 md4 mb-2>
                                    <v-text-field  v-model="itemModel.ShippingState" label="State" placeholder=" "></v-text-field>
                                </v-flex>

                                <v-flex form-item xs4 sm4 md4 mb-2>
                                    <v-text-field type="number"  v-model="itemModel.ShippingPincode" label="Pincode" placeholder=" "></v-text-field>
                                </v-flex>

                                <!-- <v-flex form-item xs4 sm4 md4 mb-2>
                                    <v-select
                                        :items="['Yes', 'No']"
                                        label="Expense Item"
                                        placeholder=" "
                                        @change="fn_changeExpenseItem"
                                        :value="itemModel.ExpenseItem"
                                    ></v-select>

                                </v-flex> -->

                                <!-- <v-flex form-item xs4 sm4 md4 v-if="itemModel.ServiceItem=='Yes'">
                                    <ValidationProvider name="Charges" rules="min_value:0|decimal2" v-slot="{ errors }">
                                        <v-text-field 
                                            :error="itemModel.chargesHasError"
                                            @input="itemModel.chargesHasError = errors.length && itemModel.Charges ? true : false"
                                            v-model="itemModel.Charges" 
                                            prefix="₹"
                                            label="Charges"
                                            autocomplete="null"
                                            :hide-details="!errors.length"
                                            :error-messages="errors">
                                        </v-text-field>
                                        <div font12 class="pt-1 error--text" v-if="itemModel.chargesHasError && !errors.length">This field must be numerical</div>
                                    </ValidationProvider>
                                </v-flex> -->

                            </v-layout>


                                <!-- <v-layout ma-0 wrap pt-4 pb-3 white-back v-if="itemModel.ServiceItem=='No'" class="section-wrapper"> -->
                        </v-form>

                    </ValidationObserver>
                </div>


                <v-card-actions white-back>
                    <v-layout row wrap ma-0>

                        <v-flex form-item xs12 sm12 md12 height48>
                            <v-btn class="save_btn" no-border-radius color="primary" full-height nomargin block no-round-borders noshadow @click="fn_checkValidations">Save</v-btn>
                        </v-flex>

                    </v-layout>
                </v-card-actions>
                  

            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import { min_value } from 'vee-validate/dist/rules'
import { extend, ValidationProvider, ValidationObserver } from 'vee-validate'

extend('verify_gstnumber', value => {
    // var strongRegex = new RegExp("^([0][1-9]|[1-2][0-9]|[3][0-7])([a-zA-Z]{5}[0-9]{4}[a-zA-Z]{1}[1-9a-zA-Z]{1}[zZ]{1}[0-9a-zA-Z]{1})+$");
    var strongRegex = new RegExp("^([0][1-9]|[1-2][0-9]|[3][0-7])([a-zA-Z]{5}[0-9]{4}[a-zA-Z]{1}[1-9a-zA-Z]{1}[a-zA-Z]{1}[0-9a-zA-Z]{1})+$");
    let isValid = strongRegex.test(value);
    if(!value || isValid){
        return true;
    }
    return 'GST Number is not valid.';
});

// extend('min_value:0|decimal2', value=>{
//     if(value>=0){
//         return true;
//     }

//     return 'This field must be 0 or more.'
// });

extend('min_value', {
    ...min_value,
    message: 'This field must be 0 or more.'
})

extend('decimal2', value =>     {

  let decimalCount = 0;
  if(Math.floor(value) != value){
     decimalCount = value.toString().split(".")[1].length || 0;
  }

  if(decimalCount <= 2){
    return true;
  }

  return 'This field must be numeric and may contain 2 decimal points.';

})

extend('verify_hsn', value => {
    if(value.length == 4 || value.length == 6 || value.length == 8){
        return true;
    }

    return 'HSN should be either 4, 6 or 8 digits.';
});

// extend('max', max);

extend('max250', value => {
    if(value.length <= 250){
        return true;
    }
    
    return 'This field can not be greater than 250 characters.'
})

export default{
    data(){
        return{
            dialog_import_item_form:false,
            groupTypeId:null,
            itemModel:null,
            validationCheckKey:this.guidWeb()
        }
    },
    components:{
        ValidationProvider,
        ValidationObserver
    },
    methods:{
        openDialogWeb: function(){
            this.dialog_import_item_form = true;
            this.itemModel = {};
            this.itemModel.Name = this.$parent.editItemField["Name"] ? this.$parent.editItemField["Name"] : null;
            this.itemModel.MobileNumber = this.$parent.editItemField["Mobile Number"] ? this.$parent.editItemField["Mobile Number"] : null;
            // this.itemModel.Charges = this.$parent.editItemField["Charges"] ? this.$parent.editItemField["Charges"] : null;
            this.itemModel.Email = this.$parent.editItemField["Email"] ? this.$parent.editItemField["Email"] : null;
            this.itemModel.PartyType = null;
            if(this.$parent.editItemField["Party Type"]?.toLowerCase() == 'customer'){
                this.itemModel.PartyType = "Customer";
            }
            else if(this.$parent.editItemField["Party Type"]?.toLowerCase() == 'supplier'){
                this.itemModel.PartyType = "Supplier";
            }
            this.itemModel.GstNumber = this.$parent.editItemField["GST Number"] ? this.$parent.editItemField["GST Number"] : null;
            this.itemModel.BillingAddress = this.$parent.editItemField["Billing Address"] ? this.$parent.editItemField["Billing Address"] : null;
            this.itemModel.BillingCity = this.$parent.editItemField["Billing City"] ? this.$parent.editItemField["Billing City"] : null;
            this.itemModel.BillingState = this.$parent.editItemField["Billing State"] ? this.$parent.editItemField["Billing State"] : null;
            this.itemModel.BillingPincode = this.$parent.editItemField["Billing Pincode"] ?  this.$parent.editItemField["Billing Pincode"] :null;

            this.itemModel.ShippingAddress = this.$parent.editItemField["Shipping Address"] ? this.$parent.editItemField["Shipping Address"] : null;
            this.itemModel.ShippingCity = this.$parent.editItemField["Shipping City"] ? this.$parent.editItemField["Shipping City"] : null;
            this.itemModel.ShippingState = this.$parent.editItemField["Shipping State"] ? this.$parent.editItemField["Shipping State"] : null;
            this.itemModel.ShippingPincode = this.$parent.editItemField["Shipping Pincode"] ?  this.$parent.editItemField["Shipping Pincode"] :null;

            this.itemModel.OpeningBalance = this.$parent.editItemField["Opening Balance"] ?  this.$parent.editItemField["Opening Balance"] : null;

            this.itemModel.hasError = this.$parent.editItemField.hasError;
            this.itemModel.nameHasError = this.$parent.editItemField.nameHasError;
            this.itemModel.partyTypeHasError = this.$parent.editItemField.partyTypeHasError;
            this.itemModel.emailHasError = this.$parent.editItemField.emailHasError;
            this.itemModel.gstNumberHasError = this.$parent.editItemField.gstNumberHasError;
            this.itemModel.openingHasError = this.$parent.editItemField.openingHasError;
        },

        // fn_changeServiceItem: function(value){
        //     this.itemModel.ServiceItem = value;
        //     this.$forceUpdate();
        // },

        // fn_changeExpenseItem: function(value){
        //     this.itemModel.ExpenseItem = value;
        //     this.$forceUpdate();
        // },

        fn_changeOpening: function(val){
            let decimalCount = 0;
            let value = Number(val);
            if(Math.floor(value) != value){
                decimalCount = value.toString().split(".")[1].length || 0;
            }

            if(decimalCount<=2){
                this.itemModel.openingHasError = false;
            }
            else{
                this.itemModel.openingHasError = true;
            }
        },

        fn_checkValidations: function(){
            this.$refs.observer.validate().then((result) => {
                if(result) {
                    this.fn_checkItemDuplicate(response=>{
                        if(response == 'invalid'){
                            this.itemModel.nameHasError = true;
                            this.validationCheckKey = this.guidWeb();
                            // this.$set(this.itemModel, 'nameHasError', true);
                            // alert(this.itemModel.nameHasError);
                            this.$eventHub.$emit('snackbarAlert', {msg  :'Item with name "'+ this.itemModel.Name +'" already exist'});
                        }
                        else{
                            this.fn_saveItem();
                        }
                    })
                }
                // else{
                //     alert('invalid');
                // }
            });
        },

        fn_checkItemDuplicate: function(callback){
            if(!this.itemModel.Name){
                this.$eventHub.$emit('snackbarAlert', {msg:"Item name should not be blank"});
                return;
            }

            this.itemModel.Name = this.itemModel.Name.trim();

            // let matchCount = 0;
            for(let i=0; i<this.$parent.importItems.length; i++){
                if(this.$parent.importItems[i].Name && this.itemModel.Name){
                    if(this.$parent.importItems[i].Name.toLowerCase() == this.itemModel.Name.toLowerCase() && this.$parent.editItemField.itemIndex != i && i < this.$parent.editItemField.itemIndex){
                        callback('invalid');
                        return;
                    }
                }
            }

            let filterObj = {};
            filterObj.importItems = [this.itemModel.Name];

            // let self = this;
            this.getFilterData('ValidateImportItems', 'Ledger', filterObj, null,
                {
                    onSuccess: function(response){
                        console.log(response);
                        let duplicateItems = JSON.parse(response);
                        if(duplicateItems.length){
                            callback('invalid');
                        }
                        else{
                            callback('valid');  
                        }
                    }, 
                    onError: function(error){
                        console.log(error);
                    }
                }
            );
        },

        fn_saveItem: function(){
            this.$parent.editItemField["Name"] = this.itemModel.Name;
            this.$parent.editItemField["Party Type"] = this.itemModel.PartyType;
            this.$parent.editItemField["Mobile Number"] = this.itemModel.MobileNumber;
            this.$parent.editItemField["Email"] = this.itemModel.Email;
            this.$parent.editItemField["Party Type"] = this.itemModel.PartyType;
            this.$parent.editItemField["GST Number"] = this.itemModel.GstNumber;
            this.$parent.editItemField["Billing Address"] = this.itemModel.BillingAddress;
            this.$parent.editItemField["Billing City"] = this.itemModel.BillingCity;
            this.$parent.editItemField["Billing State"] = this.itemModel.BillingState;
            this.$parent.editItemField["Billing Pincode"] = this.itemModel.BillingPincode;

            this.$parent.editItemField["Shipping Address"] = this.itemModel.ShippingAddress;
            this.$parent.editItemField["Shipping City"] = this.itemModel.ShippingCity;
            this.$parent.editItemField["Shipping State"] = this.itemModel.ShippingState;
            this.$parent.editItemField["Shipping Pincode"] = this.itemModel.ShippingPincode;

            this.$parent.editItemField["Opening Balance"] = this.itemModel.OpeningBalance;

            if(this.$parent.editItemField["hasError"]){
                this.$parent.invalidItemsCount--;
            }

            this.$parent.editItemField["hasError"] = false;
            this.$parent.editItemField["nameHasError"] = false;
            this.$parent.editItemField["partyTypeHasError"] = false;
            this.$parent.editItemField["emailHasError"] = false;
            this.$parent.editItemField["gstNumberHasError"] = false;
            this.$parent.editItemField["openingHasError"] = false;

            this.$parent.importItems = JSON.parse(JSON.stringify(this.$parent.importItems));

            this.dialog_import_item_form = false;
        }
    }
}

</script>