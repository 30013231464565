<template>
  <div>
        <v-card flat>
            <v-subheader class="pr-0">
                Filters
                <v-spacer></v-spacer>
                <v-btn color="primary" text @click="fn_clearAllFilters">Clear All</v-btn>
            </v-subheader>

            <!-- <v-alert type="warning" style="font-size:12px" dense :value="true">Under development!</v-alert> -->
            <!-- <v-card-title>
                <v-btn icon flat @click="closeFilters"><v-icon>close</v-icon></v-btn>
                Filters
                <v-spacer></v-spacer>
                <v-btn color="primary" flat @click="fn_clearAllFilters">Clear All</v-btn>
            </v-card-title> -->

            <v-divider></v-divider>

            <v-card-text>
                <v-layout row wrap ma-0>

                    <!-- <v-flex xs12 sm12 md12 form-item v-if="propsObj.itemLabel">
                        <v-text-field  
                            disabled
                            v-model="propsObj.itemName" 
                            :label="propsObj.itemLabel" 
                            placeholder=" "
                            append-icon="arrow_drop_down">
                        </v-text-field>
                    </v-flex> -->

                    <template v-for="(item, index) in filters">
                        <!-- <v-flex xs12 sm12 md12 form-item :key="index" @click="fn_openFilterDialog(item)" v-if="isFilterApplicable(item.reportIds)"> -->
                        <v-flex xs12 sm12 md12 :key="index" v-if="isFilterApplicable(item.reportIds) && item.ref != 'ref_fieldTaxType'">
                            <!-- <v-text-field  
                                readonly
                                v-model="item.value" 
                                :label="item.label" 
                                placeholder=" "
                                append-icon="arrow_drop_down"
                                :ref="item.ref" 
                                required>
                            </v-text-field> -->
                            <!-- <template v-if="!item.items || !item.items.length">
                                <v-text-field
                                    readonly
                                    :value="item.value && item.value.name ? item.value.name : ''"
                                    :label="item.label">
                                </v-text-field>
                            </template>
                            
                            <template v-else>
                                <v-autocomplete
                                    ref="item.ref"
                                    :items="item.items"
                                    item-text="itemName"
                                    item-value="itemId"
                                    return-object
                                    v-model="item.value"
                                    :label="item.label"
                                    @input="fn_filter(item)"
                                    append-icon="close"
                                    @click:append="fn_filter(item, true)"
                                  ></v-autocomplete>

                            </template> -->

                            <v-autocomplete
                                ref="item.ref"
                                :items="item.items ? item.items : []"
                                item-text="itemName"
                                item-value="itemId"
                                return-object
                                v-model="item.value"
                                :label="item.label"
                                @input="fn_filter(item)"
                                :append-outer-icon="item.value ? 'close' : ''"
                                @click:append-outer="fn_filter(item, true)"
                                ></v-autocomplete>
                        </v-flex>    
                    </template>

                </v-layout>

                <v-layout row wrap v-if="$route.params && ($route.params.reportId==304 || $route.params.reportId==305 || $route.params.reportId==404 || $route.params.reportId==405)">
                    <v-subheader dense form-section-header>Status
                        <v-spacer></v-spacer>
                    </v-subheader>

                    <v-flex xs12 sm12 md12>
                        <v-radio-group v-model="filterModel.status" @change="fn_orderChallanStatus">
                          <v-radio label="All" value="All"></v-radio>
                          <v-radio label="Open" value="Open"></v-radio>
                          <v-radio label="Close" value="Close"></v-radio>
                        </v-radio-group>
                    </v-flex>
                </v-layout>

                <v-layout row wrap v-if="$route.params && $route.params.reportId==503">
                    <v-flex xs12 sm12 md12>
                        <v-radio-group v-model="filterModel.groupTypeId" row @change="fn_outstandingType">
                          <v-radio label="All" value="All"></v-radio>
                          <v-radio label="To Receive" value="Receivables"></v-radio>
                          <v-radio label="To Pay" value="Payables"></v-radio>
                        </v-radio-group>
                    </v-flex>
                </v-layout>

                <v-layout row wrap v-if="$route.params && $route.params.reportId==504 || $route.params.reportId==505">
                    <v-flex xs10 sm10 md10 form-item>
                        <v-text-field  
                            type="number"
                            v-model.number="filterModel.dueDays" 
                            label="Due Days" 
                            placeholder=" ">
                        </v-text-field>
                    </v-flex>

                    <v-flex xs2 sm2 md2 form-item center-align style="display: flex;align-items: center;justify-content: center;">
                        <v-btn color="primary" text small @click="fn_dueDays">apply</v-btn>
                    </v-flex>
                    
                    <v-flex xs12 sm12 md12 form-item v-if="$route.params.reportId==504">
                        <v-checkbox label="Show MSME" v-model="filterModel.showMSME" @change="fn_showMSME"></v-checkbox>
                    </v-flex>
                </v-layout>
            </v-card-text>
        </v-card>
  </div>
</template>

<script>
const listIdObject = require('../../../config.js').configs.mastersPredefinedLists;
const accountGroupIds = require('../../../config.js').configs.accountGroupIds;

    export default{
        props:['applyFilter', 'openFilterDialog', 'closeFilters', 'closeDialog', 'propsObj', 'openDialogAndroid', 'openDatePicker', 'clearAllFilters', 'openDialogTaxRateList'],
        data(){
            return{
                // filters:[
    //                 {"label":"Party", "id":null, "value":null, "ref":"ref_fieldParty", "dialogName":"accounts", "reportIds":[101, 202, 203, 301, 303, 304, 305, 401, 403, 404, 405]},
    //                 {"label":"Item", "id":null, "value":null, "ref":"ref_fieldItem", "dialogName":"product"},
    //                 {"label":"Category", "id":null, "value":null, "ref":"ref_fieldCategory", "dialogName":"productCategories", "reportIds":[102, 103, 301, 401]},
    //                 {"label":"Brand", "id":null, "value":null, "ref":"ref_fieldBrand", "dialogName":"listItems", "reportIds":[102, 103, 301, 401]},
    //                 {"label":"Transaction Form", "id":null, "value":null, "ref":"ref_fieldTransactionForm", "dialogName":"transactionTypeForm", "reportIds":[202, 303, 403]},
    //                 {"label":"Tax Type", "id":null, "value":null, "ref":"ref_fieldTaxType", "dialogName":"accounts", "reportIds":[303, 403]},
    //                 {"label":"Account Group", "id":null, "value":null, "ref":"ref_fieldAccountGroup", "dialogName":"accountgroups", "reportIds":[302, 402]},
    //             ],
                // filterObj:{},
                filterModel:{"status":"All", "groupTypeId":"All", "dueDays":null, "showMSME":false},
                filterCalled:false
            }
        },
        methods:{
            fn_filter: function(item, isClear){
                // alert('in');
                if(isClear){
                    item.value = null;
                    this.applyFilter(null, null, item.ref);
                }
                else{
                    this.applyFilter(item.value.itemId, item.value.itemName, item.ref);    
                }
            },

            fn_formatDate: function(date){
                return this.$moment(date).format('DD-MMM-YYYY');
            },

            fn_orderChallanStatus: function(value){
                console.log(value);
                this.applyFilter(null, value, 'status')
            },

            fn_outstandingType: function(value){
                console.log(value);
                this.applyFilter(null, value, 'groupTypeId');
            },

            fn_dueDays: function(){

                if(!this.filterCalled){
                    this.filterCalled = true;
                    let value = this.filterModel.dueDays ? this.filterModel.dueDays : null;
                    this.applyFilter(null, value, 'dueDays');
                }

                setTimeout(() => {
                    this.filterCalled = false;
                }, 1000);
                
            },

            fn_showMSME: function(value){
                this.applyFilter(null, value, 'showmsme');
            },

            fn_openFilterDialog: function(item){
                if(item.ref == 'ref_compTaxRate'){
                    this.openDialogTaxRateList();
                }
                else{
                    this.openFilterDialog(item.label, item.ref, item.id, item.value, item.dialogName);
                }
            },

            fn_clearAllFilters: function(){
                for(let i=0; i<this.$root.reportFilters.length; i++){
                    // this.$root.reportFilters[i].id = null;
                    this.$root.reportFilters[i].value = null;
                }
                this.$root.reportFilterObj = {};
                this.filterModel.status = "All";
                this.filterModel.groupTypeId = "All";
                this.filterModel.dueDays = null;
                this.filterModel.showMSME = false;
                this.clearAllFilters(this.$root.reportFilterObj);
            },

            isFilterApplicable: function(reportIds){
                // alert(reportIds);
                if((reportIds && reportIds.length) && (this.$route.params && this.$route.params.reportId)){
                    if(reportIds.indexOf(Number(this.$route.params.reportId))!=-1){
                        // alert('true');
                        return true;
                    }
                    else{
                        // alert('false');
                        return false;
                    }
                }
            },

            fn_getFilterItems: function(filterItem){
                let filterObject = {};
                // filterObject.search = val ? val.toLowerCase() : '';
                filterObject.search = '';

                filterItem.ref == 'ref_fieldBrand' ? filterObject.listId = listIdObject.brandListGUID : '';
                let reportId = this.$route.params.reportId;

                if(filterItem.ref=='ref_fieldTransactionForm'){
                  reportId == 303 ? filterObject.transactionTypeId = [10003] : '';
                  reportId == 403 ? filterObject.transactionTypeId = [20003, 20005] : '';
                }

                if(reportId == 302){
                  filterObject.accountGroupIds = [accountGroupIds.debtors.id]; 
                }
                else if(reportId == 402){
                  filterObject.accountGroupIds = [accountGroupIds.creditors.id]; 
                }

                if(reportId == 401 || reportId == 403 || reportId == 404 || reportId == 405 || reportId == 409){
                  if(reportId == 403 && filterItem.ref == 'ref_fieldTaxType'){
                    filterObject.accountGroupIds = [accountGroupIds.purchaseAccount.id];
                  }
                  // else{
                  //   viewDialogObject.enableFilterChip = true;
                  //   if(this.$root.dataObj.selectedAccountGroupTypeId){
                  //     viewDialogObject.groupTypeId = this.$root.dataObj.selectedAccountGroupTypeId;
                  //   }
                  //   else{
                  //     viewDialogObject.groupTypeId = 102;
                  //   }
                  // }
                }
                else if(reportId == 101 || reportId == 202 || reportId == 203 || reportId == 301 || reportId == 303 || reportId == 304 || reportId == 305 || reportId == 308){
                  if(reportId == 303 && filterItem.ref == 'ref_fieldTaxType'){
                    filterObject.accountGroupIds = [accountGroupIds.saleAccount.id];
                  }
                  // else{
                  //   viewDialogObject.enableFilterChip = true;
                  //   if(this.$root.dataObj.selectedAccountGroupTypeId){
                  //     viewDialogObject.groupTypeId = this.$root.dataObj.selectedAccountGroupTypeId;
                  //   }
                  //   else{
                  //     viewDialogObject.groupTypeId = 101;
                  //   }
                  // }
                }
              // let self = this;
              this.getDialogItems(filterItem.dialogName, filterObject, 
                {
                  onSuccess: function (response) {
                    console.log(response);
                    let data = JSON.parse(response);
                    if(filterItem.dialogName == 'AccountDialog'){
                        filterItem.items = data.items;    
                    }
                    else{
                        filterItem.items = data;
                    }
                  },
                  onError: function (error) {
                    console.log(error);
                  }
                }
              )
            },

            fn_fetchFilterItems: function(){

                let self = this;
                for(let i=0; i<this.$root.reportFilters.length; i++){
                    (function(index){
                        let filterItem = self.$root.reportFilters[index];

                        if(filterItem.reportIds && filterItem.reportIds.indexOf(Number(self.$route.params.reportId))!=-1){
                            self.fn_getFilterItems(filterItem);
                        }
                        
                    }(i));
                }
            }


        },
        computed:{
            filters: function(){
                if(this.$root.reportFilters){
                    return this.$root.reportFilters;
                }
                else{
                    return [];
                }
            }
        }
    }
</script>