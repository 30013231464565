// import dialogPermission from '../dialogs/dialog_permission';
const paymentAccountsAndLedger = require('../../config.js').configs.paymentAccountsAndLedger;
const transactionForms = require('../../config.js').configs.transactionForms;
const fixedGstAddOns = require('../../config.js').configs.fixedGstAddOns;
const fixedCessAddOns = require('../../config.js').configs.fixedCessAddOns;
const accountGstType = require('../../config.js').configs.accountGstType;

const ledgerGroupTypeId = require('../../config.js').configs.ledgerGroupTypeId;

import { extend } from 'vee-validate'

extend('max8', value => {

    if(value.toString().length <= 8){
        return true;
    }
  
    return 'This field may not be greater than 8 characters';
});

export const transactionMixin =  {

    data(){
        return{
            ewayBillDetailSection:JSON.parse(JSON.stringify(require('../../config.js').configs.transactionFields.ewayBillDetailSection)),
            ewayBillDetailFields:JSON.parse(JSON.stringify(require('../../config.js').configs.transactionFields.ewayBillDetailFields)),
            disableAction:false,
            creditAccountLedgerId:"4117faf1-b1d1-4f7f-913a-f1dc489fdb3c",
            onCreditValue:null,
            snackbarOpenFile:false,
            offsetTop:0,
            transactionCount:0,
            loadingStatusObj:{value:0},
            parties:[],
            products:[],
            tdsLedgers:[],
            addOns:[],
            getDataCounter:0,
            bytesArray:[],
            fileBufferValue:0,
            totalChunksLength:0,
            confirmationMessage:null,
            confirmationHeader:null,
            confirmationAction:null,
            confirmationActionParam:null,
            isPartyValid:true,
            loadTimeOut:false,
            formTypeName:null,
            // hasOnlySeries:true,
			// hasSeries:false
        }
    },

    // components:{
    //     'dialog-permission': dialogPermission,
    // },

    methods: {

        fn_accountLedgers: function(val, groupTypeId, accountGroupIds, type, callback, accountGstTypes, accountGroups, callingFrom){
          let filterObject = {};
          filterObject.search = val ? val.toLowerCase() : '';

          if(this.settings.transactionTypeId == 30004){
            filterObject.accountGroupIds = [paymentAccountsAndLedger.groups.cashInHand, paymentAccountsAndLedger.groups.bank, paymentAccountsAndLedger.groups.card, paymentAccountsAndLedger.groups.wallet]
          }

          if(accountGroupIds){
            filterObject.accountGroupIds = accountGroupIds;
          }

          if(groupTypeId){
            filterObject.groupTypeId = groupTypeId;
          }

          if(accountGroups && accountGroups.length){
            filterObject.accountGroups = accountGroups;
          }

          if(accountGstTypes && accountGstTypes.length){
            filterObject.gstTypes = accountGstTypes;
          }
          
          let self = this;
          this.getDialogItems("AccountDialog", filterObject, 
            {
              onSuccess: function (response) {
                console.log(response);

                self.getDataCounter += 1;

                if(callback){
                    callback(response);
                }
                else{
                    self.parties = JSON.parse(response);

                    // passing inventory = 0 in case of expense form (only expense items)
                    // in case of loading orders/challan directly from activity we already called products first
                    if(((!self.$route.query.loadItems && !self.$route.params.tagId) || self.settings.transactionTypeId == 20005) && callingFrom != 'transactionTypeChange'){
                        // let inventory = self.settings.transactionTypeId == 20005 ? 0 : 1;
                        // self.fn_products('', inventory);
                        self.fn_addOns('');
                    }
                }
                
              },
              onError: function (error) {
                console.log(error);
              }
            }
          )
        },

        // fn_products: function(val, inventory){
        //   let filterObject = {};
        //   filterObject.search = val ? val.toLowerCase() : '';
        //   filterObject.inventory = inventory != undefined ? inventory : 1;
          
        //   let self = this;
        //   this.getDialogItems("ProductDialog", filterObject, 
        //     {
        //       onSuccess: function (response) {
        //         console.log(response);

        //         self.getDataCounter += 1;

        //         if(JSON.parse(response)){
        //             self.products = JSON.parse(response);    
        //         }
        //         else{
        //             self.products = [];   
        //         }

        //         self.fn_addOns('');
                
        //       },
        //       onError: function (error) {
        //         console.log(error);
        //       }
        //     }
        //   )
        // },

        fn_listItems: function(val, listId, callback){
          let filterObject = {};
          filterObject.search = val ? val.toLowerCase() : '';

          filterObject.listId = listId;
          
          // let self = this;
          this.getDialogItems("ListItemDialog", filterObject, 
            {
              onSuccess: function (response) {
                console.log(response);
                if(callback){
                    callback();
                }
                // self.accountGroups.unshift("header");
              },
              onError: function (error) {
                console.log(error);
              }
            }
          )
        },

        fn_addOns: function(val, addOnsType){
          let filterObject = {};
          filterObject.search = val ? val.toLowerCase() : '';
          filterObject.addOnType = addOnsType;
          
          let self = this;
          this.getDialogItems("AddOnsDialog", filterObject, 
            {
              onSuccess: function (response) {
                console.log(response);
                self.getDataCounter += 1;
                self.addOns = JSON.parse(response);
                self.fn_getTaxCategories();
              },
              onError: function (error) {
                console.log(error);
              }
            }
          )
        },

        fn_getTaxCategories: function(){
            let filterObject = {};
            let self = this;
            this.getItems("TaxCategoryList", filterObject, 
              {
                onSuccess: function (response) {
                  console.log(response);
                  self.getDataCounter += 1;
                  self.unwatch = true;
                  self.taxCategories = JSON.parse(response);
                  self.fn_getDefaultCessAddOns();
                },
                onError: function (error) {
                  console.log(error);
                }
              }
            )
        },

        fn_getDefaultCessAddOns: function () {
            let self = this;

            self.getData('AddOns', fixedCessAddOns.CESS.id, {

                onSuccess: function(response){
                  console.log(response);
                  self.getDataCounter += 1;
                  self.unwatch = true;
                  self.Form.cessAddOns = JSON.parse(response);
                  self.fn_getDefaultGstAddOns();
                }, 
                onError: function(error){
                  console.log(error);
                }
            });
        },

        // fn_getDefaultGstAddOns: function () {
        //     let self = this;
        //     this.Form.defaultTaxAddOns = [];

        //     let index = 0;
        //     for(let key in fixedGstAddOns){
        //         (function (itemId) {
        //             self.getData('AddOns', itemId, 
        //                 {
        //                     onSuccess: function(response){
        //                       console.log(response);

        //                       let itemIndex = self.Form.defaultTaxAddOns.findIndex(item=>{
        //                         return item.addOnsId == itemId;
        //                       });

        //                       if(itemIndex==-1){
        //                         self.unwatch = true;
        //                         self.Form.defaultTaxAddOns.push(JSON.parse(response));
        //                       }

        //                       index++;

        //                       if(index == Object.keys(fixedGstAddOns).length){
        //                         self.getDataCounter += 1;
        //                         if(self.$route.query.loadItems){
        //                             self.fn_checkLastInvoice();
        //                         }

        //                         if(self.$route.params && self.$route.params.tagId){
        //                             if(localStorage.getItem(self.draftStorageId)){
        //                                 if(self.settings.transactionTypeId == 10001 || self.settings.transactionTypeId == 10002 || self.settings.transactionTypeId == 10003 || self.settings.transactionTypeId == 10004 || self.settings.transactionTypeId == 10005){
        //                                     self.fn_getCustomers();
        //                                 }
        //                                 else if(self.settings.transactionTypeId == 20001 || self.settings.transactionTypeId == 20002 || self.settings.transactionTypeId == 20003 || self.settings.transactionTypeId == 20004){
        //                                     self.fn_getSuppliers();
        //                                 }
        //                             }
        //                             else{
        //                                 self.fn_get(self.$route.params.tagId);
        //                             }
        //                         }

        //                         if(self.$route.query && self.$route.query.dt){
        //                             self.fn_updateParty(self.$route.query.id, self.$route.query.name);
        //                         }
        //                       }

        //                     }, 
        //                     onError: function(error){
        //                       console.log(error);
        //                     }
        //                 }
        //             );
        //         }(fixedGstAddOns[key].id));
        //     }
        // },

        fn_getDefaultGstAddOns: function () {
            let self = this;
            this.Form.defaultTaxAddOns = [];

            let index = 0;
            for(let key in fixedGstAddOns){
                (function (itemId) {
                    self.getData('AddOns', itemId, 
                        {
                            onSuccess: function(response){
                              console.log(response);

                            //   if(fixedGstAddOns.length-1 == key){
                            //     self.getDataCounter += 1;
                            //   }

                              let itemIndex = self.Form.defaultTaxAddOns.findIndex(item=>{
                                return item.addOnsId == itemId;
                              });

                              if(itemIndex==-1){
                                self.unwatch = true;
                                self.Form.defaultTaxAddOns.push(JSON.parse(response));
                              }

                              index++;
                            //   alert(index);
                            //     alert(Object.keys(fixedGstAddOns).length);

                              if(index == Object.keys(fixedGstAddOns).length){
                                self.getDataCounter += 1;
                                if(self.$route.query.loadItems){
                                    self.fn_checkLastInvoice();
                                }

                                if(self.$route.params && self.$route.params.tagId){
                                    if(localStorage.getItem(self.draftStorageId)){
                                        // self.fn_getTransactionTypeData(self.Form.transactionTypeFormId, null, false, 'draft');
                                        if(self.settings.transactionTypeId == 10001 || self.settings.transactionTypeId == 10002 || self.settings.transactionTypeId == 10003 || self.settings.transactionTypeId == 10004 || self.settings.transactionTypeId == 10005){
                                            // self.fn_getCustomers();
                                            if(((!self.$route.query.loadItems && !self.$route.params.tagId) || self.settings.transactionTypeId == 20005)){
                                                self.fn_addOns('');
                                            }
                                        }
                                        else if(self.settings.transactionTypeId == 20001 || self.settings.transactionTypeId == 20002 || self.settings.transactionTypeId == 20003 || self.settings.transactionTypeId == 20004){
                                            // self.fn_getSuppliers();
                                            if(((!self.$route.query.loadItems && !self.$route.params.tagId) || self.settings.transactionTypeId == 20005)){
                                                self.fn_addOns('');
                                            }
                                        }
                                    }
                                    else{
                                        self.fn_get(self.$route.params.tagId);
                                    }
                                }

                                if(self.$route.query && self.$route.query.dt){
                                    self.fn_updateParty(self.$route.query.id, self.$route.query.name);
                                }
                              }

                            }, 
                            onError: function(error){
                              console.log(error);
                            }
                        }
                    );
                }(fixedGstAddOns[key].id));
            }
        },

        fn_focusFirstItem: function(e){
            if(this.Form.items.length){
                e.preventDefault();
                document.getElementById('fieldRate0').focus();
            }
        },

        fn_openDialogDraftConfirmation: function () {
            this.confirmationMessage = "Do you want to discard draft?";
            this.confirmationHeader = "Discard Draft";
            this.confirmationAction = this.fn_removeDraft;
            this.$refs['ref_dialogConfirmation'].openDialogWeb();
        },

        fn_openDialogDateConfirmation: function (value) {
            let oldFiscalYear = new Date(this.Form.transactionData.transactionDate).getFullYear();
            let oldFYMonth = new Date(this.Form.transactionData.transactionDate).getMonth() + 1;
            let oldFY = oldFYMonth <= 3 ? oldFiscalYear - 1 : oldFiscalYear;

            let newFiscalYear = new Date(value).getFullYear();
            let newFYMonth = new Date(value).getMonth() + 1;
            let newFY = newFYMonth <= 3 ? newFiscalYear - 1 : newFiscalYear;

            let fyOld = " (FY " + oldFY.toString().substring(2)+"-"+(oldFY+1).toString().substring(2) + ") ";
            let fyNew = " (FY " + newFY.toString().substring(2)+"-"+(newFY+1).toString().substring(2) + ") ";
            // this.confirmationMessage = "A new transaction number will generate as selected date belongs to different Fiscal Year. Do you want to continue?";
            this.confirmationHeader = "Update Transaction Date";
            this.confirmationMessage = fyOld+"<br>Old transaction date: "+this.$moment(this.Form.transactionData.transactionDate).format('DD-MMM-YYYY')+"<br>"+fyNew+"<br>New transaction date: "+this.$moment(value).format('DD-MMM-YYYY')+"<br><br>A new transaction number will assign.<br><br><div font-small style='background-color:#eee;padding:4px 8px'>Note: Transaction number can be updated manually as well.</div><br>Do you want to continue?";
            this.confirmationAction = this.fn_changeDateAndBindTransactionNumber;
            this.confirmationActionParam = value;
            this.$refs['ref_dialogConfirmation'].openDialogWeb();
        },

        // fn_closeDialogConfirmation: function(){
        //     this.$refs['ref_dialogConfirmation'].closeDialogWeb();
        // },

        fn_removeDraft: function(){
            localStorage.removeItem(this.draftStorageId);
            this.$refs['ref_dialogConfirmation'].closeDialogWeb();
            this.fn_get(this.$route.params.tagId);
        },

        fn_debounceSearch: function(){
             if (this.timeoutid != null){
                clearTimeout(this.timeoutid);
             }

            this.timeoutid = setTimeout(() => { 
                this.fn_searchItem();
            },100);
        },

        fn_getTransactionTitle: function(transactionListId){
            for(let i=0; i<transactionForms.length; i++){
                if(transactionForms[i].id == transactionListId){
                    return transactionForms[i].title;
                }
            }
        },

        fn_viewPartyDetails: function(partyId){
            if(partyId){
                // this.$router.push('/masters/accounts/ledgers/items/'+partyId);
                this.$router.push({path:'/'+this.$root.CurrentBusiness.BusinessID+'/'+this.$route.params.listId+'/ledger/items/'+partyId, query:{back:1}});
            }
        },

        onScrollTransactionForm (e) {
            console.log(e);
            this.offsetTop = window.pageYOffset || document.documentElement.scrollTop;
        },

        fn_scrollAndFocusToElement: function(elementId){
          setTimeout(()=>{
            let element = document.getElementById(elementId);
            element.scrollIntoView({ behavior: "smooth" });
            element.focus();
          },50)
        },

        fn_focusElement: function(elementId){
            setTimeout(()=>{
                let element = document.getElementById(elementId);
                element.focus();
            },50);
        },

        // calling from display view
        fn_checkForItems: function(){
            if(this.displayData && this.displayData.transactionProducts && this.displayData.transactionProducts.length){
                return true;
            }
            else{
                return false;
            }
        },

        fn_previewInvoice: function(fromDisplayView, type, printType, itemObj, isDirectPrint){
            if(localStorage.getItem("template_settings")){
                localStorage.removeItem("template_settings");
            }
            let title = itemObj ? itemObj.toolbarTitle : this.toolbarTitle;

            let toolbarTitle = title.toLowerCase().replace(/ /g,'');

            let listId = itemObj ? itemObj.transactionListId : this.settings.transactionTypeId;
            let tId = null;
            let template_no = null;

            let key;
            if(isDirectPrint){
                localStorage.setItem('direct_print', 1);
                key = this.guidWeb();
            }

            if(fromDisplayView){
                tId = itemObj ? itemObj.transactionId : this.displayData.transactionId;
                template_no = itemObj ? itemObj.invoiceTemplate : this.displayData.invoiceTemplate;
                // this.$router.push({path:"/invoice/preview", query:{title: toolbarTitle, listId:listId, tId:tId, template_no:template_no, type:type, printtype:printType}});
                this.$router.push(
                    {
                        path:"/"+this.$root.CurrentBusiness.BusinessID+"/"+this.$route.params.listId+"/"+toolbarTitle+"/items/"+this.$route.params.tagId+"/"+tId, 
                        query:{title: toolbarTitle, listId:listId, tId:tId, template_no:template_no, type:type, printtype:printType, transactionTypeId:this.$route.query.transactionTypeId, key:key}
                        // path:"/"+this.$root.CurrentBusiness.BusinessID+"/"+this.$route.params.listId+"/"+toolbarTitle+"/items/"+this.$route.params.tagId+"/"+tId, 
                        // query:{title: toolbarTitle, listId:listId, tId:tId, template_no:template_no, type:type, printtype:printType, transactionTypeId:this.$route.query.transactionTypeId, dp:isDirectPrint ? 1 : 0}
                    }
                ).catch(err=>{
                    console.log(err);
                });
            }
            else{
                // tId = this.Form.transactionData.transactionId;
                // template_no = this.Form.transactionTypeData.transactionTypePrints[0].invoiceTemplate;
                // this.$router.replace({path:"/invoice/preview", query:{title: toolbarTitle, listId:listId, tId:tId, template_no:template_no, type:type, printtype:printType}});

                tId = this.Form.transactionData.transactionId;
                template_no = this.Form.transactionTypeData.transactionTypePrints[0].invoiceTemplate
                // this.$router.push({path:"/invoice/preview", query:{title: toolbarTitle, listId:listId, tId:tId, template_no:template_no, type:type, printtype:printType}});
                let key;
                if(isDirectPrint){
                    localStorage.setItem('direct_print', 1);
                    key = this.guidWeb();
                }
                
                this.$router.replace(
                    {
                        path:"/"+this.$root.CurrentBusiness.BusinessID+"/"+this.$route.params.listId+"/"+toolbarTitle+"/items/"+this.$route.query.tagId+"/"+tId, 
                        query:{title: toolbarTitle, listId:listId, tId:tId, template_no:template_no, type:type, printtype:printType, key:key}
                        // path:"/"+this.$root.CurrentBusiness.BusinessID+"/"+this.$route.params.listId+"/"+toolbarTitle+"/items/"+this.$route.query.tagId+"/"+tId, 
                        // query:{title: toolbarTitle, listId:listId, tId:tId, template_no:template_no, type:type, printtype:printType, dp:isDirectPrint ? 1 : 0}
                    }
                ).catch(err=>{
                    console.log(err);
                });
            }
        },

        fn_cancelTransaction: function(transactionId, transactionListId, type, callback){
            let self = this;
            let filterObject = {};
            filterObject.transactionListId = [transactionListId];
            this.postFilterData('CancelTransaction', transactionId, filterObject, {

                onSuccess: function(response){
                    
                    console.log(response);

                    let status = Number(response);

                    callback(status);

                    // if(type=='form'){
                    //     self.fn_cleanUp();
                    // }
                    // else{
                    //     self.displayData.status = status;
                    // }
                    
                    // if(status==0){
                    //     alert("Transaction "+self.displayData.mergeTransactionNumber+ "cancelled");
                    // }

                }, 
                onError: function(error){
                  console.log(error);
                  let status = JSON.parse(error);
                    if(status && status.itemsCount){
                        let msg = 'Unable to cancel as this item already in use under [' + status.items.join(', ') + ']';
                        // alert(msg);
                        self.$eventHub.$emit('snackbarAlert', {msg:msg});
                    }
                }
            });
        },

        fn_deleteTransaction: function(transactionId, transactionListId, callback){
            let self = this;
            let filterObject = {};
            filterObject.transactionListId = [transactionListId];
            this.postFilterData('deleteTransaction', transactionId, filterObject, {

                onSuccess: function(response){
                    
                    console.log(response);

                    callback(JSON.parse(response));

                }, 
                onError: function(error){
                    console.log(error);
                    let status = JSON.parse(error);
                    if(status && status.itemsCount){
                        let msg = 'Unable to delete as this item already in use under [' + status.items.join(', ') + ']';
                        // alert(msg);
                        self.$eventHub.$emit('snackbarAlert', {msg:msg});
                    }
                }
            });
        },

        fn_getPrintData: function(name, transactionId, type, shareObj, tNumber, tDate, transactionListId){
            if(type=='print'){
                this.showLoading('Please wait...');
                this.fn_allowPrintData(name, transactionId, type, shareObj, tNumber, tDate, transactionListId);
            }
            else{
                let self = this;
                this.$root.fn_checkStoragePermission(function(){
                    self.showLoading('Please wait...');
                    self.fn_allowPrintData(name, transactionId, type, shareObj, tNumber, tDate, transactionListId);
                })
            }
            
        },

        fn_allowPrintData: function(name, transactionId, type, shareObj, tNumber, tDate, transactionListId){
            var self = this;
            this.getData(name, transactionId, 
                {
                    onSuccess: function(response){
                        console.log(response);
                        self.printData = JSON.parse(response);

                        // if(self.printData.transactionDetails && self.printData.transactionDetails.transactionItems && self.printData.transactionDetails.transactionItems.length){
                        //     self.fn_removeZeroTaxAddons();
                        // }
                        
                        setTimeout(function(){
                            self.$refs['ref_printTemplate'].fn_init();
                        },1000);

                        setTimeout(function(){
                            self.hideLoading();
                            if(type=='print'){
                                self.fn_printInvoice(tNumber, tDate, transactionListId);
                            }
                            else if(type=='pdf'){
                                self.fn_exportToPdf(shareObj, tNumber, transactionListId);
                            }
                        },2000);

                    }, 
                    onError: function(error){
                        console.log(error);           
                    }
                }
            );
        },

        fn_removeZeroTaxAddons: function(){
            for(let i=0; i<this.printData.transactionDetails.transactionItems.length; i++){
                let item = this.printData.transactionDetails.transactionItems[i];
                item.cols.addOns = item.cols.addOns.filter(addOnsItem => {
                    // return addOnsItem.isGst && addOnsItem.percentage != 0;
                    return addOnsItem.percentage != 0;
                })
            }
        },

        fn_printInvoice: function(tNumber, tDate, transactionListId){

            let transactionNumber = tNumber ? tNumber : this.displayData.mergeTransactionNumber ? this.displayData.mergeTransactionNumber : '';

            let listId = transactionListId ? transactionListId : this.displayData.transactionListId;

            let title = null;
            if(listId==10003){
                title = "Invoice";
            }
            else{
                title = this.fn_getTransactionTitle(listId);
            }

            document.title = title.replace(/ /g,'') + '-' + transactionNumber.replace(/\//g,'') + '_' + this.$root.fn_getCurrentDateTimeString();
            setTimeout(() => {document.title = '';}, 1000);
            window.print();
            // AndroidRoutingTest.print(fileName);
        },

        fn_exportToPdf: function(shareObj, tNumber, transactionListId){
            let self = this;
            // let downloadTime = new Date(this.displayData.transactionDate).getTime();
            // let downloadTime = new Date();
            let transactionNumber = tNumber ? tNumber : this.displayData.mergeTransactionNumber ? this.displayData.mergeTransactionNumber : '';

            // let transactionTypeFormName = typeFormName ? typeFormName : this.displayData.transactionTypeFormName;

            // if(!transactionTypeFormName){
            //     transactionTypeFormName = this.toolbarTitle;
            // }

            // if(transactionTypeFormName.toLowerCase() == "itc not eligible"){
            //     transactionTypeFormName = "Expense";
            // }

            // if(transactionTypeFormName == "Retail Invoice" || transactionTypeFormName == "Wholesale Invoice" || transactionTypeFormName == "Export Invoice" || transactionTypeFormName ==  "Stock Transfer"){
            //     transactionTypeFormName = "Invoice";
            // }

            let listId = transactionListId ? transactionListId : this.displayData.transactionListId;

            let title = null;
            if(listId==10003){
                title = "Invoice";
            }
            else{
                title = this.fn_getTransactionTitle(listId);
            }

            // if(transactionTypeFormName){
            //     transactionTypeFormName = this.displayData.transactionTypeFormName;
            // }
            // else{
            //     transactionTypeFormName = this.toolbarTitle;
            // }

            let fileName = title.replace(/ /g,'') + '-' + transactionNumber.replace(/\//g,'') + '_' + this.$root.fn_getCurrentDateTimeString();
            // let fileName = 'invoice_'+transactionNumber + downloadTime.getTime();
            this.exportToPdf(fileName+'.pdf',{

              onSuccess: function(response){    
                    console.log(response);
                    if(shareObj != undefined){
                        self.$root.fn_share(shareObj)
                    }
                    else{
                        self.showToast('Invoice downloaded');
                        self.snackbarOpenFile = true;
                    }
              }, 
              onError: function(error){
                  console.log(error);
                  self.showToast('Invoice download failed');
              }
            });
        },

        // fn_share: function(shareObj){
        //     var self = this;
        //     this.postData('Share', shareObj, 
        //         {
        //             onSuccess: function(response){
        //                 console.log(response);
        //             },
        //             onError: function(error){
        //                 console.log(error);
        //                 self.showToast('Unable to share at this time');
        //             }
        //         }
        //     );
        // },

        fn_getFiscalYear: function(date){
            let dateObj = date ? new Date(date) : new Date();
            let month = dateObj.getMonth() + 1;
            let year = dateObj.getFullYear();
            let fy = month > 3 ? year : year-1;
            return fy
        },

        /*=========================== Opening date mixins (using in opening ledger and opening stock) ===========================*/

        // fn_checkOpening: function(openingStockListId, openingLedgerListId){
        //     let self = this;
        //     this.getData('CheckOpening', this.$root.CurrentBusiness.BusinessID, 
        //         {
        //             onSuccess: function(response){
        //                 console.log(response);
        //                 let openingDate = null;
        //                 if(response && response!='null'){
        //                     openingDate = JSON.parse(response);
        //                 }
                        
        //                 if(openingDate){
        //                     self.openingDate = new Date(openingDate);
        //                 }
        //                 else{
        //                     self.fn_getFirstTransactionDate(openingDate, openingStockListId, openingLedgerListId);    
        //                 }
        //                 // openingDate ? self.openingDate = new Date(openingDate) : '';
        //                 // self.fn_getFirstTransactionDate(openingDate, openingStockListId, openingLedgerListId);
        //             }, 
        //             onError: function(error){
        //                 console.log(error);
        //             }
        //         }
        //     );
        // },

        // fn_getFirstTransactionDate: function(openingDate, openingStockListId, openingLedgerListId){
        //     let self = this;
        //     let filterObject = {};
        //     filterObject.transactionListId = [openingStockListId, openingLedgerListId];
        //     this.getFilterData('FirstTransactionDate', null, filterObject,
        //         {
        //             onSuccess: function(response){
        //                 console.log(response);
        //                 let ftd = JSON.parse(response);
        //                 let maxDate = null;

        //                 if(!ftd){
        //                     //Calculation of max date fy
        //                     let currentDate = new Date();
        //                     let currentMonth = currentDate.getMonth() + 1;
        //                     let currentYear = currentDate.getFullYear();

        //                     let year = null
        //                     year = currentMonth < 3 ? currentYear : currentYear+1;

        //                     maxDate = new Date(year+"-03-31");
        //                 }
        //                 else{
        //                     maxDate = new Date(ftd);
        //                 }

        //                 console.log(maxDate);

        //                 let year = self.$moment(maxDate).format('YYYY');
        //                 let month = self.$moment(maxDate).format('MM');
        //                 let day = self.$moment(maxDate).format('DD');

        //                 self.maxOpeningDate = year+"-"+month+"-"+day;
        //                 self.fn_calculateOpeningDate(ftd)
                        
        //             },
        //             onError: function(error){
        //                 console.log(error);
        //             }
        //         }
        //     );
        // },

        // fn_calculateOpeningDate: function(ftd){

        //     let openingLedgerYear = null;

        //     let firstTransactionFY = this.fn_getFiscalYear(ftd);
        //     let currentFY = this.fn_getFiscalYear();

        //     if(firstTransactionFY <= currentFY){
        //         openingLedgerYear = firstTransactionFY;
        //     }
        //     else{
        //         openingLedgerYear = firstTransactionFY - 1;
        //     }

        //     let defaultOpeningDate = new Date(openingLedgerYear+"-04-01");
        //     // !this.openingDate ? this.openingDate = defaultOpeningDate : '';

        //     //saving default opening date 1st apr if no opening date found
        //     if(!this.openingDate){
        //         this.openingDate = defaultOpeningDate;
        //         this.fn_saveOpeningDate()
        //     }
            
        //     let year = this.$moment(defaultOpeningDate).format('YYYY');
        //     let month = this.$moment(defaultOpeningDate).format('MM');
        //     let day = this.$moment(defaultOpeningDate).format('DD');

        //     this.minDate = year+"-"+month+"-"+day;
        // },

        fn_changeOpeningDate: function(){
            this.fn_closeDialog();
            let self = this;
            setTimeout(function(){
                self.askToDiscard = false;
                // self.$router.push({path:"/settings/openingdate", query:{goback:1}});
                self.$router.push({path:"/settings/openingdate"});
            },300);
        },

        // used to change opening date from opening ledger/stock
        // fn_openDatePickerOpening: function () {
        //     this.askToDiscard = false;
        //     this.fn_closeDialog();
        //     this.fn_openDialogWeb('web', 'DatePicker', 'ref_dialogDatePickerOpening');
        // },

        // fn_updateDateOpening: function(value){
        //     this.openingDate = value;
        //     console.log(this.openingDate);
        //     this.fn_saveOpeningDate();
        // },

        // fn_saveOpeningDate: function(){
        //     var self = this;

        //     let postObj = {};

        //     //subtracted 1 day from opening date as we are adding opening one day less than user selected opening date

        //     // postObj.openingDate = new Date(JSON.parse(JSON.stringify(this.openingDate)));
        //     // postObj.openingDate.setDate(postObj.openingDate.getDate()-1);
        //     postObj.openingDate = new Date(JSON.parse(JSON.stringify(this.openingDate)));
        //     postObj.BusinessID = this.$root.CurrentBusiness.BusinessID;

        //     let od = new Date(JSON.parse(JSON.stringify(this.openingDate)));
        //     od.setDate(od.getDate()-1);
        //     postObj.transactionDate = new Date(od);
        //     postObj.transactionListId = [40001, 40003];

        //     this.postData('OpeningDate', postObj, 
        //         {
        //             onSuccess: function(response){
        //                 console.log(response);
        //                 self.fn_setMinTransactionDate();
        //                 self.fn_closeDialog();
        //                 self.showToast('Opening date saved');
        //             },
        //             onError: function(error){
        //                 console.log(error);
        //                 self.showToast('Something went wrong. Try again!.');
        //             }
        //         }
        //     );
        // },

        // fn_setMinTransactionDate: function(){
        //     let year = this.$moment(this.openingDate).format('YYYY');
        //     let month = this.$moment(this.openingDate).format('MM');
        //     let day = this.$moment(this.openingDate).format('DD');

        //     this.$root.minDateSelection = year+"-"+month+"-"+day;
        // },

        /*=========================== End of Opening date mixins (using in opening ledger and opening stock) ===========================*/

        fn_checkTransactionCount: function(callback){
            let self = this;
            this.getData('TransactionCount', null, 
                {
                    onSuccess: function(response){
                        console.log(response);
                        let count = Number(response);
                        callback ? callback(count) : '';
                        self.transactionCount = Number(response);
                        // setTimeout(function(){
                        //     count >= 10 ? self.fn_gotoSetupDrive() : '';
                        // },1000);
                    }, 
                    onError: function(error){
                        console.log(error);
                    }
                }
            );
        },

        // fn_openDialogDriveSetup: function(){
        //     this.fn_openDialogWeb('web', 'Discard', 'ref_dialogDriveAlert');
        // },

        fn_gotoSetupDrive: function(){
            // if(!navigator.onLine){
            //     this.showToast("Internet not available");
            //     return;
            // }
            
            // this.fn_closeDialog();
            let self = this;
            this.fn_removeDraft(this.draftStorageId, function(){
                self.$router.push({path:"/registration/business/setupdrive"});
            });
            // this.askToDiscard = false;
            
        },

        fn_openTransacionTypeList_onFormLoad: function(){
            let self = this;
            if(!this.$root.CurrentBusiness.DataDrive && localStorage.getItem("SetDataDrive") && !localStorage.getItem('newItem')){
                this.fn_checkTransactionCount(function(transactionCount){
                    if(transactionCount < 10){
                        self.fn_openTransactionTypeList();
                    } 
                })  
            }
            else{
                this.fn_openTransactionTypeList();
            }
        },

        fn_openParties_onFormLoad: function(openParty, fnPartyDialog){
            // let self = this;
            if(openParty && !this.$route.query.dt && !localStorage.getItem('newItem')){
                setTimeout(function(){
                    fnPartyDialog();
                },300);
            }

        },

        fn_openPermissionDialog: function(permissionType){
            this.$root.permissionType = permissionType;
            // this.fn_openDialogWeb('web', 'Permission', 'ref_dialogPermission');
            this.$refs['ref_dialogPermission'].dialog_permission = true;
        },

        fn_uploadFiles: function(){
            var self = this;
            let postObj = {};
            postObj.fileExtensions = ['PDF','DOC','DOCX','TEXT','XLX','XLSX','CSV'];
            postObj.imageExtension = ['JPG','JPEG','PNG'];
            // this.showLoading('Uploading...');
            this.postData('SelectLocalDocument', postObj, 
                {
                    onSuccess: function(response){
                        console.log(response);
                        let documentObj = JSON.parse(response);
                        documentObj.documentId = self.guid();
                        documentObj.isNew = 1;
                        self.Form.attachments.push(JSON.parse(JSON.stringify(documentObj)));
                        setTimeout(function(){
                            self.$vuetify.goTo('#doc_'+documentObj.documentId, self.scrollOptions);
                        },300);
                        // self.hideLoading();
                    },
                    onError: function(error){
                        console.log(error);
                        if(error && error.toLowerCase() == "request cancel"){
                          console.log('request cancel');
                        }
                        else if(error == "Not Supported"){
                          self.showToast("File not supported");
                        }
                        else if(error == "file size exceeds"){
                            self.showToast("File size exceeds");   
                        }
                        else{
                          self.showToast("Something went wrong. Try again");
                        }
                        // self.hideLoading();
                    }
                }
            );
        },

        fn_captureImage: function(){
            let self = this;
            this.getData('CaptureImage', null, 
                {
                    onSuccess: function(response){
                        console.log(response);
                        let documentObj = JSON.parse(response);
                        documentObj.documentId = self.guid();
                        documentObj.isNew = 1;
                        self.Form.attachments.push(JSON.parse(JSON.stringify(documentObj)));
                        setTimeout(function(){
                            self.$vuetify.goTo('#doc_'+documentObj.documentId, self.scrollOptions);
                        },300);
                    }, 
                    onError: function(error){
                        console.log(error);
                        if(error && error.toLowerCase() == "request cancel"){
                          console.log('request cancel');
                        }
                        else if(error == "file size exceeds"){
                            self.showToast("File size exceeds");   
                        }
                        else{
                            self.showToast("Something went wrong. Try again");    
                        }
                    }
                }
            );
        },

        fn_removeAttachment: function(item){
            if(item.isNew){
                let itemIndex = this.Form.attachments.findIndex(file => file.documentId==item.documentId);
                this.Form.attachments.splice(itemIndex, 1);
            }
            else{
                item.uploadStatus = 2;
            }
        },

        // fn_checkIsFileRemoved: function(){
        //     let isRemoved = this.Form.attachments.findIndex(file => file.uploadStatus==2);
        //     if(isRemoved!=-1){
        //         return true;
        //     }
        //     else{   
        //         return false;
        //     }
        // },

        fn_checkFileExist: function(item){
            let self = this;
            this.getData('CheckFileExists', item.documentId, 
                {
                    onSuccess: function(response){
                        console.log(response);
                        if(response == 'file exists'){
                            self.fn_openFile(item);
                        }
                        else{
                            if(item.driveFileId){
                                self.fn_downloadFile(item);
                            }
                            else{
                                self.$eventHub.$emit('snackbarAlert', {msg:'File upload in progress. Please try after sometime'});
                            }
                        }
                    }, 
                    onError: function(error){
                        console.log(error);
                        self.$eventHub.$emit('snackbarAlert', {msg:'Something went wrong'});
                    }
                }
            );
        },

        fn_openFile: function(item){
            let self = this;
            this.$refs['ref_dialogLoading'].openDialogWeb();
            this.getFilterData('OpenDocumentFile', item.documentId, null, this.loadingStatusObj,
                {
                    onSuccess: function(response){
                        console.log(response);
                        self.fn_saveFile(response, item);
                        self.$refs['ref_dialogLoading'].closeDialogWeb();
                    }, 
                    onError: function(error){
                        console.log(error);
                        // alert("Something went wrong.");
                        self.$eventHub.$emit('snackbarAlert', {msg:'Something went wrong'});
                        self.$refs['ref_dialogLoading'].closeDialogWeb();
                    }
                }
            );
        },

        fn_downloadFile: function(item){
            this.$refs['ref_dialogLoading'].openDialogWeb();

            let self = this;

            let filterObject = {};
            filterObject.documentDetails = [item];
            filterObject.businessId = this.$root.CurrentBusiness.BusinessID;
            this.getFilterData('RestoreDocumentFile', item.documentId, filterObject, this.loadingStatusObj,
                {
                    onSuccess: function(response){
                        console.log(response);
                        self.fn_saveFile(response, item);
                        self.$refs['ref_dialogLoading'].closeDialogWeb();
                    }, 
                    onError: function(error){
                        console.log(error);
                        if(error == "need storage permission"){
                            // alert("In order to download attachment enable storage permission in BizOps mobile app!");
                            self.$eventHub.$emit('snackbarAlert', {msg:'In order to download attachment enable storage permission in BizOps mobile app'});
                        }
                        else{
                            // alert("Something went wrong.");
                            self.$eventHub.$emit('snackbarAlert', {msg:'Something went wrong'});
                        }
                        self.$refs['ref_dialogLoading'].closeDialogWeb();
                    }
                }
            );
        },

        fn_saveFile: function(fileData, item){
            var dataBlob = new Blob([fileData]);
            var url = window.URL.createObjectURL(dataBlob);

            // create <a>
            var link = document.createElement('a');
            link.href = url;
            link.download = item.fileName;
            document.body.appendChild(link);

            // trigger the download file dialog
            link.click();
            link.remove();
        },

        // ================================== Payment mode functions ================================== //

        fn_openDialog_PaymentOptions: function(){
            let self = this;
            if(this.Form.paymentOptions.length){
                for(let i=0; i<this.Form.paymentOptions.length; i++){
                    this.Form.paymentGroups.forEach(function(group){
                        group.accountLedgers.forEach(function(ledger){
                            if(ledger.accountLedgerId == self.Form.paymentOptions[i].accountLedgerId){
                                self.$set(ledger, 'selected', true);
                                self.$set(ledger, 'amount', self.Form.paymentOptions[i].amount);
                                self.$set(ledger, 'refNumber', self.Form.paymentOptions[i].refNumber);
                            }
                        });
                    });
                }
            }
            // this.fn_openDialogWeb('web', 'PaymentOptions', 'ref_dialogPaymentOptions')
            this.$refs['ref_dialogPaymentOptions'].openDialogWeb();

            setTimeout(function(){
                let formElementDialog = document.getElementsByClassName("form-element-dialog");
                if(formElementDialog && formElementDialog.length){
                    formElementDialog[0].addEventListener("submit", function(e){
                        e.preventDefault();
                    });     
                }
            },500);
        },

        // fn_getPaymentGroups: function () {
        //     let filterObject = {};
        //     filterObject.accountGroups = [paymentAccountsAndLedger.groups.cashInHand, paymentAccountsAndLedger.groups.bank, paymentAccountsAndLedger.groups.card, paymentAccountsAndLedger.groups.wallet];

        //     let self = this;
        //     this.getFilterData('PaymentGroups', null, filterObject, 
        //         {
        //             onSuccess: function(response){
        //                 console.log(response);
        //                 self.Form.paymentGroups = JSON.parse(response);

        //                 let onCreditObj = {"accountGroupId":"creditAccountGroup","accountGroupName":null,"accountLedgers":[{"accountLedgerId":self.creditAccountLedgerId,"accountLedgerName":"On Credit"}]};

        //                 self.Form.paymentGroups.unshift(onCreditObj);

        //                 self.Form.paymentGroups.forEach(function(group){
        //                     group.accountLedgers.forEach(function(ledger){
        //                         self.$set(ledger, 'selected', false);
        //                         self.$set(ledger, 'amount', null);
        //                     });
        //                 });

        //                 // assigning payment ledger id incase of paymentLedgerId undefined or !split payment for both cases new/edit
        //                 if(!self.Form.paymentLedgerId && !self.Form.splitPayment){
        //                     self.Form.paymentLedgerId = onCreditObj.accountLedgers[0].accountLedgerId;  
        //                 }
                        

        //             }, 
        //             onError: function(error){
        //               console.log(error);
        //             }
        //         }
        //     );
        // },

        fn_getPaymentGroups: function (assignRemember) {
            let filterObject = {};
            filterObject.accountGroups = [paymentAccountsAndLedger.groups.cashInHand, paymentAccountsAndLedger.groups.bank, paymentAccountsAndLedger.groups.card, paymentAccountsAndLedger.groups.wallet];

            let self = this;
            this.getFilterData('PaymentGroups', null, filterObject, null,
                {
                    onSuccess: function(response){
                        console.log(response);
                        // self.Form.paymentGroups = JSON.parse(response);

                        // let onCreditObj = {"accountGroupId":"creditAccountGroup","accountGroupName":null,"accountLedgers":[{"accountLedgerId":self.creditAccountLedgerId,"accountLedgerName":"On Credit"}]};

                        // self.Form.paymentGroups.unshift(onCreditObj);

                        let groups = JSON.parse(response);

                        groups.forEach(function(group){
                            group.accountLedgers.forEach(function(ledger){
                                !ledger.selected ? self.$set(ledger, 'selected', false) : '';
                                !ledger.amount ? self.$set(ledger, 'amount', null) : '';
                                !ledger.refNumber ? self.$set(ledger, 'refNumber', null) : '';
                            });
                        });

                        let onCreditObj = {"accountGroupId":"creditAccountGroup","accountGroupName":null,"accountLedgers":[{"accountLedgerId":self.creditAccountLedgerId,"accountLedgerName":"On Credit"}]};

                        // if(!self.Form.paymentGroups){
                        if(!self.Form.paymentGroups || !self.Form.paymentGroupIndex){
                            self.Form.paymentGroups = JSON.parse(JSON.stringify(groups));
                            self.Form.paymentGroups.unshift(onCreditObj);
                        }
                        else{
                            // let newAccountLedger = null;
                            // let groupIndex = null;

                            let oldLedgers = self.Form.paymentGroups[self.Form.paymentGroupIndex].accountLedgers;
                            groups.unshift(onCreditObj);
                            let newLedgers = groups[self.Form.paymentGroupIndex].accountLedgers;

                            var res = newLedgers.filter(item1 => !oldLedgers.some(item2 => (item2.accountLedgerId === item1.accountLedgerId)));

                            console.log(res);

                            if(self.Form.splitPayment){
                                res[0].selected = true;
                                self.$refs['ref_dialogPaymentOptions'].toggleAmount(res[0]);
                            }
                            else{
                                self.Form.paymentLedgerId = res[0].accountLedgerId;
                            }

                            self.Form.paymentGroups[self.Form.paymentGroupIndex].accountLedgers.push(res[0]);

                        }

                        // assigning payment ledger id incase of paymentLedgerId undefined or !split payment for both cases new/edit
                        if(!self.Form.paymentLedgerId && !self.Form.splitPayment){
                            self.Form.paymentLedgerId = onCreditObj.accountLedgers[0].accountLedgerId;  
                        }

                        // assigning last remember payment ledger id in case of new form
                        if(self.Form.isNew && assignRemember){
                            self.fn_assignLastPaymentId();
                         }
                        

                    }, 
                    onError: function(error){
                      console.log(error);
                    }
                }
            );
        },

        fn_assignLastPaymentId: function(){
            this.$root.fn_getLocalItem('lastpaymentledgerid', response=>{
                let data = JSON.parse(response);

                if(data && data.LocalValue){
                    if(this.$root.TransactionSettings.defaultPaymentMode && this.$root.TransactionSettings.defaultPaymentMode != 1){
                        if(this.$root.TransactionSettings.defaultPaymentMode == 2){
                            this.Form.paymentLedgerId = paymentAccountsAndLedger.ledgers.cash;
                        }
                        else if(this.$root.TransactionSettings.defaultPaymentMode == 3){
                            this.Form.paymentLedgerId = this.creditAccountLedgerId;
                        }
                    }
                    else{
                        this.Form.paymentLedgerId = data.LocalValue;
                    }
                }
            })
        },

        fn_paymentAccountName: function(accountId){
            if(this.Form.paymentGroups && this.Form.paymentGroups.length){
                for(let i=0; i<this.Form.paymentGroups.length; i++){
                    for(let j=0; j<this.Form.paymentGroups[i].accountLedgers.length; j++){
                        if(this.Form.paymentGroups[i].accountLedgers[j].accountLedgerId == accountId){
                            return this.Form.paymentGroups[i].accountLedgers[j].accountLedgerName;
                        }
                    }   
                }
            }
        },

        savePaymentOptions: function(paymentLedgerId){

            this.Form.paymentOptions = [];
            let groups = JSON.parse(JSON.stringify(this.Form.paymentGroups));

            for(let i=0; i<groups.length; i++){
                for(let j=0; j<groups[i].accountLedgers.length; j++){

                    let ledger = {};
                    ledger.accountLedgerId = groups[i].accountLedgers[j].accountLedgerId;
                    ledger.accountLedgerName = groups[i].accountLedgers[j].accountLedgerName;
                    ledger.amount = groups[i].accountLedgers[j].amount;
                    ledger.refNumber = groups[i].accountLedgers[j].refNumber;

                    if(this.Form.splitPayment){
                        if(groups[i].accountLedgers[j].amount){
                            // let ledger = {};
                            // ledger.accountLedgerId = groups[i].accountLedgers[j].accountLedgerId;
                            // ledger.accountLedgerName = groups[i].accountLedgers[j].accountLedgerName;
                            // ledger.amount = groups[i].accountLedgers[j].amount;
                            // ledger.refNumber = groups[i].accountLedgers[j].refNumber;
                            this.Form.paymentOptions.push(ledger);
                        }
                    }
                    else{
                        if(groups[i].accountLedgers[j].accountLedgerId  == paymentLedgerId){
                            this.Form.paymentLedgerId = paymentLedgerId;
                            break;
                        }
                    }
                    
                }   
            }
            
        },

        fn_generatePaymentEntries: function(ledgerAccounts, transactionLedgers){

            let amountType = null;

            if(this.settings.transactionTypeId == 10003 || this.settings.transactionTypeId == 10004){
                amountType = 1;
            }
            else{
                amountType = 0;
            }

            if(!this.Form.splitPayment){
                //Entries for ledger accounts
                let paymentLedgerObj = {};
                // paymentLedgerObj.amountType = 1;//debit
                paymentLedgerObj.amountType = amountType;//debit
                if(this.Form.paymentLedgerId == this.creditAccountLedgerId){
                    // paymentLedgerObj.accountId = this.Form.partyId;
                    paymentLedgerObj.accountId = this.Form.selectedParty ? this.Form.selectedParty.itemId : null;
                    // saving on credit amount to transaction table for reporting
                    this.onCreditValue = this.roundedAmount;
                }
                else{
                    paymentLedgerObj.accountId = this.Form.paymentLedgerId;
                    // paymentLedgerObj.subAccountId = this.Form.partyId;
                    paymentLedgerObj.subAccountId = this.Form.selectedParty ? this.Form.selectedParty.itemId : null;
                    // saving on credit amount to transaction table for reporting
                    this.onCreditValue = null;
                }
                // paymentLedgerObj.accountId = this.Form.paymentLedgerId;
                // paymentLedgerObj.subAccountId = this.Form.partyId;
                paymentLedgerObj.amount = amountType ? this.roundedAmount : -this.roundedAmount;
                let paymentLedger = this.fn_bindAndReturnSimilarValues(paymentLedgerObj);
                ledgerAccounts.push(paymentLedger);

                //Entries for transaction ledgers
                let tlObj = {};
                tlObj.transactionLedgerId = this.guid();
                // tlObj.transactionLedgerId = await this.uniqueId();
                tlObj.accountLedgerId = this.Form.paymentLedgerId;
                tlObj.orderNumber = transactionLedgers.length + 1;
                tlObj.amount = this.roundedAmount;
                // tlObj.amountType = 1;
                tlObj.amountType = amountType;
                tlObj.transactionListId = this.Form.transactionData.transactionListId;
                transactionLedgers.push(tlObj);

                // saving on credit amount to transaction table for reporting
                // this.onCreditValue = null;

                // saving payment ledger id to remember for next time"}
                this.$root.fn_saveLocalItem({LocalKey:'lastpaymentledgerid',LocalValue:this.Form.paymentLedgerId});
            }
            else{
                for(let i=0; i<this.Form.paymentOptions.length; i++){
                    //Entries for ledger accounts
                    let paymentLedgerObj = {};
                    // paymentLedgerObj.amountType = 1;//debit
                    paymentLedgerObj.amountType = amountType;//debit
                    if(this.Form.paymentOptions[i].accountLedgerId == this.creditAccountLedgerId){
                        // paymentLedgerObj.accountId = this.Form.partyId;
                        paymentLedgerObj.accountId = this.Form.selectedParty ? this.Form.selectedParty.itemId : null;
                        // saving on credit amount to transaction table for reporting
                        this.onCreditValue = this.Form.paymentOptions[i].amount;
                        // paymentLedgerObj.amount = this.roundedAmount;
                    }
                    else{
                        paymentLedgerObj.accountId = this.Form.paymentOptions[i].accountLedgerId;
                        // paymentLedgerObj.subAccountId = this.Form.partyId;
                        paymentLedgerObj.subAccountId = this.Form.selectedParty ? this.Form.selectedParty.itemId : null;
                        // paymentLedgerObj.amount = this.Form.paymentOptions[i].amount;
                    }
                    // paymentLedgerObj.accountId = this.Form.paymentOptions[i].accountLedgerId;
                    // paymentLedgerObj.subAccountId = this.Form.partyId;
                    paymentLedgerObj.amount = amountType ? this.Form.paymentOptions[i].amount : -this.Form.paymentOptions[i].amount;
                    let paymentLedger = this.fn_bindAndReturnSimilarValues(paymentLedgerObj);
                    ledgerAccounts.push(paymentLedger);

                    //Entries for transaction ledgers
                    let tlObj = {};
                    tlObj.transactionLedgerId = this.guid();
                    // tlObj.transactionLedgerId = await this.uniqueId();
                    tlObj.accountLedgerId = this.Form.paymentOptions[i].accountLedgerId;
                    tlObj.orderNumber = transactionLedgers.length + 1;
                    tlObj.amount = this.Form.paymentOptions[i].amount;
                    // tlObj.amountType = 1;
                    tlObj.amountType = amountType;
                    tlObj.transactionListId = this.Form.transactionData.transactionListId;
                    transactionLedgers.push(tlObj);
                }
            }

        },

        fn_bindAndReturnSimilarValues: function (ledgerObj) {
            ledgerObj.ledgerAccountId = this.guid();
            // ledgerObj.ledgerAccountId = await this.uniqueId();
            ledgerObj.transactionListId = this.Form.transactionData.transactionListId;
            ledgerObj.transactionDate = this.Form.transactionData.transactionDate;  
            return ledgerObj;
        },

        // ================================== End of payment mode functions ================================== //

        fn_removeAddOnsOnPlaceOfSupplyChange: function(fixedGstAddOns){
            let tAddOns = false;
            for(let i=0; i<this.Form.transactionAddOns.length; i++){
                let addOn = this.Form.transactionAddOns[i];
                if(this.Form.gstType == "IntraState" && (fixedGstAddOns.UTGST.id == addOn.addOnsId || fixedGstAddOns.IGST.id == addOn.addOnsId)){
                    this.Form.transactionAddOns.splice(i, 1);
                    tAddOns = true;
                    i=-1;
                }
                else if(this.Form.gstType == "UnionTerritory" && (fixedGstAddOns.SGST.id == addOn.addOnsId || fixedGstAddOns.IGST.id == addOn.addOnsId)){
                    this.Form.transactionAddOns.splice(i, 1);
                    tAddOns = true;
                    i=-1;
                }
                else if(this.Form.gstType == "InterState" && (fixedGstAddOns.UTGST.id == addOn.addOnsId || fixedGstAddOns.SGST.id == addOn.addOnsId || fixedGstAddOns.CGST.id == addOn.addOnsId)){
                    this.Form.transactionAddOns.splice(i, 1);
                    tAddOns = true;
                    i=-1;
                }
            }

            if(tAddOns){
                // alert("Transaction taxes removed");
                this.$eventHub.$emit('snackbarAlert', {msg:'Transaction taxes removed'});
            }
        },

        // Update dialog ref on change
        // fn_updateDialogRef: function(tagId, ref, selectedValueId, selectedValueName, isRemoved){
        //     let key = ref.substring(0, ref.lastIndexOf('_'));
        //     let dialogRef = this.$refs[key];

        //     var checkRefs = setInterval(function(){
        //         if(dialogRef.$refs.hasOwnProperty(ref)){
        //             clearInterval(checkRefs);
        //             if(Array.isArray(dialogRef.$refs[ref])){
        //                 dialogRef.$refs[ref][0].$attrs.onUpdate(selectedValueId, selectedValueName, isRemoved, ref);
        //             }
        //             else{
        //                 dialogRef.$refs[ref].$attrs.onUpdate(selectedValueId, selectedValueName, isRemoved, ref);
        //             }
        //         }
        //     },50);
        // },

        // open dialog and set new add ons
        fn_setNewAddOns: function(id, name, isRemoved){
            // if(this.Form.newSelection && !this.$refs['ref_dialogAddOns'].dialog_addons){
            if(localStorage.getItem('newSelection') && !this.$refs['ref_dialogAddOns'].dialog_addons){
                this.fn_openDialogWeb('web', 'AddOnsForm', 'ref_dialogAddOns');
            }
            let self = this;
            setTimeout(() => {
                self.$refs['ref_dialogAddOns'].fn_updateAddOns(id, name, isRemoved);
            }, 300);
        },

        // open dialog and set new product
        fn_setNewProduct: function(id, name, isRemoved){
            if(localStorage.getItem('newSelection') && !this.$refs['ref_dialogProduct'].dialog_product){
                this.fn_openDialogWeb('web', 'Product', 'ref_dialogProduct');
            }
            let self = this;
            setTimeout(() => {
                self.$refs['ref_dialogProduct'].fn_updateProduct(id, name, isRemoved);
            }, 300);
        },

        // Attachments

        fn_selectFile: function(){
            if(!this.$root.CurrentBusiness.DataDrive){
                this.$eventHub.$emit('snackbarAlert', {msg:'Set up Backup Drive in mobile app to attach documents'});
                return;
            }

            // upload status 2 is for files which are removed
            let attachedFiles = this.Form.attachments.filter(file=>{
                return file.uploadStatus != 2;
            })

            if(attachedFiles.length === 5){
                this.$eventHub.$emit('snackbarAlert', {msg:'Maximum 5 attachments are allowed.'});
                return;
            }
            
            document.getElementById("filePicker").value = "";
            document.getElementById('filePicker').click();
        },

        fn_validateFile: function(event){

            let extentions = ['PDF','DOC','DOCX','TEXT','TXT','XLX','XLSX','CSV','JPG','JPEG','PNG'];
            console.log(event.target.files[0]);
            let file = event.target.files[0];
            let fileExt = file.name.substr(file.name.lastIndexOf('.'));
            let extIndex = extentions.findIndex(ext => '.'+ext.toUpperCase()==fileExt.toUpperCase());
            if(extIndex == -1){
            //   alert("File not supported!");
                this.$eventHub.$emit('snackbarAlert', {msg:'File not supported'});
                return;
            }

            console.log(event.target.files);
            let self = this;
            self.fileBufferValue = 0;
            var reader = new FileReader();
            reader.readAsArrayBuffer(event.target.files[0]);
            reader.onload = function () {
                console.log(reader.result);
                let arrayBuffer = reader.result;
                let fileId = self.guidWeb();
                self.fn_clearLastFileArrayBuffer(fileId, arrayBuffer, file.name, fileExt);
            };
            reader.onerror = function (error) {
                console.log('Error: ', error);
            };
        },

        fn_clearLastFileArrayBuffer: function(fileId, arrayBuffer, fileName, fileExt){
            let self = this;
            this.getData('ClearLastFileArrayBuffer', '', 
                {
                    onSuccess: function(response){
                        console.log(response);
                        self.fn_breakFileDataIntoChunks(fileId, arrayBuffer, fileName, fileExt);
                    }, 
                    onError: function(error){
                        console.log(error);
                    }
                }
            );
        },

        // fn_openFileToWrite: function(fileData, fileName, fileExt){
            // console.log(fileData);
            // let fileId = this.guidWeb();
            // let filterObject = {};
            // filterObject.fileName = fileName;
            // let self = this;
            // this.getFilterData('OpenFile', fileId, filterObject, null,
            //     {
            //         onSuccess: function(response){
            //             console.log(response);
            //             self.fn_breakFileDataIntoChunks(fileId, fileData, fileName, fileExt);
            //         }, 
            //         onError: function(error){
            //             console.log(error);
            //         }
            //     }
            // );
        // },


        fn_breakFileDataIntoChunks: function(fileId, arrayBuffer, fileName, fileExt){
            // let chunkSize = (1024 * 32) - 73; //self.$parent.datachunksize //1 byte
            // 147 reducing as the size of callbackId_connectionId is 147 (check fn "sendFileChunks" and "sendDataChunks" in action inter face file)
            let chunkSize = (1024 * 32) - 147; //self.$parent.datachunksize //1 byte
            this.bytesArray = [];
            for (let i = 0; i < arrayBuffer.byteLength; i += chunkSize) {
                let datachunk = arrayBuffer.slice(i, i + chunkSize);
                this.bytesArray.push(datachunk);
            }
            this.fn_sendFileChunk(this.bytesArray[0], fileId, fileName, fileExt, this.bytesArray.length);
        },

        fn_sendFileChunk: function(dataChunk, fileId, fileName, fileExt, totalChunkLength){
            let self = this;
            this.sendFileChunks(dataChunk,
                {
                    onSuccess: function(response){
                        console.log(response);

                        self.bytesArray.shift();

                        totalChunkLength ? self.totalChunksLength = totalChunkLength : '';
                        self.fileBufferValue = Math.round(((self.totalChunksLength - self.bytesArray.length) * 100)/self.totalChunksLength);

                        if(self.bytesArray.length){
                            self.fn_sendFileChunk(self.bytesArray[0], fileId, fileName, fileExt);
                        }
                        else{
                            // alert('close file');
                            self.fn_closeFile(fileId, fileName, fileExt, response);
                        }
                    }, 
                    onError: function(error){
                        console.log(error);
                    }
                }
            );
        },

        testFile: function(datachunk, totalDataChunkLength, fileDataLength, fileName){
            // let decodedChunk = new TextDecoder("utf-8").decode(datachunk);
            // let chunk = new TextEncoder("utf-8").encode(decodedChunk);

            var decodedChunk = String.fromCharCode(...new Uint8Array(datachunk));
            var chunk = Uint8Array.from([...decodedChunk].map(ch => ch.charCodeAt()));

            console.log(chunk);

            // let strchunk = JSON.stringify(datachunk);

            // let chunk = JSON.parse(strchunk);

            chunk.forEach(array=>{
                this.bytesArray.push(array);
            })

            if(totalDataChunkLength == fileDataLength){
                console.log(this.bytesArray);
                // alert('done');
                let blob = new Blob([new Uint8Array(this.bytesArray, this.bytesArray.byteOffset, this.bytesArray.length)]);
                //new Blob([this.bytesArray]);
                const url = URL.createObjectURL(blob);
                let link = document.createElement('a');
                link.setAttribute('href', url);
                link.setAttribute('download', fileName);
                link.style.visibility = 'hidden';
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            }
        },

        // fn_writeFile: function(fileId, datachunk, totalDataChunkLength, fileDataLength, fileName, fileExt, chunkcounter){
        // fn_writeFile: function(fileId, fileName, fileExt){
        //     let self = this;
        //     let filterObject = {};
        //     // console.log(datachunk.length);
        //     let dataChunk = this.bytesArray[0];

        //     filterObject.dataBytes = String.fromCharCode(...new Uint8Array(dataChunk));//datachunk;
        //     // filterObject.chunkNumber = chunkcounter + 1;
        //     filterObject.chunkNumber = self.chunkcounter;
        //     window.prompt("sometext",filterObject.dataBytes);
        //     this.getFilterData('WriteFile', fileId, filterObject, null,
        //         {
        //             onSuccess: function(response){
        //                 window.prompt("sometext", response);
        //                 console.log('chunkNumber2', response);
        //                 self.bytesArray.shift();
        //                 if(self.bytesArray.length){
        //                     self.chunkcounter++;
        //                     self.fn_writeFile(fileId, fileName, fileExt);
        //                 }
        //                 else{
        //                     alert('close file');
        //                     self.fn_closeFile(fileId, fileName, fileExt);
        //                 }
        //                 // if(totalDataChunkLength == fileDataLength){
        //                 //     alert('close file')
        //                 //     self.fn_closeFile(fileId, fileName, fileExt);
        //                 // }
        //             }, 
        //             onError: function(error){
        //                 console.log(error);
        //             }
        //         }
        //     );
        // },

        fn_closeFile: function(fileId, fileName, fileExt, connectionId){
            let self = this;
            let filterObject = {};
            let fName = fileName.substr(0, fileName.lastIndexOf('.'));
            let fExt = fileName.substr(fileName.lastIndexOf('.'));
            filterObject.fileName = fName.replace(/ /g,'').replace(/\//g,'') + '_' + this.$root.fn_getCurrentDateTimeString() + fExt;
            filterObject.connectionId = connectionId;
            this.getFilterData('CloseFile', fileId, filterObject, null,
                {
                    onSuccess: function(response){
                        console.log(response);
                        let documentObj = {};
                        documentObj.fileExtension = fileExt;
                        // documentObj.fileName = fileName;
                        documentObj.fileName = filterObject.fileName;
                        documentObj.filePath = response;
                        documentObj.uploadStatus = 0
                        documentObj.documentId = fileId;
                        documentObj.isNew = 1;
                        self.Form.attachments.push(JSON.parse(JSON.stringify(documentObj)));
                        setTimeout(() => {
                            self.fn_scrollToElementById('doc_'+fileId);
                            self.fileBufferValue = 0;
                        },100);
                    }, 
                    onError: function(error){
                        console.log(error);
                    }
                }
            );
        },

        fn_updateTransactionDate: function(value, isNew){
            // let openingDate = new Date(this.$root.CurrentBusiness.OpeningDate);

            // let openingYear = openingDate.getFullYear();
            // let openingMonth = openingDate.getMonth() + 1;
            // let openingFY = openingMonth <= 3 ? openingYear : openingYear + 1;

            let oldTransactionDate = new Date(this.Form.transactionData.transactionDate);
            let oldTransactionDateYear = oldTransactionDate.getFullYear();
            let oldTransactionDateMonth = oldTransactionDate.getMonth() + 1;
            let oldTransactionDateFY = oldTransactionDateMonth <= 3 ? oldTransactionDateYear : oldTransactionDateYear + 1;

            let tDate = new Date(value);
            let tDateYear = tDate.getFullYear();
            let tDateMonth = tDate.getMonth() + 1;
            let tDateFY = tDateMonth <= 3 ? tDateYear : tDateYear + 1;
            
            // if(isNew || openingFY != tDateFY){
            if(isNew || oldTransactionDateFY != tDateFY){
                // if(openingFY != tDateFY){
                if(oldTransactionDateFY != tDateFY){
                    this.fn_openDialogDateConfirmation(value);
                }
                else{
                    this.Form.transactionData.transactionDate = this.$root.fn_getLocalDateFormat(value);
                    this.fn_bindTransactionNumber();
                }
            }
            else{
                this.Form.transactionData.transactionDate = this.$root.fn_getLocalDateFormat(value);
            }
        },

        fn_changeDateAndBindTransactionNumber: function(value){
            this.Form.transactionData.transactionDate = this.$root.fn_getLocalDateFormat(value);
            this.fn_bindTransactionNumber();
            this.$refs['ref_dialogConfirmation'].closeDialogWeb();
        },

        fn_loadTimeOut: function(){
            setTimeout(() => {
                if(this.loadMore){
                  this.loadTimeOut = true;
                  this.loadMore = false;
                }
                else{
                  this.loadTimeOut = false;
                }
            }, 1 * 10 * 1000);
        },

        fn_breakDataIntoChunks: function(postObj){
            // let chunkSize = (1024 * 32) - 73; //self.$parent.datachunksize //1 byte
            // 147 reducing as the size of callbackId_connectionId is 147 (check fn "sendFileChunks" and "sendDataChunks" in action inter face file)
            let chunkSize = (1024 * 32) - 147; //self.$parent.datachunksize //1 byte
            let enc = new TextEncoder(); 
            let arrayBuffer = enc.encode(postObj);

            this.bytesArray = [];
            for (let i = 0; i < arrayBuffer.byteLength; i += chunkSize) {
                let datachunk = arrayBuffer.slice(i, i + chunkSize);
                this.bytesArray.push(datachunk);
            }
            this.fn_sendDataChunk(this.bytesArray[0], this.bytesArray.length);
        },

        fn_sendDataChunk: function(dataChunk, totalChunkLength){
            let self = this;
            this.sendDataChunks(dataChunk,
                {
                    onSuccess: function(response){
                        console.log(response);

                        self.bytesArray.shift();

                        totalChunkLength ? self.totalChunksLength = totalChunkLength : '';
                        self.fileBufferValue = Math.round(((self.totalChunksLength - self.bytesArray.length) * 100)/self.totalChunksLength);

                        if(self.bytesArray.length){
                            self.fn_sendDataChunk(self.bytesArray[0]);
                        }
                        else{
                            // call save local
                            self.fn_saveLocal(null)
                        }
                    }, 
                    onError: function(error){
                        console.log(error);
                    }
                }
            );
        },

        fn_dueDate: function(billDate, transactionDate, days){
            let date = billDate ? billDate : transactionDate;
            let result = new Date(date);
            result.setDate(result.getDate() + days);
            return result;
        },

        fn_getLastTransactionDate: function(transactionListId, callback){
            let filterObject = {};
            filterObject.transactionListId = [transactionListId];
            this.getFilterData('LastTransactionDateByType', null, filterObject, null,
                {
                    onSuccess: function(response){
                    console.log(response);
                    callback ? callback(JSON.parse(response)) : '';
                },
                    onError: function(error){
                    console.log(error);
                }
            });
        },

        fn_balanceLabel: function(groupTypeId, balance){
            if(groupTypeId){
                // alert(partyDetails.balance)
                if(groupTypeId == 103){
                    if(balance < 0){
                        return "Credit"
                    }
                    else{
                        return "Debit"
                    }
                }
                else{
                    if(balance < 0){
                        return "To Pay"
                    }
                    else{
                        return "To Receive"
                    }
                }
            }
            else{
                return '';
            }
            
        }

    },
    mounted: function(){

        let self = this;

        this.$eventHub.$on('disconnected', () => {
            self.loading = false;
        });

        // for(let i=0; i<this.$root.TransactionSettings.batchFields.length; i++){
        //     let field = this.$root.TransactionSettings.batchFields[i]
        //     if(field.id == 'field1' || field.id == 'field2' || field.id == 'field3'){
        //         this.hasSeries = true;
        //     }

        //     if(field.id == 'batchNumber' || field.id == 'batchMRP' || field.id == 'purchaseRate' || field.id == 'batchSize' || field.id == 'mfgDate' || field.id == 'expDate' || field.id == 'field4'){
        //         this.hasOnlySeries = false;
        //     }
        // }

        // if(!this.Form.selectedBatchType){
        //     this.Form.selectedBatchType = !this.hasOnlySeries ? 1 : 2;
        // }

        // this.$eventHub.$once('connected', () => {
        //     self.loading = false;
        //     if(self.getDataCounter < 7){
        //         let itemId = self.$route.params && self.$route.params.tagId ? self.$route.params.tagId : null;
        //         let isDraft = localStorage.getItem(self.draftStorageId) ? true : false;
        //         let setTFT = !itemId && !isDraft;
        //         self.$route.query.loadItems ? setTFT = false : '';
        //         setTimeout(() => {
        //             self.fn_getTransactionTypeList(setTFT);
        //         }, 1000);
        //         // this.fn_closeDialogForm();
        //     }
        // });
        // let self = this;
        // window.addEventListener('keydown', function (event) {
        //     console.log(event);
        //     event.preventDefault();
        //     let routeName = self.$route.name;
        //     if(routeName.toLowerCase().indexOf('display') != -1){
        //         if (event.shiftKey && event.code === 'Delete') {
        //             console.log(self.fn_delete);
        //             self.fn_delete();
        //         }
    
        //         if (event.shiftKey && event.code === 'KeyE') {
        //             self.fn_edit();
        //         }
    
        //         if (event.shiftKey && event.code === 'KeyC') {
        //             self.fn_cancel();
        //         }
        //     }

        //     if(routeName.toLowerCase().indexOf('form') != -1){
        //         if (event.altKey && event.code === 'Enter') {
        //             self.$refs['ref_dialogForm'].fn_validateForm();
        //         }

        //         if(event.shiftKey && event.code === 'KeyY'){
        //             let element = document.getElementById("party_dropdown");
        //             element.focus();
        //             element.click();
        //         }
                
        //         if(event.shiftKey && event.code === 'KeyM'){
        //             let element = document.getElementById("fieldproducts");
        //             element.focus();
        //             element.click();
        //         }
        //     }

        //     // if(routeName.toLowerCase().indexOf('invoicepreview') != -1){

        //     //     if(event.shiftKey && event.code === 'KeyP'){
        //     //         let element = document.getElementById("invoice_print_btn");
        //     //         element.click();
        //     //     }
                
        //     //     if(event.shiftKey && event.code === 'KeyD'){
        //     //         let element = document.getElementById("invoice_export_btn");
        //     //         element.click();
        //     //     }
        //     // }
        // });
    },
    computed:{
        filteredProducts: function(){
            return this.$root.products.filter(item => {
                if(this.settings.transactionTypeId == 20005){
                    return item.inventory == 0;
                }
                else{
                    return item.inventory == 1;
                }
            })
        },
        filteredLedgers: function(){
            return this.$root.ledgers.filter(item => {
                if(this.settings.transactionTypeId == 30004){
                    let groups = [paymentAccountsAndLedger.groups.cashInHand, paymentAccountsAndLedger.groups.bank, paymentAccountsAndLedger.groups.card, paymentAccountsAndLedger.groups.wallet];
                    let itemIndex = groups.findIndex(groupId => groupId == item.accountGroupId);
                    return itemIndex != -1 ? true :  false;
                }
                if(this.settings.transactionTypeId == 20005){
                    if(this.$root.TransactionSettings.showAllParties){
                        return item.groupTypeId == ledgerGroupTypeId.supplier || item.groupTypeId == ledgerGroupTypeId.customer;
                    }
                    else{
                        return item.groupTypeId == ledgerGroupTypeId.supplier;
                    }
                }
                if(this.formTypeName == 'Sale' || this.formTypeName == 'Purchase'){
                    let accountGstTypes = null;
                    if(this.Form.transactionTypeData.importExport && this.Form.transactionTypeData.importExportTransactionAccountId){
                        accountGstTypes = [accountGstType.overseas.id, accountGstType.sez.id]
                    }
                    else{
                        accountGstTypes = [accountGstType.registered.id, accountGstType.composition.id, accountGstType.unregistered.id, accountGstType.consumer.id, accountGstType.deemed.id];
                    }

                    let gstTypeItemIndex = accountGstTypes.findIndex(gstType => gstType == item.gstType);

                    if(gstTypeItemIndex != -1){
                        if(this.$root.TransactionSettings.showAllParties){
                            return item.groupTypeId == ledgerGroupTypeId.supplier || item.groupTypeId == ledgerGroupTypeId.customer;
                        }
                        else{
                            if(this.formTypeName == 'Purchase'){
                                return item.groupTypeId == ledgerGroupTypeId.supplier;
                            }                            
                            else{
                                return item.groupTypeId == ledgerGroupTypeId.customer;
                            }
                        }
                    }
                    else{
                        return false;
                    }
                }
                else{
                    return true;
                }
            })
        }
    }
}