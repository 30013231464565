<template>
    <div>
        <v-dialog v-model="dialog_form" content-class="half-screen-dialog" no-click-animation scrollable persistent transition="slide-y-transition" :max-width="$root.fn_getActionPaneWidth()">
            <v-card no-border-radius grey-back>
                <v-toolbar :height="56" flat>
                    <v-btn icon @click="closeDialog"><v-icon>arrow_back</v-icon></v-btn>
                    <v-toolbar-title>Business Profile</v-toolbar-title>
                </v-toolbar>

                <v-divider class="noprint"></v-divider>

                <div class="data-container grey-back">

                  <ValidationObserver ref="observer">

                  <v-form @submit.prevent="submit">

                    <v-layout row wrap ma-0 pb-3 white-back>

                      <v-flex form-item xs6 sm6 md6 pt-4>

                        <ValidationProvider name="Business Name" rules="required" v-slot="{ errors }">
                            <v-text-field  
                              :disabled="loginType != 'self' || $parent.disableOnSave"
                              v-model="$parent.Form.BusinessName" 
                              label="Business Name" 
                              autocomplete="null"
                              :error-messages="errors"
                              required>
                            </v-text-field>
                         </ValidationProvider>

                      </v-flex>

                      <v-flex form-item xs6 sm6 md6 pt-4>

                        <ValidationProvider name="Business Owner Name" rules="required" v-slot="{ errors }">
                            <v-text-field  
                              :disabled="loginType != 'self' || $parent.disableOnSave"
                              v-model="$parent.Form.UserName" 
                              label="Business Owner Name" 
                              autocomplete="null"
                              :error-messages="errors"
                              required>
                            </v-text-field>
                         </ValidationProvider>
                      </v-flex>

                      <v-flex form-item xs12 sm12 md12 pt-4>

                        <ValidationProvider name="Address" rules="max:250" v-slot="{ errors }">
                            <v-textarea  
                              :disabled="$parent.disableOnSave"
                              v-model="$parent.Form.Address" 
                              label="Address (will appear on invoice and bill prints)" 
                              rows="1"
                              autocomplete="null"
                              :error-messages="errors"
                              counter="250"
                              required>
                            </v-textarea>
                         </ValidationProvider>
                      </v-flex>

                      <!-- <v-flex form-item xs6 sm6 md6 pt-4>

                        <ValidationProvider name="Email" rules="email" v-slot="{ errors }">
                            <v-text-field  
                              v-model="$parent.Form.Email" 
                              label="Email" 
                              autocomplete="null"
                              :error-messages="errors"
                              required>
                            </v-text-field>
                         </ValidationProvider>
                      </v-flex> -->

                      <v-flex form-item xs6 sm6 md6 pt-4>
                        <ValidationProvider name="State" rules="required" v-slot="{ errors }">

                            <v-autocomplete
                                :disabled="$parent.disableOnSave" 
                                :items="$root.states ? $root.states : []"
                                v-model="$parent.selectedState"
                                label="State/Place of Supply"
                                item-value="itemId"
                                item-text="itemName"
                                autocomplete="null"
                                return-object
                                required
                                :error-messages="errors">
                            </v-autocomplete>

                          </ValidationProvider>
                      </v-flex>

                      <v-flex form-item xs6 sm6 md6 pt-4>
                        <ValidationProvider name="GST registration status" rules="required" v-slot="{ errors }">

                            <v-select
                              :disabled="$parent.disableOnSave"
                              :items="$parent.registrationTypes"
                              @change="fn_updateRegistrationType"
                              label="GST Registration Status"
                              item-value="status"
                              item-text="name"
                              return-object
                              required
                              :error-messages="errors"
                              v-model="$parent.selectedRegistationType">
                             </v-select>

                          </ValidationProvider>
                      </v-flex>

                      <!-- <v-flex xs12 sm12 md12 form-item v-if="$parent.Form.GSTRegistrationStatus == 1 || $parent.Form.GSTRegistrationStatus == 2"> -->
                      <v-flex xs6 sm6 md6 form-item pt-4 v-if="$parent.selectedRegistationType && ($parent.selectedRegistationType.status == 1 || $parent.selectedRegistationType.status == 2)">
                          <ValidationProvider name="GST Number" rules="required|verify_gstnumber" v-slot="{ errors }">
                           <v-text-field 
                              :disabled="$parent.disableOnSave"
                              uppercase  
                              label="GST Number (GSTIN)" 
                              autocomplete="null"
                              @input="fn_gstNumberInUpperCase"
                              placeholder=" " 
                              v-model="$parent.Form.GSTNumber"
                              :hide-details="!errors || !errors.length"
                              :error-messages="errors">
                            </v-text-field>
                          </ValidationProvider>
                          <div class="pt-1" right-align font12 light-opacity-color>Example: 12ABCDE1234A9Z9</div>
                      </v-flex>

                      <!-- <v-flex xs12 sm12 md12 v-if="$parent.Form.GSTRegistrationStatus==1"> -->
                      <v-flex xs6 sm6 md6 form-item pt-4 v-if="$parent.selectedRegistationType && $parent.selectedRegistationType.status == 1">
                        <v-select 
                              :disabled="$parent.disableOnSave"
                              v-model="$parent.selectedTaxMode" 
                              row 
                              label="Tax"
                              :items="[{'title':'Exclusive', 'value':1},{'title':'Inclusive', 'value':2},{'title':'None', 'value':0}]"
                              return-object
                              item-text="title"
                              item-value="value">
                            </v-select>
                      </v-flex>
                    
                    </v-layout>

                  </v-form>

                  </ValidationObserver>
                </div>


                <v-card-actions white-back>
                    <v-layout row wrap ma-0>

                        <v-flex form-item xs12 sm12 md12 height48>
                            <v-btn no-border-radius color="primary" full-height nomargin block no-round-borders noshadow :disabled="$parent.disableOnSave" @click.once="fn_validateForm" :key="$parent.saveBtnKey">Save</v-btn>
                        </v-flex>

                    </v-layout>
                </v-card-actions>
                  

            </v-card>
        </v-dialog>
    </div>
</template>

<script>
// import gstrSubReport from '../print_report_templates/gstr_sub_report'
// import { required } from 'vee-validate/dist/rules'
// import { extend, ValidationProvider, setInteractionMode } from 'vee-validate'

// setInteractionMode('eager')

// extend('required', {
//   ...required,
//   message: '{_field_} can not be empty',
// })

// import { email } from 'vee-validate/dist/rules'
import { extend, ValidationProvider, ValidationObserver } from 'vee-validate'

// extend('email', email);

extend('verify_gstnumber', value => {

  // var strongRegex = new RegExp("^([0][1-9]|[1-2][0-9]|[3][0-7])([a-zA-Z]{5}[0-9]{4}[a-zA-Z]{1}[1-9a-zA-Z]{1}[zZ]{1}[0-9a-zA-Z]{1})+$");
  var strongRegex = new RegExp("^([0][1-9]|[1-2][0-9]|[3][0-7])([a-zA-Z]{5}[0-9]{4}[a-zA-Z]{1}[1-9a-zA-Z]{1}[a-zA-Z]{1}[0-9a-zA-Z]{1})+$");
  let isValid = strongRegex.test(value);

  if(isValid){
    return true;
  }

  return 'GST Number is not valid.';
});

    export default{
        props:['closeDialog'],
        data(){
            return{
                dialog_form: false,
                loginType:null
            }
        },
        components:{
            // 'gstr-sub-report':gstrSubReport
            ValidationProvider,
            ValidationObserver
        },
        methods:{
            openDialogWeb: function () {
                this.dialog_form = true;
                this.$parent.disableOnSave = false;
                this.loginType = localStorage.getItem('login_type');
            },
            closeDialogWeb: function () {
                this.dialog_form = false;
            },
            fn_updateRegistrationType: function(item){
              this.$parent.Form.GSTRegistrationStatus = item.status;
              if(item.status == 1){
                this.$parent.selectedTaxMode.value = 1;
                this.$parent.Form.HSNApplicable = true;
              }
              else{
                this.$parent.selectedTaxMode.value = 0;
                this.$parent.Form.HSNApplicable = false;
              }
            },
            fn_gstNumberInUpperCase: function(){
              this.$parent.Form.gstNumber = this.$parent.Form.gstNumber.toUpperCase();
            },
            fn_validateForm: function(){
              this.$parent.disableOnSave = true;
              this.$refs.observer.validate().then((result) => {
                if(result) {
                  this.$parent.fn_checkValidations();
                }
                else{
                  this.$parent.saveBtnKey++;
                  this.$parent.disableOnSave = false;
                  this.fn_scrollToElement('error--text');
                }
              });
            }
        }
    }
</script>