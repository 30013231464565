<template>
    <div full-height grey-back>

    <v-snackbar v-model="snackbar" :timeout="2000">{{snackbar_msg}}</v-snackbar>

    <v-snackbar multi-line :timeout="0" v-model="showSnackbarCopy">
      {{snackbarCopyMsg}}
      <v-btn text color="warning" @click.native="showSnackbarCopy=false">Cancel</v-btn>
      <v-btn text color="primary" @click.native="fn_copyLastConfigs">Okay</v-btn>
    </v-snackbar>

    <dialog-form ref="ref_dialogForm" :closeDialog="fn_closeDialogForm"></dialog-form>

    <dialog-discard ref="ref_dialogDiscard" :draftAction="fn_goBack" :save="fn_validate"></dialog-discard>

<!--     <dialog-tax-categories 
        ref="ref_dialogTaxCategories"  
        :taxCategories="taxCategories"
        :updateTaxCategory="fn_updateTaxCategory" 
        :selectedTaxCategoryId="Form.taxCategoryId">
    </dialog-tax-categories>

    <dialog-add-unit 
        ref="ref_dialogAddUnit" 
        :openDialogUnits="fn_openDialogUnits" 
        :updateSubUnit="fn_updateSubUnit"
        :checkSubVariantValidations="fn_checkSubVariantValidations"
        :fixedCQty="Form.fixedCQty">
    </dialog-add-unit>

    <dialog-opening ref="ref_dialogOpening" :amount="Form.openingQty" :rate="Form.openingRate"></dialog-opening> -->

    </div>
</template>

<script>

const listIdObject = require('../../../../config.js').configs.mastersPredefinedLists;
const defaultUnit = require('../../../../config.js').configs.fixedUnits;
const taxCategoryIds = require('../../../../config.js').configs.taxCategoryIds;

// import dialogTaxCategories from '../../../dialogs/dialog_tax_categories'
// import dialogAddUnit from './dialogs/dialog_add_unit'
import dialogDiscard from '../../../dialogs/dialog_discard'
import {draftMixin} from '../../../draft_mixin'
import dialogForm from './dialogs/dialog_form'

// import dialogOpening from './dialogs/dialog_opening'

const transactionData = require('../../../transactions/datamodel').data.transactionData;
// import dialogDraftItems from '../../../dialogs/dialog_draft_items'

    export default{
        mixins: [draftMixin],
        data(){
            return{
                loading:false,

                Form:{"name":null, "groupTypeId":101, "productCategoryId":null, "productCategoryName":null, "description":null, "productCode":null, "charges": null,  "brandId":null, "brandName":null, "companyId":null, "companyName":null, "companyBarcode":null,

                    "hsnCode":null,

                    "unitId":null, "unitName":null, "title":null, "sellingPrice":null, "mrp":null, "wholeSalePrice":null,

                    "fixedCQty": null,

                    "variant": {"description":null, "groupTypeId":401, "isDefault":false, "unitId":null, "productId":null, "productVariantId":null, "title":null, "variantBaseUnitId":null, "unitName":null, "packQuantity":null}, 

                    "productVariants":[],

                    "isSubVariant": false,

                    "taxCategoryId":null,
                    "taxCategoryName":null,

                    "inventory":1,
                    "isSystem":0,
                    "draftItems":[],
                    "createdDate":null, 
                    "modifiedDate":null,
                    "isNew":true,
                    "openingQty":null,
                    "openingRate":null,
                    "transactionId":null,
                    "purchaseRate":null,

                    "selectedTaxCategory":null,
                    "selectedUnit":null,
                    "selectedProductCategory":null,
                    "selectedBrand":null,
                    "selectedCompany":null
                },

                tagId:null,
                draftStorageId:null,
                dialogStorageId:null,
                backButtonPress:false,
                snackbar:false,
                snackbar_msg:"",
                unwatch: false,
                // loadedFromDraft:false,
                disableProduct:false,
                taxCategories:null,
                transactionData: JSON.parse(JSON.stringify(transactionData)),
                showSnackbarCopy:false,
                snackbarCopyMsg:null,
                lastItemConfig:null,

                units:null,
                productCategories:null,
                brands:null,
                companies:null,
                isExpanded:null,
                disableOnSave:false
            }
        },
        components:{
            // 'dialog-tax-categories':dialogTaxCategories,
            // 'dialog-add-unit':dialogAddUnit,
            'dialog-discard': dialogDiscard,
            // 'dialog-opening': dialogOpening,
            // 'dialog-draft-items': dialogDraftItems
            'dialog-form':dialogForm
        },
        watch:{
            'Form': {
                handler: function (){
                    if(!this.unwatch){
                        this.fn_saveDraft(this.draftStorageId, this.Form.name);
                     }
                     this.unwatch = false;
                },
                deep: true
            }
        },
        methods:{

            fn_units: function(val){
              let filterObject = {};
              filterObject.search = val ? val.toLowerCase() : '';
              
              let self = this;
              this.getDialogItems("UnitDialog", filterObject, 
                {
                  onSuccess: function (response) {
                    console.log(response);
                    self.units = JSON.parse(response);
                    // self.accountGroups.unshift("header");
                  },
                  onError: function (error) {
                    console.log(error);
                  }
                }
              )
            },

            fn_productCategories: function(val){
              let filterObject = {};
              filterObject.search = val ? val.toLowerCase() : '';
              
              let self = this;
              this.getDialogItems("ProductCategoryDialog", filterObject, 
                {
                  onSuccess: function (response) {
                    console.log(response);
                    self.productCategories = JSON.parse(response);
                    // self.accountGroups.unshift("header");
                  },
                  onError: function (error) {
                    console.log(error);
                  }
                }
              )
            },

            fn_listItems: function(val, type){
              let filterObject = {};
              filterObject.search = val ? val.toLowerCase() : '';

              filterObject.listId = type == 'brand' ? listIdObject.brandListGUID : listIdObject.companyListGUID
              
              let self = this;
              this.getDialogItems("ListItemDialog", filterObject, 
                {
                  onSuccess: function (response) {
                    console.log(response);
                    if(type == "brand"){
                        self.brands = JSON.parse(response);
                    }
                    else{
                        self.companies = JSON.parse(response);   
                    }
                    // self.accountGroups.unshift("header");
                  },
                  onError: function (error) {
                    console.log(error);
                  }
                }
              )
            },

            fn_closeDialogForm: function(){
                this.askToDiscard = true;
                if(this.fn_showDialogDiscard()){
                    this.$refs['ref_dialogDiscard'].openDialogWeb();
                }
                else{
                    this.fn_goBack();
                }
            },

            // fn_expandOtherDetails: function(){
            //     if(this.Form.selectedProductCategory || this.Form.selectedBrand || this.Form.description || this.Form.selectedCompany || this.Form.companyBarcode){
            //         return true;
            //     }
            //     else{
            //         return false;
            //     }
            // },

            fn_openDialogDiscard: function(){
                this.fn_openDialogWeb('web', 'Discard', 'ref_dialogDiscard')
            },

            fn_openDialogTaxCategories: function(){
                this.fn_openDialogWeb('web', 'TaxCategory', 'ref_dialogTaxCategories');
            },

            fn_openDialogOpening: function(){
                this.fn_openDialogWeb('web', 'Opening', 'ref_dialogOpening');
            },

            fn_setOpening: function(qty, rate){
                this.Form.openingQty = qty;
                this.Form.openingRate = rate ? rate : 0;
                // this.fn_closeDialog();
            },

            fn_getTaxCategories: function(){
              let filterObject = {};
              let self = this;
              this.getItems("TaxCategoryList", filterObject, 
                {
                  onSuccess: function (response) {
                    console.log(response);
                    self.taxCategories = JSON.parse(response);
                  },
                  onError: function (error) {
                    console.log(error);
                  }
                }
              )
            },

            fn_openDialogAddUnit: function(){
                this.fn_openDialogWeb('web', 'AddUnit', 'ref_dialogAddUnit');
            },

            fn_updateTaxCategory: function (item) {
                // this.Form.taxCategoryId = item.taxCategoryId;
                // this.Form.taxCategoryName = item.taxCategoryName;
                this.Form.selectedTaxCategory = {"taxCategoryId":item.taxCategoryId, "taxCategoryName":item.taxCategoryName};
                // this.errors.remove('Gst');
                // !preventCloseDialog ? this.fn_closeDialog() : '';
            },

            // fn_onChange: function(tagId, ref, selectedValueId, selectedValueName, isRemoved){
            //     if(ref=='ref_dialogAddUnit_subunit'){
            //         this.fn_updateSubUnit(selectedValueId, selectedValueName, isRemoved);
            //     }
            //     else{
            //         this.$refs[ref].$attrs.onUpdate(selectedValueId, selectedValueName, isRemoved);
            //     }
            // },

            fn_newBrand: function(){
                this.$router.push({path:'/'+this.$route.params.businessId + '/' + this.$route.params.listId +'/list_item/items/new', query:{tagId:this.guidWeb(), listTitle:'Brand', type:'brand', listId:listIdObject.brandListGUID, ls:1}});
            },

            fn_newCompany: function(){
                this.$router.push({path:'/'+this.$route.params.businessId + '/' + this.$route.params.listId +'/list_item/items/new', query:{tagId:this.guidWeb(), listTitle:'Company', type:'company', listId:listIdObject.companyListGUID, ls:1}});
            },

            // fn_editProductVariant: function (variant) {
            //     this.fn_getUnitData(variant.unitId, false);
            //     this.fn_setVariantData(variant);
            //     this.fn_openDialogVariant();
            // },

            // fn_setVariantData: function (variant) {
            //     this.Form.variant.description = variant.description;
            //     this.Form.variant.groupTypeId = variant.groupTypeId;
            //     this.Form.variant.isDefault = variant.isDefault;
            //     this.Form.variant.productId = variant.productId;
            //     this.Form.variant.title = variant.title;
            //     this.Form.variant.productVariantId = variant.productVariantId;
            //     this.Form.variant.unitId = variant.unitId;
            //     this.Form.variant.unitName = variant.unitName;
            //     this.Form.variant.packQuantity = variant.packQuantity;
            //     this.$forceUpdate();

            //     //Force save to local storage;
            //     this.fn_saveDraft(this.Form.name);
            // },

            // fn_openDialogWeb: function (platform, name, ref) {

            //     let scroll_y = window.scrollY;
            //     this.fn_fixBackgroundScroll();

            //     var tagId = this.guidWeb();

            //     var dialogObj = {};
            //     dialogObj.tagId = tagId;
            //     dialogObj.formId = this.formId;
            //     dialogObj.name = name;
            //     dialogObj.ref = ref;
            //     dialogObj.data = null;
            //     dialogObj.platform = platform;

            //     dialogObj.scrollPosition = {};
            //     dialogObj.scrollPosition.x = 0;
            //     dialogObj.scrollPosition.y = scroll_y;

            //     dialogjs.fn_pushDialogInStack(this.dialogStorageId, dialogObj, this);

            //     console.log(this.$refs);

            //     this.$refs[ref].openDialogWeb();
            // },

            // fn_openCategories: function (platform, name, ref) {

            //     var tagId = this.guidWeb();

            //     var dialogObj = {};
            //     dialogObj.tagId = tagId;
            //     dialogObj.formId = this.formId;
            //     dialogObj.name = name;
            //     dialogObj.ref = ref;
            //     dialogObj.platform = platform;

            //     var viewDialogObject = {};
            //     viewDialogObject.ToolbarTitle = "Select Category";

            //     //static as action is different
            //     viewDialogObject.TopMenu = [{"MenuID":1, "MenuType":null, "Icon":null, "Text":"New", "Action":"newCategory"}];
            //     viewDialogObject.isBottomMenu = false;

            //     var paramObject = {};
            //     paramObject.defaultValue = {"id":null, "name":null};

            //     if(this.Form.productCategoryId && this.Form.productCategoryName){
            //         paramObject.defaultValue = {"id":this.Form.productCategoryId, "name":this.Form.productCategoryName};
            //     }

            //     dialogjs.fn_openDialog(this, dialogObj, viewDialogObject, 'productCategories', tagId, paramObject, ref);
                
            // },

            // fn_updateCategory: function (selectedValueId, selectedValueName, isRemoved) {
            //     console.log(selectedValueId+':'+selectedValueName+':'+isRemoved);
            //     if(!isRemoved){
            //         this.Form.productCategoryId = selectedValueId;
            //         this.Form.productCategoryName = selectedValueName;
            //     }
            //     else{
            //         this.Form.productCategoryId = null;
            //         this.Form.productCategoryName = null;
            //     }
            //     this.$forceUpdate();
            //     //Force save to localStorage
            //     this.fn_saveDraft(this.Form.name);
            // },

            // fn_openBrands: function (platform, name, ref) {

            //     var tagId = this.guidWeb();

            //     var dialogObj = {};
            //     dialogObj.tagId = tagId;
            //     dialogObj.formId = this.formId;
            //     dialogObj.name = name;
            //     dialogObj.ref = ref;
            //     dialogObj.platform = platform;

            //     let viewDialogObject = {};
            //     viewDialogObject.ToolbarTitle = "Select Brand";

            //     //static as action is different
            //     viewDialogObject.TopMenu = [{"MenuID":1, "MenuType":null, "Icon":null, "Text":"New", "Action":"newBrandListItem"}];
            //     viewDialogObject.isBottomMenu = false;
            //     viewDialogObject.listId = listIdObject.brandListGUID;

            //     let paramObject = {}
            //     paramObject.defaultValue = {"id":null, "name":null};

            //     if(this.Form.brandId && this.Form.brandName){
            //         paramObject.defaultValue = {"id":this.Form.brandId, "name":this.Form.brandName};
            //     }

            //     dialogjs.fn_openDialog(this, dialogObj, viewDialogObject, 'listItems', tagId, paramObject, ref);
                
            // },

            // fn_updateBrand: function (selectedValueId, selectedValueName, isRemoved) {
            //     console.log(selectedValueId+':'+selectedValueName+':'+isRemoved);
            //     if(!isRemoved){
            //         this.Form.brandId = selectedValueId;
            //         this.Form.brandName = selectedValueName;
            //     }
            //     else{
            //         this.Form.brandId = null;
            //         this.Form.brandName = null;
            //     }
            //     this.$forceUpdate();
            //     //Force save to localStorage
            //     this.fn_saveDraft(this.Form.name);
            // },

            // fn_openCompanies: function (platform, name, ref) {

            //     var tagId = this.guidWeb();

            //     var dialogObj = {};
            //     dialogObj.tagId = tagId;
            //     dialogObj.formId = this.formId;
            //     dialogObj.name = name;
            //     dialogObj.ref = ref;
            //     dialogObj.platform = platform;

            //     var viewDialogObject = {};
            //     viewDialogObject.ToolbarTitle = "Select Company";

            //     //static as action is different
            //     viewDialogObject.TopMenu = [{"MenuID":1, "MenuType":null, "Icon":null, "Text":"New", "Action":"newCompanyListItem"}];

            //     viewDialogObject.isBottomMenu = false;
            //     viewDialogObject.listId = listIdObject.companyListGUID;

            //     let paramObject = {};
            //     paramObject.defaultValue = {"id":null, "name":null};

            //     if(this.Form.companyId && this.Form.companyName){
            //         paramObject.defaultValue = {"id":this.Form.companyId, "name":this.Form.companyName};
            //     }

            //     dialogjs.fn_openDialog(this, dialogObj, viewDialogObject, 'listItems', tagId, paramObject, ref);
                
            // },

            // fn_updateCompany: function (selectedValueId, selectedValueName, isRemoved) {
            //     console.log(selectedValueId+':'+selectedValueName+':'+isRemoved);
            //     if(!isRemoved){
            //         this.Form.companyId = selectedValueId;
            //         this.Form.companyName = selectedValueName;
            //     }
            //     else{
            //         this.Form.companyId = null;
            //         this.Form.companyName = null;
            //     }
            //     this.$forceUpdate();
            //     //Force save to localStorage
            //     this.fn_saveDraft(this.Form.name);
            // },

            // fn_openDialogUnits: function(ref){
            //     this.fn_openUnits('android', 'Unit', ref);
            // },

            // fn_openUnits: function (platform, name, ref) {

            //     var tagId = this.guidWeb();

            //     var dialogObj = {};
            //     dialogObj.tagId = tagId;
            //     dialogObj.formId = this.formId;
            //     dialogObj.name = name;
            //     dialogObj.ref = ref;
            //     dialogObj.platform = platform;

            //     var viewDialogObject = {};
            //     viewDialogObject.ToolbarTitle = "Select Unit";

            //     //static as action is different
            //     viewDialogObject.isBottomMenu = false;
            //     var paramObject = {};

            //     paramObject.defaultValue = {"id":null, "name":null};
            //     viewDialogObject.TopMenu = [{"MenuID":1, "MenuType":null, "Icon":null, "Text":"New", "Action":"newUnit"}];

            //     if(ref=='field_unit'){
            //         // paramObject = {"filters":{"conversionType":101}};
            //         if(this.Form.unitId && this.Form.unitName){
            //             paramObject.defaultValue = {"id":this.Form.unitId, "name":this.Form.unitName};
            //         }

            //         // viewDialogObject.TopMenu = [{"MenuID":1, "MenuType":null, "Icon":null, "Text":"New", "Action":"newUnit"}];
            //     }
            //     // else{
            //     //  paramObject = {"filters":{"conversionType":102, "baseUnitId":this.Form.unitId}};
            //     //  if(this.Form.variant.unitId && this.Form.variant.unitName){
            //     //      paramObject.defaultValue = {"id":this.Form.variant.unitId, "name":this.Form.variant.unitName};
            //     //  }

            //     //  viewDialogObject.TopMenu = [{"MenuID":1, "MenuType":null, "Icon":null, "Text":"New", "Action":"newSubUnit"}];
            //     // }

            //     dialogjs.fn_openDialog(this, dialogObj, viewDialogObject, 'Units', tagId, paramObject, ref);                
            // },

            fn_updateUnit: function (selectedValueId, selectedValueName, isRemoved) {
                // console.log(selectedValueId+':'+selectedValueName+':'+isRemoved);
                // alert(selectedValueId);
                if(selectedValueName=='change'){
                    if(selectedValueId==101){
                        this.Form.unitId = defaultUnit.pieces.id;
                        this.Form.unitName = defaultUnit.pieces.name;
                    }
                    else{
                        this.Form.unitId = defaultUnit.nos.id;
                        this.Form.unitName = defaultUnit.nos.name;
                    }
                    this.Form.selectedUnit = {"itemId":this.Form.unitId, "itemName":this.Form.unitName};
                    this.fn_getUnitData(this.Form.unitId, true);
                }
                else{
                    if(!isRemoved){
                        this.Form.unitId = selectedValueId;
                        this.Form.unitName = selectedValueName;

                        this.Form.selectedUnit = {"itemId":selectedValueId, "itemName":selectedValueName};
                        
                        this.fn_getUnitData(selectedValueId, true);
                        // this.fn_setDefaultVariant();
                        // this.errors.remove('unit');
                    }
                    else{
                        this.Form.unitId = null;
                        this.Form.unitName = null;
                    }
                }

                
                this.$forceUpdate();
            },

            // fn_updateSubUnit: function (selectedValueId, selectedValueName, isRemoved) {
            //     if(!isRemoved){

            //         if(this.Form.variant.unitId != selectedValueId){
            //             this.Form.variant.packQuantity = null;
            //         }

            //         this.Form.variant.unitId = selectedValueId;
            //         this.Form.variant.unitName = selectedValueName;

            //         this.fn_getUnitData(selectedValueId, false, true);
            //         this.$refs['ref_dialogAddUnit'].errors.remove('subvariant.subunit');
            //     }
            //     else{
            //         this.Form.variant.unitId = null;
            //         this.Form.variant.unitName = null;
            //         this.Form.fixedCQty = null;
            //     }
            //     this.$forceUpdate();
            //     //Force save to draft
            //     this.fn_saveDraft(this.Form.name);
            // },

            fn_getUnitData: function (unitId, isDefault, setConverionQty) {
                var self = this;
                this.getData('Units', unitId, 
                    {
                        onSuccess: function(response){
                          console.log(response);
                          let unitData = JSON.parse(response);

                          if(unitData.conversionQuantity){
                            self.Form.fixedCQty = unitData.conversionQuantity;
                            setConverionQty ? self.$set(self.Form.variant, 'packQuantity', unitData.conversionQuantity) : '';
                          }
                          else{
                            self.Form.fixedCQty = null;
                          }

                          self.fn_setDefaultVariant();

                          self.$forceUpdate();
                        }, 
                        onError: function(error){
                          console.log(error);
                        }
                    }
                );
            },

            // fn_addVariant: function () {
            //     this.Form.variant = {"description":null, "groupTypeId":401, "isDefault":false, "unitId":null, "productId":null, "productVariantId":null, "title":null, "variantBaseUnitId":null, "unitName":null, "packQuantity":null};

            //     this.Form.fixedCQty = null;

            //     this.$forceUpdate();
            //     this.fn_openDialogAddUnit();
            // },

            // fn_openDialogVariant: function () {
            //     this.$validator.reset();
            //     this.fn_openDialogAddUnit();
            // },

            // fn_checkSubVariants: function (ref) {
            //     if(this.Form.isSubVariant){
            //         this.showToast("Kindly remove added units");
            //     }
            //     else{
            //         this.fn_openDialogUnits(ref);   
            //     }
            // },

            // fn_checkSubVariantValidations: function (variantObj, isDefault) {
            //     let self = this;

            //     let variantIndex = this.Form.productVariants.findIndex(item=>{
            //         return variantObj.unitId == item.unitId && variantObj.packQuantity == item.packQuantity && variantObj.productVariantId != item.productVariantId;
            //     });

            //     if(variantIndex!=-1){
            //         this.showToast("Variant already exists");
            //         return;
            //     }

            //     this.fn_saveVariant(variantObj);

            //     setTimeout(function(){
            //         self.$vuetify.goTo(".productVariantItem", self.scrollOptions);
            //     },100);
            // },

            fn_saveVariant: function (variant) {

                // this.fn_closeDialog();

                let variantObj = JSON.parse(JSON.stringify(variant));

                !variantObj.isDefault ? this.Form.isSubVariant = true : '';

                if(!variantObj.productVariantId){
                    !variantObj.productVariantId ? variantObj.productVariantId = this.guid() : '';
                    this.Form.productVariants.push(variantObj);
                }
                else{
                    let itemIndex = this.Form.productVariants.findIndex(item=>{
                        return variantObj.productVariantId == item.productVariantId;
                    });
                    this.Form.productVariants.splice(itemIndex, 1, variantObj);
                }

                console.log(JSON.stringify(this.Form.productVariants));
            },

            // fn_removeVariant: function (productVariantId, index) {

            //     this.Form.productVariants.splice(index, 1);

            //     if(this.Form.productVariants.length<=1){
            //         this.Form.isSubVariant = false;
            //     }
            // },

            fn_setDefaultVariant: function(){

                let defaultVariant = this.Form.productVariants.find(function(variant) {
                    return variant.isDefault;
                });

                if(defaultVariant){
                    defaultVariant.unitId = this.Form.unitId;
                    defaultVariant.unitName = this.Form.unitName;
                    defaultVariant.packQuantity = null;
                }
                else{
                    defaultVariant = {};
                    defaultVariant.description = null;
                    defaultVariant.groupTypeId = this.Form.variant.groupTypeId;
                    defaultVariant.isDefault = true;
                    defaultVariant.productId = null;
                    defaultVariant.title = null;
                    defaultVariant.productVariantId = null;
                    defaultVariant.unitId = this.Form.unitId;
                    defaultVariant.unitName = this.Form.unitName;
                    defaultVariant.packQuantity = null;
                }

                this.fn_saveVariant(defaultVariant);
            },

            // fn_getBottomMenu: function (tagId) {
            //  var bottomMenu = {"BottomMenu": [menuItem.save, menuItem.reset, menuItem.help]};
            //  this.setBottomMenu(tagId, bottomMenu, 
            //      {
            //          onSuccess: function (response) {
            //              console.log(response);
            //          },
            //          onError: function (error) {
            //              console.log(error);
            //          }
            //      }
            //  )
            // },

            // fn_closeDialog: function (tagId) {
            //     dialogjs.fn_checkAndCloseOpenDialogs(this);
            // },

            fn_checkValidations: function(){
                this.fn_post();
            },

            fn_saveLocal: function(dataObj){
                var self = this;
                this.postData('Product', dataObj, 
                    {
                        onSuccess: function(response){
                            let status = JSON.parse(response);
                            if(status) {
                                // self.fn_cleanUp();
                                // self.fn_postOpening();
                                if(self.Form.openingQty){
                                    self.fn_postOpening();
                                }
                                else{
                                    if(self.Form.transactionId){
                                        self.fn_deleteTransaction(function(status){
                                            if(status){
                                                self.fn_cleanUp();
                                            }
                                        })
                                    }
                                    else{
                                        self.fn_cleanUp();
                                    }
                                }

                                self.$root.fn_getMasterItems('products', ["Product", "deleteProductWithOpening"], false, true);
                            }
                            else {
                                self.disableOnSave = false;
                                self.saveBtnKey++;
                                self.$eventHub.$emit('snackbarAlert', {msg:'Already exists with this name'});
                            }
                        },
                        onError: function(error){
                            self.disableOnSave = false;
                            self.saveBtnKey++;
                            console.log(error);
                            self.$eventHub.$emit('snackbarAlert', {msg:'Something went wrong. Try again'});
                        }
                    }
                );
            },

            fn_deleteTransaction: function(callback){
                let self = this;
                let filterObject = {};
                filterObject.transactionListId = [40001];
                this.postFilterData('deleteTransaction', this.Form.transactionId, filterObject, {

                    onSuccess: function(response){
                        
                        console.log(response);

                        callback(JSON.parse(response));

                    }, 
                    onError: function(error){
                        console.log(error);
                        let status = JSON.parse(error);
                        if(status && status.itemsCount){
                            let msg = 'Unable to delete as this item already in use under [' + status.items.join(', ') + ']';
                            // alert(msg);
                            self.$eventHub.$emit('snackbarAlert', {msg:msg});
                        }
                    }
                });
            },

            fn_postOpening: function(){

                this.transactionData.transactionId = this.Form.transactionId;
                
                //only in case of new transaction
                if(!this.transactionData.transactionId){
                    this.transactionData.transactionId = this.guid();
                    // this.transactionData.createdDate = new Date();
                    this.Form.createdDate = this.$root.fn_getLocalDateFormat();
                }

                this.transactionData.transactionListId = 40001;

                // this.transactionData.modifiedDate = new Date();
                this.Form.modifiedDate = this.$root.fn_getLocalDateFormat();

                this.transactionData.amount = this.totalAmount;
                this.transactionData.roundedAmount = Math.round(this.totalAmount);
                this.transactionData.status = 1;
                this.transactionData.refDate = null;

                let od = new Date(JSON.parse(JSON.stringify(this.$root.CurrentBusiness.OpeningDate)));
                od.setDate(od.getDate()-1);
                this.transactionData.transactionDate = new Date(od);

                let tpObj = {};

                tpObj.transactionProductId = this.guid();

                tpObj.transactionId = this.transactionData.transactionId;

                tpObj.productId = this.Form.productId;

                tpObj.productVariantId = this.Form.productVariants[0].productVariantId;

                tpObj.groupId = this.guid();

                // tpObj.mrp = tP.mrp ? tP.mrp : null;

                // tpObj.rate = tP.rate ? tP.rate : null;

                tpObj.mrp = 0;

                tpObj.rate = this.Form.openingRate;

                tpObj.additionalData = null;

                tpObj.unitId = this.Form.unitId;

                tpObj.primaryUnitId = this.Form.unitId;

                tpObj.qty = this.Form.openingQty ? this.Form.openingQty : 0;

                // tpObj.packQuantity = this.Form.productVariants[0].packQuantity;
                tpObj.packQuantity = this.Form.fixedCQty ? this.Form.fixedCQty : 1;

                tpObj.transactionProductMode = 1;

                //saving actual quantity in positive and negative in inventory products in the form of qty
                tpObj.actualQty = this.Form.openingQty;

                tpObj.amount = this.totalAmount;

                tpObj.taxableValue = this.totalAmount;

                let postObj = {};

                postObj = this.transactionData;
                postObj.transactionProducts = [];
                postObj.transactionProducts.push(tpObj);

                let currentAssetsAddOn = {};
                postObj.currentAssetsTransactions = [];
                currentAssetsAddOn.currentAssetsTransactionId = this.guid();
                postObj.currentAssetsTransactions.push(currentAssetsAddOn);
                postObj.sortDate = this.$moment(this.transactionData.transactionDate).format('YYYY-MM-DD');
                console.log(postObj);
                this.fn_saveLocalOpening(postObj);
            },

            fn_saveLocalOpening: function(dataObj){
                var self = this;
                this.postData('TransactionProducts', dataObj, 
                    {
                        onSuccess: function(response){
                            console.log(response);
                            self.fn_cleanUp();
                        },
                        onError: function(error){
                            self.disableOnSave = false;
                            self.saveBtnKey++;
                            console.log(error);
                        }
                    }
                );
            },

            fn_cleanUp: function () {

                // this.loadedFromDraft = false;

                if(this.$route.query.ls){
                    let data = {};
                    data.type = 'product';
                    data.id = this.Form.productId;
                    data.name = this.Form.name;
                    localStorage.setItem('newItem', JSON.stringify(data));
                    localStorage.setItem('closeDialog', 1);
                }

                this.$eventHub.$emit('UpdateItemList', {});
                this.fn_goBack();

                // let self = this;
                // this.fn_removeDraft(this.draftStorageId, function(){

                //  if(localStorage.getItem("activity_items_state")){
                //      let obj = JSON.parse(localStorage.getItem("activity_items_state"));
                //      obj.itemId = self.Form.productId;
                //      localStorage.setItem("activity_items_state",JSON.stringify(obj));
                //  }
                    
                //  self.hideLoading();
                //  self.$router.go(-1);
                // });
            },

            fn_post: function(){
                // this.showLoading("Please wait...");

                // if(this.Form.groupTypeId == 102){
                //     this.Form.unitId = "865cf461-99b6-4b4f-9678-4cd11c3aab69"; //static unit (nos) for type service
                //     this.Form.unitName = "Nos";
                // }

                // !this.Form.productId ? this.Form.productId = this.guid() : '';
                if(!this.Form.productId){
                    this.Form.productId = this.guid();
                    this.Form.createdDate = new Date();
                }

                this.Form.modifiedDate = new Date();
                
                this.Form.name = this.Form.name.trim();
                !this.Form.mrp ? this.Form.mrp = null : '';
                !this.Form.sellingPrice ? this.Form.sellingPrice = null : '';
                !this.Form.wholeSalePrice ? this.Form.wholeSalePrice = null : '';
                !this.Form.purchaseRate ? this.Form.purchaseRate = null : '';

                this.Form.selectedTaxCategory ? this.Form.taxCategoryId = this.Form.selectedTaxCategory.taxCategoryId : '';

                if(this.Form.groupTypeId == 102){
                    this.Form.unitId = "865cf461-99b6-4b4f-9678-4cd11c3aab69"; //static unit (nos) for type service
                    this.Form.unitName = "Nos";
                }
                else{
                    this.Form.selectedUnit ? this.Form.unitId = this.Form.selectedUnit.itemId : '';
                }
                
                this.Form.selectedProductCategory ? this.Form.productCategoryId = this.Form.selectedProductCategory.itemId : '';
                this.Form.selectedBrand ? this.Form.brandId = this.Form.selectedBrand.itemId : '';
                this.Form.selectedCompany ? this.Form.companyId = this.Form.selectedCompany.itemId : '';

                // this.fn_clearFields();

                for(let i=0; i<this.Form.productVariants.length; i++){
                    this.Form.productVariants[i].productId = this.Form.productId;
                    this.Form.productVariants[i].variantBaseUnitId = this.Form.unitId;
                }

                console.log(this.Form);
                this.fn_saveLocal(this.Form);
            },

            // fn_clearFields: function () {

            //  if(this.Form.groupTypeId == 102){
            //      this.Form.brandId = null;
            //      this.Form.brandName = null;
            //      this.Form.companyId = null;
            //      this.Form.companyName = null;
            //      this.Form.companyBarcode = null;
            //      this.Form.sellingPrice = null;
            //      this.Form.wholeSalePrice = null;
            //      this.Form.mrp = null;
            //      this.Form.productVariants = [];
            //      this.fn_setDefaultVariant();
            //  }
            //  else{
            //      this.Form.charges = null;
            //  }
            // },

            fn_get: function(itemId, copyData){
                let self = this;
                this.unwatch = true;
                this.Form.isNew = false
                this.getData('Product', itemId, 
                    {
                        onSuccess: function(response){
                            console.log(response);
                            let data = JSON.parse(response);
                            self.fn_bindData(data, copyData);
                        }, 
                        onError: function(error){
                          console.log(error);
                        }
                    }
                );
            },

            fn_bindData: function(data, copyData){
                this.unwatch = true;
                this.Form.brandId = data.brandId;
                this.Form.brandName = data.brandName;
                this.Form.charges = data.charges;
                this.Form.companyBarcode = data.companyBarcode;
                this.Form.companyId = data.companyId;
                this.Form.companyName = data.companyName;
                this.Form.description = data.description;
                this.Form.groupTypeId = data.groupTypeId;
                this.Form.hsnCode = data.hsnCode;
                this.Form.isDefault = data.isDefault;
                this.Form.inventory = data.inventory;
                this.Form.name = data.name;
                this.Form.productCategoryId = data.productCategoryId;
                this.Form.productCategoryName = data.productCategoryName;
                this.Form.productCode = data.productCode;
                this.Form.productId = data.productId;
                this.Form.taxCategoryId = data.taxCategoryId;
                this.Form.taxCategoryName = data.taxCategoryName;

                this.Form.mrp = data.mrp;
                this.Form.sellingPrice = data.sellingPrice;
                this.Form.wholeSalePrice = data.wholeSalePrice;

                this.Form.purchaseRate = data.purchaseRate;

                this.Form.productVariants = JSON.parse(JSON.stringify(data.productVariants));

                this.Form.productVariants.length > 1 ? this.Form.isSubVariant = true : this.Form.isSubVariant = false;

                for(let i=0; i<this.Form.productVariants.length; i++){
                    if(this.Form.productVariants[i].isDefault){
                        this.Form.unitId = this.Form.productVariants[i].unitId;
                        this.Form.unitName = this.Form.productVariants[i].unitName;
                    }

                    copyData ? this.Form.productVariants[i].productVariantId = this.guid() : '';
                }


                // assigning values to autocompletes
                this.Form.selectedTaxCategory = this.Form.taxCategoryId ? {"taxCategoryId":this.Form.taxCategoryId, "taxCategoryName":this.Form.taxCategoryName} : null;
                this.Form.selectedUnit = this.Form.unitId ? {"itemId":this.Form.unitId, "itemName":this.Form.unitName} : null;
                this.Form.selectedProductCategory = this.Form.productCategoryId ? {"itemId":this.Form.productCategoryId, "itemName":this.Form.productCategoryName} : null;
                this.Form.selectedBrand = this.Form.brandId ? {"itemId":this.Form.brandId, "itemName":this.Form.brandName} : null;
                this.Form.selectedCompany = this.Form.companyId ? {"itemId":this.Form.companyId, "itemName":this.Form.companyName} : null;

                this.Form.isSystem = data.isSystem;

                if(copyData){
                    this.Form.productId = null;
                    this.Form.isSystem = 0;
                    this.fn_saveDraft(this.draftStorageId, this.Form.name);
                    // alert("Item copied");
                    this.$eventHub.$emit('snackbarAlert', {msg:'Item copied'});
                }
                else{
                    if(data.opening){
                        this.Form.openingQty = Math.abs(data.opening.qty);
                        this.Form.openingRate = data.opening.rate;
                        this.Form.transactionId = data.opening.transactionId;
                    }
                }

                this.$root.fn_copyFormObject(this.Form, this.formId);
            },

            // fn_clearForm: function(){
            //  this.Form = {"name":null, "groupTypeId":101, "productCategoryId":null, "productCategoryName":null, "description":null, "productCode":null, "charges": null,  "brandId":null, "brandName":null, "companyId":null, "companyName":null, "companyBarcode":null,
            //      "hsnCode":null,
            //      "unitId":null, "unitName":null, "title":null, "sellingPrice":null,  "mrp":null, "wholeSalePrice":null,
            //      "fixedCQty":null,

            //      "variant": {"description":null, "groupTypeId":401, "isDefault":false, "unitId":null, "productId":null, "productVariantId":null, "title":null, "variantBaseUnitId":null, "unitName":null, "packQuantity":null}, 

            //      "productVariants":[],
            //      "isSubVariant": false,
            //      "taxCategoryId":null,
            //      "taxCategoryName":null,
            //      "inventory":1,
            //      "isSystem":0,
            //      "draftItems":[],
            //      "createdDate":null, 
            //      "modifiedDate":null,
            //      "isNew":true
            //  },
            //  this.$validator.reset();
            // },

            // fn_menuItemClick: function(tagId, menuId, menuAction){
            //  // tagId id form future use
            //  // menuId id form future use
            //  this.backButtonPress = false;
                
            //  if(menuAction=='save'){
            //      this.fn_checkValidations();
            //  }
            //  else if(menuAction=='newCategory'){
            //      this.$router.push({path:'/masters/inventory/product/categories/new', query:{tagId:this.guidWeb(), ls:1}})
            //  }
            //  else if(menuAction=='newBrandListItem'){
            //      this.$router.push({path:'/masters/lists/'+listIdObject.brandListGUID+'/items/new', query:{tagId:this.guidWeb(), listTitle:"Brand", ls:1, type:'brand'}})
            //  }
            //  else if(menuAction=='newCompanyListItem'){
            //      this.$router.push({path:'/masters/lists/'+listIdObject.companyListGUID+'/items/new', query:{tagId:this.guidWeb(), listTitle:"Company", ls:1, type:'company'}})
            //  }
            //  else if(menuAction=='newUnit'){
            //      this.$router.push({path:'/inventory/units/new', query:{tagId:this.guidWeb(), ls:1}})
            //  }
            //  else if(menuAction=='newSubUnit'){
            //      this.$router.push({path:'/inventory/units/new', query:{tagId:this.guidWeb(), baseUnitId:this.Form.unitId, baseUnitName:this.Form.unitName, ls:1}})
            //  }
            //  else if(menuAction=='reset'){
            //      this.fn_resetForm();
            //  }
            // },

            // fn_resetForm: function () {
            //  this.unwatch = true;
            //  // this.loadedFromDraft = false;

            //  if(this.Form.productId){
            //      this.fn_get(this.Form.productId);
            //  }
            //  else{
            //      this.fn_clearForm();
            //  }

            //  let self = this;
            //  this.fn_removeDraft(this.draftStorageId, function(){
            //      // self.showToast("Item deleted from draft");
            //      console.log("draft deleted");
            //  });
            // },

            // fn_showWebView: function () {
            //  var viewObject = {};
            //  viewObject.ToolbarTitle = "Item";
            //  viewObject.TopMenu = [menuItem.save];
            //  viewObject.isBottomMenu = true;
            //  var tagId = this.formId;
            //  var self = this;
            //  this.showWebView(tagId, viewObject, 
            //      {
            //          onSuccess: function(response){
            //            console.log(response);
            //          }, 
            //          onError: function(error){
            //            console.log(error);
            //          }, 
            //          // onChange: null,
            //          onMenuItemClick: function (tagId, menuId, menuAction) {
            //              self.fn_menuItemClick(tagId, menuId, menuAction)
            //          }
            //      }
            //  );
            // },

            // fn_setProductCode: function (code) {
            //  this.showToast(code);
            //  console.log(code);
            //  this.Form.productCode = code;
            // },

            fn_setNewItem: function(){
                if(localStorage.getItem('newItem')){

                    let itemData = JSON.parse(localStorage.getItem('newItem'));

                    switch (itemData.type) {
                        case "category":
                            // this.fn_updateCategory(itemData.id, itemData.name, false);
                            this.Form.selectedProductCategory = {"itemId":itemData.id, "itemName":itemData.name};
                            break;
                        case "brand":
                            // this.fn_updateBrand(itemData.id, itemData.name, false);
                            this.Form.selectedBrand = {"itemId":itemData.id, "itemName":itemData.name};
                            break;
                        case "company":
                            // this.fn_updateCompany(itemData.id, itemData.name, false);
                            this.Form.selectedCompany = {"itemId":itemData.id, "itemName":itemData.name};
                            break;
                        case "taxCategory":
                            // this.fn_updateTaxCategory(itemData, true)
                            this.Form.selectedTaxCategory = {"taxCategoryId":itemData.taxCategoryId, "taxCategoryName":itemData.taxCategoryName};
                            break;
                        case "unit":
                            this.fn_updateUnit(itemData.id, itemData.name, false);
                            break;
                        case "subUnit":
                            this.fn_updateSubUnit(itemData.id, itemData.name, false);
                            break;
                        default:
                            console.log("condition not matched");
                    }

                    localStorage.removeItem('newItem');
                }

                this.Form.isNew ? this.$root.fn_copyFormObject(this.Form, this.formId) : '';

                if(this.Form.selectedProductCategory || this.Form.selectedBrand || this.Form.description || this.Form.selectedCompany || this.Form.companyBarcode){
                    this.isExpanded = 0;
                }
                else{
                    this.isExpanded = null;
                }
            },

            fn_copyLastConfigs: function(){
                if(this.lastItemConfig.taxCategoryId){
                    this.fn_updateTaxCategory(this.lastItemConfig);
                }
                if(this.lastItemConfig.hsnCode){
                    this.Form.hsnCode = this.lastItemConfig.hsnCode;
                }
                this.showSnackbarCopy = false;
            },

            fn_lastConfig: function(){
                let self = this;
                this.getData('ProductLastConfig', null, {
                    onSuccess: function(response){
                        console.log(response);
                        self.lastItemConfig = JSON.parse(response);
                    }, 
                    onError: function(error){
                      console.log(error);
                    }
                });
            },

            fn_changeDefaultUnit: function(){
				if(this.Form.groupTypeId == 101){
					this.fn_updateUnit(defaultUnit.pieces.id, defaultUnit.pieces.name, false);
				}
				else{
					this.fn_updateUnit(defaultUnit.nos.id, defaultUnit.nos.name, false);
				}
			},

            fn_goBack: function(){
                this.$root.viaKeyboardShortCut = false;
                this.$refs['ref_dialogForm'].closeDialogWeb();
                localStorage.removeItem(this.draftStorageId);
                this.backButtonPress = true;
                setTimeout(() => {
                    if(this.nextRoute.path){
                        this.nextRoute.query ? this.$router.replace({path:this.nextRoute.path, query:this.nextRoute.query}) : this.$router.replace({path:this.nextRoute.path});
                    }
                    else{
                        this.$router.go(-1);
                    }
                }, 50);
            }
        },
        mounted: function(){
            var self = this;

            if(this.$route.query && this.$route.query.tagId){//in case of new form
                this.formId = this.$route.query.tagId;
                this.draftStorageId = 'draft_master_'+this.formId;
                // this.dialogStorageId = 'dialog_'+this.formId;
                this.unwatch = true;

                //updating default unit as pieces
                // this.fn_updateUnit(defaultUnit.pieces.id, defaultUnit.pieces.name, false);
                this.fn_changeDefaultUnit();

                if(!self.$route.query.copy){
                    if(this.$root.CurrentBusiness && this.$root.CurrentBusiness.GSTRegistrationStatus==1){
                        //updating taxcategory as per last used in case of register business
                        this.fn_lastConfig();
                    }
                    else{
                        //updating taxcategory to zero in case of composite/unregister business
                        // this.Form.taxCategoryId = taxCategoryIds.gst_0.id;
                        this.Form.selectedTaxCategory = {"taxCategoryId":taxCategoryIds.gst_0.id, "taxCategoryName":taxCategoryIds.gst_0.name};
                    }    
                }
                
            }
            else if(this.$route.params && this.$route.params.tagId){//in case of edit form
                this.formId = this.$route.params.tagId;
                this.draftStorageId = 'draft_master_'+this.formId;
            }



            if(localStorage.getItem(this.draftStorageId)){
                let dataObj = JSON.parse(localStorage.getItem(this.draftStorageId));
                this.Form = JSON.parse(dataObj.formData);
            }

            if(this.$route.query.groupTypeId != undefined){
                this.unwatch = true;
                this.Form.groupTypeId = Number(this.$route.query.groupTypeId);
            }

            if(this.$route.query.inventory != undefined){
                this.unwatch = true;
                this.Form.inventory = Number(this.$route.query.inventory);
            }

            this.fn_getTaxCategories();

            setTimeout(function(){self.fn_setNewItem()},300);

            this.fn_units();
            this.fn_productCategories();
            this.fn_listItems('', 'brand');
            this.fn_listItems('', 'company');
            this.$refs['ref_dialogForm'].openDialogWeb();

            this.$eventHub.$on('save', () => {
                this.$refs['ref_dialogForm'].fn_validateForm();
            });

            this.$eventHub.$on('escape', () => {
                this.fn_closeDialogForm();
            });
        },
        computed:{
            toolbarTitle: function(){
                if(this.Form.inventory)
                    return "Item";
                else
                    return "Expense Item";
            },
            totalAmount: function(){
                if(this.Form.openingRate){
                    return this.roundUpto2Decimals(this.Form.openingRate * this.Form.openingQty);
                }
                else{
                    return 0;
                }
            }
        },
        
        beforeRouteEnter (to, from, next) {
            next(vm => {
                // vm.enableToolbar();
                
                let itemId = null;
                let copyData = false;

                // to.query && to.query.copy && from.name == 'productDisplay' ? copyData = true : '';
                to.query && to.query.copy ? copyData = true : '';

                // if(to.query && to.query.itemId && from.name == 'productDisplay'){
                if(to.query && to.query.itemId){
                    itemId = to.query.itemId;
                }

                if(to.params && to.params.tagId){//from display view
                  itemId = to.params.tagId;
                }

                if(vm.$route.query && vm.$route.query.tagId){//in case of new form
                    vm.formId = vm.$route.query.tagId;
                    vm.draftStorageId = 'draft_master_'+vm.formId;
                }
                else if(vm.$route.params && vm.$route.params.tagId){//in case of edit form
                    vm.formId = vm.$route.params.tagId;
                    vm.draftStorageId = 'draft_master_'+vm.formId;
                }

                if(itemId){
                    !localStorage.getItem(vm.draftStorageId) ? vm.fn_get(itemId, copyData) : '';
                }

                let isCalled = false;
                vm.$eventHub.$on('connected', () => {
                    setTimeout(() => {
                        if(!isCalled){
                            isCalled = true;
                            vm.disableOnSave = false;
                            vm.saveBtnKey++;
                            !vm.units ? vm.fn_units() : '';
                            !vm.productCategories ? vm.fn_productCategories() : '';
                            !vm.brands ? vm.fn_listItems('', 'brand') : '';
                            !vm.companies ? vm.fn_listItems('', 'company') : '';
                            itemId ? vm.fn_get(itemId, copyData) : '';
                        }

                        setTimeout(() => {
                            isCalled = false;
                        }, 3000);
                    }, 1000);
                });
            })
        },
        beforeRouteLeave (to, from, next) {
            if(this.$root.viaKeyboardShortCut){
                this.nextRoute.path = to.path;
                this.nextRoute.query = to.query;
                this.fn_closeDialogForm();
            }
            else{
                this.nextRoute.path = null;
                this.nextRoute.query = null;
                this.$eventHub.$off('save');
                this.$eventHub.$off('escape');
                this.$eventHub.$off('item');
                this.$eventHub.$off('connected');
                next();
            }
        },
        // beforeRouteUpdate (to, from, next) {
        //  this.enableToolbar();

        //  var dialogData = JSON.parse(localStorage.getItem(this.dialogStorageId));
        //  var existingForm = false;
        //  if(dialogData && dialogData.length){
        //      var openForms = JSON.parse(localStorage.getItem('productforms'));
        //      for(var i=0; i<openForms.length; i++){
        //          if(openForms[i]==to.query.tagId){
        //              existingForm = true;
        //              dialogjs.fn_checkAndCloseOpenDialogs(this);
        //              break;
        //          }
        //      }
        //      existingForm ? next(false) : next(true);
        //  }
        //  else{
        //      next();
        //  }
     //    },
        // beforeRouteLeave (to, from, next) {
        //  var dialogData = JSON.parse(localStorage.getItem(this.dialogStorageId));
        //  if(dialogData && dialogData.length){
        //      if(this.backButtonPress){
        //          dialogjs.fn_checkAndCloseOpenDialogs(this);
        //          next(false);
        //      }
        //      else{
        //          // this.$root.fn_deleteFormObject();
        //          this.fn_saveDraft(this.Form.name);
        //          next(true);
        //      }
        //  }
        //  else{
        //      // if(this.askToDiscard){
        //      //  this.fn_openDialogDiscard();
        //      //  next(false);
        //      // }
        //      if(this.fn_showDialogDiscard()){
        //          this.fn_openDialogDiscard();
        //          next(false);
        //      }
        //      else{
        //          this.fn_clearData('productforms', function(){
        //              next();
        //          })
        //      }
        //      // if(this.loadedFromDraft || (this.$root.fn_getFormObject(this.formId) && !this.$root.fn_deepEqual(this.Form, JSON.parse(this.$root.fn_getFormObject(this.formId))))){
        //      //  this.fn_openDialogDiscard();
        //      //  next(false);
        //      // }
        //      // else{
        //      //  this.$root.fn_deleteFormObject(this.formId);
        //      //  !this.askToDiscard ? this.fn_removeDraft(this.draftStorageId, null, -1) : '';
        //      //  localStorage.removeItem('productforms')
        //      //  next(); 
        //      // }
        //  }
     //    }
    }

</script>