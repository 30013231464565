<template>
    <div full-height grey-back>

        <dialog-form ref="ref_dialogForm" :closeDialog="fn_closeDialogForm"></dialog-form>

        <v-snackbar :timeout="0" v-model="msgAlert">Something went wrong. Try again
            <v-btn text color="primary" @click.native="fn_checkValidations">retry</v-btn>
        </v-snackbar>
    </div>
</template>

<script>

import dialogForm from './dialogs/dialog_form'

    export default{
        data(){
            return{
                Form:{"BusinessID":null, "BusinessName":null, "UserName":null, "Address":null, "BusinessProfileNumber":null, "GSTNumber":null, "GSTRegistrationDate":null, "HSNApplicable":false, "businessLogo":null, "UserType":null, "IsAccountantOnly":null, "Email":null},
                registrationTypes:[{"status":1, "name":"Registered - Regular"},{"status":2, "name":"Registered - Composite"},{"status":3, "name":"Unregistered"}],
                msgAlert:false,
                FormCopy:null,
                disableBtn:false,
                businessData:null,

                selectedRegistationType:null,
                selectedTaxMode:null,
                selectedState:null,
                disableOnSave:false,
                saveBtnKey:1
            }
        },
        components:{
            // 'dialog-date-picker': dialogDatepicker,
            // 'dialog-confirmation': dialogConfirmation,
            // 'dialog-discard': dialogDiscard,
            // 'dialog-registration-types':dialogRegistrationTypes
            'dialog-form': dialogForm
        },
        methods: {

            fn_closeDialogForm: function(){
                this.$refs['ref_dialogForm'].closeDialogWeb();
                setTimeout(()=>{
                    this.fn_goBack();
                })
            },

            fn_updateDate: function (value) {
                this.Form.GSTRegistrationDate = this.$moment(value).format('DD-MMM-YYYY');
                // this.errors.remove('wef');
                // this.fn_closeDialog();
            },

            // fn_openDatepicker: function () {
            //  this.fn_openDialogWeb('web', 'OTP', 'ref_dialogDatePicker');
            // },

            fn_checkValidations: function(){
                if(this.selectedRegistationType.status==3){
                    this.Form.GSTNumber = null;
                    this.Form.GSTRegistrationDate = null;
                    this.Form.HSNApplicable = false;
                }
                else if(this.selectedRegistationType.status==2){
                    this.Form.HSNApplicable = false;
                }
                else{
                    this.Form.HSNApplicable = true;
                }

                // this.fn_updateBusinessDetail();
                this.fn_getToken();
            },

            fn_getBusinessMasterData: function(){
                let self = this;
                this.getData('BusinessDetail', this.$root.CurrentBusiness.BusinessID, 
                    {
                      onSuccess: function(response){
                          console.log(response);
                          self.businessData = JSON.parse(response);
                      }, 
                      onError: function(error){
                          console.log(error);
                      }
                    }
                );
            },

            fn_updateBusinessDetail: function(){
                // this.fn_closeDialog();

                let self = this;

                let postObj = JSON.parse(JSON.stringify(this.businessData));

                
                postObj.BusinessID =  this.Form.BusinessID;
                postObj.BusinessName =  this.Form.BusinessName.trim();
                postObj.UserName =  this.Form.UserName;
                postObj.Address =  this.Form.Address;
                // postObj.StateID =  this.Form.StateID;
                // postObj.State =  this.Form.State;
                postObj.StateID =  this.selectedState.itemId;
                postObj.State =  this.selectedState.itemName;
                postObj.BusinessProfileNumber =  this.Form.BusinessProfileNumber;
                // postObj.GSTRegistrationStatus =  this.Form.GSTRegistrationStatus;
                postObj.GSTRegistrationStatus =  this.selectedRegistationType.status;
                postObj.GSTNumber =  this.Form.GSTNumber;
                // postObj.TaxMode =  this.Form.TaxMode;
                postObj.TaxMode =  this.selectedTaxMode.value;
                postObj.HSNApplicable =  this.Form.HSNApplicable;
                postObj.Email =  this.Form.Email;

                postObj.UserType =  this.Form.UserType;
                postObj.IsAccountantOnly =  this.Form.IsAccountantOnly;

                postObj.GSTRegistrationDate = postObj.GSTRegistrationDate ? new Date(postObj.GSTRegistrationDate) : null;
                postObj.GSTNumber ? postObj.GSTNumber = postObj.GSTNumber.toUpperCase() : '';
                postObj.ModifiedOn = this.$root.fn_getLocalDateFormat();

                let businessStatusOld = this.$root.CurrentBusiness.GSTRegistrationStatus;

                console.log(JSON.stringify(postObj));
                this.postData('UpdateBusinessDetail', postObj, 
                    {
                        onSuccess: function(response){
                            console.log(response);
                            // self.hideLoading();
                            let toastMsg = self.$root.CurrentBusiness.IsAccountantOnly ? 'Details updated' : 'Business profile updated';
                            // alert(toastMsg);
                            self.$eventHub.$emit('snackbarAlert', {msg:toastMsg});
                            // self.confirmExit = true;
                            self.$root.fn_getBusiness('goback', businessStatusOld);
                        }, 
                        onError: function(error){
                            // self.hideLoading();
                            // alert('Something went wrong. Try again');
                            // self.$eventHub.$emit('snackbarAlert', {msg:'Something went wrong. Try again'});
                            self.msgAlert = true;
                            self.saveBtnKey++;
                            console.log(error);
                        }, 
                    }
                );
            },

            fn_getToken: function(){
                this.$root.fn_getAuthToken(token=>{
                    if(token=='error'){
                        this.disableOnSave = false;
                        this.saveBtnKey++;
                        return;
                    }

                    this.fn_updateBusinessDirect(token);
                });
                // this.$root.fn_getAuthToken(function(token){
                //     if(token == 'error'){
                //         self.hideLoading();
                //         self.showToast("Something went wrong");
                //         return;
                //     }

                //     self.fn_updateBusinessDirect(token);
                // })
            },

            fn_updateBusinessDirect: function(token){
                let self = this;
                let postObj = JSON.parse(JSON.stringify(this.businessData));
                postObj.BusinessID =  this.Form.BusinessID;
                postObj.BusinessName =  this.Form.BusinessName.trim();
                postObj.UserName =  this.Form.UserName;
                postObj.Address =  this.Form.Address;
                postObj.StateID =  this.selectedState.itemId;
                postObj.State =  this.selectedState.itemName;
                postObj.BusinessProfileNumber =  this.Form.BusinessProfileNumber;
                postObj.GSTRegistrationStatus =  this.selectedRegistationType.status;
                postObj.GSTNumber =  this.Form.GSTNumber;
                postObj.TaxMode =  this.selectedTaxMode.value;
                postObj.HSNApplicable =  this.Form.HSNApplicable;
                postObj.UserType =  this.Form.UserType;
                postObj.IsAccountantOnly = this.Form.IsAccountantOnly;
                postObj.Email =  this.Form.Email;
                postObj.GSTRegistrationDate = postObj.GSTRegistrationDate ? new Date(postObj.GSTRegistrationDate) : null;
                postObj.GSTNumber ? postObj.GSTNumber = postObj.GSTNumber.toUpperCase() : '';
                postObj.ModifiedOn = this.$root.fn_getLocalDateFormat();

                postObj.token = token;

                let businessStatusOld = this.$root.CurrentBusiness.GSTRegistrationStatus;

                // console.log(JSON.stringify(postObj));

                this.postServerData('UpdateBusinessDirect', postObj, {
                    onSuccess: function(response){
                        console.log(response);
                        let toastMsg = self.$root.CurrentBusiness.IsAccountantOnly ? 'Details updated' : 'Business profile updated';
                        self.$eventHub.$emit('snackbarAlert', {msg:toastMsg});
                        self.$root.fn_getBusiness(null, businessStatusOld, ()=>{
                            self.fn_goBack();
                        },true);
                        // self.confirmExit = true;
                        // self.$root.fn_getBusiness(null, null, function(){
                        //     self.$root.fn_pushDefaultJson(function(){
                        //         self.hideLoading();
                        //         self.showToast('Business profile updated');
                        //         self.$router.replace('/actions');
                        //     })
                        // });
                    }, 
                    onError: function(error){
                        console.log(error);
                        self.msgAlert = true;
                        self.disableOnSave = false;
                        self.saveBtnKey++;
                    //    if(typeof JSON.parse(error) == 'object'){
                    //      let response = JSON.parse(error);
                    //      self.$eventHub.$emit('snackbarAlert', {msg:response.Message});
                    //    }
                    }, 
                })

            },

            fn_bindData: function(){
                this.Form.BusinessID = this.$root.CurrentBusiness.BusinessID;
                this.Form.BusinessName = this.$route.query.usertype ? null : this.$root.CurrentBusiness.BusinessName;
                this.Form.UserName = this.$root.CurrentBusiness.UserName;
                this.Form.Address = this.$root.CurrentBusiness.Address;
                // this.Form.StateID = this.$root.CurrentBusiness.StateID;
                // this.Form.State = this.$root.CurrentBusiness.State;
                this.selectedState = {"itemId":this.$root.CurrentBusiness.StateID, "itemName":this.$root.CurrentBusiness.State};

                this.Form.BusinessProfileNumber = this.$root.CurrentBusiness.BusinessProfileNumber;
                // this.Form.GSTRegistrationStatus = this.$root.CurrentBusiness.GSTRegistrationStatus;
                this.selectedRegistationType = {"status":this.$root.CurrentBusiness.GSTRegistrationStatus, "name":this.$root.CurrentBusiness.GSTRegistrationStatus == 1 ? "Registered - Regular" : this.$root.CurrentBusiness.GSTRegistrationStatus == 2 ? "Registered - Composite" : "Unregistered"};

                this.Form.GSTNumber = this.$root.CurrentBusiness.GSTNumber;
                // this.Form.TaxMode = this.$root.CurrentBusiness.TaxMode;
                this.selectedTaxMode = {"value":this.$root.CurrentBusiness.TaxMode, "title":this.$root.CurrentBusiness.TaxMode == 1 ? "Exclusive" : this.Form.TaxMode == 2 ? "Inclusive" : "None"};

                this.$root.CurrentBusiness.GSTRegistrationDate ? this.fn_updateDate(this.$root.CurrentBusiness.GSTRegistrationDate) : '';
                this.Form.HSNApplicable = this.$root.CurrentBusiness.HSNApplicable;
                this.Form.Email = this.$root.CurrentBusiness.Email;

                this.Form.UserType = this.$route.query.usertype ? this.$route.query.usertype : this.$root.CurrentBusiness.UserType;
                this.Form.IsAccountantOnly = this.$root.CurrentBusiness.IsAccountantOnly;
                this.FormCopy = JSON.parse(JSON.stringify(this.Form));
            },

            fn_goBack: function(){
                this.$router.go(-1);
            }
        },
        
        mounted: function(){

            this.$refs['ref_dialogForm'].openDialogWeb();

            this.fn_bindData();
            this.fn_getBusinessMasterData();

            let isCalled = false;
            this.$eventHub.$on('connected', () => {
                setTimeout(() => {
                    if(!isCalled){
                        isCalled = true;
                        this.disableOnSave = false;
                        this.saveBtnKey++;
                        this.fn_getBusinessMasterData();
                    }

                    setTimeout(() => {
                        isCalled = false;
                    }, 3000);
                }, 1000);
            });
        },

        computed:{
            registrationType: function(){
                let type = null;
                for(let i in this.registrationTypes){
                    if(this.Form.GSTRegistrationStatus == this.registrationTypes[i].status){
                        type = this.registrationTypes[i].name;
                        break;
                    }
                }
                return type;
            }
        },
        beforeRouteLeave(to, from, next){
            this.$eventHub.$off('connected');
            next(true)
        },

      //    beforeRouteLeave (to, from, next) {
      //    var dialogData = JSON.parse(localStorage.getItem(this.dialogStorageId));
            // if(dialogData && dialogData.length){
            //  dialogjs.fn_checkAndCloseOpenDialogs(this);
            //  next(false);
            // }
            // // else if(!this.confirmExit){
            // //   this.actionMethod = this.fn_confirmExit;
            // //   this.header = "Quit Updates?"
            // //   this.fn_openDialogConfirmation();
            // //   next(false);
            // // }
            // else if(this.confirmExit){
            //  next();
            // }
            // else if(!this.$root.fn_deepEqual(this.Form, this.FormCopy)){
            //  // this.actionMethod = this.fn_confirmExit;
            //  // this.header = "Quit Updates?"
            //  // this.fn_openDialogConfirmation();
            //  this.fn_openDialogDiscard();
            //  next(false);
            // }
            // else{
            //  next();
            // }
      //   }
    }
</script>